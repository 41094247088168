import React, {useEffect, useState} from "react";

import {twMerge} from "tailwind-merge";
import {v4 as uuidv4} from "uuid";

import ProgressBar from "../ProgressBar";
import Chat from "./Chat";
import ChatInput from "./ChatInput";

import {IChatItem, useAICoaching} from "../../utils/AICoachingContext";
import {getChatHistory, getSuggestions, processChatPrompt,} from "../../actions";

import {AIModel} from "../../enums";
import Suggestions from "../Suggestions";
import ChatEndActions from "./ChatEndActions";
import {ArrowCircleDownIcon, ArrowCircleUpIcon} from "@heroicons/react/solid";
import {AnimatePresence, motion} from "framer-motion";
import Spinner from "./Spinner";
import {useTranslation} from "react-i18next";
import {AI_FLASH_TOTAL_STEPS, AI_JOURNEY_TOTAL_STEPS, AI_SESSION_TOTAL_STEPS} from "../../../../../../constants";


const ChatBox = () => {
    const {
        messages,
        setMessages,
        addMessage,
        removeMessage,
        lastChangedIndex,
        isLoading,
        setIsLoading,
        setChatPrompt,
        state,
        selectedThreadItem,
        setSelectedThreadItem,
        setCustomChatSection,
        createNewThread,
        isStartState,
        setIsStartState
    } = useAICoaching();

    const {t} = useTranslation("ai_coaching");

    const [currentChatStatus, setCurrentChatStatus] = useState({lastStep: false, step: 0});
    const [isHistoryLoading, setIsHistoryLoading] = useState(false)

    useEffect(() => {
        // if (selectedThreadItem && selectedThreadItem.isNew) setMessages([])
        if (selectedThreadItem && !selectedThreadItem.isNew) fetchHistory(selectedThreadItem?.id);
    }, [selectedThreadItem?.id]);


    const fetchHistory = async (id: number) => {
        setIsHistoryLoading(true)
        setMessages([])
        try {
            // Get this id from the selected overview item

            const historyData = await getChatHistory(id);
            const history: IChatItem[] = historyData.history
                .map(
                    (chatItem: any) => {
                        if (chatItem.content.trim() != "") {
                            return ({
                                id: uuidv4(),
                                content: chatItem.content.trim(),
                                role: chatItem.role === "user" ? "me" : "assistant",
                            } as IChatItem)
                        } else {
                            return ({
                                id: uuidv4(),
                                content: () => {
                                },
                                role: "system",
                            } as IChatItem)
                        }

                    }
                )
                .reverse();

            if (historyData && historyData.history.at(-1))
                setCurrentChatStatus({lastStep: historyData.last_step, step: historyData.history.at(-1).step})


            if (selectedThreadItem?.prevThread) {
                history.push({
                    id: uuidv4(),
                    content: () => <button
                        onClick={() => setSelectedThreadItem(selectedThreadItem?.prevThread!)}
                        className="flex gap-2 items-center px-2 py-1 mx-auto bg-black text-white rounded-md hover:text-red">See
                        prev
                        session <ArrowCircleUpIcon className="w-4 h-4"/></button>,
                    role: "system",
                } as IChatItem)
            }

            if (selectedThreadItem?.nextThread) {
                history.unshift({
                    id: uuidv4(),
                    content: () => <button
                        onClick={() => setSelectedThreadItem(selectedThreadItem?.nextThread!)}
                        className="flex gap-2 items-center px-2 py-1 mx-auto bg-black text-white rounded-md hover:text-red">See
                        next
                        session <ArrowCircleDownIcon className="w-4 h-4"/></button>,
                    role: "system",
                } as IChatItem)
            }
            if (historyData.last_step && selectedThreadItem)
                setCustomChatSection(<ChatEndActions
                    schedule={historyData.schedule}
                    aiModel={selectedThreadItem.aiModel}
                    journeyId={selectedThreadItem.aiModel == 'journey' ? selectedThreadItem.id : selectedThreadItem.journeyId}
                    threadID={selectedThreadItem.id}
                    summaryAvailable={historyData.summary_created}/>)

            setMessages(history);
        } catch (e) {
            console.log(e);
        } finally {
            setIsHistoryLoading(false);
        }
    };

    const handlePrompt = async (prompt: string, callback: () => void) => {
        setCustomChatSection(undefined)
        try {
            const chatItem = {
                id: uuidv4(),
                content: prompt,
                role: "me",
            } as IChatItem;

            setIsLoading(true);
            addMessage(chatItem);
            callback();
            // debugger
            // Get this id from the selected overview item. Else null
            let id = selectedThreadItem?.id;
            if (!id) {
                if (state == 'flash') {
                    // Dynamically call createSession/createJourney with dynamic ChatType
                    const newChat = await createNewThread(AIModel.flash);
                    id = newChat.id as number;
                } else {
                    throw new Error(`cannot create new thread `);
                }

            }

            const promptResponse = await processPrompt(id, prompt);
            setCurrentChatStatus({lastStep: promptResponse.last_step, step: promptResponse.step})
            // setSelectedThreadItem(prevItem => prevItem && prevItem.isNew ? ({
            //     ...prevItem,
            //     isNew: false
            // }) : prevItem);

            addMessage({
                id: Math.random().toString(),
                content: promptResponse.reply,
                role: "assistant",
            });
            if (promptResponse.last_step && selectedThreadItem)
                setCustomChatSection(<ChatEndActions aiModel={selectedThreadItem.aiModel}
                                                     journeyId={selectedThreadItem.aiModel == 'journey' ? selectedThreadItem.id : selectedThreadItem.journeyId}
                                                     threadID={selectedThreadItem.id}
                                                     summaryAvailable={false}
                />)
            if (!promptResponse.last_step) await processSuggestions(id)
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
            setIsStartState(false);
        }
    };

    const processSuggestions = async (id: number) => {
        getSuggestions(id).then((data) => {
            const suggestions = data?.suggestions?.map((item: string) => {
                return {
                    title: item,
                };
            });
            if (!isLoading && suggestions) {
                setCustomChatSection(<Suggestions onSelectSuggestion={(suggestion) => setChatPrompt(suggestion)}
                                                  suggestions={suggestions} collapsed={false}/>)
            }
        });
    }
    const processPrompt = async (id: number, prompt: string) => {
        return await processChatPrompt(id, prompt);
    };

    const maxStep = () => {
        if (selectedThreadItem?.aiModel == AIModel.journey) {
            return AI_JOURNEY_TOTAL_STEPS
        } else if (selectedThreadItem?.aiModel == AIModel.session) {
            return AI_SESSION_TOTAL_STEPS
        } else {
            return AI_FLASH_TOTAL_STEPS
        }
    }
    return (
        <div className="h-full flex flex-col relative overflow-x-hidden ">
            <ProgressBar maxSteps={maxStep()} currentStep={currentChatStatus.step}/>
            {/*<button onClick={() => createNewThread(AIModel.session, 485)}>btn</button>*/}
            {/*<button onClick={() => setCustomChatSection(<Questions onSelectQuestion={()=>{}}/>)}>btn</button>*/}
            {/*<button onClick={() => setCustomChatSection(undefined)}>btn</button>*/}
            <div className="h-full flex-auto flex flex-col px-3 pt-4">
                <div
                    className={twMerge(
                        "flex flex-col-reverse", //If you need to start the chat by bottom
                        "overflow-y-scroll h-full scrollbar-hide "
                    )}
                >
                    <AnimatePresence>
                        {isHistoryLoading ? <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}} className="flex flex-col w-full items-center justify-center h-full ">
                            <Spinner size="42" className=""/>

                        </motion.div> : <Chat/>}
                    </AnimatePresence>

                </div>
                {/*Input section*/}
                <div className={twMerge("py-3 duration-300 transition-opacity")}>
                    <ChatInput
                        isLoading={isLoading}
                        onNewPrompt={(prompt, callback) => handlePrompt(prompt, callback)}
                        placeholder={t("chatbox.text7")}
                    />
                </div>
            </div>
        </div>
    );
};

export default ChatBox;
