import { useTranslation } from "react-i18next";

import CoachWebsite from "./../../../../../coaching/pages/Coaching/components/CoachWebsite";

import ICoach from "../../../../../../models/ICoachAI";
import { ELanguageFr } from "../../../../../../constants/meta/ELanguageFr";
import { ESpecializationFr } from "../../../../../../constants/meta/ESpecializationFr";
import { ELocationFr } from "../../../../../../constants/meta/ELocationFr";

import locationIcon from "@img/mentoring/location_purple.svg";
import languageIcon from "@img/mentoring/languages_purple.svg";
import playIcon from "@img/coaching/icons/play-button.png";

type Props = {
  coach: ICoach;
  isSelected: boolean;
  onSelect?: () => void;
  playCoachVideo: (url: string, title: string) => void;
  displayExpertType?:boolean
};

const CoachCard = ({ coach, isSelected, onSelect, playCoachVideo, displayExpertType }: Props) => {
  const lng = localStorage.getItem("I18N_LANGUAGE") || "en";
  const { t } = useTranslation("booking");

  let city = coach.city ? coach.city : "-";

  if (lng === "fr" && ELocationFr[city as keyof typeof ELocationFr]) {
    city = ELocationFr[city as keyof typeof ELocationFr];
  }

  return (
    <div className="bg-white rounded-md relative h-max">
      <div className="block w-full overflow-hidden bg-gray-100 rounded-t-lg group h-[200px]">
        <div
          className="object-contain overflow-hidden bg-center bg-cover flex justify-end items-end group h-full relative"
          style={{ backgroundImage: `url(${coach.image_url})` }}
        >
          {coach.website ? (
            <div className="absolute top-2 right-2 z-10 cursor-pointer">
              <CoachWebsite link={coach.website} />
            </div>
          ) : null}
          <div className="flex items-center justify-start mb-2 absolute z-10">
            {coach.popular ? (
              <span className="px-1 mr-2 font-bold text-gray font-dm_sans text-xxs bg-yellow rounded">
                {t("coach_card.popular")}
              </span>
            ) : null}
            {coach.recommended ? (
              <span className="px-1 mr-2 font-bold text-gray font-dm_sans text-xxs bg-warm rounded">
                {t("coach_card.recommended")}
              </span>
            ) : null}
          </div>
          {coach.video_url ? (
            <div
              className="absolute hidden items-center justify-center cursor-pointer
                    right-0 left-0 top-0 bottom-0 group-hover:flex bg-transparent"
              onClick={() =>
                playCoachVideo(coach.video_url, t("coach_card.coach_video"))
              }
            >
              <img className="object-contain w-8 h-8" src={playIcon} alt="" />
            </div>
          ) : null}
        </div>
      </div>
      <div className="px-4 py-4 flex-1 flex flex-col justify-between h-max min-h-[315px]">
        <div>
          <div className="flex items-center justify-start mb-2">
            <p className="block pb-2 mr-3 font-bold text-black font-dm_sans text-sm truncate pointer-events-none">
              {coach.first_name} {coach.last_name}
            </p>
          </div>
          <p className="flex items-center font-bold text-gray font-dm_sans text-xxs">
            <img
              className="inline-flex mr-3 h-5 w-5"
              src={locationIcon}
              alt=""
            />
            <span>{city}</span>
          </p>
          <p className="h-12 flex items-center font-bold text-gray font-dm_sans text-xxs overflow-hidden">
            <img
              className="inline-flex mr-3 h-5 w-5"
              src={languageIcon}
              alt=""
            />
            <span>
              {coach.languages
                .map((language) =>
                  lng === "fr"
                    ? ELanguageFr[language.language as keyof typeof ELanguageFr]
                    : language.language
                )
                .join(" / ")}
            </span>
          </p>
          <div className="h-max">
            <div className="w-full flex flex-wrap relative">
              {coach.specializations.map((specialization) => {
                return (
                  <span
                    key={specialization.id}
                    className="px-1.5 py-0.5 mb-1 mr-1  font-bold text-gray font-dm_sans text-xxs rounded bg-warm"
                  >
                    {lng === "fr"
                      ? ESpecializationFr[
                          specialization.specialization as keyof typeof ESpecializationFr
                        ]
                      : specialization.specialization}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center mt-3">
          <button
            className={`w-full py-4 text-center font-bold text-white font-dm_sans text-xs
              rounded  ${coach.isMentor?"bg-[#3940AB]":"bg-red"}`}
            onClick={onSelect}
          >
            {!displayExpertType ? "Book a session" : coach.isMentor ? "Choose as my Mentor":"Choose as my Coach"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CoachCard;
