import { useTranslation } from "react-i18next";

import CoachWebsite from "./CoachWebsite";

import { useConfig } from "../../../../../utils/providers/AppConfigProvider";

import timeIcon from "@img/coaching/icons/hourglass.svg";
import calendarIcon from "@img/coaching/icons/date.svg";
import clockIcon from "@img/coaching/icons/time.svg";
import SessionDurationSelector from "./SessionDurationSelector";
import { ITimeSlot } from "../../../../../models/IBooking";

type TProfileCard = {
  name?: string;
  date?: string;
  time?: string;
  imageUrl?: string;
  website?: string;
  isOrientation?: boolean;
  onSelectDuration?: (duration: number) => void;
  timeSlot?: ITimeSlot | null;
};

const ProfileCard = ({
  imageUrl,
  name,
  website,
  date,
  time,
  isOrientation,
  onSelectDuration,
  timeSlot,
}: TProfileCard) => {
  const { t } = useTranslation("booking");
  const { config } = useConfig();
  const orientation_call_duration = config?.orientation_call_duration || 15;
  const session_duration = config?.session_duration || 60;

  const duration = isOrientation ? orientation_call_duration : session_duration;
  const duration_variations = isOrientation
    ? [orientation_call_duration]
    : config?.session_duration_variations;

  const startTime = new Date(`${timeSlot?.time.date}T${timeSlot?.time.start}`);
  const endTime = new Date(`${timeSlot?.time.date}T${timeSlot?.time.end}`);

  // Calculate the difference in milliseconds
  const durationInMilliseconds = endTime.getTime() - startTime.getTime();

  // Convert milliseconds to minutes
  const durationInMinutes = durationInMilliseconds / (1000 * 60);

  return (
    <div className="w-full h-full mb-5 bg-white divide-y divide-gray-200 rounded-lg shadow">
      <div className="relative">
        <div className="block w-full overflow-hidden bg-gray-100 rounded-t-lg group aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500">
          {imageUrl ? (
            <div
              className="object-cover overflow-hidden bg-center bg-cover"
              style={{ backgroundImage: `url(${imageUrl})` }}
            ></div>
          ) : (
            <span className="inline-block object-cover overflow-hidden bg-gray-100 pointer-events-none group-hover:opacity-7">
              <svg
                className="w-full h-full text-gray-300"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
          )}
        </div>
        {website ? (
          <div className="absolute top-3 right-3 z-10 cursor-pointer">
            <CoachWebsite link={website} />
          </div>
        ) : null}
      </div>
      <div className="flex flex-col px-5 py-6 gap-3">
        <div className="flex flex-col">
          <p className="text-gray m-0 font-dm_sans text-sm">
            {isOrientation
              ? t("profile_card.orientation_call_with")
              : t("profile_card.session_with")}
          </p>
          <p className="text-lg font-bold m-0 text-black font-dm_sans">
            {name}
          </p>
        </div>
        <div className="flex flex-col gap-2 mt-2">
          <div className="flex gap-5 items-center">
            <ProfileItem
              icon={timeIcon}
              value={
                <div className="flex gap-3 items-center">
                  {time ? (
                    `${durationInMinutes} `
                  ) : (
                    <SessionDurationSelector
                      onSelectDuration={onSelectDuration}
                      durations={duration_variations || [60]}
                      defaultSelected={duration || 60}
                    />
                  )}
                  minutes
                </div>
              }
              size={15}
            />
          </div>
          <ProfileItem icon={calendarIcon} value={date} />
          <ProfileItem icon={clockIcon} value={time} />
        </div>
      </div>
    </div>
  );
};

type TProfileItem = {
  icon: string;
  value?: any;
  size?: number;
};
const ProfileItem = ({ icon, value, size }: TProfileItem) => {
  if (value === undefined) return <></>;
  return (
    <div className="flex flex-row gap-2 font-light text-gray items-center">
      <div className="w-4 h-4 flex items-center justify-center">
        <img
          className="inline-flex"
          src={icon}
          alt="Icon"
          height={size ? size : 19}
          width={size ? size : 19}
        />
      </div>
      <p className="text-gray m-0 font-dm_sans text-sm">{value}</p>
    </div>
  );
};

export default ProfileCard;
