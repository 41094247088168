import { useNavigate } from "react-router-dom";

export const useWithParamNavigation = () => {
  const navigate = useNavigate();
  return (path: string, params: any) => {
    const queryParams = Object.keys(params)
      .map((key) => {
        return `${key}=${params[key]}`;
      })
      .join("&");
    navigate(path + "?" + queryParams);
  };
};
