import {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react";
import InputFormik from "../../../../../../shared/components/formik/InputFormik/InputFormik";
import i18n from "../../../../../../utils/lib/i18n";
import ENG from "../../../../locale/eng/groups.json";
import Fr from "../../../../locale/fr/groups.json";
import {useTranslation} from "react-i18next";
import {IMeta} from "../../../../../../models/IMeta";
import {useField} from "formik";
import {Switch} from "antd";
import ProgramPickerField from "../../../GroupCreate.old/components/ProgramPickerField";

export const TableCell = ({getValue, row, column, table}: any) => {
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);
    // const onBlur = () => {
    //     table.options.meta?.updateData(row.index, column.id, value)
    // }
    const meta = table.options.meta;
    // console.log(column);
    return (
        <InputFormik
            // readOnly={!meta?.editedRows[row.original.id]}
            readOnly={!(row.original.edited && (column.id == 'email' ? row.original.new : true))}
            className="read-only:border-none outline-none h-10 border-[1px] rounded-[5px] pl-2 hover:border-[#b3b3b3]"
            // disabled={!meta?.editedRows[row.original.studentId] }
            name={`coachees[${row.id}].[${column.id}]`}
            //   value={value}
            //   onChange={(e: any) => setValue(e.target.value)}
            // onBlur={onBlur}
        />
    );
};

interface SelectCellProps {
    getValue: any;
    row: any;
    column: any;
    table: any;
    options: IMeta[];
    setOptions: Dispatch<SetStateAction<IMeta[]>>;
}

export const SelectCell: React.FC<SelectCellProps> = ({
                                                          getValue,
                                                          row,
                                                          column,
                                                          table,
                                                          options,
                                                          setOptions,
                                                      }) => {
    i18n.addResourceBundle("en", "groups:create_group", ENG);
    i18n.addResourceBundle("fr", "groups:create_group", Fr);
    const {t} = useTranslation("groups:create_group");

    const [_, fieldMeta] = useField({
        name: `coachees[${row.id}].[${column.id}]`,
    });

    const meta = table.options.meta;

    const dropdownOptions = useMemo(() => {
        return options;
    }, [options]);

    const setValue = (value: any) => {
        meta.setFieldValue(`coachees[${row.id}].[${column.id}]`, value);
    };

    const handleChange = (value: IMeta | null) => {
        if (!value) return setValue(null);
        if (value.id) return setValue(value);
        const labelOption =
            options.find((labelMeta) => labelMeta.name === value.name) ?? null;
        if (labelOption) return setValue(labelOption);
        // const newOption = {label: value.name, value: value.name};
        // handleCreateLabel(newOption);
        // setValue(newOption);
    };

    const handleCreateLabel = (value: string) => {
        // console.log(value);
        if (value.trim() === "") return;
        setOptions((currVal) => {
            const alreadyExists = currVal.some(
                (labelMeta) =>
                    labelMeta.name.toLowerCase() === value.toLowerCase()
            );
            if (alreadyExists) return currVal;
            setValue({name: value})
            return [...currVal, {
                name: value,
            },];
        });

    };
    console.log(fieldMeta.value);
    return (
        <div>
            <ProgramPickerField
                value={fieldMeta.value}
                onChange={handleChange}
                programOptions={options}
                onCreateProgramOption={handleCreateLabel}

            />
        </div>
    );
};

export const ActivityCell = ({getValue, row, column, table}: any) => {
    const [_, fieldMeta] = useField({
        name: `coachees[${row.id}].[${column.id}]`,
    });
    i18n.addResourceBundle("en", "groups:create_group", ENG);
    i18n.addResourceBundle("fr", "groups:create_group", Fr);
    const {t} = useTranslation("groups");

    const meta = table.options.meta;

    return (
        <div className="flex justify-center items-center">
            <div className="flex gap-2 w-[100px]">
                <Switch
                    className={fieldMeta.value ? "" : "bg-red"}
                    checked={fieldMeta.value}
                    onChange={(val) =>
                        meta.setFieldValue(`coachees[${row.id}].[${column.id}]`, val)
                    }
                />
                <p>{fieldMeta.value ? t("coachees.text11") : t("coachees.text12")}</p>
            </div>
        </div>
    );
};
