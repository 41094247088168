
export const randomString = () => {
    return (Math.random() + 1).toString(36).substring(7)
}

export const capitalizeSentence = (snakeCaseSentence:string) => {
     return  snakeCaseSentence.replace(/_/g, " ")
         .replace(/^\w/, c => c.toUpperCase());
  }

