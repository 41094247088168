import React from "react";
import {CustomLabelProps} from "./types";

const CustomizedLabel: React.FC<CustomLabelProps> = ({
                                                         x,
                                                         y,
                                                         width,
                                                         value,
                                                         isVertical = false
}) => {

    return (
        <text x={isVertical ? x: x + width + 5} y={y + 10} dy={10} fill="black" fontSize="12px" textAnchor={`${isVertical ? "" : "start"}`}>
            {`${value}%`}
        </text>
    );
};

export default CustomizedLabel;