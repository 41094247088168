export const AUTH = {
    post: {
        login: {
            path: 'api/auth/login/new'
        },
        signup: {
            path: 'api/auth/signup'
        },
        resetPassword: {
            path: 'api/auth/reset-password'
        },
        sendMagicLink: {
            path: 'api/auth/magic-link'
        },
        switchRole: {
            path: 'api/auth/switch-role',
            private: true,
        },

    },
};
