import React, {useEffect, useState} from 'react';
import OverviewSection from "./components/OverviewSection";
import {IOverviewItem, IThreadItem, useAICoaching} from "./utils/AICoachingContext";
import {getJourneyOverview, getOverview} from './actions';
import {useTranslation} from 'react-i18next';

interface Props {
    handleOverviewSelect: () => void
}

const Overview = ({handleOverviewSelect}: Props) => {

    const {t} = useTranslation("ai_coaching");

    const {
        overviewItems,
        overviewJourneyItems,
        selectedThreadItem,
        setOverviewItems,
        setMessages,
        setOverviewJourneyItems,
        setSelectedThreadItem,
        setState,
        isJourneyVisible
    } = useAICoaching()
    const [isJLoading, setIsJLoading] = useState(false)
    const [isFLoading, setIsFLoading] = useState(false)

    useEffect(() => {
        fetchOverviews();
    }, []);

    const handleOnSelect = (item: IThreadItem) => {
        setMessages([])
        setSelectedThreadItem(item)
        setState("thread")
        handleOverviewSelect()
    }

    const handleOnDelete = (item: IThreadItem) => {

    }

    const fetchOverviews = async () => {
        setIsJLoading(true)
        setIsFLoading(true)
        const overviewPromise = getOverview().then((overview) => {
            const mappedOverview = overview.map(
                (overviewItem: any) =>
                    ({
                        id: overviewItem.id,
                        title: overviewItem.title ? overviewItem.title.trim() : "",
                        aiModel: overviewItem.ai_model,
                        datetime: overviewItem.datetime,
                        sessionNo: overviewItem.session_no,
                    } as IOverviewItem)
            );
            setOverviewItems(mappedOverview.reverse());

        }).finally(() => setIsFLoading(false))


        const journeyOverviewPromise = getJourneyOverview().then((journeyOverview) => {
            const mappedJourneryOverview = journeyOverview.map(
                (overviewItem: any) => {
                    const subSessionItems = overviewItem.sub_session_ids.map(
                        (sub_session_id: number, index: number) => {
                            return {
                                id: sub_session_id,
                                title: overviewItem.title ? overviewItem.title.trim() : "",
                                journeyId: overviewItem.id,
                                aiModel: 'session',
                                datetime: overviewItem.datetime,
                                sessionNo: overviewItem.session_no,
                                prevThread: undefined,  // Placeholder, will be updated later
                                nextThread: undefined,  // Placeholder, will be updated later
                            } as IThreadItem;
                        }
                    );

                    // Create a mapping of sub-session IDs to IThreadItem objects
                    const subSessionMap = new Map<number, IThreadItem>();
                    subSessionItems.forEach((item: IThreadItem, index: number) => {
                        subSessionMap.set(item.id, item);
                    });

                    // Update prevThread and nextThread fields
                    subSessionItems.forEach((item: IThreadItem, index: number) => {
                        if (index > 0) {
                            item.prevThread = subSessionMap.get(overviewItem.sub_session_ids[index - 1]);
                        }
                        if (index < overviewItem.sub_session_ids.length - 1) {
                            item.nextThread = subSessionMap.get(overviewItem.sub_session_ids[index + 1]);
                        }
                    });

                    return {
                        id: overviewItem.id,
                        title: overviewItem.title ? overviewItem.title.trim() : "",
                        aiModel: overviewItem.ai_model,
                        datetime: overviewItem.datetime,
                        sessionNo: overviewItem.session_no,
                        nextThread: subSessionItems.length > 0 ? subSessionItems.at(0) : undefined,
                        subSessions: subSessionItems,
                    } as IOverviewItem;
                }
            );
            setOverviewJourneyItems(mappedJourneryOverview.reverse());
        }).finally(() => setIsJLoading(false))

        await Promise.all([overviewPromise, journeyOverviewPromise]);
    };


    return (
        <div className="flex flex-col gap-5 h-full bg-white">
            <div className="p-3">
                <button onClick={() => {
                    setState("init")
                }}
                        className="dashboard-button font-bold bg-red border-2 border-transparent hover:border-white items-center justify-center flex w-full">
                    {t("home.new_chat")}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"/>
                    </svg>

                </button>
            </div>
            <div className="overflow-y-auto h-full">
                {isJourneyVisible && overviewJourneyItems && overviewJourneyItems.length > 0 && <div>
                    <h5 className="py-2 font-semibold">{t("home.leadership_journey")}</h5>
                    <OverviewSection
                        overviewItems={overviewJourneyItems}
                        selectedThreadItem={selectedThreadItem}
                        onSelect={handleOnSelect}
                        onDelete={handleOnDelete}
                        isLoading={isJLoading}

                    />
                </div>}
                <hr className="w-full my-2"/>
                <div>
                    <h5 className="py-2 font-semibold">{t("home.quick_resolutions")}</h5>
                    <OverviewSection
                        overviewItems={overviewItems}
                        selectedThreadItem={selectedThreadItem}
                        onSelect={handleOnSelect}
                        onDelete={handleOnDelete}
                        isLoading={isFLoading}

                    />
                </div>
            </div>

        </div>
    );
};

export default Overview;
