import { useTranslation } from "react-i18next";

type Props = {
  firstSessionComplete: boolean;
};

const FirstSessionPrep = ({ firstSessionComplete }: Props) => {
  const { t } = useTranslation("home");

  if (firstSessionComplete) return <></>;

  return (
    <div className="bg-white p-4 pt-3 rounded border-gray-50 border-[1px]">
      <h2 className="text-lg font-bold font-dm_sans text-black mt-2 mb-4">
        {t("first_session_prep.prepare_for_first_session")}
      </h2>
      <div className="flex flex-row gap-6">
        <div className="flex flex-col gap-2 basis-1/2">
          <div className="aspect-w-2 aspect-h-1 bg-black rounded relative">
            <iframe
              src="https://www.youtube.com/embed/OSbuObQ6Rj4"
              title={t("first_session_prep.what_is_coaching")}
              allowFullScreen
            />
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-bold text-xs font-dm_sans text-black m-0">
              {t("first_session_prep.what_is_coaching")}
            </p>
            <p className="text-xs font-dm_sans text-gray-400 m-0">
              {t("first_session_prep.getting_started")}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-2 basis-1/2">
          <div className="aspect-w-2 aspect-h-1 bg-black rounded">
            <iframe
              src="https://www.youtube.com/embed/xd9Th7U4IhE"
              title={t("first_session_prep.first_session")}
              allowFullScreen
            />
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-bold text-xs font-dm_sans text-black m-0">
              {t("first_session_prep.first_session")}
            </p>
            <p className="text-xs font-dm_sans text-gray-400 m-0">
              {t("first_session_prep.getting_started")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstSessionPrep;
