import React from "react";
import ReactTooltip from "react-tooltip";
import infoIcon from "../../../assets/img/teams/assessmentScore/information-circle.png";
import CircularPercentageChart from "./CircularPercentageChart";

interface Props {
  percentage: number;
  icon: any;
  text: string;
  toolTip: string;
}

const AssessmentScoreCard = (prop: Props) => {
  return (
    <div className="flex  justify-between px-5 py-3 bg-white rounded-md shadow-lg md:col-span-1 text-grey-800">
      <div style={{ flexShrink: "1" }} className="p-2 mr-3 sm:mr-2 md:mr-5">
        <img className="my-2" width={48} src={prop.icon} alt="" />
        <span>
          <span className="font-bold">{prop.text}</span>{" "}
          <img
            style={{ cursor: "pointer" }}
            data-tip="hover on me will keep the tooltip"
            data-for={prop.text}
            width={16}
            className="inline-block"
            src={infoIcon}
            alt=""
          />
          <ReactTooltip
            className={"w-60"}
            delayHide={500}
            id={prop.text}
            place="top"
            effect="solid"
          >
            {prop.toolTip}
          </ReactTooltip>
        </span>
      </div>
      <div
        className="flex"
        style={{ width: "100px", alignItems: "center", flexShrink: "0" }}
      >
        <CircularPercentageChart percentage={prop.percentage} />
      </div>
    </div>
  );
};

export default AssessmentScoreCard;
