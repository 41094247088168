import {FC, useState} from 'react'
import {Steps, useSteps} from "react-step-builder"

// Components
import SelectDate from './booking/Steps/SelectDate';
import SelectCoach from './booking/Steps/SelectCoach';
import BookingDetails from './booking/Steps/BookingDetails';
import {useNavigate, useSearchParams} from 'react-router-dom';
import ENG from "../locale/eng/booking.json";
import Fr from "../locale/fr/booking.json";
import i18n from "../../../utils/lib/i18n";

const BookingSteps: FC = () => {
    i18n.addResourceBundle('en', 'booking', ENG    );
    i18n.addResourceBundle('fr', 'booking', Fr    );

    const {jump, current} = useSteps()
    const navigate = useNavigate()
    const [loading] = useState(false)
    const [searchParams] = useSearchParams()

    const handleJump = (step: number) => {
        if (step === 1) {
            searchParams.delete('referrer')
            searchParams.delete('date')
            searchParams.delete('time')
            searchParams.delete('coach_id')
            navigate('?' + searchParams.toString())
        }

        jump(step)
    }
    return (
        <>
            <div className="flex items-center justify-center w-full h-2 pt-16">
                <div className="grid w-full sm:w-1/2 grid-cols-3 gap-3">
                    <button onClick={() => handleJump(1)}
                            className={"h-2 rounded w-30 bg-red " + (current !== 1 ? 'opacity-10' : '')}></button>
                    <button onClick={() => handleJump(2)} disabled={current == 1}
                            className={"h-2 rounded w-30 bg-red " + (current !== 2 ? 'opacity-10' : '')}></button>
                    <button onClick={() => handleJump(3)} disabled={current == 2 || current == 1}
                            className={"h-2 rounded w-30 bg-red " + (current !== 3 ? 'opacity-10' : '')}></button>
                </div>
            </div>

            <div className="flex items-stretch justify-between w-full py-5">
                {!loading && <Steps>
                    <SelectCoach/>
                    <SelectDate/>
                    <BookingDetails/>
                </Steps>}
            </div>
        </>
    )
}

export default BookingSteps
