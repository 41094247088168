import SessionViewTable from "./components/SessionViewTable";

import i18n from "../../../../utils/lib/i18n";
import ENG from "../../locale/eng/sessions_view.json";
import Fr from "../../locale/fr/sessions_view.json";
import {useTranslation} from "react-i18next";
import {useNavTitle} from "@hooks/useNavTitle";

const SessionView = ({...props}) => {
  i18n.addResourceBundle("en", "sessions_view", ENG);
  i18n.addResourceBundle("fr", "sessions_view", Fr);

  const {t} = useTranslation();
  useNavTitle(t(`layout:sidebar.${props.navTitle}`));

  return (
    <div className="m-5">
      <SessionViewTable />
    </div>
  );
};

export default SessionView;
