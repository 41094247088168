import React from "react";
import i18n from "../../../../../utils/lib/i18n";
import ENG from "../../../locale/eng/misc.json";
import Fr from "../../../locale/fr/misc.json";
import {useTranslation} from "react-i18next";
import {TrashIcon} from "@heroicons/react/outline";
import ICoach from "../../../../../models/ICoach";

interface Props {
    isCompanyCard?: boolean;
    programId?: number;
    programName?: string;
    coaches: ICoach[];
    onClick: (programId: number | null) => void;
    handleDeleteCoach: (poolId: number) => void;
}

const CoachListCard = ({isCompanyCard, programId, programName, coaches, onClick, handleDeleteCoach}: Props) => {
    i18n.addResourceBundle("en", "misc", ENG);
    i18n.addResourceBundle("fr", "misc", Fr);
    const {t} = useTranslation("misc");

    const handleClick = () => {
        if (programId !== undefined) {
            onClick(programId)
        } else {
            onClick(null);
        }
    }

    return (
        <div className="card-container px-3 flex flex-col max-h-[600px]">
            <p className={`flex justify-between items-center font-bold text-lg font-dm_sans ${isCompanyCard ? "text-red" : "text-black"} px-1`}>
                <span>
                    {isCompanyCard ? t("manage_coaches.card_title") : `${t("manage_coaches.program")}: ${programName}`}
                </span>
                <span>
                    ({coaches.length})
                </span>
            </p>
            <div className="py-2 px-1">
                <div className="border-b border-red"/>
            </div>
            {coaches.length > 0 ? (
                <div className="overflow-y-auto flex-grow px-1">
                    {coaches.map((coach, key) => (
                        <div className="grid grid-cols-6 items-center gap-3 mb-1 py-2 border-b" key={key}>
                            <div className="pl-1">
                                <img
                                    src={coach.image_url}
                                    className="rounded-full object-cover w-8 h-8"
                                    alt="coach"
                                />
                            </div>
                            <div className="col-span-4">
                                <p className="font-dm_sans text-sm">
                                    {coach.first_name} {coach.last_name}
                                </p>
                            </div>
                            <TrashIcon className=" w-4 h-4 cursor-pointer hover:text-red" onClick={() => {
                                if (coach.pool_id) {
                                    handleDeleteCoach(coach.pool_id)
                                }
                            }}/>
                        </div>
                    ))}
                </div>
            ) : (
                <p className="text-sm font-dm_sans pb-5">{isCompanyCard ? t("manage_coaches.text1") : t("manage_coaches.no_coaches_text")}</p>
            )}
            <button onClick={handleClick}
                    className={`dashboard-button ${isCompanyCard ? "bg-red hover:bg-black" : "bg-black"} px-4 py-2 justify-self-end mt-auto`}>
                + {t("manage_coaches.program_card_button")}
            </button>
        </div>
    );
};

export default CoachListCard;
