import React from "react";
import "react-circular-progressbar/dist/styles.css";
import ReportCoachingObjective from "../components/ReportCoachingObjective";
import {
  TSelfReflection,
} from "../../../../../constants/types";
import { IMeta } from "../../../../../models/IMeta";
import careerDevelopmentImage from "@img/dashboard/welcome/img/career_development.png";
import workEnvironmentImage from "@img/dashboard/welcome/img/work_environment.png";
import jobPerformanceImage from "@img/dashboard/welcome/img/job_performance.png";
import leadershipImage from "@img/dashboard/welcome/img/leadership.png";
import communicationImage from "@img/dashboard/welcome/img/communication.png";
import qualityOfLifeImage from "@img/dashboard/welcome/img/quality_of_life.png";
import selfAwarenessImage from "@img/dashboard/welcome/img/self_awareness.png";
import stressAndAnxietyImage from "@img/dashboard/welcome/img/stress_and_anxiety.png";

type Props = {
  hideObjectives?: boolean;
  onSelectObjective?: (objective: IMeta) => void;
  selectedObjectives?: IMeta[];
  lng?: string;
} & TSelfReflection;

const ReportSelfReflectionCard: React.FC<Props> = ({
  title,
  title_fr,
  coachingObjectives,
  apiResponseField,
  hideObjectives,
  onSelectObjective,
  selectedObjectives = [],
  lng,
}) => {
  const imageMap = new Map([
    ["career", careerDevelopmentImage],
    ["intellect", workEnvironmentImage],
    ["organisation", jobPerformanceImage],
    ["leadership", leadershipImage],
    ["communication", communicationImage],
    ["worklife", qualityOfLifeImage],
    ["assertivity", selfAwarenessImage],
    ["self_esteem", stressAndAnxietyImage],
  ]);

  return (
    <li className="relative h-full">
      <div className="bg-white rounded-md relative pb-3 px-2 h-full flex flex-col justify-start border-solid border py-2">
        <div className="w-full bg-white rounded-t-lg aspect-w-3 aspect-h-[2/3]">
          <div className="flex flex-col justify-between">
            <div className="flex flex-row items-center basis-12/12 overflow-hidden">
              <div className="basis-1/4 flex justify-center h-full">
                <div className="overflow-hidden bg-center bg-cover flex justify-center items-center">
                  <img
                    src={imageMap.get(apiResponseField)}
                    alt=""
                    className="object-contain pointer-events-none h-full"
                  />
                </div>
              </div>
              <div className="basis-3/4 flex justify-center">
                <div className="w-full">
                  <p className="font-bold text-md font-dm_sans">
                    {lng === "fr" ? title_fr : title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!hideObjectives && coachingObjectives.length ? (
          <div className="flex flex-col gap-2 mt-2">
            {coachingObjectives.map((objective, index) => (
              <ReportCoachingObjective
                key={index}
                lng={lng}
                objective={objective}
                onClick={() =>
                    onSelectObjective && onSelectObjective(objective)
                }
                selectedObjectives={selectedObjectives}
              />
            ))}
          </div>
        ) : null}
      </div>
    </li>
  );
};

export default ReportSelfReflectionCard;
