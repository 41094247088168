import ReactTooltip from "react-tooltip";

import NoDataWrapper from "../../../../../shared/components/NoDataWrapper";
import InformationIcon from "../../../../../shared/components/InformationIcon";

import CoachelloIcon from "@img/coachello.svg";
import SimImg from "@img/dashboard/sim.png";
import BgImg from "@img/dashboard/CreditRemain.png";
import useAuth from "@hooks/useAuth";
import Backdrop from "./Backdrop";

interface Props {
    bgImg: string;
    valueM: any;
    valueS: any;
    title: string;
    tooltip: string;
    displayInfo?: boolean
}

const CreditRemainCard = (props: Props) => {
    const {user} = useAuth();

    return (
        <div
            // style={{backgroundImage: `url(${BgImg})`}}
            className="relative rounded-2xl bg-white w-full h-full bg-origin-border bg-no-repeat bg-top "
        >
            {props.valueM == null && <NoDataWrapper/>}
            <Backdrop/>
            <div className="relative p-5 flex flex-row h-full justify-between">
                <div className="flex flex-col h-full text-black font-lexend">
                    <p className="font-bold text-sm text-gray-600">{props.title}</p>
                    {
                        props.displayInfo &&
                        <InformationIcon
                            data-for={"tooltipId"}
                            data-tip={"hover on me will keep the tooltip"}
                            className={"px-2 absolute top-4 right-0 text-black"}
                        />
                    }

                    <ReactTooltip
                        className={"w-60"}
                        delayHide={500}
                        id={"tooltipId"}
                        place="top"
                        effect="solid"
                    >
                        <span>{props.tooltip}</span>
                    </ReactTooltip>
                    <div className=" py-5 px-2">
                        <div className="flex gap-1 items-end">
                            <h2>{props.valueM}/
                            </h2>
                            <span className="font-bold  -translate-y-1/2">{props.valueS}</span>
                        </div>

                    </div>
                    <div className="flex gap-1">
                        <img src={CoachelloIcon} alt="coachello" width={16} height={14}/>
                        <a
                            className="tracking-widest font-lexend text-black text-sm"
                            href={"https://coachello.io"}
                            target="_blank"
                            rel="noreferrer"
                        >
                            coachello.io
                        </a>
                    </div>
                </div>
                <div className="h-full flex items-center w-1/3">
                    <img src={SimImg} alt="coachello" width={50} height={50} className="bg-warm-light rounded"/>

                </div>
            </div>
        </div>
    );
};

export default CreditRemainCard;
