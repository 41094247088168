import {KeyboardEvent, useEffect, useRef} from "react";
import {useAICoaching} from "../../utils/AICoachingContext";
import ArrowRightWhite from "../../../../../../assets/img/aiCoaching/arrow-right-white.svg";

type ChatInputProps = {
    isLoading: boolean;
    onNewPrompt: (value: string, callback: () => void) => void;
    placeholder?: string;
};

const ChatInput = ({
                       isLoading,
                       onNewPrompt,
                       placeholder,
                   }: ChatInputProps) => {

    const {setChatPrompt, chatPrompt, selectedThreadItem} = useAICoaching();
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        focusInput();
    }, [chatPrompt, selectedThreadItem]);

    const focusInput = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    const handleKeyUp = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            handleSubmission();
        }
    };
    const handleSubmission = () => {
        if (isLoading) return;
        if (chatPrompt.trim() === "") return;
        onNewPrompt(chatPrompt, () => {
            setChatPrompt("");
        });
    };
    return (
        <div className="flex flex-row gap-4">
            <div
                className="h-[40px] w-full flex flex-row items-center px-3 border-[1px] border-[#f2ebe3] rounded-[5px] bg-white">
                <input
                    ref={inputRef}
                    className="font-dm_sans w-full h-full text-xs text-black placeholder:font-light placeholder:text-[#828282] focus:outline-none"
                    placeholder={placeholder}
                    onKeyUp={handleKeyUp}
                    onChange={(e) => {
                        if (e.target.value.length > 300) return;
                        setChatPrompt(e.target.value);
                    }}
                    value={chatPrompt}
                />
            </div>
            <div
                className="bg-black rounded-[5px] w-[43px] h-[40px] flex justify-center items-center cursor-pointer"
                onClick={handleSubmission}
            >
                <img
                    src={ArrowRightWhite}
                    alt=""
                    className="object-cover pointer-events-none w-[15px]"
                />
            </div>
        </div>
    );
};

export default ChatInput;
