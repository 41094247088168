import axios from 'axios';

export const createBooking = (data:Object) => {
  return axios.post('/api/coach/calendar/event', data)
    .then(response => {
      return response.data
    });
}
export const cancelBooking = (meetingID: number) => {
  return axios.delete(`/api/meeting/delete-meeting/${meetingID}`)
    .then(response => {
      return response.data
    });
}
