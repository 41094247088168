import { userTimeZone } from "../../utils/helpers/time";
import axios from "axios";
import { GET, POST, PUT } from "../../utils/lib/axios";
import { COACH } from "../../constants/endpoints/coach";
import ICoachProfile from "../../models/ICoachProfile";
import moment from "moment";

export const getCoach = (coachID: number) => {
  return axios.get<any>(`api/coach/${coachID}`).then((response) => {
    return response.data.data;
  });
};
export const getCoaches = (companyId: string) => {
  return axios.get<any>("api/coach?company=" + companyId).then((response) => {
    return response.data.data;
  });
};

export const getAvailableDates = (
  coachID: number,
  start: string,
  end: string
) => {
  return axios
    .get(
      `api/coach/${coachID}/calendar?start_date=${start}&end_date=${end}&time_zone=${userTimeZone()}`
    )
    .then((response) => {
      return response.data;
    });
};

export const createBooking = (data: Object) => {
  return axios.post("/api/coach/calendar/event", data).then((response) => {
    return response.data;
  });
};

export const getCoachSessions = (
  coachID: number,
  date: Date,
  all: boolean = false,
  upcoming: boolean = false
) => {
  return GET(COACH.get.coachSessions, undefined, {
    date: moment(date).format("YYYY-MM-DD"),
    timezone: userTimeZone(),
    all,
    upcoming,
  }).then((response) => {
    return response.data;
  });
};

export const getCoachProfile = (coachID: number) => {
  return GET(COACH.get.coachProfile, undefined).then((response) => {
    return response.data;
  });
};

export const updateCoachProfile = (profile: ICoachProfile, coachID: number) => {
  return PUT(COACH.put.coachProfile, profile, undefined).then((response) => {
    return response.data;
  });
};

export const getPendingReports = (coachID: number) => {
  return GET(COACH.get.pendingReports, undefined, {
    timezone: userTimeZone(),
  }).then((response) => {
    return response.data;
  });
};

export const getSessionsOverview = (coachID: number, sessionID: number) => {
  return GET(COACH.get.sessionsOverview, undefined, {
    session_id: sessionID,
    timezone: userTimeZone(),
  }).then((response) => {
    return response.data;
  });
};

export const getReport = (coachID: number, sessionID: number) => {
  return GET(COACH.get.report, { session_id: sessionID }).then((response) => {
    return response.data;
  });
};

export const createReport = (coachID: number, sessionID: number, data: any) => {
  return POST(COACH.post.report, data, { session_id: sessionID }).then(
    (response) => {
      return response.data;
    }
  );
};

export const getOccupiedEvents = (
  coachId: string | null,
  start_date: string,
  end_date: string
) => {
  return GET(
    COACH.get.getOccupiedEvents,
    { coachId },
    { start_date, end_date, time_zone: userTimeZone() }
  ).then((response) => {
    return response.data;
  });
};

export const getAllCoaches = (companies?: Array<number>) => {
  return GET(COACH.get.getAllCoaches, undefined, {
    companies: companies
  }).then((response) => {
    return response.data;
  });
}

