import React from 'react';
import NoDataIcon from "@icons/noDataIcon.png";
import {useTranslation} from "react-i18next";

const NoDataWrapper = () => {
    const {t} = useTranslation('commons')

    return (
        <div style={{borderRadius: 'inherit'}}
             className="z-10 absolute inset-0 backdrop-blur-md flex flex-col items-center justify-center text-center p-7 gap-2 overflow-hidden">
            {/*<div className="w-full h-full z-10 loading-skeleton">*/}
            {/*    <div className="z-20">*/}
                <img src={NoDataIcon} width={36} height={36}/>
                <h4 className="md:text-sm text-xs">{t('no_data_card.text1')}</h4>
                <p className="md:text-sm text-xs">{t('no_data_card.text2')}</p>
                </div>
            // </div>
        // </div>
    );
};

export default NoDataWrapper;
