import ReportChip from "./ReportChip";

import { TOption } from "../../../../../constants/types";

type Props = {
  data?: TOption[] | null;
};

const ChipsField = ({ data }: Props) => {
  return (
    <div className="flex flex-row flex-wrap gap-1" style={{ minHeight: 32 }}>
      {data?.map((item, index) => (
        <ReportChip key={index} label={item.name} />
      ))}
    </div>
  );
};

export default ChipsField;
