import axios from "axios";
import moment from "moment/moment";

import {DELETE, GET, POST, PUT} from "../../utils/lib/axios";
import {COMPANY} from "../../constants/endpoints/company";
import {IGroup, IGroupAdmin} from "../../models/IGroup";
import {Filters} from "../../constants/types";
import {userTimeZone} from "../../utils/helpers/time";


const getFilterQueryParams = (filters: Filters = {}) => {
    const {startDate, endDate, group_ids, program_ids, labels} = filters
    let queryParams: Record<string, any> = {};
    if (startDate && endDate) {
        queryParams = {
            date_start: moment(startDate).format("YYYY-MM-DD"),
            date_end: moment(endDate).format("YYYY-MM-DD")
        };
    }
    if (group_ids) {
        queryParams['group_ids'] = group_ids
    }

    if (program_ids) {
        queryParams['program_ids'] = program_ids
    }

    if (labels) {
        queryParams['labels'] = labels
    }
    return queryParams;
}


export const getCompany = (companyName: string) => {
    return axios.get<any>(`api/company/${companyName}/id`).then((response) => {
        return response.data;
    });
};

export const getLabels = (companyId: number) => {
    return GET(COMPANY.get.getLabels, {company_id: companyId}).then(
        (response) => {
            return response.data;
        }
    );
};

export const getPrograms = (companyId: number, group_ids?: number[]) => {
    let queryParams: Record<string, any> = getFilterQueryParams({group_ids: group_ids});
    return GET(COMPANY.get.getPrograms, {company_id: companyId}, queryParams).then(
        (response) => {
            return response.data;
        }
    );
};

export const getUsage = (
    companyId: number,
    filters: Filters = {}) => {
    let queryParams: Record<string, any> = getFilterQueryParams(filters);
    return GET(COMPANY.get.getUsage, {company_id: companyId}, queryParams).then(
        (response) => {
            return response.data;
        }
    );
};

export const getUtilisation = (
    companyId: number,
    startDate: any = null,
    endDate: any = null,
) => {
    let queryParams: Record<string, any> = {};
    if (startDate && endDate) {
        queryParams = {
            date_start: moment(startDate).format("YYYY-MM-DD"),
            date_end: moment(endDate).format("YYYY-MM-DD")
        };
    }

    return GET(
        COMPANY.get.getUtilisation,
        {company_id: companyId},
        queryParams
    ).then((response) => {
        return response.data;
    });
};

export const getUtilisationSessionView = (
    companyId: number,
    startDate: any = null,
    endDate: any = null
) => {
    let queryParams: Record<string, any> | undefined;
    if (startDate && endDate) {
        queryParams = {
            date_start: moment(startDate).format("YYYY-MM-DD"),
            date_end: moment(endDate).format("YYYY-MM-DD"),

        };
    } else {
        queryParams = undefined;
    }

    return GET(
        COMPANY.get.getUtilisationSessionView,
        {company_id: companyId},
        queryParams
    ).then((response) => {
        return response.data;
    });
};

export const getPerformance = (
    companyId: number,
    filters: Filters = {}) => {
    let queryParams: Record<string, any> = getFilterQueryParams(filters);
    return GET(COMPANY.get.getPerformance, {company_id: companyId}, queryParams).then(
        (response) => {
            return response.data;
        }
    );
};

export const getCategories = (
    companyId: number,
    filters: Filters = {}) => {
    let queryParams: Record<string, any> = getFilterQueryParams(filters);
    return GET(COMPANY.get.getCategories, {company_id: companyId}, queryParams).then(
        (response) => {
            return response.data;
        }
    );
};

export const getFeelings = (
    companyId: number,
    filters: Filters = {}
) => {
    let queryParams: Record<string, any> = getFilterQueryParams(filters);
    return GET(COMPANY.get.getFeelings, {company_id: companyId}, queryParams).then(
        (response) => {
            return response.data;
        }
    );
};
export const getActions = (
    companyId: number,
    filters: Filters = {}) => {
    let queryParams: Record<string, any> = getFilterQueryParams(filters);
    return GET(COMPANY.get.getActions, {company_id: companyId}, queryParams).then(
        (response) => {
            return response.data;
        }
    );
};
export const getObjectives = (
    companyId: number,
    filters: Filters = {}) => {
    let queryParams: Record<string, any> = getFilterQueryParams(filters);
    return GET(COMPANY.get.getObjectives, {company_id: companyId}, queryParams).then(
        (response) => {
            return response.data;
        }
    );
};
export const getAllObjectives = (companyId: number,
                                 filters: Filters = {}) => {
    let queryParams: Record<string, any> = getFilterQueryParams(filters);
    return GET(COMPANY.get.getAllObjectives, {company_id: companyId}, queryParams).then(
        (response) => {
            return response.data;
        }
    );
};
export const getBehaviors = (
    companyId: number,
    filters: Filters = {}) => {
    let queryParams: Record<string, any> = getFilterQueryParams(filters);
    return GET(COMPANY.get.getBehaviors, {company_id: companyId}, queryParams).then(
        (response) => {
            return response.data;
        }
    );
};
export const getPeerObjectives = (
    companyId: number,
    filters: Filters = {}) => {
    let queryParams: Record<string, any> = getFilterQueryParams(filters);
    return GET(COMPANY.get.getPeerObjectives, {company_id: companyId}, queryParams).then(
        (response) => {
            return response.data;
        }
    );
};
export const getPeerBehaviours = (
    companyId: number,
    filters: Filters = {}) => {
    let queryParams: Record<string, any> = getFilterQueryParams(filters);
    return GET(COMPANY.get.getPeerBehaviours, {company_id: companyId}, queryParams).then(
        (response) => {
            return response.data;
        }
    );
};
export const getBookingsSummary = (
    companyId: number,
    filters: Filters = {}) => {
    let queryParams: Record<string, any> = getFilterQueryParams(filters);
    return GET(COMPANY.get.getBookingsSummary, {company_id: companyId}, queryParams).then(
        (response) => {
            return response.data;
        }
    );
};
export const getOnboardingSummary = (companyId: number,
                                     filters: Filters = {}) => {
    let queryParams: Record<string, any> = getFilterQueryParams(filters);
    return GET(COMPANY.get.getOnboardingSummary, {company_id: companyId}, queryParams).then(
        (response) => {
            return response.data;
        }
    );
};
export const getCustomFields = (companyId: number,
                                filters: Filters = {}) => {
    let queryParams: Record<string, any> = getFilterQueryParams(filters);
    return GET(COMPANY.get.getCustomFields, {company_id: companyId}, queryParams).then(
        (response) => {
            return response.data;
        }
    );
};

export const getCoachesConsole = (companyId: number, clientId?: number) => {
    return GET(COMPANY.get.getCoachesConsole, undefined, {
        company: companyId,
        client: clientId,
        time_zone: userTimeZone()
    }).then((response) => {
        return response.data.data;
    });
};

export const getCoaches = (companyId: number, clientId?: number, programs?: Array<number>) => {
    console.log(programs);
    return GET(COMPANY.get.getCoaches, undefined, {
        company: companyId,
        client: clientId,
        programs: programs,
        time_zone: userTimeZone()
    }).then((response) => {
        return response.data.data;
    });
};

export const getRemainCredits = async (companyId: number,
                                       filters: Filters = {}) => {
    let queryParams: Record<string, any> = getFilterQueryParams(filters);
    const response = await GET(COMPANY.get.getRemainCredits, {company_id: companyId}, queryParams);
    return response.data;
};

export const getCreditStats = async (companyId: number) => {
    const response = await GET(COMPANY.get.getCreditStats, {company_id: companyId});
    return response.data;
};

export const getGoalAchieved = (
    companyId: number,
    filter: Filters = {}) => {
    let queryParams: Record<string, any> = getFilterQueryParams(filter);
    return GET(COMPANY.get.getGoalAchieved, {company_id: companyId}, queryParams).then(
        (response) => {
            return response.data;
        }
    );
};

export const getReviews = (companyId: number,
                           filters: Filters = {}) => {
    let queryParams: Record<string, any> = getFilterQueryParams(filters);
    return GET(COMPANY.get.getReviews, {company_id: companyId}, queryParams).then(
        (response) => {
            return response.data;
        }
    );
};

export const getCompanyId = (company_name: string) => {
    return GET(COMPANY.get.getId, {
        company_name,
    }).then((response) => {
        return response.data;
    });
};

export const getActivePeriod = (companyId: number) => {
    return GET(COMPANY.get.getActivePeriod, {company_id: companyId}).then(
        (response) => {
            return response.data;
        }
    );
};

export const getInsights = (
    companyId: number,
    language: string,
    month: string
) => {
    const queryParams = {
        language,
        month
    };

    return GET(
        COMPANY.get.getInsights,
        {company_id: companyId},
        queryParams
    ).then((response) => {
        return response.data;
    });
};

export const getInsightsLabels = (companyId: number) => {
    return GET(COMPANY.get.getInsightsLabels, {company_id: companyId}).then(
        (response) => {
            return response.data;
        }
    );
}

export const deactivateUser = (clientId: number) => {
    return PUT(
        COMPANY.put.deactivateUser,
        {undefined},
        {client_id: clientId}
    ).then((response) => {
        return response.data;
    });
};

export const getGroups = (company_id: number, program_ids?: number[]): Promise<IGroup[]> => {
    let queryParams: Record<string, any> = getFilterQueryParams({program_ids: program_ids});
    return GET(COMPANY.get.getGroups, {company_id}, queryParams).then((response) => {
        return response.data;
    });
};

export const getGroup = (company_id: number, group_id: number): Promise<IGroup> => {
    return GET(COMPANY.get.getGroup, {company_id, id: group_id}).then((response) => {
        return response.data;
    });
};

export const getGroupAdmins = (company_id: number, group_id: number): Promise<IGroupAdmin[]> => {
    return GET(COMPANY.get.getGroupAdmin, {company_id, id: group_id}).then((response) => {
        return response.data;
    });
};

export const upsertGroupAdmins = (company_id: number, group_id: number, group_admins: IGroupAdmin[]): Promise<void> => {
    return POST(COMPANY.post.upsertGroupAdmin, group_admins, {company_id, id: group_id}).then((response) => {
        return response.data;
    });
};

export const createGroup = (company_id: number, data: any) => {
    return POST(COMPANY.post.createGroup, data, {company_id}).then(
        (response) => {
            return response.data;
        }
    );
};

export const updateGroup = (company_id: number, group_id: number, data: any) => {
    return PUT(COMPANY.put.updateGroup, data, {company_id, id: group_id}).then(
        (response) => {
            return response.data;
        }
    );
};

export const updateGroupCoachees = (company_id: number, group_id: number, data: any) => {
    return PUT(COMPANY.put.updateGroupCoachees, data, {company_id, id: group_id}).then(
        (response) => {
            return response.data;
        }
    );
};

export const deleteGroup = (company_id: number, group_id: number) => {
    return DELETE(COMPANY.delete.deleteGroup, undefined, {company_id, id: group_id}).then(
        (response) => {
            return response.data;
        }
    );
};

export const getProgramCoaches = (company_id: number) => {
    return GET(COMPANY.get.getProgramCoaches, {company_id} ).then(
        (response) => {
            return response.data;
        }
    )
}

export const getExcludeProgramCoaches = (program_id: number | null, company_id?: number) => {
    return GET(COMPANY.get.getExcludeProgramCoaches, undefined, {company_id, program_id}).then(
        (response) => {
            return response.data;
        }
    )
}

export const deleteCoachFromProgram = (pool_id: number) => {
    return DELETE(COMPANY.delete.deleteCoachFromProgram, undefined, undefined,{pool_id}).then(
        (response) => {
            return response.data;
        }
    )
}

export const updateProgramCoaches = (company_id: number, coach_ids: number[], program_id?: number | null) => {
    return PUT(COMPANY.put.updateProgramCoaches, coach_ids, {company_id}, {program_id}).then(
        (response) => {
            return response.data;
        }
    )
}