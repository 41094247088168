import {FC, useEffect, useState} from 'react'

import timeIcon from '../../../../assets/img/teams/icons/time.png'

import {useSearchParams} from 'react-router-dom'

import linkedinIcon from "../../../../assets/img/teams/social/linkedin.png";
import '../../css/LinkedinIconStyle.css'
import {useTranslation} from "react-i18next";
import {ITeamsAuthData} from "../../../../models/ITeamsAuth";
import ICoach from "../../../../models/ICoach";
import {getCoach} from "../../../../services/api/teams/CoachActions";
import useAuth from "@hooks/useAuth";

const ProfileCardSimple: FC = () => {
    const {t} = useTranslation('booking')
    const [searchParams] = useSearchParams()
    const [coach, setCoach] = useState<ICoach>()
    const {user: authUser} = useAuth()
    const companyId = authUser?.companyId

    useEffect(() => {
        let coachId = searchParams.get('coach_id')
        if (!coachId) {
            return
        }

        loadCoach(coachId)
    }, [])

    const loadCoach = (coachId: string) => {
        getCoach(coachId)
            .then(coach => {
                setCoach(coach)
            })
    }
    const openLink = (url: string) => {
        window.open(url, '_blank');
    }
    return (
        <div className="w-full mb-5 bg-white divide-y divide-gray-200 rounded-lg shadow lg:mb-0 lg:w-3/12">
            <div className="relative">
                <div
                    className="block w-full overflow-hidden bg-gray-100 rounded-t-lg group aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500">
                    {coach?.image_url
                        ?
                        <div className="object-cover overflow-hidden bg-center bg-cover"
                             style={{backgroundImage: `url(${coach?.image_url})`}}></div>
                        :
                        <span
                            className="inline-block object-cover overflow-hidden bg-gray-100 pointer-events-none group-hover:opacity-7">
              <svg className="w-full h-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                <path
                    d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
              </svg>
            </span>
                    }
                </div>
                {coach?.website && <div className="absolute top-3 right-3">
                    {/*<a title="Open in a new tab" className="hover_image" href={coach.website} target="_blank">*/}
                    {/*<img className="linkedinImg" src={linkedinIcon} alt=""/>*/}
                    {/*/!*<img className="externalImg hover_image-hide" src={externalLinkIcon} alt=""/>*!/*/}
                    {/*<svg className="externalImg hover_image-hide" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"*/}
                    {/*     width="24" height="24"*/}
                    {/*     viewBox="0 0 24 24"*/}
                    {/*     style={{fill: "#fd0054"}}>*/}
                    {/*    <path*/}
                    {/*        d="M19,21H5c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h7v2H5v14h14v-7h2v7C21,20.1,20.1,21,19,21z"></path>*/}
                    {/*    <path d="M21 10L19 10 19 5 14 5 14 3 21 3z"></path>*/}
                    {/*    <path d="M6.7 8.5H22.3V10.5H6.7z" transform="rotate(-45.001 14.5 9.5)"></path>*/}
                    {/*</svg>*/}
                    <a title={t('select_date.text_1')} href={coach.website} target="_blank">
                        <span className="new-tab-span-container">
                                <img className="" src={linkedinIcon} alt=""/>
                        <span> <p> Open </p>
                    <svg className="" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                         width="24" height="24"
                         viewBox="0 0 24 24"
                         style={{fill: "#f8f4f4"}}>
                        <path
                            d="M19,21H5c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h7v2H5v14h14v-7h2v7C21,20.1,20.1,21,19,21z"></path>
                        <path d="M21 10L19 10 19 5 14 5 14 3 21 3z"></path>
                        <path d="M6.7 8.5H22.3V10.5H6.7z" transform="rotate(-45.001 14.5 9.5)"></path>
                    </svg>

 </span>

                    </span>
                    </a></div>
                    // <img onClick={() => openLink(coach.website)} className="absolute top-3 right-3" src={linkedInIcon} alt=""/>
                }
            </div>
            <div className="px-5 py-4">
                <p className="font-light text-red-400">{t('select_date.text_2')}</p>
                <p className="text-lg font-bold">{coach?.first_name} {coach?.last_name}</p>
                <p className="mt-5 font-light text-red-400">
                    <img className="inline-flex mr-4" src={timeIcon} alt="Time"/>
                    <span>1 {t('select_date.text_2_1')}</span>
                </p>
            </div>
        </div>
    )
}

export default ProfileCardSimple;
