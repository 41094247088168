import { useTranslation } from "react-i18next";

import { ELanguageFr } from "../../../../../constants/meta/ELanguageFr";
import i18n from "../../../../../utils/lib/i18n";
import { ESpecializationFr } from "../../../../../constants/meta/ESpecializationFr";
import useAuth from "@hooks/useAuth";
import { TranslateIcon } from "@heroicons/react/outline";
import { LocationMarkerIcon, UserGroupIcon } from "@heroicons/react/solid";

import ICoach from "../../../../../models/ICoach";

interface Props {
  coach: ICoach;
}
const CoachCard = ({ coach }: Props) => {
  const { t } = useTranslation("coaches");
  const { user } = useAuth();

  return (
    <div className="card-container px-4 flex flex-col h-full">
      <div className="flex gap-5 mb-5 items-center">
        <img
          src={coach.image_url}
          className="rounded-full object-cover w-16 h-16"
          alt="coach"
        />
        <div>
            <p className="font-bold mb-2">
                {coach.first_name} {coach.last_name}
            </p>
            {
                coach.popular && <span className="py-0.5 px-2 bg-pink-light text-red rounded font-normal text-xs">
                    {t("text2")}
                  </span>
            }
        </div>
      </div>
      <div className="mb-4">
        <span className="flex gap-1 mb-2 items-center">
          {" "}
          <LocationMarkerIcon className=" w-4 h-4" /> {coach.city}
        </span>
        <span className="flex gap-1 items-center">
          {" "}
          <TranslateIcon className="w-4 h-4" />{" "}
          {coach.languages
            .map((lang: any) => {
              return i18n.language === "fr"
                ? ELanguageFr[lang.language as keyof typeof ELanguageFr]
                : lang.language;
            })
            .join(" / ")}
        </span>
      </div>
      <div className="flex flex-wrap gap-2">
        {coach.specializations.map((spec, index) => {
          return (
            <span
              key={index}
              className="bg-warm-light rounded py-0.5 px-2 text-xs font-normal"
            >
              {i18n.language === "fr"
                ? ESpecializationFr[
                    spec.specialization as keyof typeof ESpecializationFr
                  ]
                : spec.specialization}
            </span>
          );
        })}
      </div>
      {coach.coachee ? (
        <div className="ml-auto flex items-center gap-2 justify-end mt-auto pt-4">
          <UserGroupIcon className="w-4 h-4" /> {coach.coachee} {t("text3")}{" "}
          {user?.companyName}
        </div>
      ) : undefined}
    </div>
  );
};

export default CoachCard;
