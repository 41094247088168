import {EAssessmentType, EHomeDropdownAction} from "./enums";

export const quizData = {
  [EAssessmentType.SelfReflection]: {
    title: "quiz_data.self_reflection",
    key: EAssessmentType.SelfReflection,
  },
  [EAssessmentType.Reflection360]: {
    title: "quiz_data.reflection_360",
    key: EAssessmentType.Reflection360,
  },
  [EAssessmentType.ImpactReflection]: {
    title: "quiz_data.impact_reflection",
    key: EAssessmentType.ImpactReflection,
  },
  [EAssessmentType.Review]: {
    title: "quiz_data.review",
    key: EAssessmentType.Review,
  },
};

export const HomeDropdownActions = {
  [EHomeDropdownAction.Reshedule]: {
    title: "reschedule",
    action: EHomeDropdownAction.Reshedule,
  },
  [EHomeDropdownAction.Cancel]: {
    title: "cancel",
    action: EHomeDropdownAction.Cancel,
  },
  [EHomeDropdownAction.LeaveReview]: {
    title: "leave_review",
    action: EHomeDropdownAction.LeaveReview,
  },
  [EHomeDropdownAction.CheckReport]: {
    title: "check_report",
    action: EHomeDropdownAction.CheckReport,
  },
};


export const AI_JOURNEY_TOTAL_STEPS = 10;
export const AI_SESSION_TOTAL_STEPS = 10;
export const AI_FLASH_TOTAL_STEPS = 6;
