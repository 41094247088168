import React from 'react';

interface RandomColorSpanProps {
    text: string;
}

const RandomColorSpan: React.FC<RandomColorSpanProps> = ({ text }) => {
    // Handle case where text is undefined or null
    if (text == null) {
        return null; // You can also provide a default value or handle it differently
    }

    // Generate a hash from the text to ensure consistent colors
    const hashCode = (s: string) => {
        return s.split('').reduce((a, b) => {
            a = (a << 5) - a + b.charCodeAt(0);
            return a & a;
        }, 0);
    };

    // Function to generate a random color based on the hash code
    const generateRandomColor = (seed: number,opacity:number) => {
        const random = (max: number) => Math.floor(Math.random() * max);
        const hue = (seed % 360 + 360) % 360; // Ensure hue is between 0 and 359
        const saturation = random(50) + 80; // Random saturation between 50 and 100
        const lightness = random(20) + 20; // Random lightness between 40 and 60
        return `hsl(${hue}, ${saturation}%, ${lightness}% , ${opacity})`;
    };

    // Calculate hash code and generate color
    const bgColor = generateRandomColor(hashCode(text),0.2);
    const textColor = generateRandomColor(hashCode(text),1);

    return (
        <span className="p-2 rounded text-center font-normal" style={{ backgroundColor: bgColor, color: textColor ,  }}>
      {text}
    </span>
    );
};

export default RandomColorSpan;
