import React, {useState} from 'react';

interface Props {
    durations: number[];
    defaultSelected: number;
    onSelectDuration?: (duration: number) => void;
}

const SessionDurationSelector: React.FC<Props> = ({durations, defaultSelected, onSelectDuration}) => {
    const [selectedDuration, setSelectedDuration] = useState(defaultSelected);

    const handleDurationSelect = (duration: number) => {
        setSelectedDuration(duration);
        if (onSelectDuration) {
            onSelectDuration(duration);
        } // Send the selected duration back to the parent component
    };

    return (

        <div className="flex space-x-2">
            {durations.map((duration) => (
                <button
                    key={duration}
                    className={`flex-1 px-1 py-0.5 border relative rounded focus:outline-none ${
                        selectedDuration === duration
                            ? 'border-red-500 bg-red text-white ' // Animated and red border for selected
                            : 'border-black' // Black border for others
                    }`}
                    onClick={() => handleDurationSelect(duration)}
                >
                    {selectedDuration === duration &&
                        <div className="absolute z-40 w-1 h-1 bg-red -top-0.5 -right-0.5 ripple overflow-hidden"/>}
                    {duration}
                </button>
            ))}
        </div>

    );
};

export default SessionDurationSelector;
