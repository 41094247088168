import { useTranslation } from "react-i18next";
import ENG from "../locale/eng/reports.json";
import Fr from "../locale/fr/reports.json";
import i18n from "../../../utils/lib/i18n";
interface Props {
  attended: boolean;
  setAttended: (status: boolean) => void;
}

const MeetingStatusSwitch = ({ attended, setAttended }: Props) => {
  i18n.addResourceBundle("en", "reports", ENG);
  i18n.addResourceBundle("fr", "reports", Fr);
  const { t } = useTranslation("reports");
  const handleChange = (e: any) => {
    if (!attended) {
      setAttended(true);
    } else {
      setAttended(false);
    }
  };
  return (
    <div className="inline-block py-2 px-1 bg-warm-light rounded-2xl whitespace-nowrap">
      <span>
        <input
          onChange={handleChange}
          type="radio"
          id="noshow"
          className="hidden peer"
          name="meetingStatus"
          value="noShow"
          checked={!attended}
        />
        <label
          htmlFor="noshow"
          className="bg-warm-light rounded-2xl py-1 px-8 select-none cursor-pointer peer-checked:bg-red peer-checked:text-white font-normal"
        >
          {" "}
          {t("meeting_status.no_show")}
        </label>
      </span>
      <span>
        <input
          onChange={handleChange}
          id="attended"
          type="radio"
          className="hidden peer"
          name="meetingStatus"
          value="attended"
          checked={attended}
        />
        <label
          htmlFor="attended"
          className="bg-warm-light rounded-2xl py-1 px-8 select-none cursor-pointer peer-checked:bg-red peer-checked:text-white font-normal"
        >
          {" "}
          {t("meeting_status.attended")}
        </label>
      </span>
    </div>
  );
};

export default MeetingStatusSwitch;
