export const isEmpty = (obj: object) => {
    return obj ? 0 === Object.keys(obj).length : true;
}

export const isKeyExists = (obj: object, key: string) => {
    return obj.hasOwnProperty(key)
}

export const getMaxValueInInnerKey = (arr: Record<any, any>[], key: any) => {
    return arr.reduce((accumulator, element) => {
        if (accumulator[key] > element[key])
            return accumulator;
        return element;
    });

}
export const filterDict = (dict: { [key: string]: any }, allowedKeys: string[]) => {
    const filtered = allowedKeys
        .filter(key => Object.keys(dict).includes(key))
        .reduce((obj, key) => {
            return {
                ...obj,
                [key]: dict[key] && typeof dict[key] == "object" ? dict[key]?.name : dict[key]
            };
        }, {});
    return filtered;
}



