import * as microsoftTeams from "@microsoft/teams-js";
import {dialog, UrlDialogInfo} from "@microsoft/teams-js";


export const jumpToDeepLink = (entity: string, coach_id?: any, encodedWebUrl?: any, reschedule = false, meeting_id?: number) => {
    microsoftTeams.app.initialize().then(r => {
            console.log('deeplink')
            if (encodedWebUrl) {
                encodedWebUrl = encodeURIComponent(`${process.env.REACT_APP_ROOT_PATH}/${encodedWebUrl}`);
            } else {
                encodedWebUrl = "";
            }
            if (coach_id) {
                let subEntity
                if (reschedule && meeting_id) {
                    subEntity = encodeURIComponent(`{"coach_id": "${coach_id}","reschedule": "${reschedule}","meeting_id": "${meeting_id}"}`);
                } else {
                    subEntity = encodeURIComponent(`{"coach_id": "${coach_id}"}`);

                }
                let taskContext = encodeURIComponent(`{"subEntityId": "${subEntity}"}`);
                microsoftTeams.app.openLink("https://teams.microsoft.com/l/entity/" + process.env.REACT_APP_TEAMS_MANIFEST_APPID + "/" + entity + "?webUrl=" + encodedWebUrl + "&context=" + taskContext)
            } else {
                microsoftTeams.app.openLink("https://teams.microsoft.com/l/entity/" + process.env.REACT_APP_TEAMS_MANIFEST_APPID + "/" + entity + "?webUrl=" + encodedWebUrl)
            }
        }
    )
}
export const showModal = (route: string, title: string = "Coachello", submitHandler?: any, size: number = 900, height?: number) => {
    // console.log(route);
    microsoftTeams.app.initialize().then(r => {
        let url: string
        if (route.search("http") === -1 || !(route.search("=http") === -1)) {
            url = process.env.REACT_APP_ROOT_PATH + route
        } else {
            url = route
        }
        let taskInfo: UrlDialogInfo = {
            title: title,
            url: url,
            size: {
                width: size,
                height: height ? height : (size - size * 0.3)
            }
        };

        const tempSubmitHandler: dialog.DialogSubmitHandler = (res: dialog.ISdkResponse) => {
            const {err, result} = res;
            const results = result as any

            console.log(`Submit handler - result: ${results}\r`);
            console.log(`Submit handler - err: ${err}\r`);

        };
        if (submitHandler) {
            microsoftTeams.dialog.open(taskInfo, submitHandler);
        } else {
            microsoftTeams.dialog.open(taskInfo,tempSubmitHandler);
        }
    })
}

export const getLocale = () => {

        return new Promise((resolve, reject) => {
            microsoftTeams.app.initialize().then(r => {
            microsoftTeams.app.getContext().then(context =>  {
                console.log(context.app.locale);
                if (context.app.locale) {
                    const arr = context.app.locale.split('-');
                    resolve(arr[0]);
                } else {
                    resolve(null);
                }
            });
            })

        })


}
