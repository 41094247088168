export const COACH = {
  get: {
    coachProfile: {
      private: true,
      path: "/api/coaches/me/profile",
    },
    coachSessions: {
      private: true,
      path: "/api/coaches/me/sessions",
    },
    pendingReports: {
      private: true,
      path: "/api/coaches/me/pending-reports",
    },
    sessionsOverview: {
      private: true,
      path: "/api/coaches/me/sessions-overview",
    },
    report: {
      private: true,
      path: "/api/coaches/me/reports/{session_id}",
    },
    getOccupiedEvents: {
      private: true,
      path: "api/coach/{coachId}/events",
    },
    getAllCoaches: {
      private: true,
      path: "api/coach/admin/coaches",
    }
  },
  post: {
    report: {
      private: true,
      path: "/api/coaches/me/reports/{session_id}",
    },
  },
  put: {
    coachProfile: {
      private: true,
      path: "/api/coaches/me/profile",
    },
  },
};
