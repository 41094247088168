import { Outlet } from "react-router-dom";

const PageLayout = () => {
  return (
    <div className="h-[100vh]">
      <Outlet />
    </div>
  );
};

export default PageLayout;
