import {FC} from 'react'
import "../css/NoAccess.css"
import illustration from "../../../assets/img/teams/icons/Illustration.svg"
// Components

import {showModal} from "../../../utils/helpers/teams";
import {useTranslation} from "react-i18next";
import i18n from "../../../utils/lib/i18n";
import ENG from "../locale/eng/noaccess.json";
import Fr from "../locale/fr/noaccess.json";

const NoAccess: FC = () => {
    i18n.addResourceBundle('en', 'noaccess', ENG    );
    i18n.addResourceBundle('fr', 'noaccess', Fr    );
    const {t} = useTranslation('noaccess')
    return (

            <div className="noAccess-container">
                <div className="noAccess-textContainer">
                    <h1 className="noAccess-title">{t('text_1')}</h1>
                    <p className="noAccess-description">{t('text_2')}</p>
                    <div className="noAccess-footer">
                        <button onClick={() => showModal("/no-access-popup", "", false, 400, 450)}>
                            {t('text_3')}
                        </button>
                        <p>{t('text_4')} </p>
                    </div>
                </div>

                <div className="noAccess-imgContainer">
                    <img src={illustration}/>
                </div>

            </div>

    )
}

export default NoAccess;
