import React, {createContext, useState, ReactNode} from 'react';
import {OverviewItem, ReferenceData, SummaryData} from "./types";
import {deleteChat} from "../../../../services/api/aiBookingApi";

interface ContextProps {
    selectedOverviewItem: OverviewItem | undefined;
    setSelectedOverviewItem: React.Dispatch<React.SetStateAction<OverviewItem | undefined>>
    hasSummary: boolean | undefined;
    setHasSummary: React.Dispatch<React.SetStateAction<boolean | undefined>>
    displayReview: boolean;
    setDisplayReview: React.Dispatch<React.SetStateAction<boolean>>;
    handleDelete: (id: number) => void;
    setOverviewItems: React.Dispatch<React.SetStateAction<OverviewItem[]>>;
    overviewItems: OverviewItem[];
    setOverviewJourneyItems: React.Dispatch<React.SetStateAction<OverviewItem[]>>;
    setJourneyLoaded: React.Dispatch<React.SetStateAction<boolean>>;
    journeyLoaded: boolean;
    overviewJourneyItems: OverviewItem[];
    journeySessionId: number | undefined;
    setJourneySessionId: React.Dispatch<React.SetStateAction<number | undefined>>;
    summary: SummaryData | undefined;
    setSummary: React.Dispatch<React.SetStateAction<SummaryData | undefined>>;
    referenceMaterials: ReferenceData | undefined;
    setReferenceMaterials: React.Dispatch<React.SetStateAction<ReferenceData | undefined>>;
}

const BookingDataContext = createContext<ContextProps | undefined>(undefined);

interface ProviderProps {
    children: ReactNode;
}

const BookingDataProvider: React.FC<ProviderProps> = ({children}) => {
    const [selectedOverviewItem, setSelectedOverviewItem] = useState<
        OverviewItem | undefined
    >();
    const [hasSummary, setHasSummary] = useState<boolean | undefined>(false);
    const [displayReview, setDisplayReview] = useState(false);
    const [journeyLoaded, setJourneyLoaded] = useState(false);
    const [overviewItems, setOverviewItems] = useState<OverviewItem[]>([]);
    const [overviewJourneyItems, setOverviewJourneyItems] = useState<OverviewItem[]>([]);
    const [journeySessionId, setJourneySessionId] = useState<number>();
    const [summary, setSummary] = useState<SummaryData | undefined>(undefined);
    const [referenceMaterials, setReferenceMaterials] = useState<ReferenceData | undefined>(undefined);


    const handleDelete = (id: number) => {
        deleteChat(id).then((res) => {
            if (overviewJourneyItems) setOverviewJourneyItems(overviewJourneyItems?.filter(item => item?.id !== id))
            setOverviewItems(overviewItems?.filter(item => item?.id !== id))
        })
    }


    const contextValue: ContextProps = {
        selectedOverviewItem, setSelectedOverviewItem,
        hasSummary, setHasSummary,
        displayReview, setDisplayReview,
        handleDelete, overviewItems, setOverviewItems,
        overviewJourneyItems, setOverviewJourneyItems,
        setJourneyLoaded, journeyLoaded,
        journeySessionId, setJourneySessionId,
        summary, setSummary,
        referenceMaterials, setReferenceMaterials,
    };

    return (
        <BookingDataContext.Provider value={contextValue}>
            {children}
        </BookingDataContext.Provider>
    );
};

export {BookingDataContext, BookingDataProvider};
