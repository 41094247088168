import {FC, useEffect, useRef, useState} from "react";

import {SearchIcon} from "@heroicons/react/outline";
import {debounce} from "lodash";
import {useTranslation} from "react-i18next";

type SearchInputProps = {
    onChange?: (value: string) => void;
    searchText?: string;
};

const SearchInput: FC<SearchInputProps> = ({onChange, searchText}) => {
    const {t} = useTranslation('commons')
    const [text, setText] = useState(searchText);

    const debouncedSearch = useRef(
        debounce(async (criteria) => {
            onChange && onChange(criteria);
        }, 1500) // Debounce-time 1.5 seconds
    ).current;

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    return (
        <div
            className="h-[36px] w-max flex flex-row items-center gap-2 px-2 pl-3 border-[1px] border-[#f2ebe3] rounded-[5px]">
            <input
                className="w-60 font-lexend text-sm font-bold text-black placeholder:font-light placeholder:text-[#bfbfbf] focus:outline-none"
                placeholder={t('text2')}
                value={text}
                onChange={(e) => {
                    setText(e.target.value);
                    debouncedSearch(e.target.value);
                }}
            />
            <SearchIcon className="h-[20px] text-black"/>
        </div>
    );
};

export default SearchInput;
