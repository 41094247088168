import {useState} from "react";

import {useTranslation} from "react-i18next";

import ChatGuide from "../../../../../../shared/components/ChatGuide";
import VerticalExpander from "../../../../../dashboard/pages/OnboardingRegular/components/VerticalExpander";
import HT from "../../../../../dashboard/pages/OnboardingRegular/components/HighlightedText";

import bookingConfirmedImage from "@img/coaching/img/booking_confirmed.png";

const boldText = (text: string) => {
    return text.replace(/\*(.*?)\*/g, '<span class="font-bold">$1</span>');
};

const BookingConfirmed: React.FC = () => {
    const {t} = useTranslation("booking");

    return (
        <div className="w-full h-2/3 flex flex-row gap-4 justify-start items-center">
            <div className="flex flex-col gap-4">
                <h1 className="text-lg text-black font-bold">{t("booking_confirmed.title")}</h1>
                <p className="text-base text-black">
                    <span dangerouslySetInnerHTML={{__html: boldText(t("booking_confirmed.description"))}}/>
                </p>
            </div>
            <img src={bookingConfirmedImage} alt="Booking Confirmed"/>
        </div>
    );
};

export default BookingConfirmed;
