import { FC } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "../../../../shared/components/Button";

import { setGuideStatus } from "../../../../services/api/teams/GuideActions";
import i18n from "../../../../utils/lib/i18n";
import WelcomeENG from "../../locale/eng/welcome.json";
import WelcomeFR from "../../locale/fr/welcome.json";

import illustration from "@img/dashboard/welcome/img/welcome.svg";
import classified from "@img/dashboard/welcome/icon/confidential.svg";
import incognito from "@img/dashboard/welcome/icon/spy.svg";

const Welcome: FC = () => {
  i18n.addResourceBundle("en", "welcome", WelcomeENG);
  i18n.addResourceBundle("fr", "welcome", WelcomeFR);

  const navigate = useNavigate();
  const { t } = useTranslation("welcome");

  return (
    <div className="bg-linen_gradient h-screen flex items-center w-full">
      <div className="flex flex-1 h-full gap-8">
        <div className="basis-1/2 font-lexend mt-10 flex flex-col items-center justify-center">
          <div className="w-3/4">
            <h1 className="text-black text-2xl font-bold">
              <div>{t("high_quality")}</div>
              <div>{t("personalised")}</div>
            </h1>

            <p className="text-black mt-3 leading-8 font-bold text-sm">
              {t("des")}
            </p>

            <div className="my-6">
              <div className="flex flex-col items-start gap-3 sm:flex-row">
                <img
                  src={classified}
                  alt="confidential"
                  className="w-7 h-7 mt-0.5"
                />
                <div>
                  <h2 className="text-black text-lg leading-9 mb-2">
                    {t("confidential")}
                  </h2>
                  <p className="text-gray font-dm_sans text-xs">
                    {t("confidential_desc")}
                  </p>
                </div>
              </div>

              <div className="flex flex-col items-start gap-4 mt-5 sm:flex-row">
                <img
                  src={incognito}
                  alt="anonymized"
                  className="w-6 h-6 mt-1"
                />
                <div>
                  <h2 className="text-black text-lg leading-9 mb-2">
                    {t("employers_only")}
                  </h2>
                  <p className="text-gray font-dm_sans text-xs">
                    {t("employers_only_desc")}
                  </p>
                </div>
              </div>
            </div>

            <div className="ml-10">
              <Button
                className="text-white bg-black text-xs font-bold px-4 py-2"
                onClick={() => {
                  setGuideStatus(1)
                    .then(() => {
                      navigate("/dashboard/onboarding", {
                        state: {
                          statusData: {
                            step: 1,
                          },
                        },
                      });
                    })
                    .catch((e) => console.log(e));
                }}
              >
                {t("continue")}
              </Button>
              <p className="mt-2 font-dm_sans text-xs text-gray leading-6">
                {t("byproceeding")}
              </p>
            </div>
          </div>
        </div>

        <div className="relative basis-1/2 font-lexend">
          <img
            className="h-full w-full object-cover"
            src={illustration}
            alt=""
          />
          <div className="absolute flex flex-col gap-5 bottom-14 left-20">
            <h1 className="text-white text-2xl font-bold">
              {t("quote.text_1")}
              <br />
              {t("quote.text_2")}
            </h1>
            <div className="flex flex-col">
              <p className="text-white text-lg font-bold">Heraclitus</p>
              <p className="text-white text-xs font-semibold">
                {t("greek_philosopher")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
