import React, { LegacyRef, useEffect, useMemo, useRef } from "react";

import { useTranslation } from "react-i18next";
import moment, { Moment } from "moment";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { EventInput } from "@fullcalendar/core";
import "./FullCalendar.css";

import LoaderContainer from "../../../../../../shared/components/LoaderContainer";

import { userTimeZone } from "../../../../../../utils/helpers/time";
import {
  IOccupiedEvents,
  ITime,
  ITimeSlot,
} from "../../../../../../models/IBooking";

type Props = {
  occupiedEvents: IOccupiedEvents;
  timeSlot: ITimeSlot | null;
  onChange: (field: string, value: string | ITime) => void;
  handleNext?: () => void;
  isOnboarding?: boolean;
  slotSize: number;
  isLoading?: boolean;
  dayRange: {
    start: string;
    end: string;
  };
  slotSeparationSize: number;
};

const EventView: React.FC<Props> = ({
  occupiedEvents,
  timeSlot,
  onChange,
  handleNext,
  isOnboarding,
  slotSize,
  dayRange,
  slotSeparationSize,
  isLoading = false,
}) => {
  const { t } = useTranslation("booking");

  const lng = localStorage.getItem("I18N_LANGUAGE") || "en";
  const calendarRef = useRef<FullCalendar>();

  useEffect(() => {
    changeTimeGridView();
  }, [timeSlot]);

  // change time grid view upon changing calendar date
  const changeTimeGridView = () => {
    let start = moment().add(1, "days").toDate();
    let end = moment().add(3, "days").toDate();

    if (timeSlot?.date) {
      start = moment(timeSlot.date).toDate();
      end = moment(timeSlot.date).add(3, "days").toDate();
    }

    if (calendarRef.current) {
      calendarRef.current?.getApi().changeView("timeGrid", {
        start,
        end,
      });
    }
  };

  // array of (occupied events)
  let occupiedEventsArr: EventInput[] = [];

  if (occupiedEvents) {
    // constructs occupied events
    occupiedEventsArr = Object.keys(occupiedEvents)
      .map((key) => {
        return occupiedEvents[key].map((timeRange): EventInput => {
          return {
            start: moment(`${key}T${timeRange.split("-")[0]}`).toDate(),
            end: moment(`${key}T${timeRange.split("-")[1]}`).toDate(),
            display: "background",
          };
        });
      })
      .reduce((prevValue, currValue) => {
        return [...prevValue, ...currValue];
      }, []);
  }

  // array of (occupied events + selected slot event)
  let events: EventInput[] = occupiedEventsArr;

  // adds the selected time slot indication event to the events array
  if (timeSlot && timeSlot.time) {
    const time = timeSlot.time;
    events = [
      ...events,
      {
        start: moment(`${time.date}T${time.start}`).toDate(),
        end: moment(`${time.date}T${time.end}`).toDate(),
        backgroundColor: "#FFCCD8",
        textColor: "#FD0054",
        borderColor: "#FD0054",
      },
    ];
  }

  const contructTime = (start: Moment | string | null) => {
    return {
      date: moment(start).format("YYYY-MM-DD"),
      start: moment(start).format("HH:mm"),
      end: moment(start).add(slotSize, "minutes").format("HH:mm"),
    };
  };

  const getsOverlapped = (
    selectedTimeStart: Moment,
    selectedTimeEnd: Moment,
    occupiedEventStart: Moment,
    occupiedEventEnd: Moment,
  ) => {
    return (
      (selectedTimeStart.isSame(occupiedEventStart) &&
        selectedTimeEnd.isBetween(occupiedEventStart, occupiedEventEnd)) ||
      (selectedTimeEnd.isSame(occupiedEventEnd) &&
        selectedTimeStart.isBetween(occupiedEventStart, occupiedEventEnd)) ||
      (selectedTimeStart.isSame(occupiedEventStart) &&
        selectedTimeEnd.isSame(occupiedEventEnd)) ||
      (selectedTimeStart.isBetween(occupiedEventStart, occupiedEventEnd) &&
        selectedTimeEnd.isBetween(occupiedEventStart, occupiedEventEnd))
    );
  };

  const tz = useMemo(() => userTimeZone(), []);

  const slotDuration = `00:${
    String(slotSeparationSize).length > 1
      ? slotSeparationSize
      : "0" + slotSeparationSize
  }:00`;

  return (
    <div className="relative flex flex-col items-center justify-start w-full h-full py-4 bg-white rounded-lg shadow">
      {isLoading && (
        <div className="z-10 absolute right-0 left-0 top-0 bottom-0 flex justify-center items-center bg-white">
          <LoaderContainer />
        </div>
      )}

      <FullCalendar
        ref={calendarRef as LegacyRef<FullCalendar> | undefined}
        plugins={[momentTimezonePlugin, timeGridPlugin, interactionPlugin]}
        timeZone={tz}
        locale={lng}
        allDaySlot={false}
        initialDate={
          timeSlot && timeSlot.date
            ? moment(timeSlot.date).toDate()
            : moment().toDate()
        }
        slotMinTime={`${dayRange.start}:00:00`}
        slotMaxTime={`${dayRange.end}:00:00`}
        initialView={"timeGrid3Day"}
        slotLabelInterval={"01:00:00"}
        slotDuration={slotDuration}
        scrollTimeReset={false}
        eventColor="#EFEAF9"
        eventTextColor="#BDACE8"
        eventBorderColor="#BDACE8"
        dateClick={(e) => {
          const eventDate = moment(e.date);

          const selectedTimeStart = eventDate.clone();
          const selectedTimeEnd = eventDate.clone().add(slotSize, "m");

          // returns if selected slot end passes the day range end
          if (
            selectedTimeEnd.isAfter(
              moment(e.date)
                .set("hour", Number(dayRange.end))
                .set("minutes", 0),
            )
          )
            return;

          // checks if the picked slot overlaps with any events
          const overlappingOccupiedEvent = occupiedEventsArr.find((event) => {
            const occupiedEventStart = moment(event.start);
            const occupiedEventEnd = moment(event.end);
            return (
              getsOverlapped(
                selectedTimeStart,
                selectedTimeEnd,
                occupiedEventStart,
                occupiedEventEnd,
              ) ||
              (selectedTimeStart.isBefore(occupiedEventEnd) &&
                selectedTimeEnd.isAfter(occupiedEventEnd)) ||
              (selectedTimeStart.isBefore(occupiedEventStart) &&
                selectedTimeEnd.isAfter(occupiedEventStart))
            );
          });

          // adjusts the picked slot if possible (if overlaps with any events)
          if (overlappingOccupiedEvent) {
            let availableTimeSpaceAbove = 0;
            let availabilityStart = null;

            for (let i = 1; i <= slotSize / 5; i++) {
              const currentIterationStart = moment(
                overlappingOccupiedEvent.start,
              ).subtract(5 * i, "minutes");
              const currentIterationEnd = moment(currentIterationStart).add(
                5,
                "minutes",
              );
              availabilityStart = currentIterationStart;
              const overlaps = occupiedEventsArr.find((event) => {
                const occupiedEventStart = moment(event.start);
                const occupiedEventEnd = moment(event.end);

                return getsOverlapped(
                  currentIterationStart,
                  currentIterationEnd,
                  occupiedEventStart,
                  occupiedEventEnd,
                );
              });
              if (!overlaps && (i - 1) * 5 === availableTimeSpaceAbove) {
                availableTimeSpaceAbove = i * 5;
              }
            }
            if (
              availabilityStart &&
              availabilityStart.isBefore(
                moment(e.date)
                  .set("hour", Number(dayRange.start))
                  .set("minutes", 0),
              )
            )
              return;
            if (availableTimeSpaceAbove === slotSize) {
              // emit adjusted time slot
              onChange("time", contructTime(availabilityStart));
            }
          } else {
            const dateStr = e.dateStr.split("Z")[0];
            // emit un-adjusted time slot
            onChange("time", contructTime(dateStr));
          }
        }}
        dayHeaderContent={(e) => {
          return (
            <div
              className={`text-center font-normal ${
                e.isToday ? "text-secondary" : "text-gray"
              }`}
            >
              {moment(e.date).format("DD")}
              <br />
              {moment(e.date).locale(lng).format("ddd")}
            </div>
          );
        }}
        views={{
          timeGrid3Day: {
            type: "timeGrid",
            duration: { days: 3 },
            buttonText: "3 day",
          },
        }}
        // editable
        eventOverlap={false}
        events={events}
        headerToolbar={false}
        slotLabelContent={(e) => (
          <div className="font-light text-xxs px-1">
            {moment(e.date).format("HH:mm")}
          </div>
        )}
      />
      {!isOnboarding && (
        <button
          className="mt-5 rounded-md px-6 py-2 text-black border-2 border-black text-xs font-bold h-max hover:bg-black hover:text-white"
          onClick={() => handleNext && handleNext()}
        >
          {t("event_view.continue")}
        </button>
      )}
    </div>
  );
};

export default EventView;
