import {useTranslation} from "react-i18next";
import {twMerge} from "tailwind-merge";

import CoachWebsite from "./CoachWebsite";

import ICoach from "../../../../../models/ICoach";
import {ELanguageFr} from "../../../../../constants/meta/ELanguageFr";
import {ESpecializationFr} from "../../../../../constants/meta/ESpecializationFr";
import {ELocationFr} from "../../../../../constants/meta/ELocationFr";

import locationIcon from "@img/coaching/icons/location.svg";
import languageIcon from "@img/coaching/icons/languages.svg";
import playIcon from "@img/coaching/icons/play-button.png";
import resumeIcon from "@img/icons/resume.png";

type Props = {
    coach: ICoach;
    isSelected: boolean;
    onSelect?: () => void;
    playCoachVideo: (url: string, title: string) => void;
};

const CoachCard = ({coach, isSelected, onSelect, playCoachVideo}: Props) => {
    const lng = localStorage.getItem("I18N_LANGUAGE") || "en";
    const {t} = useTranslation("booking");

    let city = coach.city ? coach.city : "-";

    if (lng === "fr" && ELocationFr[city as keyof typeof ELocationFr]) {
        city = ELocationFr[city as keyof typeof ELocationFr];
    }

    const textColor = coach.orientation ? "text-[#26934a]" : "text-red";
    const bgColor = coach.orientation ? "bg-[#26934a]" : "bg-red";
    const borderColor = coach.orientation ? "border-[#26934a]" : "border-red";

    return (
        <div className="bg-white rounded-md relative h-max">
            <div className="block w-full overflow-hidden bg-gray-100 rounded-t-lg group h-[200px]">
                <div
                    className="object-contain overflow-hidden bg-center bg-cover flex justify-end items-end group h-full relative"
                    style={{backgroundImage: `url(${coach.image_url})`}}
                >
                    <div className="flex absolute top-2 right-2 z-10 gap-2   ">
                        {coach.cv_url ? (
                            <a
                                title="Open this CV"
                                data-tip={"hover on me will keep the tooltip"}
                                data-for={"cv"}
                                target="_blank"
                                href={coach.cv_url}
                                className="cursor-pointer"
                            >
                                <img src={resumeIcon} className={twMerge("w-6 h-6  hover:bg-white rounded")}/>
                            </a>

                        ) : null}
                        {coach.website ? (
                            <div className="cursor-pointer  ">
                                <CoachWebsite link={coach.website}/>
                            </div>
                        ) : null}
                    </div>

                    <div className="flex items-center justify-start mb-2 absolute z-10">
                        {coach.popular ? (
                            <span className="px-1 mr-2 font-bold text-gray font-dm_sans text-xxs bg-yellow rounded">
                {t("coach_card.popular")}
              </span>
                        ) : null}
                        {coach.recommended ? (
                            <span className="px-1 mr-2 font-bold text-gray font-dm_sans text-xxs bg-warm rounded">
                {t("coach_card.recommended")}
              </span>
                        ) : null}
                    </div>
                    {coach.video_url ? (
                        <div
                            className="absolute hidden items-center justify-center cursor-pointer
                    right-0 left-0 top-0 bottom-0 group-hover:flex bg-transparent"
                            onClick={() =>
                                playCoachVideo(coach.video_url, t("coach_card.coach_video"))
                            }
                        >
                            <img className="object-contain w-8 h-8" src={playIcon} alt=""/>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="px-4 py-4 flex-1 flex flex-col justify-between h-max min-h-[315px]">
                <div className="flex flex-col gap-1">
                    <div className="flex items-center justify-start">
                        <p className="block pb-2 mr-3 font-bold text-black font-dm_sans text-sm truncate pointer-events-none">
                            {coach.first_name} {coach.last_name}
                        </p>
                    </div>
                    <p className="flex items-center font-bold text-gray font-dm_sans text-xxs">
                        <img
                            className="inline-flex mr-3 h-5 w-5"
                            src={locationIcon}
                            alt=""
                        />
                        <span>{city}</span>
                    </p>
                    <div className="flex items-center  font-bold text-gray font-dm_sans text-xxs ml-0.5">
                        <svg
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 122.88 119.08"
                            className="w-4 h-4 fill-red mr-3.5"
                        >
                            <style type="text/css">
                                {`
        .st0 {
          fill-rule:evenodd;
          clip-rule:evenodd;
        }
      `}
                            </style>
                            <g>
                                <path
                                    className="st0"
                                    d="M55.27,0c29.86,0,54.19,23.69,55.23,53.29c-2.28-1.25-4.7-2.29-7.23-3.08c0.08-0.88,0.32-1.11,0.57-2.19
        l1.76,0.19c-2.68-19.88-16.6-36.2-35.14-42.37l0,0l-6.81,1.98v-1.8l-1.2,0L62.4,7.69l-2.67,0.73l-0.34,1.25l-3.57-2.94l4.81,0.49
        c-0.83-1.13-5.9-3-7.8-3l-1.8,0c-1.94,0-8.05,5.14-10.82,6.6l0.65,3.14l3.5-0.72l0.54,2.58l-2.27-1.41c-0.01,0.01-0.6,1.12-0.6,1.2
        c0,1.37,0.2,0.7,0.37,1.96l-8.77,4.04v1.2l0.65-0.4l1.75,4l-5.4,0v6c2.38,0.2,1.45,0.6,3,0.6c2.33,0,2.94-1.12,3.25-3.35l2.89-2.77
        l2.93-1.15l4.89,5.13l-1.96,0.94c2.1,2.91,2.16-1.24,2.66-2.68l-3.81-4l0.59-0.58l4.47,3.19l2.08,5.28c0.53-0.3,1.2-0.39,1.2-1.2
        c0-1.37-0.2-0.7-0.58-2.33l2.08-0.55l2.09,4.56l4.81-0.47c0,2.38-0.12,5.4-2.4,5.4h-1.2c-3.31,0-4.01-1.2-6.7-1.83l-0.56,2.56
        l-5.94-2.53v-4.2l-5.4,0c-1.96,0-1.58,1.8-4.8,1.8l-1.5,0.26l-2.7,2.75v3c-1.96,1.31-4.8,4.69-4.8,7.8v7.2c0,0.85,3.83,7.8,6,7.8
        h2.4c2.62,0,2.18-1.2,4.8-1.2h0.6c1.66,0,1.44,0.46,1.8,1.8h2.4v4.8c0,0.76,3,4.91,3,7.8c0,3.13-1.2,3.47-1.2,6c0,2.98,3,8.99,3,13.2
        l3.6,0c0.45,0,0.89-0.06,1.32-0.17c1.07,5.55,3.3,10.68,6.43,15.14c-1.36,0.1-2.74,0.15-4.12,0.15C24.75,110.53,0,85.79,0,55.27
        C0,24.75,24.75,0,55.27,0L55.27,0L55.27,0z M91.57,56.46c17.29,0,31.31,14.02,31.31,31.31c0,17.29-14.02,31.31-31.31,31.31
        s-31.31-14.02-31.31-31.31C60.26,70.48,74.28,56.46,91.57,56.46L91.57,56.46L91.57,56.46z M87.23,74.07h3.83c0.7,0,1.27,0.58,1.27,1.27
        v12.69h12.43c0.7,0,1.27,0.58,1.27,1.27v3.83c0,0.7-0.58,1.27-1.27,1.27H85.95V75.35C85.95,74.64,86.52,74.07,87.23,74.07L87.23,74.07L87.23,74.07z
        M91.57,63.57c13.37,0,24.2,10.83,24.2,24.2c0,13.37-10.83,24.2-24.2,24.2c-13.37,0-24.2-10.83-24.2-24.2C67.37,74.41,78.2,63.57,91.57,63.57
        L91.57,63.57L91.57,63.57z M96.16,48.71l-0.56-1.73l-1.48,1.54C94.81,48.57,95.49,48.63,96.16,48.71L96.16,48.71z M85.48,48.91l-0.27-1.52l-1.28,0.93
        l-2.9-3.91l-4.33,0.13l-1.66-1.9l-1.7,0.47l-3.34-3.83l-0.64,0.44l2.07,5.29l2.4,0v-1.2l1.2,0c0.87,2.39,1.8,0.97,1.8,2.4c0,4.99-6.16,8.66-10.2,9.6
        c0.11,0.48,0.14,0.95,0.3,1.29C72.18,52.9,78.53,49.99,85.48,48.91L85.48,48.91z M10.58,83.82c-3.26-4.94-5.69-10.47-7.12-16.39l9.73,4.69l0.05,2.9
        c0,1.07-1.82,3.34-2.4,4.2L10.58,83.82L10.58,83.82L10.58,83.82z"
                                />
                            </g>
                        </svg>
                        {coach.timezone}
                    </div>
                    <p className="flex items-center font-bold text-gray font-dm_sans text-xxs overflow-hidden mb-3">
                        <img
                            className="inline-flex mr-3 h-5 w-5"
                            src={languageIcon}
                            alt=""
                        />
                        <span>
              {coach.languages
                  .map((language) =>
                      lng === "fr"
                          ? ELanguageFr[language.language as keyof typeof ELanguageFr]
                          : language.language,
                  )
                  .join(" / ")}
            </span>
                    </p>
                    <div className="h-max">
                        <div className="w-full flex flex-wrap relative">
                            {coach.specializations.map((specialization) => {
                                return (
                                    <span
                                        key={specialization.id}
                                        className="px-1.5 py-0.5 mb-1 mr-1  font-bold text-gray font-dm_sans text-xxs rounded bg-warm"
                                    >
                    {lng === "fr"
                        ? ESpecializationFr[
                            specialization.specialization as keyof typeof ESpecializationFr
                            ]
                        : specialization.specialization}
                  </span>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className="flex items-center justify-center mt-3">
                    <button
                        className={twMerge(
                            "w-full py-3 text-center font-bold font-dm_sans text-xs rounded border-2",
                            isSelected ? `${textColor} bg-white` : `text-white ${bgColor}`,
                            borderColor,
                        )}
                        onClick={onSelect}
                    >
                        {isSelected
                            ? t("coach_card.selected")
                            : coach.orientation ? t("coach_card.orientation_session") : t("coach_card.book_session")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CoachCard;
