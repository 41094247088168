// ChatContext.tsx
import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
// Import Immer for more efficient state updates
import {produce} from "immer"
import Questions from "../components/Questions";
import {AIModel} from "../enums";
import {createAIThread, processChatPrompt} from "../actions";


// Create the context
const AICoachingContext = createContext<AICoachingContextType | undefined>(undefined);

export type IChatItem = {
    id: string;
    role: "me" | "assistant" | "system";
    content: any;
    header?: ReactNode;
    footer?: ReactNode;
    showProfile?: boolean
    profile?: string
    timestamp?: Date;
    // placement: BubblePlacement;
    // needUserInput?: boolean
};


export type IOverviewItem = IThreadItem & {
    subSessions?: IThreadItem[];
};

export type  AICoachingState = "init" | "flash" | "journey" | "thread" | "summary"

export type IThreadItem = {
    id: number;
    journeyId?: number;
    title: string | null;
    datetime: string;
    aiModel?: AIModel | null;
    image?: string;
    sessionNo?: number;
    isNew?: boolean;
    prevThread?: IThreadItem;
    nextThread?: IThreadItem;
};

interface AICoachingContextType {
    messages: IChatItem[];
    addMessage: (message: IChatItem) => void;
    setMessages: (messages: IChatItem[]) => void;
    removeMessage: (item: IChatItem, index: number) => void;
    lastChangedIndex: number;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    // isOverviewLoaded: boolean;
    // setIsOverviewLoaded: (isOverviewLoaded: boolean) => void;
    overviewItems: IOverviewItem[]
    setOverviewItems: (items: IOverviewItem[]) => void
    overviewJourneyItems?: IOverviewItem[]
    setOverviewJourneyItems: (items: IOverviewItem[]) => void
    selectedThreadItem?: IThreadItem
    setSelectedThreadItem: React.Dispatch<React.SetStateAction<IThreadItem | undefined>>
    setCustomChatSection: (content: any) => void
    customChatSection: any;
    state: AICoachingState;
    setState: (state: AICoachingState) => void;
    chatPrompt: string;
    setChatPrompt: (text: string) => void;
    createNewThread: (aiModel: AIModel, journeyId?: number) => Promise<any>
    isStartState: boolean;
    setIsStartState: (isStartState: boolean) => void;
    isJourneyVisible?: boolean;
}

// Create a provider component
export const AICoachingProvider: React.FC<{ children: ReactNode; isJourneyVisible?: boolean }> = ({children, isJourneyVisible = true}) => {
    const [overviewItems, setOverviewItems] = useState<IOverviewItem[]>([]);
    const [overviewJourneyItems, setOverviewJourneyItems] = useState<IOverviewItem[]>([]);
    const [selectedThreadItem, setSelectedThreadItem] = useState<IThreadItem | undefined>();

    const [customChatSection, setCustomChatSection] = useState<any>();
    const [chatPrompt, setChatPrompt] = useState("");

    const [state, setState] = useState<AICoachingState>("init");
    const [isStartState, setIsStartState] = useState(false);

    const [messages, setMessages] = useState<IChatItem[]>([]);
    const [isLoading, setIsLoading] = useState(false)
    // const [isOverviewLoaded, setIsOverviewLoaded] = useState(false)
    const [lastChangedIndex, setLastChangedIndex] = useState<number>(0);


    // console.log(selectedThreadItem);
    // console.log(state);
    // console.log(overviewJourneyItems);
    // console.log(messages);


    useEffect(() => {
        if (!selectedThreadItem) setMessages([])
    }, [selectedThreadItem]);

    function addSubSessionToJourney(
        journeyId: number,
        newSubSession: IThreadItem,
        afterSubSessionId?: number
    ) {
        const newMappedJourneyOverview = [...overviewJourneyItems];
        // Find the journey
        const journey = newMappedJourneyOverview.find(journey => journey.id === journeyId);
        if (!journey) {
            throw new Error(`Journey with id ${journeyId} not found`);
        }
        if (!journey.subSessions) journey.subSessions = []

        // Find the index to insert the new sub-session
        let insertIndex = journey.subSessions.length;
        if (afterSubSessionId !== undefined) {
            const afterSubSessionIndex = journey.subSessions.findIndex(subSession => subSession.id === afterSubSessionId);
            if (afterSubSessionIndex === -1) {
                throw new Error(`Sub-session with id ${afterSubSessionId} not found`);
            }
            insertIndex = afterSubSessionIndex + 1;
        }

        // Insert the new sub-session
        journey.subSessions.splice(insertIndex, 0, newSubSession);

        // Update prevThread and nextThread references
        const prevSubSession = insertIndex > 0 ? journey.subSessions[insertIndex - 1] : undefined;
        const nextSubSession = insertIndex < journey.subSessions.length - 1 ? journey.subSessions[insertIndex + 1] : undefined;

        if (prevSubSession) {
            prevSubSession.nextThread = newSubSession;
            newSubSession.prevThread = prevSubSession;
        }
        if (nextSubSession) {
            nextSubSession.prevThread = newSubSession;
            newSubSession.nextThread = nextSubSession;
        }
        return newMappedJourneyOverview;
    }

    const createNewThread = async (aiModel: AIModel, journeyId?: number) => {
        const newThread = await createAIThread(aiModel, journeyId);
        let newThreadItem: IThreadItem
        if (aiModel == AIModel.journey) {

            newThreadItem = {
                id: newThread.id,
                title: newThread.title,
                datetime: newThread.datetime,
                aiModel: newThread.ai_model,
                sessionNo: newThread.session_no,


            };
            setOverviewJourneyItems([newThreadItem, ...overviewJourneyItems])

        } else if (aiModel == AIModel.session && journeyId) {
            newThreadItem = {
                id: newThread.id,
                title: newThread.title,
                datetime: newThread.datetime,
                journeyId: journeyId,
                aiModel: newThread.ai_model,
                sessionNo: newThread.session_no,
                prevThread: undefined,
                nextThread: undefined
            };

            const overview = addSubSessionToJourney(journeyId, newThreadItem)
            setOverviewJourneyItems(overview)
        } else {

            newThreadItem = {
                id: newThread.id,
                title: newThread.title,
                datetime: newThread.datetime,
                aiModel: newThread.ai_model,
                sessionNo: newThread.session_no,

            };
            setOverviewItems([newThreadItem, ...overviewItems])

        }
        setSelectedThreadItem({...newThreadItem, isNew: true})
        return newThreadItem
    }


    const handleSetSelectedThreadItem: React.Dispatch<React.SetStateAction<IThreadItem | undefined>> = (item: React.SetStateAction<IThreadItem | undefined>) => {
        setCustomChatSection(undefined)
        setChatPrompt("")
        setSelectedThreadItem(prevItem => {
            const updatedItem = typeof item === 'function' ? (item as (prevState: IThreadItem | undefined) => IThreadItem | undefined)(prevItem) : item;
            return updatedItem;
        });
        // setSelectedThreadItem(prevItem => {
        //     return prevItem? {...prevItem}: prevItem
        // })
    };

    const handleSetState = async (state: AICoachingState) => {
        if (state == "flash") {
            setCustomChatSection(<Questions onSelectQuestion={(question) => setChatPrompt(question)}/>)
        }
        if (state == "init") setSelectedThreadItem(undefined)
        setChatPrompt("")
        setState(state)
        if (state == 'journey') {
            setIsLoading(true);
            setIsStartState(true);
            const newThread = await createNewThread(AIModel.journey)
            const promptResponse = await processChatPrompt(newThread.id, '');

            addMessage({
                id: Math.random().toString(),
                content: promptResponse.reply,
                role: "assistant",
            });
            setIsLoading(false);
            setIsStartState(false);
        }
    }

    function removeMessage(item: IChatItem, index: number) {
        // if (onRemoveMessage) {
        //     onRemoveMessage(item)
        // }
        const newMessages = [...messages];

        setLastChangedIndex(index)
        const removedItem = newMessages.splice(newMessages.findIndex(({id}) => id === item.id), 1);
        setMessages(newMessages);
        return removedItem
        // setMessages((messages) => messages.filter((m, i) =>  m.id !== item.id));
    }

    const addMessage = (message: IChatItem) => {
        // setMessages((current) => [newMessage, ...current])
        setMessages((currentMessages) =>
            produce(currentMessages, (draft) => {
                draft.unshift(message);
            })
        );//This is the performance optimized way
        // setMessages((prevMessages) => {
        //
        //     const updatedMessages = [...prevMessages];
        //     const emptyMessageIndex = 1;
        //
        //         updatedMessages[emptyMessageIndex] = {
        //             ...updatedMessages[emptyMessageIndex],
        //             content:mes.content, // Replace 'Your new content' with the actual content you want to show
        //         };
        //
        //     return updatedMessages;
        // });
        // const r = removeMessage(mes,0)[0]
        // setMessages((current) => [{...r,content:mes.content}, ...current])
        setLastChangedIndex(1) // Loading item is in 0    };
    }
    return (
        <AICoachingContext.Provider
            value={{
                messages, addMessage, setMessages, removeMessage,
                lastChangedIndex,
                isLoading, setIsLoading,
                isStartState, setIsStartState,
                // isOverviewLoaded,setIsOverviewLoaded,
                overviewItems, overviewJourneyItems, setOverviewJourneyItems, setOverviewItems,
                selectedThreadItem,
                setSelectedThreadItem: handleSetSelectedThreadItem,
                customChatSection, setCustomChatSection,
                state,
                setState: handleSetState,
                chatPrompt, setChatPrompt,
                createNewThread,
                isJourneyVisible
            }}>
            {children}
        </AICoachingContext.Provider>
    );
};

// Custom hook to use the AICoachingContext
export const useAICoaching = (): AICoachingContextType => {
    const context = useContext(AICoachingContext);
    if (!context) {
        throw new Error('useChat must be used within a ChatProvider');
    }
    return context;
};
