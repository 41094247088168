import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import ISidebarMenu from "../../models/ISidebarMenu";
import LanguageSelector from "../../shared/components/LanguageSelector/LanguageSelector";
import {SidebarData} from "../../constants/data/SidebarData";
import useAuth from "@hooks/useAuth";
import {useConfig} from "../../utils/providers/AppConfigProvider";
import {AICoachType} from "../../modules/dashboard/pages/AICoaching/enum";

const SidebarItem = ({
                         item,
                         isOpen,
                     }: {
    item: ISidebarMenu;
    isOpen: boolean;
}) => {
    const {t} = useTranslation("layout");

    return (
        <NavLink
            className={({isActive}) => {
                return (
                    (isActive
                        ? "border-l-4 border-l-blue font-semibold text-blue focus:text-blue focus:font-bold focus:no-underline"
                        : "focus:no-underline") +
                    " flex gap-2 items-center my-2 hover:border-l-4 hover:border-l-blue h-7 hover:font-semibold w-full" +
                    " hover:text-blue hover:font-bold hover:no-underline"
                );
            }}
            to={item.path ? item.path : ""}
        >
            {({isActive}) => (
                <>
                    <i className="md:ml-7 ml-2 w-6 h-6">
                        {" "}
                        {isActive
                            ? item.icon_selected
                                ? item.icon_selected
                                : item.icon
                            : item.icon}
                    </i>
                    <span
                        className={`md:block hidden opacity-0 translate-x-28 md:translate-x-0 md:overflow-auto overflow-hidden transition-opacity 
                    ${
                            isOpen
                                ? "duration-200 ease-in delay-100 md:opacity-100 translate-x-0 max-w-screen"
                                : "duration-200 ease-out md:opacity-0 -translate-x-2 max-w-0"
                        }`}
                    >
            {t(`sidebar.${item.title}`)}{" "}
          </span>
                </>
            )}
        </NavLink>
    );
};

const Sidebar = ({
                     isOpen,
                 }: {
    isOpen: boolean;
    onClickSideMenu: React.MouseEventHandler;
}) => {
    const {config} = useConfig();
    const {user} = useAuth();
    const standaloneAiCoach =
        user?.aiCoachType &&
        (user.aiCoachType === AICoachType.standalone ||
            user.aiCoachType === AICoachType.both);

    return (
        <div
            className={`aside fixed h-full w-12 py-14 m-0 flex flex-col bg-white justify-between z-10 
        shrink-0 item-center transform ease-in-out w-min-max border-warm-dark border-[1px] 
        ${isOpen ? "duration-700 md:w-52" : "duration-700 md:w-20"}`}
        >
            <div>
                <div className="mt-5 flex flex-col items-start scale-100">
                    {SidebarData.reduce((menuItems, currVal) => {
                        if (!config?.report && currVal.path === "reports/view") {
                            return menuItems;
                        }
                        if (standaloneAiCoach && currVal.path === "coach/booking") {
                            return menuItems
                        }
                        if (!standaloneAiCoach && currVal.path === "ai/coaching") {
                            return menuItems
                        }
                        if (!config?.resources && currVal.path === "resources") {
                            return menuItems;
                        }

                        return [...menuItems, currVal];
                    }, [] as ISidebarMenu[]).map((item, index) => {

                        if (
                            !(
                                item.allowedRoles &&
                                user?.role &&
                                item.allowedRoles?.indexOf(user.role) === -1
                            )
                        ) {
                            return <SidebarItem key={index} item={item} isOpen={isOpen}/>;
                        }
                    })}
                </div>
            </div>
            <div className="mb-10">
                {/*<SidebarItem item={{*/}
                {/*    title:  t('text6') ,*/}
                {/*    icon: <UsageIcon/>,*/}
                {/*    path: "settings",*/}
                {/*}}/>*/}

                <div
                    className={`flex gap-2 items-center my-3 pl-0 md:pl-6 md:px-1 ${
                        !isOpen && "md:w-16"
                    }`}
                >
                    <LanguageSelector/>
                </div>
            </div>
            {/*<div className="hidden flex-row w-10 h-20 md:block lg:block xl:block overflow-hidden text-white*/}
            {/*absolute right-0 -mr-5 top-1/2 transform -translate-y-1/2 rounded-full*/}
            {/*border-warm-dark border-0 border-r-[1px]"*/}
            {/*     onClick={onClickSideMenu} >*/}
            {/*    <div className="h-full w-full flex flex-row">*/}
            {/*        <div className="h-full w-1/2 bg-gradient-to-r from-transparent via-transparent to-white"/>*/}
            {/*        <div className="h-full w-1/2 flex items-center justify-end p-0 bg-white self-end">*/}
            {/*        {*/}
            {/*            isOpen ?  <ChevronLeftIcon className="text-warm"/> :  <ChevronRightIcon className="text-warm"/>*/}
            {/*        }*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default Sidebar;
