import React from 'react';

interface Props {
    icon?: any
    topic: string
    title: string
    content: any
    img: any
    action?: any
}

const InsightCard = (props: Props) => {
    return (
        <div className="rounded-md bg-white text-black p-5">
            <div className="flex justify-around p-3">
                <div className="rounded-md bg-white pr-5">
                <span className="flex gap-4 items-center mb-4">
                    {props.icon}
                    <p className="font-bold text-gray-600">{props.topic}</p>
                </span>
                    <h4 className="mb-3">{props.title}</h4>
                    <p>
                        {props.content}
                    </p>
                </div>
                <div className="rounded-md bg-white flex items-center">
                    <img className="w-full" src={props.img}/>
                </div>
            </div>
            <div className="w-full flex justify-end">
                {props.action}
            </div>
        </div>
    );
};

export default InsightCard;
