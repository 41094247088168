import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import HT from "./HighlightedText";
import VerticalExpander from "./VerticalExpander";
import ChatGuide from "../../../../../shared/components/ChatGuide";

import introStep2 from "@img/dashboard/welcome/img/introSteps2.png";
import crownIcon from "@img/dashboard/welcome/icon/crown.svg";

const Step2 = () => {
  const { t } = useTranslation("welcome");
  const [showContent, setShowContent] = useState(false);

  const labels = [
    {
      title: t("step_2.lead_the_way"),
      icon: crownIcon,
    },
    {
      title: t("step_2.track_progress"),
    },
    {
      title: t("step_2.coaching_sessions"),
    },
    {
      title: t("step_2.coach_discovery"),
    },
    {
      title: t("step_2.self_reflection"),
    },
  ];

  return (
    <>
      <ChatGuide
        title={<HT>{t("step_2.title")}</HT>}
        description={t("step_2.description")}
        descriptionClassName="font-normal"
        onShowContent={() => setShowContent(true)}
      />

      <div className="w-full flex flex-row justify-center items-center flex-1">
        <VerticalExpander expanded={showContent}>
          <div className="my-10 flex flex-row w-max justify-center">
            <div className="w-[125px] 2xl:w-[150px]">
              <img src={introStep2} alt="step 2" />
            </div>
            <div className="flex flex-col justify-between w-max">
              {showContent &&
                labels.map((label, index) => (
                  <Label
                    key={index}
                    icon={label.icon}
                    indexInReverse={labels.length - index}
                    index={index}
                  >
                    {label.title}
                  </Label>
                ))}
            </div>
          </div>
        </VerticalExpander>
      </div>
    </>
  );
};

const Label = ({
  children,
  icon,
  indexInReverse,
  index,
}: {
  children?: string;
  icon?: string;
  indexInReverse: number;
  index: number;
}) => {
  const [showLabel, setShowLabel] = useState(false);
  const delay = 1000 * indexInReverse;
  const marginLeft = 30 * indexInReverse;
  const marginRight = 30 * index;

  useEffect(() => {
    setTimeout(() => {
      setShowLabel(true);
    }, delay);
  }, []);

  return (
    <div
      className={`overflow-hidden transition-all ease-linear duration-700 ${
        showLabel ? "opacity-100" : "opacity-0"
      }`}
      style={{ marginLeft, marginRight }}
    >
      <div
        className={`w-full flex flex-row gap-2 py-2 px-5 min-w-[224px] rounded-full bg-white text-xs text-black font-dm_sans
      justify-center items-center`}
      >
        {icon && <img className="w-[18px]" src={icon} alt={children} />}
        {children}
      </div>
    </div>
  );
};

export default Step2;
