import React, {FC} from 'react'

// Components
import careerDevelopmentImage from '../../../assets/img/teams/resources/Career-development.jpg'
import workEntertainmentImage from '../../../assets/img/teams/resources/Work-environment.jpg'
import jobPerformanceImage from '../../../assets/img/teams/resources/Job-performance.jpg'
import leadershipImage from '../../../assets/img/teams/resources/Leadership.jpg'
import relatioshipsImage from '../../../assets/img/teams/resources/Relationships.jpg'
import qualityOfLifeImage from '../../../assets/img/teams/resources/Quality-of-life.jpg'
import confidanceImage from '../../../assets/img/teams/resources/Confidence.jpg'
import stressImage from '../../../assets/img/teams/resources/Stress-Anxiety.jpg'
import {showModal} from "../../../utils/helpers/teams";
import {useTranslation} from "react-i18next";
import noResultIcon from "../../../assets/img/teams/noReports.png";
import {ITeamsAuthData} from "../../../models/ITeamsAuth";
import i18n from "../../../utils/lib/i18n";
import ENG from "../locale/eng/resources.json";
import Fr from "../locale/fr/resources.json";

const Resources: FC = () => {
    i18n.addResourceBundle('en', 'resources', ENG);
    i18n.addResourceBundle('fr', 'resources', Fr);
    const {t} = useTranslation('resources')
    const language = localStorage.getItem("I18N_LANGUAGE")
    let authUser: ITeamsAuthData = JSON.parse(localStorage.getItem('ch_user') || '{}') as ITeamsAuthData

    const baseResources = language == 'fr' ? "https://resources.coachello.io/resources-french" : "https://resources.coachello.io";

    const openLink = (url: string) => {
        window.open(url, '_blank');
    }
    return (
        <>
            {['HEC', 'Enedis', 'Enedis-Pilot'].includes(authUser?.company_name) ?
                <div className="flex justify-center mt-14 flex-col text-center">
                    <img style={{
                        margin: "auto",
                        width: " 20%",
                        marginTop: "1%"
                    }} className="inline-flex mr-4" src={noResultIcon} alt=""/>
                    <h4 className="mt-5 px-6">No resources are available for this version</h4>
                </div> :
                <ul className="grid w-full grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                    <li className="relative">
                        <div onClick={() => showModal(baseResources + "/career-development", t('career'))}
                             className="bg-white rounded-lg">
                            <div
                                className="block w-full overflow-hidden bg-gray-100 rounded-t-lg group aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500">
                                <img src={careerDevelopmentImage} alt=""
                                     className="object-cover pointer-events-none group-hover:opacity-75"/>
                            </div>
                            <p className="block py-3 mt-2 text-sm font-medium text-center text-pink-500 truncate pointer-events-none">{t('career')}</p>
                        </div>
                    </li>
                    <li className="relative">
                        <div onClick={() => showModal(baseResources + "/work-environment", t('work'))}
                             className="bg-white rounded-lg">
                            <div
                                className="block w-full overflow-hidden bg-gray-100 rounded-t-lg group aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500">
                                <img src={workEntertainmentImage} alt=""
                                     className="object-cover pointer-events-none group-hover:opacity-75"/>
                            </div>
                            <p className="block py-3 mt-2 text-sm font-medium text-center text-pink-500 truncate pointer-events-none">{t('work')}</p>
                        </div>
                    </li>
                    <li className="relative">
                        <div onClick={() => showModal(baseResources + "/job-performance", t('job'))}
                             className="bg-white rounded-lg">
                            <div
                                className="block w-full overflow-hidden bg-gray-100 rounded-t-lg group aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500">
                                <img src={jobPerformanceImage} alt=""
                                     className="object-cover pointer-events-none group-hover:opacity-75"/>
                            </div>
                            <p className="block py-3 mt-2 text-sm font-medium text-center text-pink-500 truncate pointer-events-none">{t('job')}</p>
                        </div>
                    </li>
                    <li className="relative">
                        <div onClick={() => showModal(baseResources + "/leadership", t('leadership'))}
                             className="bg-white rounded-lg">
                            <div
                                className="block w-full overflow-hidden bg-gray-100 rounded-t-lg group aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500">
                                <img src={leadershipImage} alt=""
                                     className="object-cover pointer-events-none group-hover:opacity-75"/>
                            </div>
                            <p className="block py-3 mt-2 text-sm font-medium text-center text-pink-500 truncate pointer-events-none">{t('leadership')}</p>
                        </div>
                    </li>
                    <li className="relative">
                        <div onClick={() => showModal(baseResources + "/relationships", t('relationships'))}
                             className="bg-white rounded-lg">
                            <div
                                className="block w-full overflow-hidden bg-gray-100 rounded-t-lg group aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500">
                                <img src={relatioshipsImage} alt=""
                                     className="object-cover pointer-events-none group-hover:opacity-75"/>
                            </div>
                            <p className="block py-3 mt-2 text-sm font-medium text-center text-pink-500 truncate pointer-events-none">{t('relationships')}</p>
                        </div>
                    </li>
                    <li className="relative">
                        <div onClick={() => showModal(baseResources + "/quality-of-life", t('quality'))}
                             className="bg-white rounded-lg">
                            <div
                                className="block w-full overflow-hidden bg-gray-100 rounded-t-lg group aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500">
                                <img src={qualityOfLifeImage} alt=""
                                     className="object-cover pointer-events-none group-hover:opacity-75"/>
                            </div>
                            <p className="block py-3 mt-2 text-sm font-medium text-center text-pink-500 truncate pointer-events-none">{t('quality')}</p>
                        </div>
                    </li>
                    <li className="relative">
                        <div onClick={() => showModal(baseResources + "/confidence", t('confidence'))}
                             className="bg-white rounded-lg">
                            <div
                                className="block w-full overflow-hidden bg-gray-100 rounded-t-lg group aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500">
                                <img src={confidanceImage} alt=""
                                     className="object-cover pointer-events-none group-hover:opacity-75"/>
                            </div>
                            <p className="block py-3 mt-2 text-sm font-medium text-center text-pink-500 truncate pointer-events-none">{t('confidence')}</p>
                        </div>
                    </li>
                    <li className="relative">
                        <div onClick={() => showModal(baseResources + "/stress-and-anxiety", t('stress'))}
                             className="bg-white rounded-lg">
                            <div
                                className="block w-full overflow-hidden bg-gray-100 rounded-t-lg group aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500">
                                <img src={stressImage} alt=""
                                     className="object-cover pointer-events-none group-hover:opacity-75"/>
                            </div>
                            <p className="block py-3 mt-2 text-sm font-medium text-center text-pink-500 truncate pointer-events-none">{t('stress')}</p>
                        </div>
                    </li>
                </ul>
            }
        </>
    )
}

export default Resources;
