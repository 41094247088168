import { useState } from "react";

import { FiTrash2 } from "react-icons/fi";
import { Panel } from "rsuite";
import { twMerge } from "tailwind-merge";
import { FaChevronDown } from "react-icons/fa6";
import { FieldArray, Form, Formik, FormikHelpers, FormikValues } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import ENG from "../../../locale/eng/groups.json";
import Fr from "../../../locale/fr/groups.json";

import InputFormik from "../../../../../shared/components/formik/InputFormik/InputFormik";
import Button from "../../../../../shared/components/Button";
import { Spinner } from "../../../../../shared/components";

import {
  getGroupAdmins,
  upsertGroupAdmins,
} from "../../../../../services/api/companyApi";
import useAuth from "../../../../../utils/hooks/useAuth";

import { IGroupAdmin } from "../../../../../models/IGroup";
import i18n from "../../../../../utils/lib/i18n";
import { useTranslation } from "react-i18next";

const GroupAdminForm = () => {
  i18n.addResourceBundle("en", "groups:create_group", ENG);
  i18n.addResourceBundle("fr", "groups:create_group", Fr);
  const { t } = useTranslation("groups:create_group");
  const { groupId } = useParams();
  const { user } = useAuth();
  const [adminDetailsVisible, setAdminDetailsVisibility] = useState(false);
  const [groupAdmins, setGroupAdmins] = useState<IGroupAdmin[]>([]);
  const [isSavingGroupAdmins, setIsSavingGroupAdmins] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const groupAdminValidationSchema = Yup.object().shape({
    groupAdmins: Yup.array().of(
      Yup.object().shape({
        email: Yup.string().trim().required(t("admin.email_req")),
        first_name: Yup.string().trim().required(t("admin.first_name_req")),
        last_name: Yup.string().trim().nullable(),
      })
    ),
  });

  const _handleSubmit = async (
    values: FormikValues,
    actions: FormikHelpers<any>
  ) => {
    setIsSavingGroupAdmins(true);
    try {
      await upsertGroupAdmins(
        user!.companyId,
        Number(groupId),
        values.groupAdmins as IGroupAdmin[]
      );
      toast.success(t("admin.success"));
    } catch (e) {
      const err = e as AxiosError<{ message: string }>;
      const message = err.response?.data?.message ?? t("admin.error");
      toast.error(message);
    } finally {
      setIsSavingGroupAdmins(false);
    }
  };

  const togglePanel = (resetForm: () => void) => {
    if (isLoading) return;
    if (!adminDetailsVisible) {
      setIsLoading(true);
      return getGroupAdmins(user!.companyId, Number(groupId))
        .then((data) => {
          if (!data.length) {
            setGroupAdmins([{ email: "", first_name: "", last_name: "" }]);
          } else {
            setGroupAdmins(data);
          }
          setAdminDetailsVisibility(true);
        })
        .finally(() => {
          resetForm();
          setIsLoading(false);
        });
    }
    setAdminDetailsVisibility(!adminDetailsVisible);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ groupAdmins }}
      validationSchema={groupAdminValidationSchema}
      onSubmit={(values, actions) => {
        setIsSavingGroupAdmins(true);
        _handleSubmit(values, actions);
      }}
    >
      {({ resetForm, submitForm }) => (
        <Form className="flex flex-col gap-3 py-4 px-8 bg-white rounded border-gray-50 border-[1px] ">
          <div className="flex flex-col gap-3 justify-center">
            <div
              className="flex items-center gap-4 cursor-pointer w-max"
              onClick={() => togglePanel(resetForm)}
            >
              <p className="font-lexend font-bold text-black text-base">
                {t("admin.title")}
              </p>
              <div className="w-[15px] h-[15px]">
                {isLoading ? (
                  <Spinner size={"15px"} />
                ) : (
                  <FaChevronDown
                    className={twMerge(
                      "transition-all ease-in-out duration-500",
                      adminDetailsVisible ? "-rotate-180" : ""
                    )}
                    color="black"
                    size={15}
                  />
                )}
              </div>
            </div>
            <Panel collapsible expanded={adminDetailsVisible}>
              <FieldArray name="groupAdmins">
                {({ push, remove, form }) => (
                  <div className="w-full flex flex-col gap-3">
                    <div className="flex mb-4">
                      <Button
                        type="button"
                        onClick={() => {
                          push({ email: "", first_name: "", last_name: "" });
                        }}
                        className="hover:shadow-lg w-32 px-4 py-1 flex justify-center border-2 border-red
                                            text-sm font-normal transition-colors duration-500 text-white bg-red"
                      >
                        {t("admin.add_a_row")}
                      </Button>
                    </div>
                    {form.values.groupAdmins.map((_: any, index: number) => (
                      <div key={index} className="flex gap-3 items-center">
                        <InputFormik
                          placeholder={t("admin.email_placeholder")}
                          label="Email"
                          name={`groupAdmins.${index}.email`}
                        />
                        <InputFormik
                          placeholder={t("admin.first_name_placeholder")}
                          label={t("admin.first_name")}
                          name={`groupAdmins.${index}.first_name`}
                        />
                        <InputFormik
                          placeholder={t("admin.last_name_placeholder")}
                          label={t("admin.last_name")}
                          name={`groupAdmins.${index}.last_name`}
                        />
                        <FiTrash2
                          className="cursor-pointer text-red"
                          size={20}
                          onClick={() => remove(index)}
                        />
                      </div>
                    ))}

                    <div className="flex justify-end gap-2 mt-3">
                      <Button
                        type="button"
                        onClick={() => {
                          submitForm();
                        }}
                        className="hover:shadow-lg w-32 px-4 py-1 flex justify-center border-2 border-red
                                            text-sm font-normal transition-colors duration-500 text-white bg-red"
                      >
                        {isSavingGroupAdmins ? t("admin.saving"): t("admin.save")}
                      </Button>
                    </div>
                  </div>
                )}
              </FieldArray>
            </Panel>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default GroupAdminForm;
