import React from "react";

type Props = {
  children?: React.ReactNode;
  noPadding?: boolean;
  className?: string;
  onClick?: () => void;
};

const Card = ({ children, noPadding = false, className, onClick }: Props) => {
  return (
    <div
        className={`bg-white rounded-lg w-full ${
        noPadding ? "" : "p-4"
      } ${onClick ? "cursor-pointer" : ""} ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Card;
