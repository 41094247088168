import React, {useImperativeHandle, useState} from "react";

import Modal from "../../modules/dashboard/components/Modal";
import {AssessmentFr} from "../../modules/teams/pages/AssessmentFr";
import {Assessment} from "../../modules/teams/pages/Assessment";
import Feedback360Emails from "../../modules/teams/pages/Feedback360Emails";

import {quizData} from "../../constants";
import {EAssessmentType} from "../../constants/enums";
import {ImpactAssessment} from "../../modules/dashboard/components/ImpactAssessment";
import ReviewModal from "../../modules/teams/pages/Review";
import {AICoachType} from "../../modules/dashboard/pages/AICoaching/enum";
import useAuth from "@hooks/useAuth";
import AssessmentAI from "../../modules/teams/AssessmentAI";

const FrameModal: React.ForwardRefRenderFunction<{
    open: (
        key: EAssessmentType | string,
        title: string,
        frameElementType?: "iframe" | "video",
        sessionId?: number
    ) => void;
}> = (_, ref) => {
    const lng = localStorage.getItem("I18N_LANGUAGE") || "en";
    const {user} = useAuth();

    const [isOpen, setIsOpen] = useState(false);
    const [content, setContent] = useState<{
        child: React.ReactNode | string;
        title: string;
        frameElementType: string;
    }>();
    const standaloneAiCoach =
        user?.aiCoachType &&
        (user.aiCoachType === AICoachType.standalone ||
            user.aiCoachType === AICoachType.both);

    const handleClose = () => {
        setIsOpen(false);
        setContent(undefined);
    }

    useImperativeHandle(ref, () => ({
        open: (key, title, frameElementType = "video", sessionId) => {
            let assessmentElement;
            switch (key) {
                case quizData.self_reflection.key:
                    if (standaloneAiCoach) {
                        assessmentElement = <AssessmentAI/>
                    } else {
                        assessmentElement = lng === "fr" ? <AssessmentFr/> : <Assessment/>;
                    }
                    break;
                case quizData.reflection360.key:
                    assessmentElement = <Feedback360Emails onNotificationSuccess={handleClose}/>;
                    break;
                case quizData.impact_reflection.key:
                    assessmentElement = <ImpactAssessment/>;
                    break;
                case quizData.review.key:
                    assessmentElement = <ReviewModal onClose={handleClose} meetingId={sessionId}/>
                    break;
            }

            setContent({
                child: key.includes("http") ? key : assessmentElement,
                title,
                frameElementType
            });
            setIsOpen(true);
        },
    }));

    return (
        <Modal
            title={content ? content.title : ""}
            isOpen={isOpen}
            onClose={handleClose}
        >
            <div className="w-[60vw] h-[70vh]">
                {content && (
                    <>
                        {typeof content.child === "string" ? (
                            <>
                                {content.frameElementType === "video" ? (
                                    <video
                                        className="h-[60vh] w-full"
                                        src={content.child}
                                        title={content.title}
                                        autoPlay
                                        controls
                                    />
                                ) : (
                                    <iframe
                                        className="h-[60vh] w-full"
                                        src={content.child}
                                        title={content.title}
                                    />
                                )}
                            </>
                        ) : (
                            content.child
                        )}
                    </>
                )}
            </div>
        </Modal>
    );
};

export default React.forwardRef(FrameModal);
