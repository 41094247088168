import {Dispatch, SetStateAction} from "react";

import {twMerge} from "tailwind-merge";

import HumanCoachCard from "./components/HumanCoachCard";
import {AIOnlyCoaching} from "../AIOnlyCoaching";


type AiBookingProps = {
  setIsHumanCoach: Dispatch<SetStateAction<boolean>>
};

const RegularCoach = ({ setIsHumanCoach }: AiBookingProps) => {

    return (
        <div className={twMerge("h-full")}>
            <div className="flex flex-col lg:flex-row gap-2 w-full h-full">
                <div className="lg:w-5/6 w-full h-full">
                    <AIOnlyCoaching isJourneyVisible={false} />
                </div>
                <div>
                    <HumanCoachCard setIsHumanCoach={setIsHumanCoach} />
                </div>
            </div>
        </div>
    );
};

export default RegularCoach;
