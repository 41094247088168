import {GET, POST} from "../../utils/lib/axios";
import {SLACK} from "../../constants/endpoints/slack";


export const postSlackCode = (data:any) => {
    return POST(SLACK.post.SlackCode,data)
        .then(response => {
            return response.data;
        });
}

export default {postSlackCode}
