import {Widget} from '@typeform/embed-react'
import useAuth from "@hooks/useAuth";
import Loader from "../../../shared/components/Loader/Loader";

export const ImpactAssessment = () => {
    const lng = localStorage.getItem("I18N_LANGUAGE") || "en";
    const {user} = useAuth()
    const formId = () => {
        if (lng == 'fr') {
            return 't3L0xWRW';
        } else if (lng == 'de') {
            return 'r1xbuUkY'
        } else {
            return 'uiJhT2Vg'
        }
    }
    if (!user) {
        return <Loader/>
    }
    return <Widget
        hidden={{
            email: user?.email,
            name: user?.firstName,
            companyname: user?.companyName,
        }} id={formId()} style={{width: '100%', height: '100vh', flex: '1 1 auto'}}/>
}
