export enum ELocationFr {
  "Sao Paulo, Brasil" = "Sao Paulo, Brésil",
  "San Francisco" = "San Francisco",
  "Paris" = "Paris",
  "London" = "Londres",
  "Tallinn, Estonia" = "Tallinn, Estonie",
  "Belgrade" = "Belgrade",
  "San Diego" = "San Diego",
  "Sydney" = "Sydney",
  "Brussels" = "Bruxelles",
  "Los Angeles" = "Los Angeles",
  "Bayern" = "Bavière",
  "Bordeaux" = "Bordeaux",
  "Casablanca" = "Casablanca",
  "Strasbourg" = "Strasbourg",
  "Milan" = "Milan",
  "Toronto" = "Toronto",
  "Madrid, Spain" = "Madrid, Espagne",
  "Ann Arbor, Michigan" = "Ann Arbor, Michigan",
  "New York" = "New York",
  "Montreal" = "Montréal",
  "United Kingdom" = "Royaume-Uni",
  "Miami" = "Miami",
  "Barcelona" = "Barcelone",
  "Ithaca, NY" = "Ithaca, NY",
  "Amsterdam" = "Amsterdam",
  "Lisbon" = "Lisbonne",
  "Witten, Germany" = "Witten, Allemagne",
  "Ghent, Belgium" = "Gand, Belgique",
  "Bordeaux, France" = "Bordeaux, France",
  "Paris & Singapore" = "Paris et Singapour",
  "Dreux" = "Dreux",
  "Luxembourg" = "Luxembourg",
  "Copenhagen" = "Copenhague",
  "USA and Hong Kong" = "États-Unis et Hong Kong",
  "Tokyo" = "Tokyo",
  "Berlin" = "Berlin",
  "Cologne" = "Cologne",
  "Hong Kong" = "Hong Kong",
  "Mallorca, Spain" = "Majorque, Espagne",
  "Vilnius" = "Vilnius",
  "Singapore" = "Singapour",
  "Greece" = "Grèce",
  "Basel" = "Bâle",
  "Baltimore, US" = "Baltimore, États-Unis",
  "Rennes" = "Rennes",
  "Vilnius, Lithuania" = "Vilnius, Lituanie",
  "Sao Paulo" = "Sao Paulo",
  "Edinburgh" = "Édimbourg",
  "Zoug, Switzerland " = "Zoug, Suisse",
  "Nice/Paris" = "Nice/Paris",
  "Cascais, Portugal " = "Cascais, Portugal",
  "Antigua, Guatemala" = "Antigua, Guatemala",
  "Mumbai, India" = "Mumbai, Inde",
  "Berlin, Germany" = "Berlin, Allemagne",
  "Rotterdam" = "Rotterdam",
  "Paris/Bale" = "Paris/Bâle",
  "Cheltenham, UK" = "Cheltenham, Royaume-Uni",
  "Istanbul" = "Istanbul",
  "Athens" = "Athènes",
  "Bucharest" = "Bucarest",
  "Athens, Greece" = "Athènes, Grèce",
  "Fort Worth, TX, United States" = "Fort Worth, TX, États-Unis",
  "Bogota, Colombia" = "Bogota, Colombie",
  "Taipei" = "Taipei",
  "Budapest" = "Budapest",
  "Paris, France " = "Paris, France",
  "Koln" = "Cologne",
  "Montesson, France" = "Montesson, France",
  "Culemborg, Nederland " = "Culemborg, Pays-Bas",
  "Porto, Portugal " = "Porto, Portugal",
  "Cologne, Germany" = "Cologne, Allemagne",
  "Austin" = "Austin",
  "The Hague" = "La Haye",
  "Utrech" = "Utrecht",
  "New York, US" = "New York, États-Unis",
  "Cambridge, USA" = "Cambridge, États-Unis",
  "Milano" = "Milan",
  "Madrid" = "Madrid",
  "Wroclaw" = "Wroclaw",
  "Pune, India " = "Pune, Inde",
  "Dubai, UAE" = "Dubai, Émirats arabes unis",
  "Valleres" = "Valleres",
  "France" = "France",
  "London/Paris" = "Londres/Paris",
  "Paris / Budapest" = "Paris/Budapest",
  "Doha" = "Doha",
  "Rhode Island, US" = "Rhode Island, États-Unis",
  "Barcelona, Spain" = "Barcelone, Espagne",
  "Las Vegas" = "Las Vegas",
  "Atlanta, USA" = "Atlanta, États-Unis",
  "Palm Springs, USA" = "Palm Springs, États-Unis",
  "Washington, USA" = "Washington, États-Unis",
  "New York, USA" = "New York, États-Unis",
  "Georgia" = "Géorgie",
  "Washington DC, USA" = "Washington DC, États-Unis",
  "Florida, USA" = "Floride, États-Unis",
  "Maryland, USA" = "Maryland, États-Unis",
  "New Mexico, USA" = "Nouveau-Mexique, États-Unis",
  "Calgary, Canada" = "Calgary, Canada",
  "Dublin, Ireland" = "Dublin, Irlande",
  "Los Angeles, USA" = "Los Angeles, États-Unis",
  "San Francisco, USA" = "San Francisco, États-Unis",
  "Texas, USA" = "Texas, États-Unis",
  "Illinois, USA" = "Illinois, États-Unis",
  "California, USA" = "Californie, États-Unis",
  "Ontario, Canada " = "Ontario, Canada",
  "Edmonton, Canada" = "Edmonton, Canada",
  "Saint Cyprien, France" = "Saint Cyprien, France",
  "Berlin - Milan" = "Berlin - Milan",
  "Setúbal, Portugal" = "Setúbal, Portugal",
  "London, UK" = "Londres, Royaume-Uni",
  "New Jersey, USA" = "New Jersey, États-Unis",
}
