import React, {createContext, ReactNode, Ref, useEffect, useMemo, useState} from "react";

import {toast} from "react-toastify";
import {useLocation, useNavigate, useOutlet, useSearchParams} from "react-router-dom";
import {AuthenticationResult} from "@azure/msal-browser";

import {msalInstance} from "../lib/microsoft";
import {decodeToken} from "../helpers/jwt";
import authApi from "../../services/api/authApi";
import useGAuth from "@hooks/useGAuth";
import useMSAuth from "@hooks/useMSAuth";
import storage from "../../services/storage/localStorage";

import {IAuthUser, ILoginRequest, ISignUpRequest} from "../../models/IAuth";
import {EAuthType, ERole} from "../../constants/enums";
import TagManager from "react-gtm-module";
import {useConfig} from "./AppConfigProvider";

interface IAuthContext {
    user: IAuthUser | null;
    login: (loginType: EAuthType, state?: string | null, emailPass?: IEmailLogin, tokenReq?: ITokenLogin) => Promise<void>
    switchRole: (newRole: ERole) => Promise<void>
    signUp: (data: ISignUpRequest) => Promise<void>
    resetPassword: (email: string) => Promise<void>
    sendMagicLink: (email: string) => Promise<void>
    logout: () => void
    gLoginRef: Ref<any>;
    loginImplicitFlow: any;
    loginAuthCodeFlow: any;
}

interface IEmailLogin {
    email: string;
    password: string
}

interface ITokenLogin {
    email: string;
    token: string
}

export interface LocationProps {
    pathname?: string;
    search?: string;
    state: {
        from: Location;
    };
};


export const AuthContext = createContext({} as IAuthContext);
export const AuthLayout = () => {
    const outlet = useOutlet();

    return (
        <AuthProvider>{outlet}</AuthProvider>
    );
};
export const AuthProvider = ({children}: { children: ReactNode }) => {
        const [user, setUser] = useState<IAuthUser | null>(storage.get('user'));
        const navigate = useNavigate();
        const {loginMS, logoutMS} = useMSAuth()
        const {fetchConfig} = useConfig();
        const {state, pathname} = useLocation() as LocationProps;
    const [searchParams] = useSearchParams()

        // Google auth callback
        const handleCallbackResponse = (response: any) => {
            if (response.error) {
                console.error(response.error)

            } else {
                console.log(response, state)
                const requestBody: ILoginRequest = {
                    code: response.code,
                    auth_type: EAuthType.GAuth
                }
                authApi.userLogin(requestBody)
                    .then(async res => {
                        console.log(res);
                        await handleLoginSuccess(res.data, EAuthType.GAuth)
                    }).catch((e) => {
                    // handleLogOut()
                    toast.error(e.response.data.message)
                    console.log("api error");
                })
            }

        }

        const {gLoginRef, loginImplicitFlow, loginAuthCodeFlow} = useGAuth(handleCallbackResponse)

        // MS auth callback
        useEffect(() => {
            const code = searchParams.get('code')
            if (code) {
                console.log(code)
                const requestBody: ILoginRequest = {
                    code: code,
                    auth_type: EAuthType.MS
                }
                authApi.userLogin(requestBody)
                    .then(async res => {
                        await handleLoginSuccess(res.data, EAuthType.MS)
                    }).catch((e) => {
                    // handleLogOut()
                    toast.error(e.response.data.message)
                    console.log("api error");
                })
            } else {
                msalInstance
                    .handleRedirectPromise()
                    .then((resp: AuthenticationResult | null) => {
                        if (resp) {
                            const tokenDecoded = decodeToken(resp.accessToken);
                            const requestBody: ILoginRequest = {
                                email: tokenDecoded.upn,
                                auth_token: resp.accessToken,
                                first_name: tokenDecoded.name,
                                last_name: tokenDecoded.family_name,
                                auth_type: EAuthType.MS
                            }
                            console.log(resp.state);
                            if (resp.state == 'marketplace') {

                            } else {
                                authApi.userLogin(requestBody)
                                    .then(async res => {
                                        console.log(res);
                                        await handleLoginSuccess(res.data, EAuthType.MS, resp.state)
                                    }).catch((e) => {

                                    toast.error(e.response.data.message)
                                    // handleLogOut()
                                    console.log("api error");
                                })
                            }

                        }
                    }).catch((e) => {
                        console.error(e)

                    }
                );

            }

        }, [searchParams.get('code')])

        const handleLoginSuccess = async (res: any, authType: EAuthType, from?: string) => {
            const obj = {
                id: res.user_id,
                email: res.email,
                accessToken: res.access_token,
                imageUrl: res.image_url,
                firstName: res.first_name,
                lastName: res.last_name,
                companyId: res.company_id,
                companyName: res.company_name,
                role: res.role,
                secondaryId: res.secondary_id,
                roles: res.roles,
                loggedType: authType,
                aiCoachType: res.ai_coach_type
            }
            setUser(obj)
            storage.set('user', obj)

            // GTAG
            TagManager.dataLayer({
                dataLayer: {
                    event: 'Login',
                    email: res.email,
                    login_type: authType,
                    user_id: res.user_id
                },
            });
            console.log('fetching')
            await fetchConfig(obj)
            console.log('fetching after')
            navigate(from || "/dashboard");

            // if (obj.role == ERole.HrAdmin || obj.role == ERole.SuperAdmin) {
            //     navigate(from || "/dashboard/coaching");
            // } else if (obj.role == ERole.ItAdmin) {
            //     navigate(from || "/dashboard/integration");
            //
            // } else if (obj.role == ERole.Coach) {
            //     navigate(from || "/dashboard/reports");
            // } else if (obj.role == ERole.Coachee) {
            //     navigate(from || "/dashboard/home");
            // } else {
            //     toast.error("Permission denied.! \n Please login with your HR Admin account.")
            // }

        }
        const handleLogOut = () => {
            setUser(null)
            storage.clear()
            if (user?.companyName == "JulhietSterwenEU") {
                navigate("/julhietsterwen/auth/login", {replace: true});
            }

        }

    function buildMSAuthorizationUrl(locationState: any) {
        const clientId = process.env.REACT_APP_MS_APP_CLIENT_ID
        const redirectUri = encodeURIComponent(window.location.origin + process.env.REACT_APP_MSAL_REDIRECT_URI);
        // Change to url you need
        const responseType = "code";
        const scope = encodeURIComponent("openid profile offline_access User.Read Calendars.ReadWrite MailboxSettings.Read");
        const state = encodeURIComponent(locationState);
        return `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
    }

    // call this function when you want to authenticate the user
        const login = async (loginType: EAuthType, state?: string | null, emailPass?: IEmailLogin, tokenReq?: ITokenLogin) => {
            console.log(state);
            if (loginType == EAuthType.GAuth) {

            } else if (loginType == EAuthType.MS) {
                if (state == 'marketplace') {
                    loginMS(state)
                } else {
                    window.location.href = buildMSAuthorizationUrl(state)
                }

            } else if (loginType == EAuthType.Token) {
                const requestBody: ILoginRequest = {
                    email: tokenReq?.email,
                    token: tokenReq?.token,
                    auth_type: EAuthType.Token

                }
                authApi.userLogin(requestBody)
                    .then(async res => {
                        await handleLoginSuccess(res.data, EAuthType.Token, state || undefined)
                    }).catch((e) => {

                    toast.error(e.response.data.message)
                    // handleLogOut()
                    console.log("api error");
                })
            } else {
                const requestBody: ILoginRequest = {
                    email: emailPass?.email,
                    password: emailPass?.password,
                    auth_type: EAuthType.Email

                }
                authApi.userLogin(requestBody)
                    .then(async res => {
                        console.log(res);
                        await handleLoginSuccess(res.data, EAuthType.Email)
                    }).catch((e) => {

                    toast.error(e.response.data.message)
                    // handleLogOut()
                    console.log("api error");
                })
            }
            // navigate("/dashboard");
        };

        const switchRole = async (newRole: ERole) => {
            authApi.switchRole(newRole)
                .then(async res => {
                    await handleLoginSuccess(res.data, user?.loggedType!)
                }).catch((e) => {

                toast.error(e.response.data.message)
                console.log("api error");
            })

        };
        const signUp = async (data: ISignUpRequest) => {
            return authApi.signUp(data)


        };
        const resetPassword = async (email: string) => {
            return authApi.resetPassword(email)
        };

        const sendMagicLink = async (email: string) => {
            return authApi.sendMagicLink(email)
        };

        // call this function to sign out logged user
        const logout = () => {
            if (user?.loggedType == EAuthType.GAuth) {
                handleLogOut();
                navigate("/auth/login", {replace: true});

            } else if (user?.loggedType == EAuthType.MS) {
                handleLogOut()
                logoutMS()
                // navigate( "/auth/login");
            } else {
                handleLogOut()
            }

            // navigate("/", { replace: true });
        };

        const value = useMemo(
            () => ({
                user,
                gLoginRef,
                login,
                logout,
                signUp,
                resetPassword,
                switchRole,
                sendMagicLink,
                loginImplicitFlow,
                loginAuthCodeFlow
            }),
            [user]
        );
        return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
    }
;
