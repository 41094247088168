import { axiosPrivate } from "../../../utils/lib/axios";

export const get360FeedbackReports = (clientId: number) => {
  return axiosPrivate
    .get(`/api/meeting/feedback360/${clientId}/reports`)
    .then((response) => {
      return response.data;
    });
};
export const send360FeedbackRequests = (clientId: number, emails: string[]) => {
  const data = {
    emails: emails,
  };
  return axiosPrivate
    .post(`/api/meeting/feedback360/${clientId}/emails`, data)
    .then((response) => {
      return response.data;
    });
};

export const get360FeedbackPendingRequests = (clientId: number) => {
    return axiosPrivate
        .get(`/api/meeting/feedback360/${clientId}/pending-requests`)
        .then((response) => {
            return response.data;
        });
};
