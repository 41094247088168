import {IRoute} from "../../../models/IRoute";
import {ERouteType} from "../../../constants/enums";
import PageLayout from "../../../layouts/PageLayout";
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import Marketplace from "../pages/Marketplace";
import MarketplaceThanks from "../pages/MarketplaceThanks";
import TokenAuth from "../pages/TokenAuth";
import LoginEusa from "../pages/LoginEusa";
import SignupEusa from "../pages/SignupEusa";
import LoginEnedis from "../pages/LoginEnedis";

const AuthRoutes: IRoute[] = [
    {
        path: "/auth",
        layout: PageLayout,
        routeType: ERouteType.public,
        routes: [
            {
                name: 'Login',
                path: "login",
                component: Login,
                routeType: ERouteType.restricted,
            },
            {
                name: 'Signup',
                path: "signup",
                component: Signup,
                routeType: ERouteType.public,
            },
            {
                name: 'Token',
                path: "token/:token",
                component: TokenAuth,
                routeType: ERouteType.public,
            },
            {
                name: 'Marketplace',
                path: "marketplace/signup",
                component: Marketplace,
                routeType: ERouteType.public,
            },
            {
                name: 'Market Place',
                path: "marketplace/success",
                component: MarketplaceThanks,
                routeType: ERouteType.public,
            }
        ]

    },
    {
        path: "/julhietsterwen/auth",
        layout: PageLayout,
        routeType: ERouteType.public,
        routes: [
            {
                name: 'Login',
                path: "login",
                component: LoginEusa,
                routeType: ERouteType.restricted,
            },
            {
                name: 'Signup',
                path: "signup",
                component: SignupEusa,
                routeType: ERouteType.public,
            },
        ]
    },
    {
        path: "/enedis/auth",
        layout: PageLayout,
        routeType: ERouteType.public,
        routes: [
            {
                name: 'Login',
                path: "login",
                component: LoginEnedis,
                routeType: ERouteType.restricted,
            }
        ]
    },
];

export default AuthRoutes;
