type StepProps = {
  active?: boolean;
  backwardNavigatable?: boolean;
  onClick: () => void;
};

const StepMark: React.FC<StepProps> = ({
  active,
  onClick,
  backwardNavigatable,
}) => {
  const handleClick = () => {
    if (!active || !backwardNavigatable) return;
    onClick();
  };

  return (
    <div
      className={`flex-1 h-1.5 bg-red rounded ${
        !active ? "opacity-10" : ""
      } transition duration-1000
        cursor-${active && backwardNavigatable ? "pointer" : "default"}`}
      onClick={handleClick}
    ></div>
  );
};

type Props = {
  currentStep: number;
  totalSteps: number;
  directStepChangeHandler?: (currentStep: number) => void;
};

const StepIndicator: React.FC<Props> = ({
  currentStep,
  totalSteps,
  directStepChangeHandler,
}) => {
  const steps = [];
  for (let i = 0; i < totalSteps; i++) {
    steps.push("");
  }

  const handleClick = (stepIndex: number) => {
    if (directStepChangeHandler) directStepChangeHandler(stepIndex + 1);
  };

  return (
    <div className="w-full h-1.5 flex justify-between flex-row gap-7 items-center">
      {steps.map((_step, index) => {
        const stepIsActive = currentStep >= index + 1;
        return (
          <StepMark
            key={index}
            active={stepIsActive}
            backwardNavigatable={!!directStepChangeHandler}
            onClick={() => handleClick(index)}
          />
        );
      })}
    </div>
  );
};

export default StepIndicator;
