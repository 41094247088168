import ReactTooltip from "react-tooltip";

import CircularChart from "../../../shared/components/CircularChart/CircularChart";
import BoxIcon from "../../../shared/components/BoxIcon";
import NoDataWrapper from "../../../shared/components/NoDataWrapper";
import InformationIcon from "../../../shared/components/InformationIcon";

import ENG from "../locale/eng/performance.json";
import Fr from "../locale/fr/performance.json";
import i18n from "../../../utils/lib/i18n";
import { randomString } from "../../../utils/helpers/str";
import NoDataIcon from "@icons/noDataIcon.png";

interface Props {
  img: string;
  percentage: number;
  title: string;
  tooltip: string;
  displayInfo?: boolean;
  displayChart?: boolean;
  isFiltered?: boolean ;
  noDataText?: string;
}

const UsageCard = (props: Props) => {
  i18n.addResourceBundle("en", "coaching", ENG);
  i18n.addResourceBundle("fr", "coaching", Fr);

  const tooltipId = randomString();

  return (
    <div className="relative w-full flex flex-col justify-center bg-white rounded border-warm-dark">
      {!props.percentage && !props.isFiltered && <NoDataWrapper />}
       {props.percentage == null || props.percentage === 0 ? 
            <div>
             <h4 className="font-bold pl-4">{props.title}</h4>
            <div className="flex flex-col items-center justify-center p-2">
                     <img src={NoDataIcon} width={40} height={40} alt="No Data Icon" />
                          <h5 className="text-m" style={{ fontWeight: 'bold' }}>{props.noDataText}</h5>
            </div> 
            </div>
            :
      <div className="flex justify-between items-center gap-5 p-4">
        <div className="flex gap-2 justify-start text-black w-2/3">
          <BoxIcon img={props.img}/>
          <div className="">
            <h2 className="font-lexend font-bold">{props.percentage}%</h2>
            <span className="font-bold text-start">{props.title}</span>
          </div>
        </div>
        <div className="min-w-[32px] w-1/3">
          {
            props.displayChart && <CircularChart percentage={props.percentage} />
          }
        </div>
        {
          props.displayInfo &&
            <InformationIcon
              data-for={tooltipId}
              data-tip={"hover on me will keep the tooltip"}
              className={`px-2 absolute right-2 text-black ${props.displayChart && "top-1 right-1"}`}
            />
        }

        <ReactTooltip
          className={"w-60"}
          delayHide={500}
          id={tooltipId}
          place="top"
          effect="solid"
        >
          <span>{props.tooltip}</span>
        </ReactTooltip>
      </div>}
    </div>
  );
};

export default UsageCard;
