import {AiFillDelete} from "react-icons/ai";
import {IThreadItem} from "../utils/AICoachingContext";
import {twMerge} from "tailwind-merge";

type OverviewItemProps = {
    item: IThreadItem
    title: string;
    tag?: string;
    tagColor?: string;
    icon?: string;
    onDelete?: (item: IThreadItem) => void;
    isSelected: boolean;
    onSelect: (item: IThreadItem) => void;
};

const OverviewItem = ({
                          item,
                          title,
                          tag,
                          tagColor = "bg-warm-dark",
                          icon,
                          onDelete,
                          isSelected,
                          onSelect,
                      }: OverviewItemProps) => {

    return (
        <div onClick={() => onSelect(item)}
             className={twMerge(isSelected ? "bg-[#FFE6EE] border-[1px] border-red " : "", "hover:bg-[#FFE6EE] flex flex-row gap-2 relative justify-between items-center rounded-lg overflow-x-hidden cursor-pointer  px-4 py-2 text-gray transition-all")}>
            {icon && <img className="w-6 h-6" src={icon}/>}
            <div className="flex flex-col basis-4/5 gap-0.5 ">
                <div className="flex flex-row gap-2 items-center">
                    <p className={twMerge(isSelected ? "font-bold" : "font-normal ", "flex-wrap text-ellipsis font-dm_sans m-0 text-sm")}>{title}</p>
                </div>
                {/*{tag && <span*/}
                {/*    className={`text-xs font-extralight rounded px-1 mr-auto text-gray-600 ${tagColor}`}>{tag}</span>}*/}
            </div>
            {onDelete && <div
                className="flex h-8 w-8 min-w-[30px] basis-1/5 hover:text-red border-[1px] border-gray hover:border-red rounded-xl items-center justify-center absolute right-2"
                onClick={() => onDelete(item)}
            >
                <AiFillDelete className="w-3 h-3"/>
            </div>}
        </div>
    )
};
export default OverviewItem;
