export enum ESpecializationFr {
    'Assertivity' = 'Assertivité',
    'Burn-out Prevention' = 'Prévention du burn-out',
    'Career Transition' =  'Transition de carrière',
    'Confidence' =  'Confiance en soi',
    'Communication' =  'Communication' ,
    'Effectiveness' =  'Efficacité',
    'Emotional Intelligence' = 'Intelligence émotionnelle',
    'Integration at a new position' =  'Intégration dans un nouveau poste',
    'Management' = 'Management',
    'Motivation' =  'Motivation',
    'Multicultural Environment' =  'Environnement multiculturel' ,
    'Leadership' =  'Leadership',
    'Organisational Change' =  'Changement organisationnel' ,
    'Public Speaking' = 'Prise de parole en public' ,
    'Resilience' = 'Résilience',
    'Sense of Belonging' =  'Sentiment d’appartenance',
    'Stress Management' =  'Gestion du stress',
    'Team Building' =  'Teambuilding' ,
    'Teamwork' = 'Travail d’équipe',
    'Women Leadership' = 'Leadership féminin',
    'Positive Intelligence' =  'Intelligence positive',
    'Career Development' = 'Développement de carrière',
    'Meaning at Work' =  'Sens du travail',
    'Strengths and Values' =  'Forces et valeurs',
    'Feedback' = 'Feedback',
    'Performance Review' = 'Evaluation des performances' ,
    'Skills and Productivity' = 'Compétences et productivité' ,
    'Relationships' = 'Relationnel' ,
    'Stress & Anxiety' ='Stress et anxiété' ,
    'Quality of Life' = 'Qualité de vie',
    'AI Product Development' = 'Développement de produits IA',
    'Scalability' = 'Scalabilité',
    'Mobile Applications' = 'Applications mobiles',
    'Framework Selection' = 'Sélection de framework',
    'HealthTech' = 'Technologie de santé',
    'Wellbeing' = 'Bien-être'
}