import { useTranslation } from "react-i18next";

import {IArticleSchema, ICoachArticleSchema} from "../../../../../models/IHome";

type RecommendedProps = {
    articles: ICoachArticleSchema[];
};

const Recommended = ({ articles }: RecommendedProps) => {
  const { t } = useTranslation("home");

  if (!articles.length) return <></>;
  return (
    <div className="bg-white p-4 pt-3 rounded border-gray-50 border-[1px]">
      <h2 className="text-lg font-bold font-dm_sans text-black mt-2 mb-4">
        {t("recommended.recommended_by_your_coach")}
      </h2>
      <ul className="grid w-full grid-cols-2 gap-x-6 gap-y-6">
        {articles.map((article, index) => (
          <Card key={index} article={article} />
        ))}
      </ul>
    </div>
  );
};

type CardProps = {
    article: ICoachArticleSchema;
};

const Card = ({ article }: CardProps) => {
  const { t } = useTranslation("home");

  return (
    <div className="flex flex-col gap-2 basis-1/2">
      <div
        className="cursor-pointer aspect-w-2 aspect-h-1 border-gray-50 rounded relative"
        onClick={() => window.open(article.link, "_blank")}
      >
        {article.image && (
          <img
            src={article.image}
            alt="coach"
            className="object-cover rounded"
          />
        )}
      </div>

      <div className="flex flex-col gap-1">
        <p className="font-bold text-xs font-dm_sans text-black m-0">
          {`${t("recommended.suggestion_from_session")} ${
            article.session_no <= 9
              ? `0${article.session_no}`
              : article.session_no
          }`}
        </p>
        <p className="text-xs font-dm_sans text-gray-400 m-0">
          {article.title}
        </p>
      </div>
    </div>
  );
};

export default Recommended;
