import React, {useEffect, useState} from 'react';
import useMSAuth from "@hooks/useMSAuth";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import authApi from "../../../services/api/authApi";
import {EAuthType} from "../../../constants/enums";
import {toast} from "react-toastify";
import marketplaceApi from "../../../services/api/marketplaceApi";
import {LocationProps} from "../../../utils/providers/AuthProvider";
import {Button, Input} from "antd";
import Navbar from "../../../layouts/components/Navbar";
import Img from "../../../shared/components/Img";
import logoImgSm from "@img/coachello.svg";
import logoImg from "@img/coachello.svg";
import {Loader} from "@components";
import moment from "moment";

const Marketplace = () => {
    const {loginMS, authenticated} = useMSAuth()
    const [searchParams] = useSearchParams()
    const location = useLocation() as LocationProps;
    const [subscription, setSubscription] = useState<Record<string, any>>()
    const [userAuth, setUserAuth] = useState<Record<string, any>>()
    const [companyName, setCompanyName] = useState('')
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (subscription?.subscription.saasSubscriptionStatus == 'Subscribed') {
            navigate('./../success')
        }
    }, [subscription])

    useEffect(() => {

        authenticated().then(
            r => {
                console.log(r);
                if (r) {
                    setUserAuth(r)
                    const token = searchParams?.get('token')
                    if (token) {
                        resolveSubscription(token)
                    }
                } else {
                    loginMS('marketplace')
                }
            }
        )

    }, [])

    const activateSubscription = () => {
        setLoading(true)
        let startDate;
        let endDate;
        if (!subscription?.term?.startDate) {
            startDate = subscription?.subscription.created
            endDate = moment(subscription?.subscription.created).add(1, 'years').format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZ');
        }
        const data = {
            company_name: companyName,
            tid: subscription?.subscription.purchaser.tenantId,
            purchaser: {
                email: subscription?.subscription.purchaser.emailId,
                name: userAuth?.account.name
            },
            plan_id: subscription?.planId,
            subscription_id: subscription?.id,
            term_start_date: startDate,
            term_end_date: endDate,
            auto_renew: subscription?.subscription.autoRenew,
        }
        console.log(data);
        marketplaceApi.activateSubscription(data)
            .then(res => {
                toast.success('Your account is activated!')
                navigate('./../success')
            }).catch((e) => {
            toast.error(e.response.data)
            console.log("api error");
        }).finally(
            () => setLoading(false)
        )
    }
    const resolveSubscription = (token: string) => {
        setLoading(true)

        marketplaceApi.resolveSubscription(token)
            .then(res => {
                setSubscription(res)
                console.log(res);
            }).catch((e) => {
            toast.error(e.response.data)
            console.log("api error");
        }).finally(() => setLoading(false))
    }
    return (
        <div className="p-24">
            <div className="w-full flex justify-center">
                <div className="m-auto p-2 md:p-5">
                    <Img className="w-auto" triggerPx={768} srcSmall={logoImgSm} srcLarge={logoImg}/>
                </div>
            </div>
            {loading ? <div
                className="z-40 backdrop-blur-sm absolute inset-0 flex items-center justify-center -mt-2"><Loader/>
            </div> : <div className="w-full gap-3 flex flex-col justify-center items-center">
                <h4>Hi, {userAuth?.account.name}!</h4>
                <div className=" gap-3 flex flex-col justify-center">
                    <h3>Thank you for your subscription</h3>
                    <p>Please verify the following information is correct before submitting<br/> your subscription for
                        activation by Coachello.</p>
                    <br/>
                    <div className="flex-col flex gap-3 text-black/60">

                        <p>Name: {userAuth?.account.name}</p>
                        <p>Email: {subscription?.subscription.purchaser.emailId}</p>
                        <p>Tenant id: {subscription?.subscription.purchaser.tenantId}</p>
                        <p>Plan: {subscription?.planId}</p>
                        <p>Offer: {subscription?.offerId}</p>
                        <p>State: {subscription?.subscription.saasSubscriptionStatus}</p>
                        <div className="flex gap-3 items-center">
                            <label className="text-black whitespace-nowrap">Company Name*</label>
                            <Input placeholder="Enter your organization name"
                                   onChange={(e) => setCompanyName(e.target.value)}/>
                        </div>
                        <br/>
                        <button disabled={companyName == ''}
                                className="dashboard-button bg-black justify-center disabled:bg-gray-400"
                                onClick={activateSubscription}>
                            Activate
                        </button>
                    </div>
                </div>
            </div>}

        </div>
    );
};

export default Marketplace;
