import {useContext} from "react";
import {AuthContext} from "../providers/AuthProvider";

 const useAuth = () => {
    const auth = useContext(AuthContext);
    if (!auth) {
        throw new Error('useAuth can\'t be used outside a ProvideAuth.')
    }
    return  auth;
};
export default useAuth;