import React from 'react';
import OverviewItem from "./OverviewItem";
import {IOverviewItem, IThreadItem} from "../utils/AICoachingContext";
import journeyIcon from "../../../../../assets/img/aiCoaching/stairs.png";
import sessionIcon from "../../../../../assets/img/aiCoaching/puzzle_blocks.png";
import flashIcon from "../../../../../assets/img/aiCoaching/ad_hoc_session.svg";
import {twMerge} from "tailwind-merge";
import Spinner from "./ChatBox/Spinner";
import {useTranslation} from 'react-i18next';

interface Props {
    overviewItems: IOverviewItem[]
    selectedThreadItem?: IThreadItem
    onSelect: (item: IThreadItem) => void
    onDelete: (item: IThreadItem) => void
    isLoading?: boolean
}

const OverviewSection = ({overviewItems, selectedThreadItem, isLoading, onSelect, onDelete}: Props) => {

    const {t} = useTranslation("ai_coaching");

    if (isLoading) {
        return <div className="flex flex-col w-full items-center justify-center h-full ">
            <Spinner size="32" className=""/></div>
    }
    return (
        <div className="flex flex-col gap-1">
            {
                overviewItems.map(item => {
                    if (item.aiModel == "journey") {
                        return <div
                            className={twMerge(item.subSessions && item.subSessions.length > 0 && "", " rounded-md")}>
                            <OverviewItem
                                item={{...item}}
                                onSelect={onSelect}
                                isSelected={item.id == selectedThreadItem?.id}
                                title={item.title ? item.title?.toString() : t("chatbox.text8")}
                                tag="journey"
                                icon={journeyIcon}
                                // onDelete=""
                            />
                            {item.subSessions && item.subSessions.length > 0 && <div className="">
                                {item.subSessions.map((subSession, index) => {
                                    return <OverviewItem
                                        item={subSession}
                                        onSelect={onSelect}
                                        isSelected={subSession.id == selectedThreadItem?.id}
                                        title={`Session ${(index + 1).toString()}`}
                                        tag="session"
                                        icon={sessionIcon}
                                        tagColor="bg-gray-300"
                                        // onDelete=""
                                    />
                                })}
                            </div>}

                        </div>

                    } else {
                        return <OverviewItem
                            item={item}
                            onSelect={onSelect}
                            isSelected={item.id == selectedThreadItem?.id}
                            title={item.title ? item.title?.toString() : t("chatbox.text8")}
                            tag="flash"
                            icon={flashIcon}
                            tagColor="bg-[#FFE6EE]"
                            // onDelete=""
                        />
                    }

                })
            }
        </div>
    );
};

export default OverviewSection;
