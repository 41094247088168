import {useOutletContext} from "react-router-dom";
import {useEffect} from "react";

export const useNavTitle = (title:string) => {
    const { setNavTitle } = useOutletContext<any>();

    useEffect(() => {
        setNavTitle(title);
    }, [setNavTitle, title]);
};
