import { ReactNode, RefObject, createRef, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import HighlightedText from "../../modules/dashboard/pages/OnboardingRegular/components/HighlightedText";

import introStepsDp from "@img/dashboard/welcome/img/introStepsDp.png";

type Props = {
  coachSubText?: string;
  title: React.ReactNode;
  description?: string | ReactNode;
  descriptionClassName?: React.ComponentProps<"div">["className"];
  onShowContent?: () => void;
};

const ChatGuide: React.FC<Props> = ({
  coachSubText,
  title,
  description,
  descriptionClassName,
  onShowContent,
}) => {
  const { t } = useTranslation("general");

  const [refs, setRefs] = useState<RefObject<HTMLSpanElement>[]>([]);
  const [desc, setDesc] = useState<any[]>([]);
  const [index, setIndex] = useState(-1);
  const [boldMode, setBoldMode] = useState(false);

  useEffect(() => {
    if (
      description &&
      typeof description === "string" &&
      index < description.length
    ) {
      setTimeout(() => {
        const char = description[index];
        if (char === "*") {
          if (boldMode) {
            setBoldMode(false);
          } else {
            setBoldMode(true);
            const ref = createRef<RefObject<HTMLSpanElement>>();
            setDesc([
              ...desc,
              <HighlightedText
                elRef={ref as any}
                key={index}
              ></HighlightedText>,
            ]);
            setRefs([...refs, ref as any]);
          }
          return setIndex(index + 1);
        }

        const ref = refs[refs.length - 1];

        if (boldMode && ref.current) {
          ref.current.innerHTML += char;
        }

        if (!boldMode) {
          desc.push(description[index]);
        }

        setIndex(index + 1);
      }, 10);
    }
  }, [index]);

  useEffect(() => {
    if (
      description &&
      typeof description === "string" &&
      index === description.length - 1
    ) {
      onShowContent && onShowContent();
    }
  }, [index]);

  return (
    <>
      <div className="flex gap-3 my-3 items-center w-full">
        <div className="w-10 h-10">
          <img src={introStepsDp} alt="marie" />
        </div>
        <div className="flex flex-col gap-1 justify-center">
          <p className="font-bold text-xs m-0">Marie Aragon</p>
          <p className="font-bold text-gray text-xs font-dm_sans m-0">
            {coachSubText ? coachSubText : t("head_of_coaching")}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-1 w-full">
        <div
          className={`w-max px-4 py-2 rounded-tl-2xl rounded-tr-2xl rounded-br-2xl bg-white ${
            !description ? "rounded-2xl" : ""
          }`}
        >
          {title}
        </div>
        {description ? (
          <div className="w-full basis-5/12 px-4 py-3 rounded-bl-2xl rounded-tr-2xl rounded-br-2xl bg-white">
            {typeof description === "string" ? (
              <p
                className={`font-dm_sans text-xs whitespace-pre-line ${descriptionClassName}`}
              >
                {desc}
              </p>
            ) : (
              description
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ChatGuide;
