import React, {useState} from 'react';
import {twMerge} from "tailwind-merge";
import {CheckIcon, EyeOffIcon, HeartIcon, ThumbDownIcon} from "@heroicons/react/outline";
import {HeartIcon as SolidHeartIcon} from "@heroicons/react/solid";
import {useTranslation} from "react-i18next";
import {ContentState} from "../../../constants/enums";
import {IContentState} from "../../../constants/types";

interface Props {
  title: string;
  imageUrl: string;
  linkUrl: string;
  type?: string;
  className?: { font?: string, img?: string, card?: string };
  contentId: number;
  markAsRead?: boolean;
  saved?: boolean;
  handleClick?: (contentId:number, data:IContentState) => void;
  irrelevant?: boolean;
  removed?: boolean;
}

function InfoCard({ title, imageUrl, linkUrl, type, className, contentId, markAsRead, saved, handleClick, irrelevant, removed }: Props) {
  const { t } = useTranslation("general")

  const [isCheckFavorite, setIsCheckFavorite] = useState(saved || false);
  const [isMarkAsRead, setIsMarkAsRead] = useState(markAsRead || false);

  const handleFavorite = () => {
    handleAction({
      content_state: ContentState.saved,
      content_state_value: !isCheckFavorite
    });
    setIsCheckFavorite(!isCheckFavorite);
  }

  const handleMarkAsRead = () => {
    handleAction({
      content_state: ContentState.mark_as_read,
      content_state_value: !isMarkAsRead
    });
    setIsMarkAsRead(!isMarkAsRead);
  }

  const handleAction = (data: IContentState) => {
      handleClick && handleClick(contentId, data);
  }


  const getTypeTag = () => {
    switch (type) {
      case 'video':
        return <div
            className="top-0 right-0 mt-0.5 mb-1 mr-2 bg-pink-light text-black-700 text-xs px-1 py-0.5 rounded w-14 font-medium">Youtube</div>;
      case 'podcast':
        return <div className="top-0 right-0 mt-0.5 mb-1 mr-2 bg-yellow text-black-700 text-xs px-1 py-0.5 rounded w-14 font-medium">Podcast</div>;
      case 'article':
        return <div className="top-0 right-0 mt-0.5 mb-1 mr-2 bg-green-50 text-black-700 text-xs px-1 py-0.5 rounded w-14 font-medium" >Article</div>;
      default:
        return null;
    }
  };

  return (
      <div className={twMerge("flex flex-col bg-white rounded-lg h-full w-full overflow-clip ", className?.card)}>
        <div className="relative">
          <a
              href={linkUrl}
              target="_blank"
              rel="noopener noreferrer"
              title={title}
              onClick={() => handleAction({content_state: ContentState.has_clicked, content_state_value: true})}
          >
          <img
              src={imageUrl}
              alt={title}
              className={className?.img}
          />
          </a>
          {markAsRead !== undefined &&
              <div className="absolute top-2 right-2 gap-1 flex flex-col">
                <div className={`group flex items-center ${isMarkAsRead ? "bg-[#B4E1C6] shadow-[#B4E1C6]" : "bg-black shadow-black"} rounded-full p-1 shadow `}>
                  <button className="flex justify-center items-center" onClick={handleMarkAsRead}>
                    <CheckIcon className={`h-3.5 w-3.5 ${isMarkAsRead ? "text-white" : "text-white"}`}/>
                    <p className={`text-xs ${isMarkAsRead ? "text-white" : "text-white"} font-medium px-1 hidden group-hover:flex`}>
                      {isMarkAsRead ? t("resources.marked_as_read") : t("resources.mark_as_read")}
                    </p>
                  </button>
                </div>
              </div>
          }
          {irrelevant !== undefined &&
              <div className="absolute bottom-9 right-2 gap-1 flex flex-col">
                <div className="group flex items-center bg-black p-1 rounded-full shadow shadow-black">
                  <button className="flex justify-center items-center" onClick={() =>
                      handleAction({content_state: ContentState.irrelevant, content_state_value: true})}>
                    <ThumbDownIcon className="text-white h-3.5 w-3.5"/>
                    <p className="text-xs text-white font-medium px-1 hidden group-hover:flex">{t("resources.show_less")}</p>
                  </button>
                </div>
              </div>
          }
          {removed !== undefined &&
              <div className="absolute bottom-2 right-2 gap-1 flex flex-col">
                <div className="group flex items-center bg-black p-1 rounded-full shadow-sm shadow-black">
                  <button className="flex justify-center items-center" onClick={() =>
                      handleAction({content_state: ContentState.removed, content_state_value: true})}>
                    <EyeOffIcon className="text-white h-3.5 w-3.5"/>
                    <p className="text-xs text-white font-medium px-1 hidden group-hover:flex">{t("resources.remove_from_view")}</p>
                  </button>
                </div>
              </div>
          }
        </div>
        <div className='p-2'>
          <div className="flex justify-between items-center">
            {getTypeTag()}
            {saved !== undefined &&
                <div className="flex items-center">
                  <button className="flex justify-center items-center" onClick={handleFavorite}>
                    {isCheckFavorite ? <SolidHeartIcon className="text-red h-5 w-5"/> :
                        <HeartIcon className="text-red h-5 w-5"/>}
                  </button>
                </div>
            }
          </div>
          <a
              href={linkUrl}
              target="_blank"
              rel="noopener noreferrer"
              className=" hover:text-red hover:no-underline "
              title={title}
              onClick={() => handleAction({content_state: ContentState.has_clicked, content_state_value: true})}
          >
            <h6 className={className?.font}>{title}</h6>
          </a>
        </div>
      </div>
  );
}

export default InfoCard;
