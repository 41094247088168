import jwt_decode from "jwt-decode";


const getDecodedAccessToken = (token: string): any => {
    try {
        return jwt_decode(token);
    } catch (Error) {
        return null;
    }
}
export const IsTokenExp = (token: string) => {

    const tokenDecoded = getDecodedAccessToken(token);
    if (tokenDecoded.exp * 1000 < Date.now()) {
        console.log(tokenDecoded.exp * 1000)
        console.log(Date.now())
        return true
    }else{
        return false
    }
}