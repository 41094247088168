import {ElementType} from "react";

import {CgSpinner} from "react-icons/cg";

type SpinnerProps = {
    size?: string | number;
    indicator?: ElementType;
    className?: string;
};

const Spinner = (props: SpinnerProps) => {
    const {indicator: Component = CgSpinner, size = 20, className} = props;

    const spinnerStyle = {
        height: size,
        width: size,
    };

    return (
        <Component style={spinnerStyle} className={`animate-spin text-red ${className}`}/>
    );
};

export default Spinner;
