import React, {useEffect, useRef, useState} from 'react';
import {Steps, StepsProvider, useSteps} from 'react-step-builder';
import ChatBox from "./journey/ChatBox";
import {BookingDataProvider} from "./journey/ChatDataContext";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useGuideStatus from "@hooks/useGuideStatus";
import ICoach from "../../../../../models/ICoach";
import {getGuideStatus, setGuideStatus} from "../../../../../services/api/teams/GuideActions";
import Coaches from "./components/Coaches";
import FrameModal from "../../../../../shared/components/FrameModal";
import Container from "./components/Container";
import {IOccupiedEvents, ITimeSlot} from "../../../../../models/IBooking";
import {getCoaches} from "../../../../../services/api/companyApi";
import useAuth from "@hooks/useAuth";
import ChooseTimeSlot from "./components/ChooseTimeSlot";
import ConfirmBookingContainer from "./components/ConfirmBookingContainer";
import BookingConfirmed from "./components/BookingConfirmed";
import ActionButton from "./components/ActionButton";
import SummaryContent from "./journey/SummaryContent";
import Loader from "./journey/Loader";
import {getSummary} from "../../../../../services/api/AiBookingActions";
import {coachesList} from "./components/coaches.constant";

const Step1 = ({handleStepNavigation, journeyId, setJouneyId}: {
    handleStepNavigation: (
        stepNo: number,
        guideComplete?: boolean) => void
    journeyId: number | undefined
    setJouneyId: React.Dispatch<React.SetStateAction<number | undefined>>
}) => {
    const {current, next} = useSteps()
    return (
        <div className="h-full flex justify-center w-full flex-col gap-6 items-center bg-warm">
            <div className="bg-white flex flex-col rounded p-4 gap-5 border-v2_grey-300 h-5/6 border-[1px] w-5/6">
                <BookingDataProvider>
                    <ChatBox handleJump={() => {
                        handleStepNavigation(current, false)
                        next()
                    }}
                             setJouneyId={setJouneyId} journeyId={journeyId}/>
                </BookingDataProvider>
            </div>
        </div>
    );
};
const Step2 = ({handleStepNavigation, journeyId}: {
    journeyId: number | undefined,
    handleStepNavigation: (
        stepNo: number,
        guideComplete?: boolean) => void
}) => {
    const {current, next} = useSteps()
    return (
        <div className="h-full flex justify-center w-full flex-col gap-6 items-center bg-warm">
            <div className="bg-white flex flex-col rounded p-4 gap-5 border-v2_grey-300 h-5/6 border-[1px] w-5/6">
                <SummaryContent onClickNext={() => {
                    handleStepNavigation(current, false)
                    next()
                }} sessionId={journeyId}/>
            </div>
        </div>
    );
};

const Step3 = ({journeyId, handleSelectCoach, handleStepNavigation}: {
    // coaches: ICoach[]
    journeyId: number
    handleSelectCoach: (coach: ICoach) => void
    handleStepNavigation: (
        stepNo: number,
        guideComplete?: boolean) => void
}) => {
    const {next, current} = useSteps();
    const frameModalRef = useRef<React.ElementRef<typeof FrameModal>>(null);
    const [coaches, setCoaches] = useState<ICoach[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const {user} = useAuth();


    useEffect(() => {
        setIsLoading(true);
        getSummary(journeyId).then((summary) => {
            console.log(summary);
            const coaches = coachesList?.map((coach: ICoach, index: number) => {
                if (coach.isMentor == true && coach.id == 479) {
                            return {
                                ...coach,
                                specializations: summary.keywords.slice(0, 5).map((item: string) => {
                                    return {
                                        id: Math.random(),
                                        specialization: item
                                    }
                                }),

                            }
                } else {
                    return coach
                        }

            })
                    setCoaches(coaches);

        }).finally(() => {
            setIsLoading(false);
        });

        console.log('Loading coaches')
    }, []);
    return (
        <Container children={
            <div className="w-full h-full">
                <FrameModal ref={frameModalRef}/>

                {isLoading ? <Loader/> : <Coaches
                    coaches={coaches}
                    // selectedCoach={selectedCoach}
                    handleSelectCoach={(coach: ICoach) => {
                        handleSelectCoach(coach);
                        handleStepNavigation(current)
                        next()
                    }}
                    playCoachVideo={(url: string, title: string) => {
                        frameModalRef.current?.open(url, title);
                    }}
                />}
            </div>
        } currentStep={current}/>


    );
};
const Step4 = ({selectedCoach, timeSlot, occupiedEvents, setTimeSlot, setOccupiedEvents, handleStepNavigation}: {
    selectedCoach: ICoach | null,
    timeSlot: ITimeSlot | null,
    occupiedEvents: IOccupiedEvents,
    setTimeSlot: React.Dispatch<React.SetStateAction<ITimeSlot | null>>,
    setOccupiedEvents: (occupiedEvents: IOccupiedEvents) => void;
    handleStepNavigation: (
        stepNo: number,
        guideComplete?: boolean) => void
}) => {
    const {current, next, prev} = useSteps();
    return (
        <Container children={
            <div className="w-5/6">
                <ChooseTimeSlot
                    coach={selectedCoach}
                    timeSlot={timeSlot}
                    setTimeSlot={setTimeSlot}
                    occupiedEvents={occupiedEvents}
                    setOccupiedEvents={setOccupiedEvents}
                    isOnboarding
                    onGetOccupiedEventsFail={() => {
                        // toast.error(t("choose_time_slot.cannot_access_coach_calendar"));
                        // handleStepNavigation();
                    }}
                />
                <div className="w-full flex flex-row justify-between">
                    <ActionButton label={"Back"} onClick={prev}/>
                    <ActionButton label={"Next"} onClick={() => {
                        if (!timeSlot || !timeSlot.time) return;
                        handleStepNavigation(current)
                        next()
                    }}/>
                </div>
            </div>
        } currentStep={current}/>


    );
};

const Step5 = ({selectedCoach, timeSlot, handleStepNavigation}: {
    selectedCoach: ICoach | null,
    timeSlot: ITimeSlot | null,
    handleStepNavigation: (
        stepNo: number,
        guideComplete?: boolean) => void
}) => {
    const {current, next, prev} = useSteps();
    return (
        <Container children={
            <ConfirmBookingContainer selectedCoach={selectedCoach} timeSlot={timeSlot}
                                     handleStepNavigation={() => {
                                         handleStepNavigation(current, true)
                                         next()
                                     }}
                                     onBackBtnClick={prev}
            />
        } currentStep={current}/>

    );
};

const Step6 = () => {
    const {current, jump} = useSteps();
    const nav = useNavigate()
    return (
        <Container children={
            <div className="w-2/3 h-full flex flex-col items-center">
                <BookingConfirmed/>
                <div className="w-full flex flex-row justify-between">
                    <ActionButton label={"Rebook"} onClick={() => jump(3)}/>
                    <ActionButton label={"Done"} onClick={() => nav("/dashboard/home")}/>
                </div>
            </div>
        } currentStep={current} displayStep={false}/>

    );
};

const Content = () => {

    const navigate = useNavigate();
    const {t} = useTranslation("welcome");
    const guideStatus = useGuideStatus();
    const {next, current, jump} = useSteps();
    const {user} = useAuth();
    const company_id = user?.companyId;
    const client_id = user?.id;

    const {state}: any = useLocation();
    const step = state && state.statusData ? state.statusData.step : 0;
    const [occupiedEvents, setOccupiedEvents] = useState<IOccupiedEvents>({});
    const [timeSlot, setTimeSlot] = useState<ITimeSlot | null>(null);
    const [visibleStep, setVisibleStep] = useState(1);
    const [selectedCoach, setSelectedCoach] = useState<ICoach | null>(null);
    const [journeyId, setJouneyId] = useState<number | undefined>();


    useEffect(() => {
        guideStatus.checkStatus(() => {
            navigate("/");
        });
        setSelectedCoach(JSON.parse(localStorage.getItem("coach") as string));

        getGuideStatus().then(res => {
            if (res && res.step) {
                setVisibleStep(res.step)
                current !== res.step && jump(res.step)

            }
        })
    }, []);


    const handleSelectCoach = (coach: ICoach) => {
        localStorage.setItem("coach", JSON.stringify(coach));
        setOccupiedEvents({});
        setTimeSlot(null);
        setSelectedCoach(coach);
    }
    const handleStepNavigation = (
        stepNo: number,
        guideComplete?: boolean,
        callback?: () => {}
    ) => {

        setGuideStatus(stepNo, guideComplete)
            .then(() => {
                // jump(stepNo);
            })
            .catch((e) => console.log(e));
    };

    return (
        <div key={visibleStep} className="w-full h-full">
            <Steps startsFrom={visibleStep}>
                <Step1 handleStepNavigation={handleStepNavigation} setJouneyId={setJouneyId} journeyId={journeyId}/>
                <Step2 handleStepNavigation={handleStepNavigation} journeyId={journeyId}/>
                <Step3
                    // coaches={coaches}
                    journeyId={journeyId!}
                    handleSelectCoach={handleSelectCoach}
                    handleStepNavigation={handleStepNavigation}/>
                <Step4 selectedCoach={selectedCoach}
                       timeSlot={timeSlot}
                       occupiedEvents={occupiedEvents}
                       setTimeSlot={setTimeSlot}
                       setOccupiedEvents={setOccupiedEvents}
                       handleStepNavigation={handleStepNavigation}/>
                <Step5 selectedCoach={selectedCoach} timeSlot={timeSlot}
                       handleStepNavigation={handleStepNavigation}/>
                <Step6/>
            </Steps>
        </div>
    );
};

const OnboardingAI = () => {
    return (
        <StepsProvider>
            <Content/>
        </StepsProvider>
    )
}
export default OnboardingAI;
