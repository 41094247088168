export const DUMMY_CHART_DATA = [
    { label: 'Career', value: 5, color: "#B6E2A1" },
    { label: 'Self Development', value: 8, color: "#FFB26B" },
    { label: 'Communication', value: 3, color: "#8093FF" },
    { label: 'Leadership', value: 7, color: "#FF7F50" },
    { label: 'Creativity', value: 2, color: "#A0CED9" },
    { label: 'Problem Solving', value: 6, color: "#C0C0C0" },
    { label: 'Teamwork', value: 4, color: "#FFD700" },
    { label: 'Adaptability', value: 1, color: "#87CEEB" },
    { label: 'Time Management', value: 9, color: "#90EE90" },
    { label: 'Decision Making', value: 5, color: "#FF69B4" },
];

export const DUMMY_BOOKING_DATA = [
    { count : 1, label : "02-17-24" },
    { count : 2, label : "02-20-24" },
    { count : 3, label : "02-23-24" },
    { count : 0, label : "02-27-24" },
    { count : 1, label : "03-02-24" },
]