import {FC} from 'react';

import userIcon from '../../../../assets/img/teams/icons/user.png';

import Session from "../../../../models/ISession";
import {TFunction, useTranslation} from "react-i18next";
import {ITeamsAuthData} from "../../../../models/ITeamsAuth";
import {jumpToDeepLink} from "../../../../utils/helpers/teams";

interface Props {
    sessionCount?: number
    loading?: boolean
}

function renderCard(props:Props,t: TFunction<"home", undefined>) {

    let authUser: ITeamsAuthData = JSON.parse(localStorage.getItem('ch_user') || '{}') as ITeamsAuthData

    if (props?.sessionCount && props?.sessionCount>0) {
        return (<>
            <div className="flex items-center justify-start">
                <div className="p-2 mr-5 bg-red-100 rounded">
                    <img src={userIcon} alt=""/>
                </div>
                <div>{authUser?.company_name === 'HEC' ? t('text_18_1'): t('text_18')}</div>
            </div>

        </>)
    } else{
        return (<>
            <div className="flex items-center justify-start">
                <div className="p-2 mr-5 bg-red-100 rounded">
                    <img src={userIcon} alt=""/>
                </div>
                <div>{authUser?.company_name === 'HEC' ? t('text_19_1'):t('text_19')}</div>
            </div>
            <button onClick={() => jumpToDeepLink("booking")}
                    className="px-20 py-2 text-white rounded bg-red flex-end">{authUser?.company_name === 'HEC' ? t('text_20_1'):t('text_20')}
            </button>
        </>)
    }

}

const NoLastSession: FC<Props> = (props) => {
    const {t} = useTranslation('home')
    console.log('nolastsession')
    return (
        <div className="flex items-center justify-between px-5 py-5 bg-white rounded-md shadow-lg text-grey-800">

            {props.loading
                ?
                <div className="inline-flex justify-center w-full py-5 text-center">
                    <div className="flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             style={{margin: "auto", background: "0 0", display: "block", shapeRendering: "auto"}}
                             width="24" height="24" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <circle cx="50" cy="50" fill="none" stroke="#fd0054" strokeWidth="8" r="35"
                                    strokeDasharray="164.93361431346415 56.97787143782138">
                                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite"
                                                  dur="1.1363636363636365s" values="0 50 50;360 50 50" keyTimes="0;1"/>
                            </circle>
                        </svg>
                        <p className="ml-3 font-light">Loading...</p>
                    </div>
                </div>
                :
                <>{renderCard(props,t)}</>
            }
        </div>
    )
}

export default NoLastSession;
