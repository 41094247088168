import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

import Card from "../../../components/Card";
import InfoPopover from "../../../components/InfoPopover";

type Props = {
  title?: string;
  image?: string;
  progress?: number;
  tooltip: string;
};

const AssessmentCard = ({ image, progress = 0, title, tooltip }: Props) => {
  return (
    <Card>
      <div className="flex flex-row justify-between items-center pb-4">
        <img src={image} alt={title} className="h-20" />
        <div className="w-14">
          <CircularProgressbar
            value={progress}
            text={`${progress}%`}
            strokeWidth={12}
            styles={buildStyles({
              rotation: 0.25,
              textColor: "black",
              pathColor: "#EF476F",
              trailColor: "#fdedf1",
            })}
          />
        </div>
      </div>
      <div className="flex flex-row items-center justify-between">
        <div className="font-serif font-bold">{title}</div>
        <InfoPopover content={tooltip} trigger="hover" placement="topEnd" />
      </div>
    </Card>
  );
};

export default AssessmentCard;
