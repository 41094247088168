import ReactTooltip from "react-tooltip";

import NoDataWrapper from "../../../../../shared/components/NoDataWrapper";
import InformationIcon from "../../../../../shared/components/InformationIcon";

import CoachelloIcon from "@img/coachello.svg";
import SimImg from "@img/dashboard/sim.png";
import Backdrop from "./Backdrop";

interface Props {
    bgImg: string;
    value: any;
    title: string;
    isFiltered?: boolean ;
}

const UsedCreditsCard= (props: Props) => {
    return (
        <div
            className="relative rounded-2xl bg-white w-full h-full bg-origin-border bg-no-repeat bg-top "
        >
            {props.value == null && !props.isFiltered && <NoDataWrapper/>}
            <Backdrop/>
            <div className="relative p-5 flex flex-row h-full justify-between">
                <div className="flex flex-col h-full text-black font-lexend">
                    <p className="font-bold text-sm text-gray-600">{props.title}</p>
                    <ReactTooltip
                        className={"w-60"}
                        delayHide={500}
                        id={"tooltipId"}
                        place="top"
                        effect="solid"
                    >
                    </ReactTooltip>
                    <div className=" py-5 px-2">
                        <div className="flex gap-1 items-end">
                            {/* <h2>{props.value}</h2> */}
                            {props.value == null ? <h2 className="font-bold text-4xl">hii</h2> : <h2 className="font-bold text-4xl">{props.value}</h2>}
                        </div>

                    </div>
                    <div className="flex gap-1">
                        <img src={CoachelloIcon} alt="coachello" width={16} height={14}/>
                        <a
                            className="tracking-widest font-lexend text-black text-sm"
                            href={"https://coachello.io"}
                            target="_blank"
                            rel="noreferrer"
                        >
                            coachello.io
                        </a>
                    </div>
                </div>
                <div className="h-full flex items-center w-1/3">
                    <img src={SimImg} alt="coachello" width={50} height={50} className="bg-warm-light rounded"/>

                </div>
            </div>
        </div>
    );
};

export default UsedCreditsCard;
