import React, {useEffect, useImperativeHandle, useState} from "react";

import {ReactMultiEmail} from "react-multi-email";
import {useTranslation} from "react-i18next";
import "react-multi-email/style.css";

import {get360FeedbackPendingRequests, send360FeedbackRequests} from "../../../services/api/teams/Feedback360Actions";
import i18n from "../../../utils/lib/i18n";
import ENG from "../locale/eng/reports.json";
import Fr from "../locale/fr/reports.json";

import useAuth from "@hooks/useAuth";
import {useConfig} from "../../../utils/providers/AppConfigProvider";
import {ISentFeedbackRequestData} from "../../../constants/types";
import FeedbackRequestCard from "../../dashboard/pages/Reports/components/FeedbackRequestCard";

type Props = {
    onChangeEmails?: (emails: string[]) => void;
    inputHeight?: string;
    hideSendButton?: boolean;
    onNotificationSuccess?: () => void;
    sendButtonText?: string;
};

const Feedback360Emails: React.ForwardRefRenderFunction<
    { sendNotify: () => Promise<any> },
    Props
> = (
    {
        onChangeEmails,
        inputHeight,
        hideSendButton,
        onNotificationSuccess,
        sendButtonText,
    },
    ref
) => {
    i18n.addResourceBundle("en", "reports", ENG);
    i18n.addResourceBundle("fr", "reports", Fr);

    const [emails, setEmails] = React.useState<string[]>([]);
    const {user} = useAuth();
    const {config} = useConfig();
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation("reports");
    const [feedbackPending, setFeedbackPending] = useState<ISentFeedbackRequestData[]>([]);

    const flash_feedback_required = config?.flash_feedback_required;

    useEffect(() => onChangeEmails && onChangeEmails(emails), [emails]);

    useImperativeHandle(ref, () => ({
        sendNotify,
    }));

    const sendNotify = () => {
        setLoading(true);
        return send360FeedbackRequests(user?.id!, emails)
            .then(() => {
                setLoading(false);
                onNotificationSuccess && onNotificationSuccess();
            })
            .catch(() => setLoading(false));
    };

    const handleClick = () => {
        if (emails.length) {
            sendNotify();
        } else {
            onNotificationSuccess && onNotificationSuccess();
        }
    };

    const getButtonText = (buttonText: string) => {
        return (flash_feedback_required || emails.length) ? buttonText : `Skip & ${buttonText}`;
    };

    useEffect(() => {
        if (user) {
            get360FeedbackPendingRequests(user?.id).then((response) => {
                setFeedbackPending(response);
            })
        }
    }, [user]);

    return (
        <div className="p-5 flex flex-col justify-center gap-5 h-full">
            {feedbackPending.length > 0 && (
                <>
                    <h4>{t("pending_requests")}</h4>
                    <FeedbackRequestCard sentRequests={feedbackPending} maxHeight="max-h-44"/>
                </>
            )}
            <h4>{t("feedback_360.text_3")}</h4>
            <ReactMultiEmail
                className={inputHeight ? inputHeight : "h-36"}
                placeholder={t("feedback_360.text_4")}
                emails={emails}
                onChange={(_emails: string[]) => setEmails(_emails)}
                getLabel={(
                    email: string,
                    index: number,
                    removeEmail: (index: number) => void
                ) => {
                    return (
                        <div data-tag key={index}>
                            {email}
                            <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
                        </div>
                    );
                }}
            />

            {!hideSendButton && (
                <button
                    onClick={handleClick}
                    disabled={!emails.length && flash_feedback_required}
                    className={
                        "disabled:bg-gray-400 mx-auto col-span-3 px-4 py-2 text-white rounded bg-red md:col-span-1 cursor-pointer"
                    }
                >
                    {loading
                        ? t("feedback_360.text_5")
                        : sendButtonText
                            ? getButtonText(sendButtonText)
                            : t("feedback_360.text_6")}
                </button>
            )}
        </div>
    );
};

export default React.forwardRef(Feedback360Emails);
