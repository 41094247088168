import React from 'react';
import {FeedbackStatus} from "../../../../../constants/enums";
import {ISentFeedbackRequestData} from "../../../../../constants/types";
import {useTranslation} from "react-i18next";
import i18n from "../../../../../utils/lib/i18n";
import ENG from "../../../locale/eng/reports.json";
import Fr from "../../../locale/fr/reports.json";

interface Props {
    sentRequests: ISentFeedbackRequestData[];
    maxHeight?: string;
}

const FeedbackRequestCard: React.FC<Props> = ({sentRequests, maxHeight}) => {
    i18n.addResourceBundle('en', 'reports', ENG);
    i18n.addResourceBundle('fr', 'reports', Fr);
    const { t } = useTranslation("reports");
    return (
        <div>
            <div className="grid grid-cols-3 gap-4 px-3 pb-1">
                <h5 className="font-bold">{t("email")}</h5>
                <h5 className="font-bold">{t("sent_date")}</h5>
                <div className="flex justify-start">
                    <h5 className="text-center font-bold w-5/12">{t("status")}</h5>
                </div>
            </div>
            {sentRequests.length > 0 ? (
                <div className={`${maxHeight} overflow-auto flex flex-col bg-white gap-5 p-3 rounded border`}>
                    {sentRequests.map((item, key) => (
                        <div className="grid grid-cols-3 gap-4" key={key}>
                            <div>{item.receiver_email}</div>
                            <div>{item.sent_at}</div>
                            <div className="flex justify-start">
                                <span className={`${item.status === FeedbackStatus.received ?
                                    "bg-[#DDF6EF] text-[#1FC191]" : "bg-[#FFF5F8] text-[#FE7BA6]"}  
                                     w-5/12 py-1 mx-3  rounded-full text-center text-xs font-normal capitalize`}>
                                    {t(`${item.status}`)}
                                </span>
                            </div>

                        </div>
                    ))}
                </div>
            ) : (
                <p className="px-3 py-2">{t("no_req_text")}</p>
            )}
        </div>
    );
};

export default FeedbackRequestCard;