import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import img_action_plan from "./../../../../../assets/img/aiCoaching/Individual-coaching.png";

import {useAICoaching} from "../utils/AICoachingContext";
import {useConfig} from "../../../../../utils/providers/AppConfigProvider";
import {ReferenceItem, SummaryData} from "../../AICoaching/types";
import {getRecommendations, getSummary} from "../actions";
import Loader from "../../AICoaching/components/Loader";
import {NoDataWrapperArticles} from "../../Resources/AIResources/AIResources";
import InfoCard from "../../../../../shared/components/InfoCard/InfoCard";
import {CgClose} from "react-icons/cg";

type SummaryProps = {
    threadID?: number
    summaryData?: SummaryData
    // viewConversation
}

function Summary({
                     threadID,
                     summaryData
                 }: SummaryProps) {

    const {t} = useTranslation("ai_coaching");
    const {config} = useConfig();
    const {state, setState} = useAICoaching();

    let getSummaryAbortController = new AbortController();
    let getReferenceMaterialsAbortController = new AbortController();

    const isAIGeneratedMaterial = config?.ai_generated_materials;
    // const isAIGeneratedMaterial = true;

    const [isSummaryLoading, setIsSummaryLoading] = useState(false)
    const [isAIMaterialsLoading, setIsAIMaterialsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    const [summary, setSummary] = useState<string | undefined>(undefined);
    const [actionPlanSteps, setActionPlanSteps] = useState<string[]>()
    const [refMaterialsArray, setRefMaterialsArray] = useState<ReferenceItem[]>([]);

    useEffect(() => {
        resetAll();
        if (!threadID) return
        setIsSummaryLoading(true)
        if (summaryData) {
            setSummary(summaryData.summary);
            setActionPlanSteps(summaryData.actions);
            setIsSummaryLoading(false)
            return;
        } else {
            getSummary(threadID, getSummaryAbortController)
                .then((data) => {
                    if (data) {
                        setSummary(data.summary);
                        setActionPlanSteps(data.actions);
                    }
                })
                .finally(() => {
                    setIsSummaryLoading(false)
                    fetchReferenceMaterials(threadID);
                });
        }
    }, [threadID]);


    const fetchReferenceMaterials = async (id: number) => {
        setIsError(false);
        setIsAIMaterialsLoading(true)
        try {
            if (config?.ai_generated_materials === false) return;
            const materials = await getRecommendations(id, getReferenceMaterialsAbortController);
            if (materials) {
                const selectedMaterials = [];
                if (materials.articles.length > 0) selectedMaterials.push({
                    ...materials.articles[0],
                    media_type: 'article'
                });
                if (materials.youtubes.length > 0) selectedMaterials.push({
                    ...materials.youtubes[0],
                    media_type: 'video'
                });
                if (materials.podcasts.length > 0) selectedMaterials.push({
                    ...materials.podcasts[0],
                    media_type: 'podcast'
                });
                setRefMaterialsArray(selectedMaterials);
            }
        } catch (error) {
            console.error("Failed to fetch reference materials:", error);
            setIsError(true);
        } finally {
            setIsAIMaterialsLoading(false)
            console.log(isError)
        }
    };

    const abortNetworkCalls = () => {
        getSummaryAbortController.abort();
        getReferenceMaterialsAbortController.abort()
        getSummaryAbortController = new AbortController();
        getReferenceMaterialsAbortController = new AbortController()
    };

    const resetAll = () => {
        abortNetworkCalls();
        setIsSummaryLoading(false);
        setIsAIMaterialsLoading(false);
        setSummary(undefined);
        setActionPlanSteps([])
        setRefMaterialsArray([])
    };

    return (
        <div
            className="flex flex-col rounded box-shadow w-full h-full relative gap-4 p-5 bg-warm-light border-warm-dark text-black text-sm overflow-auto">
            <button className="rounded p-1 border hover:bg-red ml-auto relative flex items-center justify-center"
                    onClick={() => setState('thread')}><CgClose
                className="w-5 h-5 relative"/></button>
            <div className="flex flex-row items-start justify-between">
                <p className="font-bold">{t("summary.title")}</p>
            </div>
            {
                isSummaryLoading &&
                <div className="flex justify-center items-center">
                    <Loader isFullscreen={false} spinnerColor="red"/>
                </div>
            }
            {!summary && <p>{t("summary.not_available")}</p>}
            {summary && (
                <div className="bg-warm-dark w-full px-3 py-3 text-left rounded-xl">
                    <p>{summary}</p>
                </div>
            )}
            {actionPlanSteps &&
                actionPlanSteps.length !== undefined &&
                actionPlanSteps.length > 0 && (
                    <div className="flex flex-1 flex-row items-center justify-center gap-5">
                        <img
                            src={img_action_plan}
                            className="h-32 w-auto"
                            alt="action plan"
                        />
                        <div>
                            <p className="font-medium py-2">{t("summary.action_plan")}</p>
                            <ul className="list-disc text-left">
                                {actionPlanSteps.map((step) => (
                                    <li>{step}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}

            {isAIGeneratedMaterial &&
                <>
                    <p className="font-bold pb-1">Reference Materials</p>
                    <div className="w-full justify-center flex gap-4 relative p-5">
                        {!(refMaterialsArray?.length > 0) && !isAIMaterialsLoading && <NoDataWrapperArticles/>}
                        {
                            !isError && isAIMaterialsLoading &&
                            <div className="flex justify-center items-center">
                                <Loader isFullscreen={false} spinnerColor="red"/>
                            </div>
                        }
                        {
                            refMaterialsArray.map((item, index) => (
                                <div className="flex-col flex-1 h-auto">
                                    <InfoCard
                                        title={item.title}
                                        imageUrl={item.thumbnail}
                                        linkUrl={item.url}
                                        type={item.media_type}
                                        className={{font: "text-xs font-bold", img: "h-[15vh] object-cover w-full"}}
                                        contentId={item.id}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </>
            }

        </div>
    )
}

export default Summary
