import useAuth from "@hooks/useAuth";
import React, {useEffect, useRef, useState} from 'react';
import logoImg from '@img/auth/js.png'
import loginImg from '@img/auth/login.png'
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {DocumentTextIcon, HomeIcon, KeyIcon, LockClosedIcon, MailIcon} from "@heroicons/react/outline";
import LanguageSelector from "../../../shared/components/LanguageSelector/LanguageSelector";
import i18n from "../../../utils/lib/i18n";
import ENG from "../locale/eng/signup.json";
import Fr from "../locale/fr/signup.json";
import {useTranslation} from "react-i18next";
import {is} from "date-fns/locale";
import {use} from "i18next";
import InputPassword from "../../../shared/components/InputPassword";
import Button from "../../../shared/components/Button";
import {toast} from "react-toastify";


const Signup = () => {

    i18n.addResourceBundle('en', 'signup', ENG);
    i18n.addResourceBundle('fr', 'signup', Fr);
    const {t} = useTranslation('signup')

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();
    const {signUp} = useAuth();


    const [password, setPassword] = useState<string>('');
    const [passwordC, setPasswordC] = useState<string>('');
    const [isValid, setValid] = useState<boolean>(false);
    const [isValidC, setValidC] = useState<boolean>(false);
    const submit = () => {
        if (password != passwordC) {
            toast.error(t('text15'))
            return
        }
        signUp({token: searchParams.get("token"), password: password})
            .then(res => {
                toast.success(t('text23'))
                navigate("/julhietsterwen/auth/login");
            }).catch((e) => {
            toast.error(e.response?.data?.message)
            console.log("api error");
        })

    }

    return (
        <div className="flex-col lg:flex-row flex">
            <div className="flex flex-col flex-1">

                <div className="flex justify-center">
                    <img className={"w-1/3 m-8"} src={logoImg}/>
                </div>
                <div className="flex flex-col justify-center items-center gap-5">
                    <div className="flex flex-col gap-3 w-1/2">

                        <h2> {t('text21') + " " + (searchParams.get("firstName") != null ? searchParams.get("firstName") : '')} !</h2>
                        <p> {t('text22')}</p>

                        <div>
                            <div className="flex flex-col gap-3 ">
                                <InputPassword key="p" setValid={setValid}
                                               onChange={e => setPassword(e.target.value)}
                                               label={t('text3')}
                                               name='password'/>

                                <InputPassword key="pc" setValid={setValidC}
                                               onChange={e => setPasswordC(e.target.value)}
                                               label={t('text4')}
                                               name='passwordC'/>
                            </div>
                        </div>
                    </div>

                    <button onClick={() => submit()} disabled={!(isValid && isValidC)}
                            className="dashboard-button bg-black m-auto px-16 disabled:bg-gray-600"
                    >Create
                    </button>

                    <span className="text-gray-600 text-sm">{t('text17')}</span>
                    <button onClick={() => navigate('/julhietsterwen/auth/login')}
                            className="dashboard-button bg-black m-auto px-16">
                        {t('text16')}
                    </button>

                </div>
                <div className="flex justify-center gap-9 mt-10 mb-8">
                    <a href={'https://coachello.io/'} target="_blank" className={"flex items-center gap-1.5"}>
                        <HomeIcon className="text-gray-600 h-5 w-5"/>
                        {t('text5')}
                    </a>
                    <a href={'https://coachello.io/privacy-policy/'} target="_blank"
                       className={"flex items-center gap-1.5"}>
                        <LockClosedIcon className="text-gray-600 h-5 w-5"/>
                        {t('text6')}
                    </a>
                    <a href={'https://coachello.io/terms-and-conditions/'} target="_blank"
                       className={"flex items-center gap-1.5"}>
                        <DocumentTextIcon className="text-gray-600 h-5 w-5"/>
                        {t('text7')}
                    </a>
                </div>
                <div className="absolute bottom-0 right-0">
                    <LanguageSelector/>
                </div>
            </div>
            <div
                style={{backgroundImage: `url(${loginImg})`}}
                className="flex-1 bg-cover lg:h-screen items-end flex hidden lg:flex">
                {/*<img src={loginImg}/>*/}
                <div className={"text-white ml-20 mb-16"}>
                    <h2>{t('text8')}</h2>
                    <div className={"mt-5"}>
                        <h4>
                            {t('text9')}
                        </h4>
                        <span>{t('text10')}</span>
                    </div>
                </div>
            </div>

        </div>
    );
};


export default Signup;
