import React, {ReactNode} from "react";

interface Props {
    icon: ReactNode;
    value: any;
    title: string;
}

const DetailCard = (props: Props) => {
    return (
        <div className="relative w-full h-full flex flex-col justify-center rounded bg-white border-gray-50">
            <div className="p-1 flex justify-between items-center h-full text-black">
                <div className="flex gap-2">
                    <div className="p-1 rounded-2xl my-auto">
                        {props.icon}
                    </div>
                    <div className="">
                        <h4 className="font-lexend font-bold">{props.value}</h4>
                        <span className="text-xs text-start">{props.title}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailCard;
