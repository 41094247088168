import {KeyboardEvent} from "react";

import ArrowRightWhite from "../../../../../../assets/img/mentoring/arrow-right-white.svg";

type ChatInputProps = {
  onNewPrompt: (value: string) => void;
  question: string;
  setQuestion: (value: string) => void;
  placeholder?: string;
  isDisabled?: boolean;
};

const ChatInput = ({
  onNewPrompt,
  question,
  setQuestion, isDisabled = false,
  placeholder = "Type your question here...",
}: ChatInputProps) => {

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmission();
    }
  };
  const handleSubmission = () => {
    if (question.trim() === "") return;
    onNewPrompt(question);
  };

  return (
    <div className="flex flex-row gap-4">
      <div className="h-[40px] w-full flex flex-row items-center border-[1px] border-[#f2ebe3] rounded-[5px] bg-white">
        <input
          className="font-dm_sans w-full h-full text-xs px-3 text-black placeholder:font-light placeholder:text-[#828282] focus:outline-none"
          placeholder={placeholder}
          onKeyUp={handleKeyUp}
          disabled={isDisabled}
          onChange={(e) => {
            if (e.target.value.length > 300) return;
            setQuestion(e.target.value);
          }}
          value={question}
        />
      </div>
      <div
        className="bg-black rounded-[5px] w-[43px] h-[40px] flex justify-center items-center cursor-pointer"
        onClick={handleSubmission}
      >
        <img
          src={ArrowRightWhite}
          alt=""
          className="object-cover pointer-events-none w-[15px]"
        />
      </div>
    </div>
  );
};

export default ChatInput;
