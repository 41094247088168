import { LegacyRef, createRef, useMemo } from "react";

import { Popover, Whisper } from "rsuite";

import infoIcon from "@img/dashboard/reports/icons/info_icon.png";

type Props = {
  content: string | React.ReactNode[];
  trigger?: "click" | "hover";
  placement?: "bottomEnd" | "topEnd" | 'bottomStart';
};

const InfoPopover = ({
  content,
  trigger = "click",
  placement = "bottomEnd",
}: Props) => {
  const popoverContainerRef = useMemo(
    () => createRef<HTMLDivElement | undefined>(),
    []
  );

  return (
    <div
      className="relative"
      ref={popoverContainerRef as LegacyRef<HTMLDivElement>}
    >
      <Whisper
        trigger={trigger}
        placement={placement}
        container={(() => popoverContainerRef.current) as () => HTMLElement}
        speaker={
          <Popover>
            <p className="text-xs font-lexend w-60 cursor-default">{content}</p>
          </Popover>
        }
      >
        <img
          className="cursor-pointer"
          src={infoIcon}
          alt="info"
          width={25}
          height={25}
        />
      </Whisper>
    </div>
  );
};

export default InfoPopover;
