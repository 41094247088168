import { FC, RefObject, useEffect } from "react";

import * as Yup from "yup";

import InputFormik from "../../../../../shared/components/formik/InputFormik/InputFormik";
import Button from "../../../../../shared/components/Button";

import CoachelloLogo from "@img/coachello.svg";
import { Form, Formik, FormikProps, FormikValues } from "formik";

type Props = {
  formikRef: RefObject<FormikProps<FormikValues>>;
  setEmail: (value: string) => void;
  handleNext: (value: string) => void;
  onMount: () => void;
};

const EmailStep: FC<Props> = ({ formikRef, setEmail, handleNext, onMount }) => {
  useEffect(() => {
    onMount();
  }, []);

  return (
    <div className="flex justify-center items-center h-full">
      <div className="w-1/3 flex flex-col items-center gap-2">
        <img src={CoachelloLogo} alt="Coachello" />
        <span className="">Welcome to Coachello!</span>
        <div className="w-full flex flex-col items-center">
          <Formik
            innerRef={formikRef}
            initialValues={{ email: "" }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Invalid email address")
                .required("Required"),
            })}
            onSubmit={(values) => {
              handleNext(values["email"]);
            }}
          >
            <Form className="w-full flex flex-col gap-4">
              <InputFormik
                placeholder="Enter your email"
                label="Email"
                name="email"
                onKeyUp={(e: any) => setEmail(e.target.value)}
              />
              <Button
                className="m-auto text-white bg-red text-xs font-bold px-10 py-2"
                onClick={() => formikRef.current?.submitForm()}
              >
                Continue
              </Button>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EmailStep;
