import ReactTooltip from "react-tooltip";

import BoxIcon from "../../../shared/components/BoxIcon";
import InformationIcon from "../../../shared/components/InformationIcon";
import NoDataWrapper from "../../../shared/components/NoDataWrapper";

import ENG from "../locale/eng/performance.json";
import Fr from "../locale/fr/performance.json";
import i18n from "../../../utils/lib/i18n";
import { randomString } from "../../../utils/helpers/str";
import NoDataIcon from "@icons/noDataIcon.png";

interface Props {
  img: string;
  value: any;
  title: string;
  tooltip: string;
  isFiltered?: boolean ;
  noDataText?: string;
}

const PerformanceCard = (props: Props) => {
  i18n.addResourceBundle("en", "performance", ENG);
  i18n.addResourceBundle("fr", "performance", Fr);

  const tooltipId = randomString();

  return (
    <div className="relative w-full h-full flex flex-col justify-center bg-white rounded border-gray-50">
      {!parseInt(props.value) && !props.isFiltered && <NoDataWrapper />}
       {props.value == null || props.value == 0? 
            <div>
             <h4 className="font-bold pl-4">{props.title}</h4>
            <div className="flex flex-col items-center justify-center p-2">
                     <img src={NoDataIcon} width={40} height={40} alt="No Data Icon" />
                          <h5 className="text-m" style={{ fontWeight: 'bold' }}>{props.noDataText}</h5>
            </div> 
            </div>
            :
      <div className="p-4 flex justify-between items-center h-full text-black">
        <div className="flex gap-5 px-2">
          <BoxIcon img={props.img} />
          <div className="">
            <h2 className="font-lexend font-bold">{props.value}</h2>
            <span className="font-bold text-start">{props.title}</span>
          </div>
        </div>
        <InformationIcon
          data-for={tooltipId}
          data-tip={"hover on me will keep the tooltip"}
          className={"px-2"}
        />
        <ReactTooltip
          className={"w-60"}
          delayHide={500}
          id={tooltipId}
          place="top"
          effect="solid"
        >
          <span>{props.tooltip} </span>
        </ReactTooltip>
      </div>}
    </div>
  );
};

export default PerformanceCard;
