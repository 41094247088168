export const serverErr = () => {
    return <div className="flex flex-col gap-1">
        <h2 className="flex gap-3 items-center">
            {/*<img className="w-8 h-8 " src={closeIcon}/> */}
            Service Update Notice </h2>
        <div className="">
            We’re in the midst of some scheduled maintenance to enhance the quality of our service
            for you. We encourage you to revisit us soon (5-10 minutes).
        </div>
        <div className="flex gap-1 items-center">
            Heartfelt thanks for your understanding. <span className="text-red">❤️</span>
        </div>
    </div>
}
export {}
