import domtoimage from "dom-to-image";
import {jsPDF} from "jspdf";

const downloadFile = ({data, fileName, fileType}: { data: string; fileName: string; fileType: string }) => {
    const blob = new Blob([data], {type: fileType})

    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
}
export const exportToJson = (data: Array<any>) => {
    downloadFile({
        data: JSON.stringify(data),
        fileName: 'users.json',
        fileType: 'text/json',
    })
}

export const exportToCsv = (data: Array<any>, columns: Array<any>, fileName: string = 'data.csv') => {
    console.log(columns)
    // Headers for each column
    let headers = [columns.join(',')]

    // Convert users data to a csv
    let dataCsv = data.reduce((acc, obj) => {
        acc.push(Object.values(obj).join(','))
        return acc
    }, [])
    // console.log(dataCsv);
    downloadFile({
        data: [...headers, ...dataCsv].join('\n'),
        fileName: fileName,
        fileType: 'text/csv',
    })
}

export const downloadAsImage = async (id: string, filename: string) => {
    const input = document.getElementById(id);

    if (!input) {
        console.error(`Element not found: ${id}`);
        return;
    }

    const margin = 10;

    try {
        const dataUrl = await domtoimage.toPng(input);
        const img = new Image();
        img.src = dataUrl;

        const pdf = new jsPDF("p", "pt", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
        const pdfHeight = pdf.internal.pageSize.getHeight() - 2 * margin;

        img.onload = () => {
            const imgWidth = img.width;
            const imgHeight = img.height;
            const ratio = imgWidth / pdfWidth;
            const totalPages = Math.ceil(imgHeight / (pdfHeight * ratio));

            for (let i = 0; i < totalPages; i++) {
                const sourceY = i * pdfHeight * ratio;
                const currentImgHeight = Math.min(pdfHeight * ratio, imgHeight - sourceY);

                const canvas = document.createElement("canvas");
                canvas.width = imgWidth;
                canvas.height = currentImgHeight;
                const ctx = canvas.getContext("2d");

                if (ctx) {
                    ctx.drawImage(img, 0, sourceY, imgWidth, currentImgHeight, 0, 0, imgWidth, currentImgHeight);

                    const pageDataUrl = canvas.toDataURL("image/png");

                    if (i > 0) {
                        pdf.addPage();
                    }

                    const pageHeight = (currentImgHeight * pdfWidth) / imgWidth;
                    pdf.addImage(pageDataUrl, "PNG", margin, margin, pdfWidth, pageHeight);
                } else {
                    console.error("Unable to get canvas context.");
                }
            }

            pdf.save(`${filename}.pdf`);
        };
    } catch (error) {
        console.error("Error capturing image: ", error);
    }
};
