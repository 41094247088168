import Coaching from "../../../coaching/pages/Coaching";
import {useTranslation} from "react-i18next";
import {useNavTitle} from "@hooks/useNavTitle";
import {useEffect, useState} from "react";
import AICoaching from "../AICoaching";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useConfig} from "../../../../utils/providers/AppConfigProvider";
import {AICoachType} from "../AICoaching/enum";
import useAuth from "@hooks/useAuth";

const CoachBooking = ({...props}) => {
    const [searchParams] = useSearchParams();
    const {t} = useTranslation();
    const {config} = useConfig();
    useNavTitle(t(`layout:sidebar.${props.navTitle}`));
    const {user} = useAuth();

    const [isHumanCoach, setIsHumanCoach] = useState(false);
    const [currentBookingCoach, setCurrentBookingCoach] = useState<number>();
    const [reschedulingSession, setReschedulingSession] = useState<number>();
    const navigate = useNavigate();

    const standaloneAiCoach =
        user?.aiCoachType &&
        (user.aiCoachType === AICoachType.standalone ||
            user.aiCoachType === AICoachType.both);

    useEffect(() => {
        if (!config?.ai_coach) {
            setIsHumanCoach(true);
        }
    }, []);

    useEffect(() => {
        setCurrentBookingCoach(Number(searchParams.get("coach_id")));
        setReschedulingSession(Number(searchParams.get("meeting_id")));
    }, [searchParams]);

    if (standaloneAiCoach) navigate('/dashboard/ai/coaching')

    return isHumanCoach || currentBookingCoach ? (
        <div className="bg-linen_gradient flex flex-col gap-4 py-6 px-6 h-full">
            <Coaching coachId={currentBookingCoach} meetingId={reschedulingSession}/>
        </div>
    ) : (
        <AICoaching setIsHumanCoach={setIsHumanCoach}/>
    );
};

export default CoachBooking;
