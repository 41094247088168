import Calendar from "react-calendar";
import moment from "moment";
import { useTranslation } from "react-i18next";

import LoaderContainer from "../../../../../../shared/components/LoaderContainer";

import "./Calender.css";
import ICoach from "../../../../../../models/ICoach";
import {IOccupiedEvents, ITimeSlot} from "../../../../../../models/IBooking";
import { userTimeZone } from "../../../../../../utils/helpers/time";

import clockIcon from "@img/coaching/icons/time.svg";

type Props = {
  selectedCoach: ICoach | null;
  timeSlot: ITimeSlot | null;
  onChange: (field: string, value: string) => void;
  loadOccupiedDates: (coachId: number, date: string) => void;
  isLoading?: boolean;
  occupiedEvents: IOccupiedEvents;
};

const MonthView = ({
  selectedCoach,
  timeSlot,
  onChange,
  loadOccupiedDates,
  isLoading = false,
  occupiedEvents,
}: Props) => {
  const { t } = useTranslation("booking");

  const lng = localStorage.getItem("I18N_LANGUAGE") || "en";

  const handleDateChange = (date: any) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    onChange("date", formattedDate);
  };

  const handleDisableDates = (date: Date) => {
    // Disable dates before today or weekends
    if (moment(date).isBefore(new Date()) || [0, 6].includes(date.getDay())) {
      return true;
    }

    const formattedDate = moment(date).format("YYYY-MM-DD");
    const slots = occupiedEvents[formattedDate];

    // If no slots are occupied for this date, it's not disabled
    if (!slots || slots.length === 0) {
      return false;
    }

    // Sort the occupied slots by their start times
    const sortedSlots = slots.sort((a, b) => {
      const [startTimeA] = a.split("-");
      const [startTimeB] = b.split("-");
      return moment(startTimeA, "HH:mm").diff(moment(startTimeB, "HH:mm"));
    });

    // Check if the day is fully occupied from 07:00 to 22:00
    const dayStart = moment(`${formattedDate} 07:00`, "YYYY-MM-DD HH:mm");
    const dayEnd = moment(`${formattedDate} 21:00`, "YYYY-MM-DD HH:mm");

    let currentCheckTime = moment(dayStart);

    for (let i = 0; i < sortedSlots.length; i++) {
      const [startTimeStr, endTimeStr] = sortedSlots[i].split("-");
      const startTime = moment(`${formattedDate} ${startTimeStr}`, "YYYY-MM-DD HH:mm");
      const endTime = moment(`${formattedDate} ${endTimeStr}`, "YYYY-MM-DD HH:mm");

      // Check if the current slot starts after the current check time
      if (startTime.isAfter(currentCheckTime)) {
        return false;
      }
      currentCheckTime = endTime;
    }

    // After looping through all slots, check if the last slot ends at or after dayEnd time
    return !currentCheckTime.isBefore(dayEnd);
  };

  const handleMonthChange = ({ activeStartDate }: any) => {
    if (
      (activeStartDate.getUTCFullYear() === new Date().getUTCFullYear() &&
        activeStartDate.getMonth() < new Date().getMonth()) ||
      (activeStartDate.getUTCFullYear() !== new Date().getUTCFullYear() &&
        activeStartDate.getUTCFullYear() < new Date().getUTCFullYear())
    )
      return;
    const coachId = selectedCoach ? selectedCoach.id : -1;
    loadOccupiedDates(coachId, moment(activeStartDate).toString());
  };

  return (
    <div className="relative w-full h-full flex flex-col items-center justify-start px-4 py-4 bg-white rounded-lg shadow">
      {isLoading && (
        <div className="z-10 absolute right-0 left-0 top-0 bottom-0 flex justify-center items-center backdrop-blur">
          <LoaderContainer />
        </div>
      )}

      <p className="font-bold">{t("month_view.select_day")}</p>
      <Calendar
        locale={lng}
        className="border-0 lg:mb-4 2xl:mb-8"
        next2Label=""
        prev2Label=""
        calendarType="US"
        formatShortWeekday={(locale, date) =>
          moment(date).locale(locale).format("ddd").charAt(0)
        }
        tileDisabled={({ activeStartDate, date }) => handleDisableDates(date)}
        onChange={handleDateChange}
        onActiveStartDateChange={handleMonthChange}
        defaultValue={
          timeSlot && timeSlot.date
            ? moment(timeSlot.date).toDate()
            :  moment().day() == 5 ? moment().add(3, "day").toDate() // If the current day is friday or saturday ...
                  : moment().day() == 6 ? moment().add(2, "day").toDate() //  ... next available day should be monday
                      : moment().add(1, "day").toDate()
        }
      />
      <p className="font-light mt-1 text-sm 2xl:text-base">
        <img className="inline-flex mr-5" src={clockIcon} alt="" />
        <span>
          {t("month_view.local_time")} {userTimeZone()}
        </span>
      </p>
    </div>
  );
};

export default MonthView;
