import BoxIcon from "../../../../../shared/components/BoxIcon";
import {ReactNode} from "react";

interface Prop {
    img: string;
    title: string;
    text?: string;
    label?: string;
    cta?: ReactNode;
}

const ToolCard = (props: Prop) => {
    return (
        <div className="relative basis-1/2 text-center card-container bg-black text-white p-10 rounded-xl">
            <div className="flex justify-center mb-4">
                <BoxIcon img={props.img} className={"bg-white"}/>
            </div>
            <div>
                <h4 className="flex justify-center mb-3">{props.title}</h4>
                <p className="w-full"></p>
            </div>
            {props.label && <div className="flex justify-center">
                <a href={props.text} target="_blank" rel="noreferrer">
                    <button className="dashboard-button bg-white text-black m-auto mt-5">
                        {props.label} →
                    </button>
                </a>
            </div>}
            <br/>
            {props.cta && <div className="flex justify-center">
                {props.cta}
            </div>}
        </div>
    );
};

export default ToolCard;
