import {FC} from 'react'

import {useTranslation} from "react-i18next";
import {jumpToDeepLink} from "../../../utils/helpers/teams";
import i18n from "../../../utils/lib/i18n";
import ENG from "../locale/eng/booking.json";
import Fr from "../locale/fr/booking.json";


const BookingSuccess: FC = () => {
    i18n.addResourceBundle('en', 'booking', ENG    );
    i18n.addResourceBundle('fr', 'booking', Fr    );
    const {t} = useTranslation('booking')
    return (

            <div className="flex flex-col items-center justify-center w-full h-screen">
                <h1 className="text-4xl font-cursive text-red">{t('success.text_1')}</h1>
                <h1 className="text-4xl mb-14 font-cursive text-red">{t('success.text_2')}</h1>
                {/*<h4 className="text-xl font-cursive text-red">(prévision - illustration)</h4>*/}
                <button onClick={() => jumpToDeepLink("home")}
                        className="px-20 py-2 text-white rounded bg-red flex-end">
                    {t('success.text_3')}
                </button>
            </div>

    )
}

export default BookingSuccess;
