import {ChevronDownIcon, ChevronRightIcon} from '@heroicons/react/outline';
import {dialog} from "@microsoft/teams-js";
import React, {FC, useState} from 'react'
import {useTranslation} from "react-i18next";
import noteImg from '../../../assets/img/teams/icons/note.png'
import IFeedback360 from '../../../models/IFeedback360';
import DiscreteProgressbar from "../../../shared/components/DiscreteProgressbar";

interface Props {
    feedback360: IFeedback360

}

const Feedback360Item: FC<Props> = ({feedback360}) => {
    const {t} = useTranslation('reports')
    const [open, setOpen] = useState(false)

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()

        setOpen(!open)
    }

    return (
        <>
            <div className="flex items-center justify-between px-4 py-4 sm:px-6 cursor-pointer">
                <div onClick={handleClick} className="flex items-center flex-1 min-w-0">
                    {t('feedback_360.text_2')} {feedback360?.name}
                </div>
                <div style={{display: "inline-flex", gap: "30px"}}>

                    <div onClick={handleClick}>
                        {(open) ? <ChevronDownIcon className="w-5 h-5 text-gray-400 " aria-hidden="true"/> :
                            <ChevronRightIcon className="w-5 h-5 text-gray-400" aria-hidden="true"/>}
                    </div>
                </div>
            </div>
            <div className={'px-6 pt-4 pb-6 text-sm font-light ' + ((open) === false ? 'hidden' : '')}>

                <div className="mt-3">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 lg:grid-cols-3">
                        {
                            Object.keys(feedback360).map((key, index) => {
                                if (key != 'name') {
                                    if (feedback360[key].constructor.name == "Array") {
                                        console.log(key, feedback360[key]);
                                        return <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">{key}</dt>
                                            {feedback360[key].map((item: string, i: number) => {
                                                return (
                                                    <dd className="font-bold mt-1 text-sm text-gray-900">{item}</dd>
                                                )
                                            })}
                                        </div>
                                    } else {
                                        return <div className="sm:col-span-1">
                                            <dt className="mb-2 text-sm font-medium text-gray-500">{key}</dt>
                                            <dd className="mt-1 text-sm text-gray-900 font-bold">{feedback360[key]}</dd>
                                        </div>
                                    }
                                }
                            })
                        }
                    </dl>

                </div>
            </div>
        </>
    )
}

export default Feedback360Item
