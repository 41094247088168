import * as Yup from "yup";

// Custom validation method to check for unique emails
export const uniqueEmails = (coachees: any[]) => {
    const emails = coachees?.map(coachee => coachee.email);
    const emailSet = new Set(emails);
    return emailSet.size === emails.length;
};
export const groupUserValidationSchema = Yup.object().shape({
    coachees: Yup.array()
        .of(
            Yup.object().shape({
                email: Yup.string().min(4, 'too short').required('Required'),
                // these constraints take precedence
                first_name: Yup.string().min(3, 'too short').required('Required'), // these constraints take precedence
            })
        ).test('unique', 'Emails must be unique', function (value) {
            return uniqueEmails(value || []);
        })
        .required('Coachees required') // these constraints are shown if and only if inner constraints are satisfied
    // .min(3, 'Minimum of 3 coachees required'),
});

export const groupDetailsValidationSchema = (maxCredit: number | null) => Yup.object({
        name: Yup.string()
            .trim()
            .required("Group Name is required"),
        credits: Yup.lazy((value) => {
            return Yup.number().max(maxCredit || 1000, `Cannot be more than ${maxCredit} credits`).min(1, 'Credits must be more than 1')
                .nullable().typeError("Invalid value")
        }),
        credit_limit_per_user: Yup.lazy((value) => {
            return Yup.number().nullable()
                .typeError("Invalid value")
        }),
        program: Yup.object()
            .shape({
                id: Yup.number().nullable(),
                name: Yup.string().nullable(),
                name_fr: Yup.string().nullable(),
            })
            .nullable()
            .default(null),
        group_expiration: Yup.string()
            .nullable()
            .default(null),
        color_code: Yup.string()
            .trim()
            .nullable(),
    description: Yup.string().nullable()
            .trim(),
        logo: Yup.mixed()
            .nullable()
            .default(null),
    language: Yup.object()
        .shape({
            id: Yup.number().nullable(),
            name: Yup.string().nullable(),
            name_fr: Yup.string().nullable(),
        })
        .nullable()
        .default(null),
    ai_coach_type: Yup.object()
        .shape({
            label: Yup.string().nullable(),
            value: Yup.string().nullable(),
        })
        .nullable()
        .default(null),
    }
)
