import {useBlocker} from "react-router-dom";

interface PromptProps {
    when: boolean;
    message: string;
}

export const NavigationPrompt: React.FC<PromptProps> = ({when, message}) => {
    useBlocker(() => {
        if (when) {
            return !window.confirm(message);
        }
        return false;
    });

    return <div key={when.toString()}/>;
};
