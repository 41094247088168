export enum ERouteType {
  private = "private",
  public = "public",
  restricted = "restricted",
  teamsPrivate = "teamsPrivate",
}

export enum ETheme {
  light = "light",
  dark = "dark",
}

export enum EAuthType {
  Email = "Email",
  GAuth = "GAuth",
  MS = "MS",
  Token = "Token"
}

export enum ERole {
  SuperAdmin = "super_admin",
  Coach = "coach",
  HrAdmin = "hr_admin",
  GroupAdmin = "group_admin",
  ItAdmin = "it_admin",
  Coachee = "coachee",
}

export enum EHomeDropdownAction {
  Reshedule = "Reshedule",
  Cancel = "Cancel",
  CheckReport = "CheckReport",
  LeaveReview = "LeaveReview",
  JoinSession = "JoinSession",
  ScheduleNext = "ScheduleNext",
  Message = "Message",
  CompleteNow = "CompleteNow",
}

export enum EAssessmentType {
  SelfReflection = "self_reflection",
  Reflection360 = "reflection360",
  ImpactReflection = "impact_reflection",
  Review = "review"
}

export enum EConfirmBookingFormField {
  firstName = "firstName",
  lastName = "lastName",
  noteForCoach = "noteForCoach",
}

export enum EAssessmentScoreField {
  career = "career",
  intellect = "intellect",
  organisation = "organisation",
  leadership = "leadership",
  communication = "communication",
  worklife = "worklife",
  assertivity = "assertivity",
  self_esteem = "self_esteem",
}

export enum ESessionSlotSize {
  Sixty = "Sixty",
  Fifteen = "Fifteen",
}

export enum Colors {
  red = "#FD0054",
  blue = "#ACC7E8",
  green = '#B4E1C6',
  pink = '#FECBC4',
  purple = '#BDACE8',
  yellow = '#FFE589',
  yellowLight = '#FFEADD',
  warm = '#F2EBE3',
  warmDark = '#e6dcd1',
}

export enum ContentState {
  mark_as_read = "mark_as_read",
  saved  = "saved",
  irrelevant = "irrelevant",
  removed = "removed",
  has_clicked = "has_clicked",
}

export enum FeedbackStatus {
  pending = "pending",
  received = "received"
}