import { Objective } from "../AICoaching/types";

export const dummyObjectives: Objective[] = [
    {
        id: 1,
        name: "Succeed my career transition",
        name_fr: "Réussir ma transition de carrière",
        key: "career_development",
    },
    {
        id: 2,
        name: "Adapt collective intelligence",
        name_fr: "Adapter l'intelligence collective",
        key: "work_environment",
    },
    {
        id: 3,
        name: "Nail my public speaking",
        name_fr: "Maîtriser l'art de la prise de parole en public",
        key: "job_performance",
    },
];