import Loader from "./Loader/Loader";

const LoaderContainer = () => {
  return (
    <div className="flex items-center h-full w-full justify-center">
      <Loader />
    </div>
  );
};

export default LoaderContainer;
