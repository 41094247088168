import ICoach from "../../../../../../models/ICoach";

export const coachesList: ICoach[] = [
    {
        "id": 124,
        "first_name": "Marie",
        "last_name": "Aragon",
        "email": "coachcoachello@gmail.com",
        "created_time": null,
        "role": null,
        "image_url": "https://i.ibb.co/Dr4pbkj/woman-owner-for-web-orig-1-modified.jpg",
        "video_url": "https://media.videoask.com/transcoded/e170ac4f-c790-4b4a-9edf-b95636c52de7/video.mp4?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJtZWRpYV9pZCI6ImUxNzBhYzRmLWM3OTAtNGI0YS05ZWRmLWI5NTYzNmM1MmRlNyIsImV4cCI6MTYzMTEwODA0NH0.m28BoElWRNdIQRwEkTTZSYsHEIPySMoBqNNVvl8Rd47Z6UWaCgOqiE7NtUPWTZV0tchYufGCnki7hr1FNtFY3ipfVDOi4rDQiuRja3mSOwSiLCAMG-i_enaYyTfUmU-85xREKkwNM9eTjhYR86Fn3TY1CO6Z6295-VqAOXtEbxMdMU48g5SfGSx2fMt-x37U6PILMHagmP2POzov5vsKYAkTS11qaDcymtycFqtu8IK342jBqgP01hdolTlVHi6JOPWBny9aTbm4eAm6GIwh_eQppw1zGFfU1zustl06jD9W5B8eiXZ4mm57KJsSmaxPtAaB_HgcHoW5X-ledocypNOlrA1XcrwWTIzjuLt3JQ7dWZ8wUNhgXQU2Yh756Tm_7oRMs6rZir2cqu8ARXxa9ZIyAMBBR2zemFkUqxZ6hB4755SJGgvBw49R0DgPm4jrsNbeMpz-YfEScN0x0r2M1ShjyGqpxv6YPvR3ZDAXNs-uXh5M0-n-dOjdastOhnAn-3jGP19kVJ2vUf0FU6RsgFAnxZfBL1esULTFPX_B2FlFHfPijapzUCjP1lvEGH2W89XJRUkBg3Kb6MZ-ZI9Cif6N_JgnzovUTyazrrIc4SzhKksx8tWG2XGZbhYZatqa3z-qiFE1wv4Y6i_OTg77BYSZPyKW6Ol2uQ9T-a5xuN4",
        "phone_number": "+33 5651410196",
        "certified_by": "ICF",
        "city": "Bordeaux, France",
        "website": "https://www.linkedin.com/in/ameliepapin",
        "industry": null,
        "years_coaching": "5",
        "years_experience": "10",
        "has_logged_in": null,
        "has_management_experience": true,
        "coach_type": null,
        "meetings": null,
        "pools": [
            {
                "id": 32,
                "name": "Individual"
            },
            {
                "id": 35,
                "name": "Super-Company"
            },
            {
                "id": 43,
                "name": "MonsieurCoquelicot"
            },
            {
                "id": 46,
                "name": "TestCompany"
            },
            {
                "id": 51,
                "name": "DemoCompany"
            },
            {
                "id": 12,
                "name": "Pumpkin"
            },
            {
                "id": 61,
                "name": "MariaSchools"
            },
            {
                "id": 80,
                "name": "Jellysmack"
            },
            {
                "id": 91,
                "name": "Pixaidy"
            },
            {
                "id": 10,
                "name": "Coachello"
            },
            {
                "id": 100,
                "name": "CRFEG-CRFE"
            },
            {
                "id": 101,
                "name": "CRFEG-LFS"
            },
            {
                "id": 102,
                "name": "CRFEG-LUNEX"
            },
            {
                "id": 104,
                "name": "Microslack"
            },
            {
                "id": 108,
                "name": "SlackTest123"
            },
            {
                "id": 116,
                "name": "Accenture"
            }
        ],
        "specializations": [
            {
                "id": 10,
                "specialization": "Motivation"
            },
            {
                "id": 12,
                "specialization": "Leadership"
            },
            {
                "id": 15,
                "specialization": "Resilience"
            },
            {
                "id": 6,
                "specialization": "Effectiveness"
            }
        ],
        "languages": [
            {
                "id": 2,
                "language": "French",
                "language_code": "FR"
            },
            {
                "id": 5,
                "language": "Italian",
                "language_code": "IT"
            }
        ],
        "popular": true,
        "recommended": true,
        "reserved": false,
        "bio": null,
        "testimonials": [],
        "coachee": 1
    },
    {
        "id": 368,
        "first_name": "Christa",
        "last_name": "Lyons",
        "email": "christajlyons@gmail.com",
        "created_time": null,
        "role": null,
        "image_url": "https://i.ibb.co/ZNNrVNB/headshot-C-Lyons-Christa-Lyons-1-1.jpg",
        "video_url": "https://media.videoask.com/transcoded/453a2f26-3555-4e52-a842-c648ef495437/video.mp4?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZWRpYV9pZCI6IjQ1M2EyZjI2LTM1NTUtNGU1Mi1hODQyLWM2NDhlZjQ5NTQzNyIsImV4cCI6MTY3OTk1MDI5MH0.HA4scbF80n7OMQBTdUTPXDmWmGStthh4Lbh-g3Hilb1ZqDYEaN6wQC3jT0-XBt6Ki5iyD6GQJyEyTNUgmtDHxs41vNNpyjOQxBcDKYuDs43UmZz4_hTiE_mxnGmNHWihzd1IxQVl1IQwvYkCzlA4kA-Tt3XhKarDtNfG5Umwq4Yt-5gCOfL9J0DLjk9yhxs4r-GP5tOWu_UQNPpevsHZPxCP_epeBK4T5RltZfbBjXHmEmCH_mEtJkccHUxTh_Pv2eoPYNXTTftSLGNNXaFf2BY7KRiQDO2qpRVNM9KXzrab0bgE35cXgWYp3PsFhVSmZEsYQn9mdI0EDIFVQQ0RY8c3YRSuEC-5VQFBaj4b9V7W1vu8fmPNFTF0dSEwvEDDMLZYNMEzMTkSvrDmZaNrRBm1PGiVlJEnVjNS6cBijvyGTYwaLbvceCiwtTi-0tHG0qHbmlDKh92QdDhTihQoml1uSR32A4WBNkLllLBdtaBS_s6CCxR1BCIY5Mndwh5tO4H3o0kYSfhwLKsaNwbLwaLGcjX1-3oHu9xpG66r6BDrgwDiUef_NZoGCAGhR6sOEQKWeKzXUzcBmiFoc2pbwSYbRnH3u-m1be8VSBXScCIKP02ROREItOanOpD7Okug0q3bm4i2PhlPjjDWSV3QClMZtA-dEnhBR3anI-9D77E",
        "phone_number": "+1 3602042578",
        "certified_by": null,
        "city": "Washington, USA",
        "website": "linkedin.com/in/christa-lyons-pcc",
        "industry": null,
        "years_coaching": "10",
        "years_experience": "10",
        "has_logged_in": null,
        "has_management_experience": true,
        "coach_type": null,

        "meetings": null,
        "pools": [
            {
                "id": 10,
                "name": "Coachello"
            },
            {
                "id": 51,
                "name": "DemoCompany"
            },
            {
                "id": 71,
                "name": "Microsoft-Modern-Work"
            },
            {
                "id": 98,
                "name": "XplorTechnologies"
            },
            {
                "id": 99,
                "name": "Temenos"
            },
            {
                "id": 74,
                "name": "Hyland"
            },
            {
                "id": 116,
                "name": "Accenture"
            }
        ],
        "specializations": [
            {
                "id": 4,
                "specialization": "Confidence"
            },
            {
                "id": 12,
                "specialization": "Leadership"
            },
            {
                "id": 22,
                "specialization": "Career Development"
            },
            {
                "id": 27,
                "specialization": "Skills and Productivity"
            }
        ],
        "languages": [
            {
                "id": 1,
                "language": "English",
                "language_code": "EN"
            }
        ],
        "popular": false,
        "recommended": false,
        "reserved": false,
        "bio": "I am a PCC certified professional coach with 20+ years of experience working in HR. I earned my BA in Psychology and my Professional Coaching Certification and have been rewarded with the opportunity to partner with many leaders on their personal career journeys. Much of my own career has been spent working with clients in Tech, Financial Services, Retail and Start-ups. Working for many years with leaders across multiple levels has provided me with valuable insight into the challenges that workers today face as well as solutions that helped them move forward.\n\nI create the space that clients need to define their goals and build the roadmap to accomplish them. I ask powerful questions that enable clients to explore ideas and options that move them towards achieving their goals.",
        "testimonials": [],
        "coachee": 0
    },
    {
        "id": 479,
        "first_name": "Flavian",
        "last_name": "Hautbois",
        "email": "test.lasitha@gmail.com",
        "created_time": null,
        "role": null,
        "image_url": "https://i.ibb.co/pQKYvZv/flavian-modified.jpg",
        "video_url": "https://media.videoask.com/transcoded/3dd612e6-61e5-47fe-812e-5a086ea0b989/video.mp4?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZWRpYV9pZCI6IjNkZDYxMmU2LTYxZTUtNDdmZS04MTJlLTVhMDg2ZWEwYjk4OSIsImV4cCI6MTcwNzczNDEzOH0.MUHO1kCzVpCWoHaTQUjXLNZPhARF8FaRm2OV4IeaI6zeY2BKwoVaL-krgzCG5gEq5ylDPTimU5xLFAIXQKVkRvkXziebYIXXHDZ6BQskfsjGkZ8RkEggc63oZKHPD8f0smRpFi1zi61RyU0Qq9jOp2x5A6JKe6GQWWVerIXWQM5HKL4BJ_KzB8c0VdGi2irAdJfwNLKZ8S_9wbcWvFiUJiNJ6y2e_XalnSv9zWvq7cQwjXFEf2PBQ1rBmTwLa2VoTb2rk4ROq0QzBc4P7zsjuwWi1ghDnVZoZSRMo2KIQKg75wkDgZ2WUs15xcGHn5hybj_O4rRDuV54IlBUpo6q6K_gpF77emvH--eobnGdvC6jxvoyHogAq3FVHnRHsxP69pJw9Q5R3PSjv9g9CWCdLeQWldqZsHhgXg6Mpc6MN7O_81QlTe54bHTQ-df7_UKEXEGqS_HXC2WFqZ2l25z9ycy6wFHUEvkTV8WQqb7Z2Vsp2i3O-bmd3EixvtHeOAC9ns5kCAjWAsejolLhwO2906Cjmg5CA17MuAROh6bb44_Gcem38hvroQ2bctBb6LJ1gFxcdbxGBkjDRApaTwIDSI8kzVWtySdn3JR_B7txtsa2S3L5ALG_qOdaQ26XhBWZ1kbgQ2x-kdf8irhS0z20H8KsCKWwaW4tvaLhSBf7zPo",
        "phone_number": "+33 ",
        "certified_by": null,
        "city": "Paris, France",
        "website": "https://www.linkedin.com/in/flavianhautbois/overlay/about-this-profile/",
        "industry": null,
        "years_coaching": null,
        "years_experience": null,
        "has_logged_in": null,
        "has_management_experience": true,
        "coach_type": null,

        "meetings": null,
        "pools": [
            {
                "id": 116,
                "name": "Accenture"
            }
        ],
        "specializations": [
            {
                "id": 32,
                "specialization": "AI Product Development"
            },
            {
                "id": 33,
                "specialization": "Lean Innovation"
            },
            {
                "id": 35,
                "specialization": "Product Development      "
            }
        ],
        "languages": [
            {
                "id": 1,
                "language": "English",
                "language_code": "EN"
            }
        ],
        "popular": false,
        "recommended": true,
        "reserved": true,
        "bio": null,
        "testimonials": [],
        "coachee": 3,
        "isMentor": true
    },

    {
        "id": 485,
        "first_name": "Peter",
        "last_name": "Kappus",
        "email": "peterk+coachello@peterkappus.com",
        "created_time": null,
        "role": null,
        "image_url": "https://i.ibb.co/VL7N6ZW/DALL-E-2024-02-05-11-32-53-a-man-in-a-suit-in-front-of-a-smooth-background-modified.png",
        "video_url": "https://media.videoask.com/transcoded/f41ba0fe-7fe8-4798-817c-1d458ffbad5b/video.mp4?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZWRpYV9pZCI6ImY0MWJhMGZlLTdmZTgtNDc5OC04MTdjLTFkNDU4ZmZiYWQ1YiIsImV4cCI6MTcwNzQ4MDEyNX0.JWkZG385nGcaibqN9J4lVQIXmcuK9g9s9xulCeBkxUaEuqOee_9RhwCHM0Suy8DtCrVSqW0Gg3aKJIl533OKcUQSAixUy7telAKVwT7JsS-7Ixe_u7xV9ue_OCXkVj5mJ4q2CUS5pX1RBmBQgtdvpZdFRRczgwu-oK3D_QQXyAsrMUHF0w8GdwZZeKaw6BGRcFokvz2j-QwcPd32PJ2vH8ihe5gJroCCQij3BMtG2k1EYMLjKaOxb5Yi6Lmc4J7YHp7Rc08MaQoB3t7Q0mLPebJkHRVuMXaj8IJd6Albkl47hr_DOIVPw_O4lSnRJx3KdthgLDgmJXnepwC_84p83BI1SF_6NUO9-MmnIw40wnJpxzCu1DFFwHP1cKHhj0_4x0CjZjR8zfD35pAMYOMfdgD6toNuGl_rQzfP5qHK-WuFLxkRxyd6R_ahVENdd44AoTNhwnyni_2pWzhdcSH-XFPhZ8Tn1--cjyXZ8Esi6y4RIru7z3y1eGXsAL_sXIKlgpne2s0vzLP1Q4T4JT25fD1zs7O0AafOjpeHbdwwA2a4QWERimausqZYLcbehSVO6190wb84Ra-3eyTVW2FKWiASL1-rysL7XFhJhEGqiEOjx1mTERPUohEvkNGTa7Qaf_yTTOw_CYPHpq5VIKnNR0uNYvm6v2izgWzi0zuxDlI",
        "phone_number": "+33 ",
        "certified_by": null,
        "city": "London, UK",
        "website": "linkedin.com/in/peterkappus",
        "industry": null,
        "years_coaching": null,
        "years_experience": null,
        "has_logged_in": null,
        "has_management_experience": true,
        "coach_type": null,

        "meetings": null,
        "pools": [
            {
                "id": 10,
                "name": "Coachello"
            },
            {
                "id": 116,
                "name": "Accenture"
            }
        ],
        "specializations": [
            {
                "id": 32,
                "specialization": "AI Product Development"
            },
            {
                "id": 7,
                "specialization": "Emotional Intelligence"
            },
            {
                "id": 13,
                "specialization": "Organisational Change"
            },
            {
                "id": 18,
                "specialization": "Team Building"
            }
        ],
        "languages": [
            {
                "id": 1,
                "language": "English",
                "language_code": "EN"
            }
        ],
        "popular": false,
        "recommended": false,
        "reserved": false,
        "bio": null,
        "testimonials": [],
        "coachee": 0,
        "isMentor": true
    },

    {
        "id": 361,
        "first_name": "Meghann",
        "last_name": "McNiff",
        "email": "meghann@seattlecoachingcollective.com",
        "created_time": null,
        "role": null,
        "image_url": "https://i.ibb.co/q5JvJ1S/Meghann-Certified-Coach-Meghann-Mc-Niff-1-1.jpg",
        "video_url": "https://media.videoask.com/transcoded/c0c6fc66-5d4d-480f-ab00-218679c38d44/video.mp4?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZWRpYV9pZCI6ImMwYzZmYzY2LTVkNGQtNDgwZi1hYjAwLTIxODY3OWMzOGQ0NCIsImV4cCI6MTY3OTk2MzA4OH0.WuHhk9JA44Le0X0V5eN4UGwz1DPd7VKrCxkJOHr9c_p7Z8a3POfzbiRTkEHEnd1XxyfK7NFqlMqiMuQ5mX-pDLSIS2ZOlkkNOjsRp6IEcc8yupM7P17M6kikDbzcNdUezTq2JY-r-E81E8ZlpBwEQHw2WY2Jkzb-5S9_B-h5ajr7SFptk2NkUO46F1gTYSwvGGdmgCwBJXsHn5jvJYjgwQxsugCcpYsHZSIpnoBgXyqrFT32MxZDucWgziixfbtCGyQoMyhJgV0xy9xur6a8xTh4PsX38Wl7HGkCgWL3x0qzFqy7zzwZUVF5oKR6LRGbqv4t1F_5HJYFRsERH0Xqx6us2IWPAE-6rpx6tk6si-mNnEAcZCXWJYp7AJEret2ALqRZxq4noDdFo8vTjjIZovs_cLBimpHLc_RNqi6FlPF3UM6x8wWA6groKcSIV4-prgPSP9W72X5n3xfA2yyOhq4cZ_mtV7con8SnUP_Bc-Io41I-scY8gBJe-HPMCdFkKrMyGmRhA6q62WAo5Vb4ZKBpQnRD0sEqgg2B-EGTcU52fCIuTRr_gZ2cnKAg3zlZm0k5IsJ9QReDtYSvn1s1y5m5UQwCGQ5W8IIjOIDYdS_yXxEMuqXa7scJ34n2r6biZAPwpW2RQnDnBQMcFrmQT3wa-jLjZwE8cbktdkeqB8A",
        "phone_number": "+1 6175011357",
        "certified_by": null,
        "city": "Washington, USA",
        "website": "https://www.linkedin.com/in/meghann-mcniff-pcc-mph-ma-506a15",
        "industry": null,
        "years_coaching": "10",
        "years_experience": "10",
        "has_logged_in": null,
        "has_management_experience": true,
        "coach_type": null,

        "meetings": null,
        "pools": [
            {
                "id": 10,
                "name": "Coachello"
            },
            {
                "id": 51,
                "name": "DemoCompany"
            },
            {
                "id": 71,
                "name": "Microsoft-Modern-Work"
            },
            {
                "id": 98,
                "name": "XplorTechnologies"
            },
            {
                "id": 99,
                "name": "Temenos"
            },
            {
                "id": 46,
                "name": "TestCompany"
            },
            {
                "id": 74,
                "name": "Hyland"
            },
            {
                "id": 116,
                "name": "Accenture"
            }
        ],
        "specializations": [
            {
                "id": 2,
                "specialization": "Burn-out Prevention"
            },
            {
                "id": 10,
                "specialization": "Motivation"
            },
            {
                "id": 12,
                "specialization": "Leadership"
            },
            {
                "id": 4,
                "specialization": "Confidence"
            }
        ],
        "languages": [
            {
                "id": 1,
                "language": "English",
                "language_code": "EN"
            }
        ],
        "popular": false,
        "recommended": false,
        "reserved": false,
        "bio": "I'm an ICF Certified Coach with 20+ years of experience who specializes in sustainable behavior change, burnout resilience, and peak performance. \n\nI bring over six years coaching in private practice, 10 years as a management consultant in Global Health, and five years as an Officer in the US Air Force—as well as a life-long commitment to my own personal growth and development, and an embodied dedication to equity and inclusion. I am a graduate of the Air Force Academy with a Master of Arts in Critical and Creative Thinking, and a Master of Public Health in International, Maternal and Child Health. \n\nI discovered coaching when recovering from burnout after eight years working to prevent maternal mortality in Afghanistan. For most of us, feeling fully alive and meaningfully contributing to the world around us, as our authentic selves — is not nearly as easy as it sounds. I continue to coach because I believe we are here to express ourselves. ",
        "testimonials": [],
        "coachee": 0
    },
    {
        "id": 68,
        "first_name": "Amélie",
        "last_name": "Papin",
        "email": "coach@ameliepapin.com",
        "created_time": null,
        "role": null,
        "image_url": "https://i.ibb.co/phq55St/01-Amelie-Papin-Portrait-Photoshoot-Octobre-2019-compressed-Ame-lie-Papin-NEWUX.jpg",
        "video_url": "https://media.videoask.com/transcoded/e170ac4f-c790-4b4a-9edf-b95636c52de7/video.mp4?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJtZWRpYV9pZCI6ImUxNzBhYzRmLWM3OTAtNGI0YS05ZWRmLWI5NTYzNmM1MmRlNyIsImV4cCI6MTYzMTEwODA0NH0.m28BoElWRNdIQRwEkTTZSYsHEIPySMoBqNNVvl8Rd47Z6UWaCgOqiE7NtUPWTZV0tchYufGCnki7hr1FNtFY3ipfVDOi4rDQiuRja3mSOwSiLCAMG-i_enaYyTfUmU-85xREKkwNM9eTjhYR86Fn3TY1CO6Z6295-VqAOXtEbxMdMU48g5SfGSx2fMt-x37U6PILMHagmP2POzov5vsKYAkTS11qaDcymtycFqtu8IK342jBqgP01hdolTlVHi6JOPWBny9aTbm4eAm6GIwh_eQppw1zGFfU1zustl06jD9W5B8eiXZ4mm57KJsSmaxPtAaB_HgcHoW5X-ledocypNOlrA1XcrwWTIzjuLt3JQ7dWZ8wUNhgXQU2Yh756Tm_7oRMs6rZir2cqu8ARXxa9ZIyAMBBR2zemFkUqxZ6hB4755SJGgvBw49R0DgPm4jrsNbeMpz-YfEScN0x0r2M1ShjyGqpxv6YPvR3ZDAXNs-uXh5M0-n-dOjdastOhnAn-3jGP19kVJ2vUf0FU6RsgFAnxZfBL1esULTFPX_B2FlFHfPijapzUCjP1lvEGH2W89XJRUkBg3Kb6MZ-ZI9Cif6N_JgnzovUTyazrrIc4SzhKksx8tWG2XGZbhYZatqa3z-qiFE1wv4Y6i_OTg77BYSZPyKW6Ol2uQ9T-a5xuN4",
        "phone_number": "+33 0667004419",
        "certified_by": "ICF",
        "city": "Bordeaux",
        "website": "https://www.linkedin.com/in/ameliepapin/",
        "industry": null,
        "years_coaching": "4",
        "years_experience": "12",
        "has_logged_in": null,
        "has_management_experience": true,
        "coach_type": null,

        "meetings": null,
        "pools": [
            {
                "id": 15,
                "name": "Gorgias"
            },
            {
                "id": 16,
                "name": "Ubisoft"
            },
            {
                "id": 17,
                "name": "Lefty"
            },
            {
                "id": 18,
                "name": "Germinal"
            },
            {
                "id": 10,
                "name": "Coachello"
            },
            {
                "id": 19,
                "name": "Amplitude"
            },
            {
                "id": 23,
                "name": "Disney"
            },
            {
                "id": 24,
                "name": "ETAM"
            },
            {
                "id": 27,
                "name": "Acheel"
            },
            {
                "id": 26,
                "name": "orpi-garibaldi"
            },
            {
                "id": 32,
                "name": "Individual"
            },
            {
                "id": 36,
                "name": "Papernest"
            },
            {
                "id": 40,
                "name": "Morning"
            },
            {
                "id": 42,
                "name": "SwipeGuide"
            },
            {
                "id": 45,
                "name": "Kepler"
            },
            {
                "id": 39,
                "name": "IWD"
            },
            {
                "id": 53,
                "name": "AccurX"
            },
            {
                "id": 12,
                "name": "Pumpkin"
            },
            {
                "id": 56,
                "name": "Excelya"
            },
            {
                "id": 59,
                "name": "Fundimmo"
            },
            {
                "id": 65,
                "name": "Famoco1"
            },
            {
                "id": 69,
                "name": "Blablacar"
            },
            {
                "id": 71,
                "name": "Microsoft-Modern-Work"
            },
            {
                "id": 84,
                "name": "Alma"
            },
            {
                "id": 85,
                "name": "Ceran"
            },
            {
                "id": 89,
                "name": "Kaizen"
            },
            {
                "id": 107,
                "name": "Weglot"
            },
            {
                "id": 113,
                "name": "CEA"
            },
            {
                "id": 114,
                "name": "Garance"
            },
            {
                "id": 116,
                "name": "Accenture"
            }
        ],
        "specializations": [
            {
                "id": 11,
                "specialization": "Multicultural Environment"
            },
            {
                "id": 17,
                "specialization": "Stress Management"
            },
            {
                "id": 3,
                "specialization": "Career Transition"
            },
            {
                "id": 12,
                "specialization": "Leadership"
            }
        ],
        "languages": [
            {
                "id": 1,
                "language": "English",
                "language_code": "EN"
            },
            {
                "id": 2,
                "language": "French",
                "language_code": "FR"
            }
        ],
        "popular": false,
        "recommended": false,
        "reserved": false,
        "bio": null,
        "testimonials": [],
        "coachee": 0
    },
    {
        "id": 486,
        "first_name": "Luke",
        "last_name": "Salvoni",
        "email": "luke.salvoni@gmail.com",
        "created_time": null,
        "role": null,
        "image_url": "https://i.ibb.co/Pg8BcJ1/DALL-E-2024-02-05-11-25-34-background-modified.png",
        "video_url": "https://media.videoask.com/transcoded/3780c7a5-1f18-4e2e-903f-ebecd9eaf5a3/video.mp4?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZWRpYV9pZCI6IjM3ODBjN2E1LTFmMTgtNGUyZS05MDNmLWViZWNkOWVhZjVhMyIsImV4cCI6MTcwNzY1NzI2Nn0.aYvsJTIi27jmWeCTlovFZ3IKxP3iON3UCUCtkZOzaPfxJDJ84GwIpoJJxQwJqDHkD14ZZ9bULCntyRkl_gFUyqC4byrMEyHY-Hg5OSSPc9OwXk1mFQGyqSrfjYB59zFyDsOcyaZyYwk5ugsGS4vKHPgPp8M6SWIkE7qPUnghFUK8N5t7x3LqCyHg2M4cu60DYTOZ0I-1ysUna33hEpzWENa553t0-E4G_q_dseOmTFZVGZL3Po6K-R4aIYWox-yjWoIxTJ5SEIi9DVTOUWtq22lwby9BYm7tV7WUusTz-u9CZFtuM8UePFcfCVVE1tv2lgWW3cZWMrVsNNR7yqZaQsmqphH6yAZgR0afyTvkQxJVMtW_IRJpw0_sJtXvM7SxlSOjdy-KsjuHBF76WEP69pemt5t5JbK6z8-1eRwYoloJgpb55rmwNcFUj0vEfYSFr2i8u0XM8R9gy7Kq0HDuW1gk1f5k1zsk3ihzoCnmM1D9TcZIkFQAGGA0TwxEm_leoJTxbDfNLI3fiTwfZuH-v4uuTJq7tVqUNyTCTVqFy0f309dCBRhvJMnSvMypDNzG555hdhg5w3nScyzsHPXBhez-m125wfg-tUNYiRCEQNFQP-AcsGSjosVZTP0cFOckDwo3mQrny8zO3w5FqTFvrc2p2PRIOdCdkA2IEHQa078",
        "phone_number": "+33 ",
        "certified_by": null,
        "city": "London, UK",
        "website": "linkedin.com/in/lukesalvoni",
        "industry": null,
        "years_coaching": null,
        "years_experience": null,
        "has_logged_in": null,
        "has_management_experience": true,
        "coach_type": null,

        "meetings": null,
        "pools": [
            {
                "id": 10,
                "name": "Coachello"
            },
            {
                "id": 116,
                "name": "Accenture"
            }
        ],
        "specializations": [
            {
                "id": 45,
                "specialization": "Framework Selection"
            },
            {
                "id": 39,
                "specialization": "Scalability"
            },
            {
                "id": 43,
                "specialization": "Mobile Applications"
            },
            {
                "id": 46,
                "specialization": "HealthTech"
            }
        ],
        "languages": [
            {
                "id": 1,
                "language": "English",
                "language_code": "EN"
            }
        ],
        "popular": false,
        "recommended": false,
        "reserved": false,
        "bio": null,
        "testimonials": [],
        "coachee": 0,
        "isMentor": true
    },

    {
        "id": 480,
        "first_name": "Dan ",
        "last_name": "Magier",
        "email": "sltclasith@gmail.com",
        "created_time": null,
        "role": null,
        "image_url": "https://i.ibb.co/WDnsDgz/dan2-modified.jpg",
        "video_url": "https://media.videoask.com/transcoded/453a2f26-3555-4e52-a842-c648ef495437/video.mp4?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZWRpYV9pZCI6IjQ1M2EyZjI2LTM1NTUtNGU1Mi1hODQyLWM2NDhlZjQ5NTQzNyIsImV4cCI6MTY3OTk1MDI5MH0.HA4scbF80n7OMQBTdUTPXDmWmGStthh4Lbh-g3Hilb1ZqDYEaN6wQC3jT0-XBt6Ki5iyD6GQJyEyTNUgmtDHxs41vNNpyjOQxBcDKYuDs43UmZz4_hTiE_mxnGmNHWihzd1IxQVl1IQwvYkCzlA4kA-Tt3XhKarDtNfG5Umwq4Yt-5gCOfL9J0DLjk9yhxs4r-GP5tOWu_UQNPpevsHZPxCP_epeBK4T5RltZfbBjXHmEmCH_mEtJkccHUxTh_Pv2eoPYNXTTftSLGNNXaFf2BY7KRiQDO2qpRVNM9KXzrab0bgE35cXgWYp3PsFhVSmZEsYQn9mdI0EDIFVQQ0RY8c3YRSuEC-5VQFBaj4b9V7W1vu8fmPNFTF0dSEwvEDDMLZYNMEzMTkSvrDmZaNrRBm1PGiVlJEnVjNS6cBijvyGTYwaLbvceCiwtTi-0tHG0qHbmlDKh92QdDhTihQoml1uSR32A4WBNkLllLBdtaBS_s6CCxR1BCIY5Mndwh5tO4H3o0kYSfhwLKsaNwbLwaLGcjX1-3oHu9xpG66r6BDrgwDiUef_NZoGCAGhR6sOEQKWeKzXUzcBmiFoc2pbwSYbRnH3u-m1be8VSBXScCIKP02ROREItOanOpD7Okug0q3bm4i2PhlPjjDWSV3QClMZtA-dEnhBR3anI-9D77E",
        "phone_number": "+33 ",
        "certified_by": null,
        "city": "Berlin, Germany",
        "website": "https://www.linkedin.com/in/dan-magier-67a9374/",
        "industry": null,
        "years_coaching": null,
        "years_experience": null,
        "has_logged_in": null,
        "has_management_experience": true,
        "coach_type": null,

        "meetings": null,
        "pools": [
            {
                "id": 116,
                "name": "Accenture"
            }
        ],
        "specializations": [
            {
                "id": 36,
                "specialization": "Dev-Ops"
            },
            {
                "id": 37,
                "specialization": "Test Driven Development"
            },
            {
                "id": 38,
                "specialization": "Continuous Integration & Delivery"
            },
            {
                "id": 39,
                "specialization": "Scalability"
            }
        ],
        "languages": [
            {
                "id": 1,
                "language": "English",
                "language_code": "EN"
            },
            {
                "id": 2,
                "language": "French",
                "language_code": "FR"
            }
        ],
        "popular": false,
        "recommended": false,
        "reserved": false,
        "bio": null,
        "testimonials": [],
        "coachee": 0,
        "isMentor": true
    },

]
