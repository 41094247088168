import {FC, useEffect, useRef, useState} from "react";

import {useTranslation} from "react-i18next";

import AssessmentResultsSection from "./sections/AssessmentResultsSection";
import FrameModal from "../../../../shared/components/FrameModal";
import LoaderContainer from "../../../../shared/components/LoaderContainer";
import CoachingReportsSection from "./sections/CoachingReportsSection";
import Feedback360Section from "./sections/Feedback360Section";
import {getAssessmentScore} from "../../../../services/api/teams/AssessmentActions";
import {get360FeedbackPendingRequests, get360FeedbackReports} from "../../../../services/api/teams/Feedback360Actions";

import {getClientReports} from "../../../../services/api/teams/ReportActions";
import storage from "../../../../services/storage/localStorage";
import i18n from "../../../../utils/lib/i18n";
import ENG from "../../locale/eng/reports.json";
import FR from "../../locale/fr/reports.json";
import {useNavTitle} from "@hooks/useNavTitle";
import {useConfig} from "../../../../utils/providers/AppConfigProvider";

import {EAssessmentType} from "../../../../constants/enums";
import IAssessmentScore from "../../../../models/IAssessmentScore";
import {I360FeedbackReport, IClientReport} from "../../../../models/IReport";
import useAuth from "@hooks/useAuth";
import {AICoachType} from "../AICoaching/enum";
import {ISentFeedbackRequestData} from "../../../../constants/types";

interface ReportProps {
    navTitle: String;
}

const Reports: FC<ReportProps> = (props) => {
    i18n.addResourceBundle("en", "reports", ENG);
    i18n.addResourceBundle("fr", "reports", FR);

    const {t} = useTranslation();
    useNavTitle(t(`layout:sidebar.${props.navTitle}`));
    const {config} = useConfig();
    const {user} = useAuth();
    const frameModalRef = useRef<React.ElementRef<typeof FrameModal>>(null);
    const [isLoading, setIsLoading] = useState(true);

    const [assessmentScores, setAssessmentScores] = useState<IAssessmentScore>();
    const [coachingReports, setCoachingReports] = useState<IClientReport[]>();
    const [feedback360, setFeedback360] = useState<I360FeedbackReport[]>();
    const [feedbackPending, setFeedbackPending] = useState<ISentFeedbackRequestData[]>([]);

    useEffect(() => {
        init();
    }, []);
    const standaloneAiCoach =
        user?.aiCoachType &&
        (user.aiCoachType === AICoachType.standalone ||
            user.aiCoachType === AICoachType.both);

    const init = async () => {
        setIsLoading(true);

        const user = storage.get("user");
        const clientId = user.id;

        const as = await getAssessmentScore(clientId);
        setAssessmentScores(as);

        const cr = await getClientReports(clientId);
        cr.sort(
            (a: any, b: any) =>
                Date.parse(b.meeting_date) - Date.parse(a.meeting_date),
        );
        setCoachingReports(cr.sort((a: any, b: any) => b.date - a.date));

        const fb360 = await get360FeedbackReports(clientId);
        fb360.sort((a: any, b: any) => Date.parse(b.date) - Date.parse(a.date));
        setFeedback360(fb360);

        const fb360PendingRequests = await get360FeedbackPendingRequests(clientId);
        setFeedbackPending(fb360PendingRequests);

        setIsLoading(false);
    };

    const openModal = (key: EAssessmentType, title: string) => {
        frameModalRef.current?.open(key, title);
    };

    return (
        <>
            {isLoading && <LoaderContainer/>}

            <div className="bg-linen_gradient flex flex-col gap-4 py-6 px-6 h-full">
                {config?.self_reflection && assessmentScores && (
                    <AssessmentResultsSection
                        data={assessmentScores}
                        openModal={openModal}
                    />
                )}
                {!standaloneAiCoach && coachingReports && <CoachingReportsSection data={coachingReports}/>}
                {config?.flash_reflection && feedback360 && (
                    <Feedback360Section data={feedback360} openModal={openModal} sentRequests={feedbackPending}/>
                )}
                <FrameModal ref={frameModalRef}/>
            </div>
        </>
    );
};

export default Reports;
