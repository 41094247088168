import React, {useEffect, useState} from 'react';
import Input, {InputProps} from "./Input";
import InputWithIcon from "./InputWithIcon";
interface InputPassword extends InputProps {
    setValid?:(b:boolean)=>void
}
const InputPassword = (props:InputPassword ) => {
    const [showPassword, setShowPassword] = useState(false)
    const [passwordError, setPasswordError] = useState<string>('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const password = e.target.value;
        if (props.onChange) {
            props.onChange(e); // Call the custom onChange function from the parent component
        }
        // Password validation
        if (password.length < 8) {
            setPasswordError('Password must be at least 8 characters long.');
            if (props?.setValid) {
                props?.setValid(false)
            }
        } else if (!password.match(/[a-z]/) || !password.match(/[A-Z]/) || !password.match(/[0-9]/) ) {   //|| !password.match(/[^a-zA-Z0-9]/)    , and one special character
            setPasswordError('Password must contain at least one uppercase letter, one lowercase letter and one number.');
            if (props?.setValid) {
                props?.setValid(false)
            }
        } else {
            setPasswordError('');
            if (props?.setValid) {
                props?.setValid(true)
            }
        }
    };

// useEffect(()=>{
//     if (passwordError=='' && password){
//         is
//     }
// },[passwordError])
    return (
        <InputWithIcon {...props}
                       error={passwordError}
                       onChange={handleInputChange}
                       type={showPassword ? 'text' : 'password'}
                       right={true}
                       icon={<svg onClick={() => setShowPassword(prevState => !prevState)}
                                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                  className=" hover:opacity-50 cursor-pointer">
                           <path d="M12 15a3 3 0 100-6 3 3 0 000 6z"/>
                           <path fillRule="evenodd"
                                 d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                                 clipRule="evenodd"/>
                       </svg>}/>


    );
};

export default InputPassword;
