export enum ELanguageFr {
    'English'= 'Anglais' ,
    'French'=  'Français' ,
    'German'= 'Allemand' ,
    'Spanish'= 'Espagnol' ,
    'Italian'=  'Italien' ,
    'Dutch'=  'Néerlandais' ,
    'Mandarin'=  'Mandarin' ,
    'Serbian'= 'Serbe' ,
    'Polish'= 'Polonais' ,
    'Portuguese'= 'Portugais' ,
    'Romanian'= 'Roumain' ,
    'Czech'= 'Tchèque' ,
    'Turkish'=  'Turc' ,
    'Albanian'=  'Albanais' ,
    'Greek'= 'Grec' ,
    'Estonian'=  'Estonien' ,
    'Cantonese'=  'Cantonais' ,
    'Japanese'=  'Japonais' ,
    'Danish'=  'Danois' ,
    'Swedish'= 'Suédois' ,
    'Filipino'=  'Philippin' ,
    'Norwegian'= 'Norvégien' ,
    'Finnish'=  'Finnois' ,
    'Indonesian'=  'Indonésien' ,
    'Hindi'= 'Hindi' ,
    'Lithuanian'=  'Lituanien' ,
    'Russian'=  'Russe' ,
    'Slovak'= 'Slovaque' ,
    'Slovene'=  'Slovène' ,
    'Croatia'= 'Croate' ,
    'Hungarian'= 'Hongrois' ,
    'Bulgarian'=  'Bulgare' ,
    'Ukrainian'=  'Ukrainien' ,
    'Tamil'=  'Tamoul' ,
    'Telugu'=  'Télougou' ,
    'Marathi'= 'Marathi' ,
    'Hebrew'= 'Hébreu'
}