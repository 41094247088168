import { twMerge } from "tailwind-merge";

import CoachCard from "../CoachCard";

import ICoach from "../../../../../../models/ICoach";
import OrientationCoachCard from "../OrientationCoachCard";

type CoachesWrapperProps = {
  title: string;
  coaches: ICoach[];
  handleSelectCoach: (coach: ICoach) => void;
  selectedCoach: ICoach | null;
  playCoachVideo: (url: string, title: string) => void;
  titleColor?: string;
  wrapperColor?: string;
};

const CoachesWrapper = ({
  title,
  coaches,
  handleSelectCoach,
  selectedCoach,
  playCoachVideo,
  titleColor,
  wrapperColor,
}: CoachesWrapperProps) => {
  return (
    <div
      className={twMerge(
        "flex flex-col px-4 pt-3 pb-4 rounded-md h-max bg-[#F4D4D4]",
        wrapperColor ? wrapperColor : "",
        `col-span-${coaches.length}`,
      )}
    >
      <p
        className={twMerge(
          "font-bold text-center mb-3 text-red text-sm h-10",
          titleColor ? titleColor : "",
        )}
      >
        {title}
      </p>
      <ul
        className={twMerge(
          "h-full grid w-full gap-x-4 gap-y-8 relative",
          `grid-cols-${coaches.length}`,
        )}
      >
        {coaches.map((coach) =>
          coach.orientation ? (
            <OrientationCoachCard
              key={coach.id}
              coach={coach}
              onSelect={() => handleSelectCoach(coach)}
              isSelected={selectedCoach?.id === coach.id}
              playCoachVideo={playCoachVideo}
            />
          ) : (
            <CoachCard
              key={coach.id}
              coach={coach}
              onSelect={() => handleSelectCoach(coach)}
              isSelected={selectedCoach?.id === coach.id}
              playCoachVideo={playCoachVideo}
            />
          ),
        )}
      </ul>
    </div>
  );
};

export default CoachesWrapper;
