import {Component, ComponentType, FunctionComponent, ReactElement} from "react";
import useAuth from "@hooks/useAuth";

export const withSwitchByCompany = (WrappedComponent: FunctionComponent<any>,
                                WrappedComponentV2: FunctionComponent<any>,
                                companyName: string) => {

    const ComponentSelector: ComponentType<unknown> = (props: any) => {
        const {user} = useAuth()

        const ComponentToRender = companyName === user?.companyName ? WrappedComponentV2 : WrappedComponent;
        return <ComponentToRender {...props} />;
    };

    return ComponentSelector;
};
