import React from 'react';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './CircularChart.css'

interface Props {
    percentage: number;
}

const CircularChart = ({percentage}: Props) => {

    return (
        <CircularProgressbar
            value={percentage}
            text={`${percentage}%`}
            strokeWidth={12}
            styles={buildStyles({
                // Rotation of path and trail, in number of turns (0-1)
                rotation: 0.25,

                // Whether  to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'round',

                // Text size
                textSize: '16px',

                // How long animation takes to go from one percentage to another, in seconds
                pathTransitionDuration: 1,

                // Can specify path transition in more detail, or remove it entirely
                // pathTransition: 'none',

                // Colors
                pathColor: `rgba(180, 225, 198, 1)`,
                textColor: '#070707',
                trailColor:  'rgba(180, 225, 198, 0.23)',
                backgroundColor: '#FD0054',
            })}
        />


    );
};

export default CircularChart;