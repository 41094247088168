import moment from "moment/moment";

import {
  BriefcaseIcon,
  CalendarIcon,
  ClockIcon,
} from "@heroicons/react/outline";
import defaultProfileImg from "@img/icons/default-profile.png";

interface Props {
  index: number;
  datetime: Date;
  profile: any;
  name: string;
  companyName: string;
  selected: boolean;
  setSelected: (index: number) => void;
}

const ReportSessionItem = (props: Props) => {
  return (
    <div
      onClick={() => props.setSelected(props.index)}
      className={`rounded-md bg-white p-2 border-2 border-transparent 
        ${
          props.selected
            ? "border-red after:top-4 after:border-2 triangle-nouch"
            : "hover:border-gray-400 hover:after:top-4 hover:after:border-2 hover:triangle-nouch"
        }`}
    >
      <div className="flex gap-3 justify-between">
        <p className="icon-text">
          <CalendarIcon className="text-red" />
          {moment(props.datetime).format("ll")}{" "}
        </p>
        <p className="icon-text">
          <ClockIcon className="text-red" />
          {moment(props.datetime).format("HH:mm")}{" "}
        </p>
      </div>
      <hr className="-mx-2 border-[0.2px]" />
      <div className="flex gap-3 items-center">
        <img
          src={
            props.profile
              ? props.profile
              : defaultProfileImg
          }
          className="rounded-full w-14 h-14"
          alt=""
        />
        <div className="">
          <h4>{props.name}</h4>
          {/*<ul className="flex mt-2">*/}
          {/*    <li className="flex gap-2 rounded bg-red-50 p-1.5 text-sm ">*/}
          {/*        <AcademicCapIcon className="w-4"/>*/}
          {/*        Leadership*/}
          {/*    </li>*/}
          {/*</ul>*/}
        </div>
      </div>
      <hr className="-mx-2 border-[0.2px]" />
      <p className="icon-text">
        <BriefcaseIcon className="text-gray-400 stroke-1" />
        {props.companyName}{" "}
      </p>
    </div>
  );
};

export default ReportSessionItem;
