import {FC, useState} from 'react'

import ReactSlider from 'react-slider'
import * as microsoftTeams from "@microsoft/teams-js";


import {useSearchParams} from "react-router-dom";

import {useTranslation} from "react-i18next";
import {saveClientFeedback} from "../../../services/api/teams/ReportActions";
import {IClientFeedback} from "../../../models/IReport";
import {isKeyExists} from "../../../utils/helpers/object";
import { ObjectType } from 'typescript';
import i18n from "../../../utils/lib/i18n";
import ENG from "../locale/eng/home.json";
import Fr from "../locale/fr/home.json";

interface Props {
    isOpen?: boolean,
    onClose?: (e: any) => any,
    meetingId?: any
}

const schema = [
    'rating',
]

const ReviewModal: FC<Props> = (props) => {
    i18n.addResourceBundle('en', 'home', ENG    );
    i18n.addResourceBundle('fr', 'home', Fr    );
    const {t} = useTranslation('home')

    const [reviewData, setReviewData] = useState({rating: 0})
    const [loading] = useState(false)
    const [errors, setErrors] = useState({})
    const [searchParams] = useSearchParams()


    const handleSliderChange = (value: number) => {
        setReviewData({
            ...reviewData, ...{
                rating: value
            }
        })
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        setReviewData({
            ...reviewData, ...{
                [e.target.name]: e.target.value
            }
        })
    }

    const validate = () => {
        let valid = true
        let vErrors = {}

        schema.map((input) => {
            if (!reviewData[input as keyof typeof reviewData]) {
                valid = false
                vErrors = {
                    ...vErrors,
                    ...{
                        [input]: 'This field is required.'
                    }
                }
            }
        })

        setErrors(vErrors)

        return valid
    }

    const handleSubmit = () => {
        if (!validate()) {
            return
        }
        submitReview(reviewData)
        console.log(reviewData);

    }
    const submitReview = (reviewD:{[key: string]:any }) => {
            reviewD["meeting_id"] = searchParams.get('meeting_id')
            microsoftTeams.app.initialize().then(r => {
                microsoftTeams.dialog.submit(reviewD, process.env.REACT_APP_TEAMS_MANIFEST_APPID);
            })
    }
    const submitHandler = (result: any) => {
        let thumbs = result.rating;

        const clientReview: IClientFeedback = {
            thumbs_reply: thumbs,
            review: result.comment ? result.comment : ""
        }

        saveClientFeedback(result.meeting_id, clientReview)
            .then(res => {
                console.log(res.message);
                if (props.onClose) {
                    props.onClose(false)
                }
            })

    };
    return (
        // <Modal title="Leave a review" isOpen={props.isOpen} onClose={props.onClose}>
        <form style={{padding: "4%"}} action="src/modules/teams/pages/ReviewModal" className="mt-2 w-full">

            <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">{t('text_14')}</label>
                <div className="relative mt-4">
                    <ReactSlider
                        className="mb-2 horizontal-slider"
                        marks
                        markClassName="example-mark"
                        min={1}
                        max={10}
                        thumbClassName="example-thumb"
                        trackClassName="example-track"
                        onChange={(value, i) => handleSliderChange(value)}
                        renderThumb={(p, state) => <div {...p}>{state.valueNow}</div>}
                    />
                    {isKeyExists(errors, 'rating') ?
                        <small className="mt-3 text-sm font-light text-red-400">{t('text_15')}</small> : ''}
                </div>
            </div>
            <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">{t('text_16')}</label>
                <div className="mt-1">
                    <textarea onChange={e => handleInputChange(e)} rows={6} name="comment" placeholder={t('text_17')}
                              id="first-name" autoComplete="off"
                              className="block w-full px-3 py-2 border border-gray-600 rounded shadow-sm sm:text-sm placeholder:font-light"></textarea>
                </div>
            </div>
            <div className="flex items-center justify-end col-span-2 mt-4">
                <button type="button" onClick={handleSubmit}
                        className="px-10 py-2 text-white rounded bg-red placeholder:font-light"
                        disabled={loading}>{loading ? 'Confirming...' : 'Confirm'}</button>
            </div>
        </form>
        // </Modal>
    )
}

export default ReviewModal
