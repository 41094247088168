import {GET, POST} from "../../utils/lib/axios";
import {META} from "../../constants/endpoints/meta";
import {IConfig} from "../../utils/providers/AppConfigProvider";


export const getCompanies = () => {
    return GET(META.get.companies)
        .then(response => {
            return response.data.data;
        });
}

export const getLanguages = () => {
    return GET(META.get.languages)
        .then(response => {
            return response.data.data;
        });
}

export const getSpecializations = () => {
    return GET(META.get.specializations)
        .then(response => {
            return response.data.data;
        });
}

export const getReportOptions = (companyId?: number, meetingId?: number) => {
    return GET(META.get.reportOptions, undefined, {company_id: companyId, meeting_id: meetingId})
        .then(response => {
            return response.data.data;
        });
}
export const passToCoachello = (data: Object) => {
    return POST(META.post.passToCoachello, data)
        .then(response => {
            return response.data;
        });
}

export const getAppConfig = async (companyId: number): Promise<IConfig> => {
    return GET(META.get.getAppConfig, {company_id: companyId}).then((response) => {
        return response.data;
    });
};

export default {getCompanies, getLanguages, getSpecializations, getReportOptions, passToCoachello, getAppConfig}
