import React from "react";
import styled from "@emotion/styled";
import { PulseLoader } from "react-spinners";

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backdropFilter: "blur(4px)",
  zIndex: 999999,
});

type Props = {
  isFullscreen?: boolean;
  backdropColor?: string;
  spinnerColor?: string;
};

const Loader = ({
  isFullscreen = true,
  backdropColor = "transparent",
  spinnerColor = "white",
}: Props) => {
  return (
    <Container
      style={{
        position: isFullscreen ? "fixed" : "absolute",
        backgroundColor: backdropColor,
      }}
    >
      <PulseLoader color={spinnerColor} size={8} speedMultiplier={0.5} />
    </Container>
  );
};

export default Loader;

export const DarkLoader = ({
  isFullscreen = true,
  backdropColor = "#fff2",
  spinnerColor = "#fd0054",
}: Props) => (
  <Loader
    backdropColor={backdropColor}
    spinnerColor={spinnerColor}
    isFullscreen={isFullscreen}
  />
);
