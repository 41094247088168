import {FC, HTMLAttributes, useEffect, useState} from "react";

import {useFormikContext} from "formik";

import Spinner from "../../Spinner/Spinner";

import style from "./SubmitFormik.module.css";

interface Props extends HTMLAttributes<HTMLButtonElement> {
    text: string;
    onSubmittingText: string;
    disabled?: boolean;
}

const SubmitFormik: FC<Props> = ({
                                     text,
                                     onSubmittingText,
                                     disabled,
                                     ...props
                                 }) => {
    const {isSubmitting, setSubmitting} = useFormikContext();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isSubmitting && !isLoading) {
            setIsLoading(true);
        }
        if (!isSubmitting && isLoading) {
            setTimeout(() => {
                setIsLoading(false)
                setSubmitting(false)
            }, 1500);
        }
    }, [isLoading, isSubmitting, setSubmitting]);

    return (
        <>
            <button
                className={style.submitButton}
                disabled={isSubmitting || disabled}
                type="submit"
                {...props}
            >
                {isLoading ? (
                    <>
                        {onSubmittingText}
                        <Spinner size={"24px"}/>
                    </>
                ) : (
                    text
                )}
            </button>
        </>
    );
};

export default SubmitFormik;
