import React, {useEffect, useState} from 'react';
import {BriefcaseIcon, CalendarIcon, ClockIcon} from "@heroicons/react/outline";
import MultiSelectFormik from "../../../shared/components/formik/SelectFormik";
import {Formik, FormikHelpers, FormikValues} from "formik";
import * as Yup from "yup";
import DiscreteProgressbar from "../../../shared/components/DiscreteProgressbar";
import TextareaFormik from "../../../shared/components/formik/TextareaFormik/TextareaFormik";
import MeetingStatusSwitch from "../components/MeetingStatusSwitch";
import InputFormik from "../../../shared/components/formik/InputFormik/InputFormik";
import moment from "moment/moment";
import MetaApi from "../../../services/api/metaApi";
import {createReport, getReport} from "../../../services/api/coachApi";
import useAuth from "@hooks/useAuth";
import Loader from "../../../shared/components/Loader/Loader";
import {toast} from "react-toastify";
import Spinner from "../../../shared/components/Spinner/Spinner";
import {useTranslation} from "react-i18next";
import ENG from "../locale/eng/reports.json";
import Fr from "../locale/fr/reports.json";
import i18n from "../../../utils/lib/i18n";
import {TSelfReflection} from "../../../constants/types";
import {IMeta} from "../../../models/IMeta";
import ReportSelfReflectionCard from "../pages/CreateReport/components/ReportSelfReflectionCard";
import defaultProfileImg from "@img/icons/default-profile.png";
import LoadingScreen from "../components/LoadingScreen";

interface Props {
    sessionId: number;
    datetime: Date
    sessionNo: number;
    profile?: any;
    coacheeName: string;
    companyName: string;
    companyId: number;
    attended: boolean | null;
    reload: () => void
    selfReflections: TSelfReflection[];
}

const reportInit = {
    categories: [],
    behaviors: [],
    gradePriorOption: null,
    appliedActionOption: null,
    gradeAfterOption: null,
    feelingAfterOption: null,
    goalOption: null,
    feelingAfterOther: "",
    appliedAction: "",
    goal: "",
    agreedAction: "",
    coachingMaterials: "",
    note: "",
}
const ReportCreationForm = (props: Props) => {
        const [attended, setAttended] = useState(props.attended == null ? true : props.attended)
        const [readOnly, setReadOnly] = useState(props.attended != null)
        const [loading, setLoading] = useState(false)
        const [report, setReport] = useState<any>(reportInit)
        const {user} = useAuth()
        const [dynamicValidationRules, setDynamicValidationRules] = useState({});
        i18n.addResourceBundle("en", "reports", ENG);
        i18n.addResourceBundle("fr", "reports", Fr);
        const { t } = useTranslation("reports");
        const lng = localStorage.getItem("I18N_LANGUAGE") || "en";
        const [selectedObjectives, setSelectedObjectives] = useState<IMeta[]>([]);
        const [isLoading, setIsLoading] = useState(false);

        const [metaOptions, setMetaOptions] = useState({
            actions: [],
            behaviors: [],
            categories: [],
            feelings: [],
            // companyObjectives: [],
            goals: [],
            custom_fields: []
        })
        // console.log(attended, readOnly);
// Create a dynamic validation schema based on metaApiResponse
//     const dynamicValidationSchema = metaApiResponse.custom_fields.reduce((acc, field) => {
//         if (field.type === 'dropdown') {
//             const dropdownValidation = Yup.array()
//                 .of(
//                     Yup.object().shape({
//                         value: Yup.string(),
//                         label: Yup.string(),
//                     })
//                 )
//                 .nullable()
//                 .required('This field is required.')
//                 .min(field.selectable_count, `Choose at least ${field.selectable_count}`);
//
//             acc[field.name] = dropdownValidation;
//         }
//
//         // Add other field types here if needed
//
//         return acc;
//     }, {});
        useEffect(() => {
            setAttended(props.attended == null ? true : props.attended)
            setReadOnly(props.attended != null)
        }, [props.attended])

        useEffect(() => {
            console.log(props.attended)
            if (props.attended) {
                setLoading(true)
                getReport(user?.id!, props.sessionId).then((res: any) => {
                    console.log(res);
                    let custom_fields: Record<string, any> = {};
                    res.option_answers.custom_fields?.map((field: any) => {
                        // console.log("Processing field:", field);

                        if (field.type == 'dropdown') {
                            custom_fields[field.name] = field.options;
                            // console.log(`Setting ${field.name} to options:`, field.options);
                        } else {
                            custom_fields[field.name] = field.text;
                            // console.log(`Setting ${field.name} to text:`, field.text);
                        }
                    });

                    // console.log("Final custom_fields:", custom_fields);
                    console.log(custom_fields);
                    setReport({
                        categories: res.option_answers.categories,
                        behaviors: res.option_answers.behaviors,
                        gradePriorOption: res.option_answers.grade_prior,
                        appliedActionOption: res.option_answers.applied_action,
                        gradeAfterOption: res.option_answers.grade_after,
                        feelingAfterOption: res.option_answers.feeling_after,
                        goalOption: res.option_answers.towards_goal,

                        feelingAfterOther: res.summary_answers[5],
                        appliedAction: res.summary_answers[1],
                        goal: res.summary_answers[2],
                        agreedAction: res.summary_answers[3],
                        coachingMaterials: res.summary_answers[4],
                        note: res.note,
                        ...custom_fields
                    });
                    setLoading(false)
                }).catch(() => {
                    console.log("api error");
                    setLoading(false)
                })
            }
        }, [props.attended, props.sessionId])

        useEffect(() => {
            if (props.attended != true) {
                setReport(reportInit)
            }
        }, [props.sessionId])

        useEffect(() => {
            console.log(props.companyId);
            MetaApi.getReportOptions(props.companyId, props.sessionId).then((res) => {
                    // console.log(res);
                    setMetaOptions(res)

                }
            ).catch((e) => {
                console.log("api error getting meta");
            })
            // .then(() => getCoachProfile(user?.id!)
            // .then(res => {
            //     setProfile(res)
            // }).catch((e) => {
            //     console.log("api error");
            // }))

        }, [props.companyId])
        console.log(metaOptions.custom_fields);
        console.log(report);
        const _handleSubmit = async (values: FormikValues, actions: FormikHelpers<FormikValues>) => {
            setIsLoading(true);
            // console.log(values);
            let report: {}
            if (attended) {
                const custom_fields = metaOptions.custom_fields?.map((field: any) => {
                        let selectedField;
                        selectedField = field
                        if (field.type == 'dropdown') {
                            selectedField.options = values[field.name]
                        } else {
                            selectedField.text = values[field.name]
                        }

                        return selectedField

                    }
                )

                report = {
                    "status": "success",
                    "summary_answers": {
                        1: values.appliedAction,
                        2: values.goal,
                        3: values.agreedAction,
                        4: values.coachingMaterials,
                        5: values.feelingAfterOther,
                    },
                    "option_answers": {
                        "categories": values?.categories,
                        "behaviors": values?.behaviors,
                        "applied_action": values.appliedActionOption,
                        "towards_goal": values.goalOption,
                        "feeling_after": values?.feelingAfterOption,
                        "grade_after": values.gradeAfterOption,
                        "grade_prior": values.gradePriorOption,
                        "custom_fields": custom_fields
                    },
                    // "goal": values.goal,
                    // "agreed_action": values.agreedAction,
                    "note": values.note
                };

            } else {
                report = {
                    "status": "no_show_by_client"
                }
            }
            // console.log('submitting...');
            // await new Promise(f => setTimeout(f, 2000));

            console.log(report);
            // actions.setSubmitting(false);

            createReport(user?.id!, props.sessionId, report).then(() => {
                    setIsLoading(false);
                    toast.success('Report has been saved!')
                    actions.setSubmitting(false);
                    setReport(reportInit)
                    props.reload()
                }
            ).catch(() => {
                toast.error('Sorry, something went wrong!')
                actions.setSubmitting(false);
            })
        }
        return (

            <Formik
                initialValues={report}
                enableReinitialize={true}
                validationSchema={(!readOnly && attended) &&
                    Yup.object({
                        categories: Yup.array().min(1, t("report_creation_form.text_1"))
                            .required(t("report_creation_form.text_3")),
                        gradePriorOption: Yup.mixed() // Validates for numerical value
                            .required(t("report_creation_form.text_3")), // Sets it as a compulsory field
                        gradeAfterOption: Yup.mixed() // Validates for numerical value
                            .required(t("report_creation_form.text_3")), // Sets it as a compulsory field
                        appliedActionOption: Yup.object()
                            .shape({
                                value: Yup.string(),
                                label: Yup.string(),
                            })
                            .nullable()
                            .required(t("report_creation_form.text_2")),
                        feelingAfterOption: Yup.object()
                            .shape({
                                value: Yup.string(),
                                label: Yup.string(),
                            })
                            .nullable()
                            .required(t("report_creation_form.text_2")),
                        goalOption: Yup.object()
                            .shape({
                                value: Yup.string(),
                                label: Yup.string(),
                            })
                            .nullable()
                            .required(t("report_creation_form.text_2")),

                        goal: Yup.string()
                            .required(t("report_creation_form.text_3")),
                        appliedAction: Yup.string()
                            .required(t("report_creation_form.text_3")),

                        agreedAction: Yup.string()
                            .required(t("report_creation_form.text_3")),
                    })}
                onSubmit={_handleSubmit}>
                {({values, setFieldValue, handleSubmit, isValid, isSubmitting, touched, errors}) => ( //this put only to use in discrete progressbar

                    <div>
                        <div className="card-container p-4">
                            <div className="flex gap-5 justify-between">
                                <p className="font-medium">Session {props.sessionNo}</p>
                                <div className="flex gap-5">
                                    <p className="icon-text">
                                        <CalendarIcon className="text-red"/>
                                        {moment(props.datetime).format('ll')}                </p>
                                    <p className="icon-text">
                                        <ClockIcon className="text-red"/>
                                        {moment(props.datetime).format('HH:mm')}                </p>
                                </div>
                            </div>
                            <hr className="-mx-4"/>
                            <div className="p-2 flex gap-5 w-full justify-between">
                                <div className="flex gap-3 items-center">
                                    <img
                                        src={props.profile ? props.profile : defaultProfileImg}
                                        className="rounded-full w-14 h-14"/>
                                    <div className="">
                                        <h4> {props.coacheeName}</h4>
                                        {/*<ul className="flex mt-2">*/}
                                        {/*    {props.categories?.map((category, i) =>*/}
                                        {/*            <li key={i}*/}
                                        {/*                  className="flex gap-2 rounded bg-red-50 p-1.5 text-sm ">*/}
                                        {/*        <AcademicCapIcon className="w-4"/>*/}
                                        {/*                {category}*/}
                                        {/*    </li>*/}
                                        {/*    )}*/}

                                        {/*</ul>*/}
                                    </div>
                                </div>
                                <div className="flex gap-1 lg:flex-row flex-col items-center font-light">
                                    {t("report_creation_form.meeting_status")}
                                    <MeetingStatusSwitch attended={attended} setAttended={readOnly ? () => {
                                    } : setAttended}/>
                                </div>
                            </div>
                            <hr className="-mx-4"/>

                            <div className="icon-text">
                                <BriefcaseIcon className=""/>
                                <p>{props.companyName}</p>
                            </div>
                        </div>

                        {loading ? <Loader/> :
                            (attended) && (<div>
                                <div className="card-container mt-5 p-4">
                                    <div className="flex gap-5 justify-between">
                                        <p className="font-medium">{t("report_creation_form.title_1")}</p>
                                    </div>
                                    <hr className="-mx-4"/>
                                    <div className="p-5 flex flex-col gap-5 w-full">
                                        <div className="w-full flex flex-col gap-2">
                                            <span className="">{t("report_creation_form.field_1")}</span>
                                        <div>
                                            <ul className="h-max grid w-full grid-cols-2 gap-x-5 gap-y-5 sm:grid-cols-3 lg:grid-cols-4">
                                                {props.selfReflections.map((selfReflection, index) => (
                                                    <ReportSelfReflectionCard
                                                        key={index}
                                                        {...selfReflection}
                                                        selectedObjectives={selectedObjectives}
                                                        onSelectObjective={(objective) => {
                                                            const objIndex = selectedObjectives.findIndex(
                                                                (obj) => obj.id === objective.id
                                                            );
                                                            const updatedObjectives = [...selectedObjectives];

                                                            if (objIndex > -1) {
                                                                updatedObjectives.splice(objIndex, 1);
                                                            } else{
                                                                updatedObjectives.push(objective);
                                                            }
                                                            setSelectedObjectives(updatedObjectives);
                                                            setFieldValue('categories', updatedObjectives);
                                                        }}
                                                        lng={lng}

                                                    />
                                                ))}
                                            </ul>
                                        </div>
                                        {touched.categories && typeof errors.categories === 'string' && (
                                            <span className="text-red font-extralight">{errors.categories}</span>
                                        )}
                                        </div>

                                        <div className="w-full flex gap-5 items-center">
                                            <span>{t("report_creation_form.field_2")}</span>
                                            <DiscreteProgressbar value={values.gradePriorOption || 0}
                                                                 setValue={value => (readOnly ? {} : setFieldValue('gradePriorOption', value))}/>
                                            {touched.gradePriorOption && typeof errors.gradePriorOption === 'string' && (
                                                <span className="text-red font-extralight">{errors.gradePriorOption}</span>
                                            )}
                                            {/*{touched['gradePriorOption'] && errors['gradePriorOption']  ?*/}
                                            {/*    <span className="text-red font-extralight">{errors.gradePriorOption}</span>*/}
                                            {/* : null}*/}
                                        </div>
                                        <hr className="w-full"/>
                                        {/*<div className="w-full flex flex-col gap-2">*/}
                                        {/*    <span>Feeling before sessions *</span>*/}
                                        {/*    <MultiSelectFormik labelKey="name" valueKey="id"  isMulti={false} name="feelingBefore" options={metaOptions.feelings}/>*/}
                                        {/*</div>*/}
                                        <div className="w-full flex flex-col gap-2">
                                            <span>{t("report_creation_form.field_3")}</span>
                                            <MultiSelectFormik isDisabled={readOnly} isMulti={false} labelKey="name"
                                                               valueKey="id"
                                                               name="appliedActionOption"
                                                               options={metaOptions.actions}
                                                placeholder={t("report_creation_form.text_4")}/>
                                        </div>
                                        <div className="w-full">
                                            <span>{t("report_creation_form.field_4")}</span>
                                            <TextareaFormik placeholder={t("report_creation_form.field_4_placeholder")} readOnly={readOnly}
                                                            name="appliedAction" label=""/>
                                        </div>
                                    </div>
                                </div>


                                <div className="card-container mt-5 p-4">
                                    <div className="flex gap-5 justify-between">
                                        <p className="font-medium">{t("report_creation_form.title_2")}</p>
                                    </div>
                                    <hr className="-mx-4"/>
                                    <div className="p-5 flex flex-col gap-5 w-full">

                                        {/*<div className="w-full flex flex-col gap-2">*/}
                                        {/*    <span>Coaching category *</span>*/}
                                        {/*    <MultiSelectFormik labelKey="name" valueKey="id"  name="languages" options={[*/}
                                        {/*        {value: 'chocolate', label: 'Chocolate'},*/}
                                        {/*        {value: 'strawberry', label: 'Strawberry'},*/}
                                        {/*        {value: 'vanilla', label: 'Vanilla'}*/}
                                        {/*    ]}/>*/}
                                        {/*</div>*/}
                                        <div className="w-full flex gap-5 items-center">
                                            <span>{t("report_creation_form.field_5")}</span>
                                            <DiscreteProgressbar value={values.gradeAfterOption || 0}
                                                                 setValue={value => setFieldValue('gradeAfterOption', value)}/>
                                            {touched.gradeAfterOption && typeof errors.gradeAfterOption === 'string' && (
                                                <span className="text-red font-extralight">{errors.gradeAfterOption}</span>
                                            )}
                                        </div>
                                        <hr className="w-full"/>
                                        {['Enedis-Pilot', 'Enedis'].includes(props.companyName) &&
                                            <div className="w-full flex gap-5">
                                            <div className="flex-1 flex flex-col gap-2">
                                                <span>{t("report_creation_form.field_6")}</span>
                                                <MultiSelectFormik isDisabled={readOnly} labelKey="name" valueKey="id"
                                                                   name="behaviors"
                                                                   options={metaOptions.behaviors}
                                                    placeholder={t("report_creation_form.text_4")}/>
                                            </div>

                                        </div>}


                                        {metaOptions.custom_fields?.map((field: any) => {
                                            if (field.type == "dropdown") {
                                                return <div className="flex-1 flex flex-col gap-2">
                                                    <span>{field.label}</span>

                                                    <MultiSelectFormik isDisabled={readOnly} isMulti={true} labelKey="name"
                                                                       valueKey="id"
                                                                       name={field.name}
                                                                       options={field.options}
                                                        placeholder={t("report_creation_form.text_4")}/>
                                                </div>
                                            } else {
                                                return <div className="w-full ">
                                                    <span>{field.label}</span>
                                                    <TextareaFormik readOnly={readOnly} name={field.name} label=""/>
                                                </div>
                                            }

                                        })
                                        }
                                        <div className="w-full flex flex-col gap-2">
                                            <span>{t("report_creation_form.field_7")}</span>
                                            <MultiSelectFormik isDisabled={readOnly} isMulti={false} labelKey="name"
                                                               valueKey="id"
                                                               name="feelingAfterOption"
                                                               options={metaOptions.feelings.slice(0, -1)}
                                                placeholder={t("report_creation_form.text_4")}/>

                                        </div>

                                        {values?.feelingAfterOption?.['id'] === 9 && <div className="w-full ">
                                            <span>{t("report_creation_form.field_8")}</span>
                                            <TextareaFormik readOnly={readOnly} name="feelingAfterOther" label=""/>
                                        </div>
                                        }
                                        <div className="w-full flex flex-col gap-2">
                                            <span>{t("report_creation_form.field_9")}</span>
                                            <MultiSelectFormik isDisabled={readOnly} isMulti={false} labelKey="name"
                                                               valueKey="id"
                                                               name="goalOption"
                                                               options={metaOptions.goals}
                                                placeholder={t("report_creation_form.text_4")}/>

                                        </div>


                                        <div className="w-full ">
                                            <span>{t("report_creation_form.field_10")}</span>
                                            <TextareaFormik readOnly={readOnly} name="agreedAction" label=""/>
                                        </div>
                                        <div className="w-full ">
                                            <span>{t("report_creation_form.field_11")}</span>
                                            <TextareaFormik readOnly={readOnly} name="goal" label=""/>
                                        </div>
                                        <div className="w-full ">
                                            <span>{t("report_creation_form.field_12")}</span>
                                            <InputFormik readOnly={readOnly} name="coachingMaterials" label=""/>
                                        </div>
                                        <div className="w-full ">
                                            <span>{t("report_creation_form.field_13")}</span>
                                            <TextareaFormik readOnly={readOnly} name="note" label=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                        <br/>
                        {!readOnly && <div className="flex justify-end">
                            <button disabled={isSubmitting} type="submit" onClick={() => handleSubmit()}
                                    className="dashboard-button bg-black disabled:bg-gray">
                                {t("report_creation_form.submit")}
                            </button>
                        </div>}
                        {isLoading && <LoadingScreen/>}
                    </div>
                )}
            </Formik>
        )
            ;
    }
;

export default ReportCreationForm;
