import { Navigate, useLocation } from "react-router-dom";
import useAuth from "@hooks/useAuth";
import IAuthRouterProp from "../../models/IAuthRouter";

const PrivateRoute = ({ Component, allowedRoles, props }: IAuthRouterProp) => {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) {
    return (
      <Navigate to="/auth/login" replace state={{ from: location.pathname }} />
    );
  }
  // check if route is restricted by role
  if (allowedRoles && allowedRoles?.indexOf(user.role) === -1) {
    // role not authorised so redirect to home page
    return <Navigate to="/404" replace />;
  }
  return <Component {...props} />;
};

export default PrivateRoute;
