import React, {createContext, useState, ReactNode} from 'react';
import {OverviewItem, SummaryData} from "./types";

interface ContextProps {
    selectedOverviewItem: OverviewItem | undefined;
    setSelectedOverviewItem: React.Dispatch<React.SetStateAction<OverviewItem | undefined>>
    hasSummary: boolean | undefined;
    setHasSummary: React.Dispatch<React.SetStateAction<boolean | undefined>>
    displayReview: boolean;
    setDisplayReview: React.Dispatch<React.SetStateAction<boolean>>;
    journeySessionId: number | undefined;
    setJourneySessionId: React.Dispatch<React.SetStateAction<number | undefined>>;
    summary: SummaryData | undefined;
    setSummary: React.Dispatch<React.SetStateAction<SummaryData | undefined>>;
}

const BookingDataContext = createContext<ContextProps | undefined>(undefined);

interface ProviderProps {
    children: ReactNode;
}

const BookingDataProvider: React.FC<ProviderProps> = ({children}) => {
    const [selectedOverviewItem, setSelectedOverviewItem] = useState<
        OverviewItem | undefined
    >();
    const [hasSummary, setHasSummary] = useState<boolean | undefined>(false);
    const [displayReview, setDisplayReview] = useState(false);
    const [journeySessionId, setJourneySessionId] = useState<number>();
    const [summary, setSummary] = useState<SummaryData | undefined>(undefined);




    const contextValue: ContextProps = {
        selectedOverviewItem, setSelectedOverviewItem,
        hasSummary, setHasSummary,
        displayReview, setDisplayReview,
        journeySessionId, setJourneySessionId,
        summary, setSummary
    };

    return (
        <BookingDataContext.Provider value={contextValue}>
            {children}
        </BookingDataContext.Provider>
    );
};

export {BookingDataContext, BookingDataProvider};
