import {
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
} from "react-router-dom";
import axios from "axios";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import "rsuite/dist/rsuite.min.css";
import "./assets/styles/fonts.css";
import "./assets/styles/colour.css";
import "./assets/styles/App.css";

import {ConfigProvider} from "./utils/providers/AppConfigProvider";
import React from "react";
import routerMiddleware from "./routes/RouterMiddleware/RouterMiddleware";
import routes from "./routes";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;


const router = createBrowserRouter(
    createRoutesFromElements(
        routerMiddleware(routes)
    )
);


function App() {
    return (
        <ConfigProvider>
            <ToastContainer
                position="top-center"
                autoClose={9000}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <RouterProvider router={router}/>


            {/*<Routes> <Route path="/coaching" element={<Coaching/>}/>*/}
            {/*    <Route path="/usage" element={<Usage/>}/>*/}
            {/*    <Route path="/404" element={<NotFound/>}/>*/}
            {/*    <Route path="*" element={  <Navigate replace to="/404"/>}/>*/}
            {/*</Routes>*/}
        </ConfigProvider>
    );
}

export default App;
