import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import HT from "./HighlightedText";
import ReactSelect from "react-select";
import ChatGuide from '../../../../../shared/components/ChatGuide';
import { getLanguages } from '../../../../../services/api/metaApi';
import { IMeta } from '../../../../../models/IMeta';

interface Props {
    selectedLanguage: undefined | IMeta;
    setSelectedLanguage: (lng: any) => void
}

const Preference = (props: Props) => {
    const { t } = useTranslation("welcome");
    const [languagesOptions, setLanguagesOptions] = useState<IMeta[]>()
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        getLanguages().then((lng) => setLanguagesOptions(lng))
    }, []);

    return (
        <div>
            <ChatGuide
                title={<HT>{t("step_4_1.title")}</HT>}
                description={t("step_4_1.description")}
                descriptionClassName="font-medium"
                onShowContent={() => setShowContent(true)}

            />
            {showContent &&
                <div className="mt-5 ">
                    <div className="flex gap-5 items-center mt-10">
                        <span className="font-medium">{t("step_4_1.preferred_language")}</span>
                        <ReactSelect
                            getOptionLabel={(option) => option['name']}
                            // @ts-ignore
                            getOptionValue={(option) => option['id']}
                            onChange={(item) => props.setSelectedLanguage(item as IMeta)}
                            isMulti={false}
                            name="language"
                            options={languagesOptions}
                            placeholder={t("step_4_1.language")} />
                    </div>
                </div>
            }


        </div>
    );
};

export default Preference;

