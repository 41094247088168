import { FC } from "react";

import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";

const CoachCardSkeleton: FC = () => {
  return (
    <div className="bg-white rounded-lg h-max flex flex-col justify-between relative">
      <div className="absolute top-3 right-3 z-10">
        <Skeleton width={20} height={20} />
      </div>

      <div
        className="block w-full overflow-hidden bg-gray-100 rounded-t-lg group h-[200px]
                focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
      >
        <Skeleton style={{ top: "-5px" }} height={"100%"} width={"100%"} />
      </div>
      <div className="px-4 py-4 h-full min-h-[315px] flex flex-col justify-between">
        <div>
          <div className="flex items-center justify-start mb-3">
            <div className="block pb-2 mr-3 font-medium text-gray-900 truncate pointer-events-none">
              <Skeleton width={100} />
            </div>
            <div className="block pb-2 mr font-medium text-gray-900 truncate pointer-events-none">
              <Skeleton width={100} />
            </div>
          </div>
          <div className="font-light">
            <div className="flex gap-2 mb-1">
              <Skeleton width={20} height={20} />
              <Skeleton width={130} height={20} />
            </div>
          </div>
          <div className="font-light h-12">
            <div className="flex gap-2 mb-1">
              <Skeleton width={20} height={20} />
              <Skeleton width={130} height={20} />
            </div>
          </div>
          <div className="h-16">
            <div className="w-full flex flex-wrap relative">
              {[1, 2, 3, 4].map((_, index) => {
                return (
                  <span
                    key={index}
                    className="px-1 py-1 mb-1 mr-2 text-xs font-light rounded"
                  >
                    <Skeleton width={50} height={24} />
                  </span>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center mt-3">
          <Skeleton
            className="flex-1 py-3 text-center text-white rounded"
            width={150}
          />
        </div>
      </div>
    </div>
  );
};

export default CoachCardSkeleton;
