import {IsTokenExp} from "../../utils/helpers/token-exp";
import React, {useEffect, useState} from "react";
import NoAccess from "../../modules/teams/pages/NoAccess";
import {authenticate} from "../../services/api/teams/AuthActions";
import {ITeamsAuthData} from "../../models/ITeamsAuth";
import IAuthRouterProp from "../../models/IAuthRouter";
import {Navigate} from "react-router-dom";

export const TeamsPrivateRoute = ({Component, props}: IAuthRouterProp) => {

    const [loading, setLoading] = useState(true)

    let authUser = localStorage.getItem('ch_user')

    useEffect(() => {

        if (!authUser) {
            console.log('Auth user not found. Authenticating...');
            authenticate().then(() => {
                console.log('Authenticated');
                setLoading(false)

            })
        } else {
            let authUserObj: ITeamsAuthData = JSON.parse(authUser || '{}') as ITeamsAuthData

            if (IsTokenExp(authUserObj.teams_token)) {
                localStorage.clear()
                authenticate().then(() => {
                    console.log('Authenticated');
                    setLoading(false)
                })
            }
        }

    }, [authenticate, authUser])

    return authUser ? <Component {...props}/> : (<>

        {loading && <div className="inline-flex justify-center w-full py-5 text-center">
            <div className="flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg"
                     style={{margin: "auto", background: "0 0", display: "block", shapeRendering: "auto"}}
                     width="24" height="24" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <circle cx="50" cy="50" fill="none" stroke="#fd0054" strokeWidth="8" r="35"
                            strokeDasharray="164.93361431346415 56.97787143782138">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite"
                                          dur="1.1363636363636365s" values="0 50 50;360 50 50" keyTimes="0;1"/>
                    </circle>
                </svg>
                <p className="ml-3 font-light">Loading...</p>
            </div>
        </div>}
        {!loading && <NoAccess/>}
    </>)

}
