import {useEffect, useState} from "react";

import {twMerge} from "tailwind-merge";

import WelcomeCard from "./components/WelcomeCard";
import {OverviewItem} from "../AICoaching/types";
import useGuideStatus from "../../../../utils/hooks/useGuideStatus";
import {getAIJourneyOverview} from "../../../../services/api/AiBookingActions";
import {getAIOverview} from "../../../../services/api/aiBookingApi";
import {getAllSchedule} from "./actions";


const AiHome = () => {
    const [overviewItems, setOverviewItems] = useState<OverviewItem[]>([]);
    const [overviewJourneyItems, setOverviewJourneyItems] = useState<
        OverviewItem[]
    >([]);

    const [schedules, setSchedules] = useState<any[]>([]);
    const guideStatus = useGuideStatus();

    useEffect(() => {
        guideStatus.checkStatus(() => {
            console.log('done')
        });

    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const sessionList = await getAllSchedule();
            setSchedules(sessionList);
            const journeyOverviewData = await getAIJourneyOverview();
            if (journeyOverviewData) setOverviewJourneyItems(journeyOverviewData.reverse());
            const overviewData = await getAIOverview();
            setOverviewItems(overviewData.reverse());
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div
            className="bg-linen_gradient h-screen flex flex-col justify-center gap-4 py-6 px-4 lg:px-[7.5%] overflow-x-hidden align-center">
            <div className={twMerge(
                "h-full flex w-full flex-col gap-9 items-center mt-20",
                schedules?.length || overviewItems?.length || overviewJourneyItems?.length
                    ? "justify-start"
                    : "justify-center"
            )}>

                <WelcomeCard overviewJourneyItems={overviewJourneyItems}/>


            </div>
            <br/>
        </div>
    );
};

export default AiHome;
