import useAuth from "@hooks/useAuth";
import React, {useEffect, useRef, useState} from 'react';
import logoImg from '@img/auth/js.png'
import loginImg from '@img/auth/js-login.png'
import googleIcon from '@icons/google.png'
import microsoftIcon from '@icons/microsoft.png'
import {useLocation, useNavigate} from "react-router-dom";
import {DocumentTextIcon, HomeIcon, KeyIcon, LockClosedIcon, MailIcon} from "@heroicons/react/outline";
import {EAuthType} from "../../../constants/enums";
import LanguageSelector from "../../../shared/components/LanguageSelector/LanguageSelector";
import i18n from "../../../utils/lib/i18n";
import ENG from "../locale/eng/login.json";
import Fr from "../locale/fr/login.json";
import {useTranslation} from "react-i18next";
import useModal from "@hooks/useModal";
import ResetPassword from "../components/ResetPassword";
import Modal from "../../dashboard/components/Modal";
import {toast} from "react-toastify";

interface LocationProps {
    state: {
        from: Location;
    };
};


const LoginEusa = () => {
    i18n.addResourceBundle('en', 'login', ENG);
    i18n.addResourceBundle('fr', 'login', Fr);
    const {t} = useTranslation('login')
    const languageSelectorRef =
        useRef<React.ElementRef<typeof LanguageSelector>>(null);

    const navigate = useNavigate();
    const {sendMagicLink} = useAuth();
    const {state} = useLocation() as LocationProps;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const {isOpen, toggle} = useModal();

    useEffect(() => {
        languageSelectorRef.current?.changeLanguageAction('en');
    }, [languageSelectorRef]);

    // Function to validate email format using regular expression
    const isValidEmail = (email: string) => {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <div className="flex-col lg:flex-row flex">
            <div className="flex flex-col flex-1">

                <div className="flex justify-center">
                    <img className={"w-1/2 m-8"} src={logoImg}/>
                </div>
                <div className="flex flex-col justify-center items-center">
                    <div className="flex flex-col gap-3">
                        <div className="flex flex-col gap-3 justify-center w-2/3 mx-auto">
                            <h2 className="text-center">{t('text12_1')}</h2>
                            <p className="text-center">
                                {t('text12_2')}
                            </p>
                            <div className="mt-4 relative text-gray-600 rounded lg:w-2/3 mx-auto">
                                <button className="absolute left-2 top-0 bottom-0 mr-4">
                                    <MailIcon className="text-gray-600 h-5 w-5"/>

                                </button>
                                <input
                                    onChange={e => setEmail(e.target.value)}
                                    placeholder={t('text3')}

                                    className=" mx-auto w-full border-2 border-gray-light bg-white h-10 px-5 pl-8 rounded text-sm focus:outline-none"
                                    name="email"/>
                            </div>

                        </div>


                        <button disabled={email == ''}
                                className="dashboard-button bg-black m-auto disabled:bg-gray-600"
                                onClick={() => {
                                    sendMagicLink(email)
                                        .then(r => toast.success(t('text18')))
                                        .catch(e => toast.error('Sorry! Something went wrong.'))
                                }}>
                            {t('text11_1')}
                        </button>
                        <button
                            onClick={() => toggle()}
                            className=" hover:underline mx-auto"
                        >{t('text14')}</button>
                        {/*<button onClick={() => navigate('/auth/signup')}*/}
                        {/*        className="dashboard-button bg-black m-auto">*/}
                        {/*    {t('text13')}*/}
                        {/*</button>*/}
                    </div>
                </div>
                <div className="flex justify-center gap-9 mt-16 mb-8">
                    <a href={'https://coachello.io/'} target="_blank" className={"flex items-center gap-1.5"}>
                        <HomeIcon className="text-gray-600 h-5 w-5"/>
                        {t('text5')}
                    </a>
                    <a href={'https://coachello.io/privacy-policy/'} target="_blank"
                       className={"flex items-center gap-1.5"}>
                        <LockClosedIcon className="text-gray-600 h-5 w-5"/>
                        {t('text6')}
                    </a>
                    <a href={'https://coachello.io/terms-and-conditions/'} target="_blank"
                       className={"flex items-center gap-1.5"}>
                        <DocumentTextIcon className="text-gray-600 h-5 w-5"/>
                        {t('text7')}
                    </a>
                </div>
            </div>
            {/*<div*/}
            {/*    style={{backgroundImage: `url(${loginImg})`}}*/}
            {/*    className="flex-1 bg-left-top bg-contain lg:h-screen items-end flex hidden lg:flex">*/}
            <img className="lg:h-screen items-end flex hidden lg:flex" src={loginImg}/>
            {/*<div className={"text-white ml-20 mb-16"}>*/}
            {/*    <h2>{t('text8')}</h2>*/}
            {/*    <div className={"mt-5"}>*/}
            {/*        <h4>*/}
            {/*            {t('text9')}*/}
            {/*        </h4>*/}
            {/*        <span>{t('text10')}</span>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*</div>*/}
            <div className="absolute bottom-0">
                <LanguageSelector ref={languageSelectorRef}/>
            </div>
            <Modal
                isOpen={isOpen}
                onClose={toggle}
            >
                <ResetPassword toggle={toggle}/>
            </Modal>

        </div>
    );
};


export default LoginEusa;
