import React, {useState} from 'react';
import Input from "../../../shared/components/Input";
import useAuth from "@hooks/useAuth";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

const ResetPassword = ({toggle}: { toggle: () => void }) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState<string | undefined>(undefined);
    const {resetPassword} = useAuth()
    const {t} = useTranslation('login')

    const validateEmail = (email: string): boolean => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()\[\]\\.,;:\s@"]+\.)+[^<>()\[\]\\.,;:\s@"]{2,})$/i;
        return re.test(email.toLowerCase());
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!validateEmail(email)) {
            setError('Please enter a valid email address.');
            return;
        }
        setError(undefined); // Clear any existing errors
        resetPassword(email).then(() => {
            toast.success(t('text16'))
            toggle()
        }).catch(() => toast.error(t('text17')));
    };
    return (
        <div className="p-6 bg-white rounded-md lg:w-[30vw]">
            <h2 className="text-lg font-semibold mb-4">{t('text14')}</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <Input
                    className="w-full"
                    label={t('text15')}
                    placeholder={t('text3')}
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={error}
                />
                <div className="flex justify-end">
                    <button type="submit" className="dashboard-button bg-black px-4 py-2 ">
                        Send Reset Link
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ResetPassword;
