import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IGroupBasic, IGroupCoachee } from "../../../../models/IGroup";
import { FieldArray, Form, Formik, FormikHelpers, FormikValues } from "formik";
import { GroupUserTable } from "./components/GroupUserTable";
import { uniqueEmails } from "./validators";
import GroupDetailsForm from "./components/GroupDetailsForm";
import Loader from "../../../../shared/components/Loader/Loader";
import Button from "../../../../shared/components/Button";
import i18n from "../../../../utils/lib/i18n";
import ENG from "../../locale/eng/groups.json";
import Fr from "../../locale/fr/groups.json";
import { useTranslation } from "react-i18next";
import useAuth from "../../../../utils/hooks/useAuth";
import {
  getGroup,
  updateGroup,
  updateGroupCoachees,
} from "../../../../services/api/companyApi";
import { v4 as uuidv4 } from "uuid";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";
import { NavigationPrompt } from "../../../../shared/components/NavigationPrompt";
import { PencilIcon } from "@heroicons/react/solid";
import * as Yup from "yup";
import GroupAdminForm from "./components/GroupAdminForm";
import { ERole } from "../../../../constants/enums";

const GroupEdit = () => {
  const { groupId } = useParams();
  const groupIdValue = Number(groupId || "");
  i18n.addResourceBundle("en", "groups:create_group", ENG);
  i18n.addResourceBundle("fr", "groups:create_group", Fr);
  const { t } = useTranslation("groups:create_group");
  const { user } = useAuth();
  const [groupDetails, setGroupDetails] = useState<IGroupBasic>();
  const [coachees, setCoachees] = useState<IGroupCoachee[]>();
  const [detailsEditMode, setDetailsEditMode] = useState(false);
  const [isSavingGroupDetails, setIsSavingGroupDetails] = useState(false);
  const [isSavingGroupCoachees, setIsSavingGroupCoachees] = useState(false);
  const [maxCredit, setMaxCredit] = useState<number | null>(null);

  // Fetch group details
  useEffect(() => {
    getSelectedGroup()
  }, [groupIdValue]);


  const getSelectedGroup = () => {
    if (!user) return;
    getGroup(user?.companyId, groupIdValue)
      .then((group) => {

        if (!group) return;
        setMaxCredit(group.available_credits || Number(group.credits) || 1000)
        setGroupDetails({
          name: group.name,
          credits: group.credits,
          credit_limit_per_user: group.credit_limit_per_user,
          description: group.description,
          program: group.program,
          ai_coach_type: group.ai_coach_type,
          language: group.language,
          group_expiration: group.group_expiration,
          color_code: group.color_code,
        });
        setCoachees(
          group.coachees.map((coachee) => ({
            ...coachee,
            id: uuidv4(),
          }))
        );
      })
      .catch((err) => console.log(err));
  };

  const _handleSubmit = async (
    values: FormikValues,
    actions: FormikHelpers<any>
  ) => {

        console.log(values);
    try {
      await updateGroup(user!.companyId, groupIdValue, {
        group: values,
      });
      toast.success(t("create_group.text26"));
      getSelectedGroup();
      setDetailsEditMode(false);
    } catch (e) {
      const err = e as AxiosError<{ message: string }>;
      const message = err.response?.data?.message ?? "Something went wrong";
      toast.error(message);
    } finally {
      setIsSavingGroupDetails(false);
    }
  };

    const handleUpdateGroupCoachees = async (values: FormikValues, formikHelpers: FormikHelpers<{
        coachees: IGroupCoachee[]
    }>) => {
    setIsSavingGroupCoachees(true);
    console.log(values.coachees);
        const requestData = values.coachees.filter((coachee: IGroupCoachee) => coachee.edited);

    console.log(requestData);
    if (requestData.length == 0) {
      toast.info(t("create_group.text37"));
      setTimeout(() => setIsSavingGroupCoachees(false), 1000);
            return
    }
    try {
      await updateGroupCoachees(user!.companyId, groupIdValue, {
        coachees: requestData,
      });
      toast.success(t("create_group.text26"));
      getSelectedGroup();
            formikHelpers.resetForm()
    } catch (e) {
      const err = e as AxiosError<{ message: string }>;
      const message = err.response?.data?.message ?? "Something went wrong";
      toast.error(message);
    } finally {
      setTimeout(() => setIsSavingGroupCoachees(false), 1000);
    }
  };

    const groupDetailsValidationSchema = (maxCredit: number | null) => Yup.object({
            name: Yup.string()
                .trim()
                .required(t("create_group.text46")),
      credits: Yup.lazy((value) => {
                return Yup.number().max(maxCredit || 1000, `Cannot be more than ${maxCredit} credits`).min(1, 'Credits must be more than 1')
                    .nullable().typeError(t("create_group.text47"))
      }),
      credit_limit_per_user: Yup.lazy((value) => {
                return Yup.number().nullable()
                    .typeError(t("create_group.text47"))
      }),
      program: Yup.object()
        .shape({
          id: Yup.number().nullable(),
          name: Yup.string().nullable(),
          name_fr: Yup.string().nullable(),
        })
        .nullable()
        .default(null),
            group_expiration: Yup.string()
                .nullable()
                .default(null),
            color_code: Yup.string()
                .trim()
                .nullable(),
            description: Yup.string().nullable()
                .trim(),
            logo: Yup.mixed()
                .nullable()
                .default(null),
      language: Yup.object()
        .shape({
          id: Yup.number().nullable(),
          name: Yup.string().nullable(),
          name_fr: Yup.string().nullable(),
        })
        .nullable()
        .default(null),
      ai_coach_type: Yup.object()
        .shape({
          label: Yup.string().nullable(),
          value: Yup.string().nullable(),
        })
        .nullable()
        .default(null),
        }
    )
    if (!groupDetails || !coachees) return <Loader/>;

  return (
    <div className="bg-linen_gradient flex flex-col gap-4 p-6 h-full">
      <Formik
        enableReinitialize={true}
        initialValues={groupDetails}
                    validationSchema={groupDetailsValidationSchema(maxCredit)}
        onSubmit={(values, actions) => {
          setIsSavingGroupDetails(true);
          _handleSubmit(values, actions);
        }}
      >
                    {({resetForm, submitForm, dirty}) => (
                        <Form className="flex flex-col bg-white rounded gap-4 border-gray-50 border-[1px] pb-4 mb-2">

                            <div
                                className="flex flex-row justify-center items-center w-full p-4 py-5 border-b-[2px] border-[#FD0054]">
              <p className="font-bold text-black text-lg">
                {t("create_group.text0_1")}
              </p>
            </div>
            <GroupDetailsForm
              groupDetails={groupDetails}
              editMode={detailsEditMode}
            />
            {detailsEditMode ? (
              <div className="flex justify-end pr-8 gap-2">
                <Button
                  type="button"
                  onClick={() => {
                    submitForm();
                  }}
                  className="hover:shadow-lg w-32 px-4 py-1 flex justify-center border-2 border-red
                                            text-sm font-normal transition-colors duration-500 text-white bg-red"
                >
                  {isSavingGroupDetails
                    ? "Saving..."
                    : t("create_group.text27")}
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    resetForm();
                    setDetailsEditMode(false);
                  }}
                  className="hover:shadow-lg w-32 px-4 py-1 flex justify-center border-2 border-black
                                            text-sm font-normal transition-colors duration-500 text-white bg-black"
                >
                  {t("create_group.text35")}
                </Button>
              </div>
            ) : (
              <div className="flex justify-end pr-8">
                <Button
                  type="button"
                  onClick={() => setDetailsEditMode(true)}
                  className="hover:shadow-lg px-4 py-1 flex justify-center border-2 border-red
                                            text-sm font-normal transition-colors duration-500 text-white bg-red"
                >
                                        <PencilIcon className="w-4 h-4"/> {t("create_group.text32")}
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>

      {user?.role === ERole.SuperAdmin && <GroupAdminForm />}

      <Formik
                    initialValues={{coachees}}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          coachees: Yup.array()
            .of(
              Yup.object().shape({
                                    email: Yup.string().min(4, t("create_group.text43")).required(t("create_group.text42")),
                // these constraints take precedence
                                    first_name: Yup.string().min(3, t("create_group.text43")).required(t("create_group.text42")), // these constraints take precedence
              })
                            ).test('unique', t("create_group.text44"), function (value) {
              return uniqueEmails(value || []);
            })
                            .required(t("create_group.text45")) // these constraints are shown if and only if inner constraints are satisfied
          // .min(3, 'Minimum of 3 coachees required'),
        })}
                    onSubmit={(values, formikHelpers) => handleUpdateGroupCoachees(values, formikHelpers)
        }
      >
                    {({submitForm, errors, touched, values, validateForm, dirty}) => {
          // console.log(errors.coachees,dirty,touched);
          return (

            <Form className="rounded bg-white gap-4 border-gray-50 border-[1px]">
              <NavigationPrompt
                when={dirty}
                message={t("create_group.text0_2")}
              />
              <FieldArray name="coachees">
                {(helper) => {
                                        return <GroupUserTable helper={helper}/>;
                }}
              </FieldArray>
              <div className="px-8 py-2 w-full">
                {/*{errors.coachees && typeof errors.coachees === "string" ? (*/}
                {/*    <p className="text-red text-center">{errors.coachees}</p>*/}
                {/*) : null}*/}
                <Button
                  type="button"
                  onClick={() => {
                    if (errors.coachees || isSavingGroupCoachees) return;
                    submitForm();
                  }}
                  className={twMerge(
                    "ml-auto dashboard-button bg-black py-2 justify-center",
                    errors.coachees ? "cursor-not-allowed opacity-50" : ""
                  )}
                >
                                        {isSavingGroupCoachees ? t("create_group.text39") : t("create_group.text40")}
                </Button>
              </div>
            </Form>
                        )
        }}
      </Formik>
    </div>
  );
};

export default GroupEdit;
