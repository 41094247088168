import React from 'react';
import { useTranslation } from "react-i18next";
import NoDataIcon from "@icons/noDataIcon.png";
const NoDataMessage = () => {
    const { t } = useTranslation('commons')

    return (
        <div style={{ borderRadius: 'inherit' }}
            className=" z-10 absolute inset-0 backdrop-blur-md flex flex-col items-center justify-center text-center p-7 gap-2 overflow-hidden">
            <img src={NoDataIcon} width={54} height={54} />
            <h4 className="text-m">{t('no_data.text1')}</h4>

        </div>
    );
};

export default NoDataMessage;