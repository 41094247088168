import React from 'react';

type ProgressBarProps = {
    maxSteps: number;
    currentStep: number;
};

const ProgressBar = ({
                         maxSteps,
                         currentStep
                     }: ProgressBarProps) => {

    const width = (100 * currentStep) / maxSteps || 0;

    return (
        <div className="absolute w-full h-1.5">
            <div className="w-full bg-gray-200 rounded-full mb-2 z-10">
                <div
                    className="bg-red h-1.5 rounded-full"
                    style={{width: `${width}%`}}
                />
            </div>
        </div>
    );
}

export default ProgressBar;
