import mem from "mem";
import {GET, POST} from "../../utils/lib/axios";
import {COMPANY} from "../../constants/endpoints/company";
import {AUTH} from "../../constants/endpoints/auth";
import {ILoginRequest, ISignUpRequest} from "../../models/IAuth";
import {ERole} from "../../constants/enums";
import {userTimeZone} from "../../utils/helpers/time";
// import {axiosPublic} from "../../utils/lib/axios";
//
//
// const refreshTokenFn = async () => {
//     const session = JSON.parse(localStorage.getItem("session"));
//
//     try {
//         const response = await axiosPublic.post("/user/refresh", {
//             refreshToken: session?.refreshToken,
//         });
//
//         const { session } = response.data;
//
//         if (!session?.accessToken) {
//             localStorage.removeItem("session");
//             localStorage.removeItem("user");
//         }
//
//         localStorage.setItem("session", JSON.stringify(session));
//
//         return session;
//     } catch (error) {
//         localStorage.removeItem("session");
//         localStorage.removeItem("user");
//     }
// };
//
// const maxAge = 10000;
//
// export const memoizedRefreshToken = mem(refreshTokenFn, {
//     maxAge,
// });

const userLogin = (data: ILoginRequest) => {
    data.timezone = userTimeZone()
    return POST(AUTH.post.login, data)
        .then(response => {
            return response.data;
        });
}
const signUp = (data: ISignUpRequest) => {
    return POST(AUTH.post.signup, data)
        .then(response => {
            return response.data;
        });
}
const resetPassword = (email: string) => {
    return POST(AUTH.post.resetPassword, {email})
        .then(response => {
            return response.data;
        });
}
const sendMagicLink = (email: string) => {
    return POST(AUTH.post.sendMagicLink, {email})
        .then(response => {
            return response.data;
        });
}
const switchRole = (role: ERole) => {
    return POST(AUTH.post.switchRole, {new_role: role})
        .then(response => {
            return response.data;
        });
}
export default {userLogin, switchRole, signUp, resetPassword, sendMagicLink}
