import React from "react";
import TagManager from 'react-gtm-module'

import ReactDOM from "react-dom/client";
import { hotjar } from "react-hotjar";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);


const tagManagerArgs = {
    gtmId: 'GTM-T7BTFMV'
}

TagManager.initialize(tagManagerArgs)

hotjar.initialize(Number(process.env.REACT_APP_HOT_JAR_DASHBOARD), 6);


root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
