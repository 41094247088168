import {AIModel} from "./enums";
import {GET, POST, PUT} from "../../../../utils/lib/axios";
import {userTimeZone} from "../../../../utils/helpers/time";

export const getChatHistory = async (id: number): Promise<any> => {
    return GET({path: `/api/clients/me/ai/history/${id}`, private: true})
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to get chat history:", err);
        });
};


export const createAIThread = async (type: AIModel, journeyId?: number) => {
    try {
        const response = await POST(
            {
                path: `/api/clients/me/ai/create?ai_model=${type}${journeyId ? "&journey_id=" + journeyId : ""}`,
                private: true
            }
            , {});
        return {...response.data};
    } catch (err) {
        console.log("Failed to create ai thread:", err);
    }
};

export const processChatPrompt = async (
    id: number,
    prompt: any
): Promise<any> => {
    const lng = localStorage.getItem("I18N_LANGUAGE") || "en";
    return POST({
        path: `/api/clients/me/ai/process-chat/${id}`, private: true
    }, {
        prompt: prompt,
        lng: lng,
    })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to process chat prompt:", err);
        });
};

export const getOverview = async (): Promise<any> => {
    return GET({path: "/api/clients/me/ai/overview", private: true})
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to get overview:", err);
        });
};

export const getJourneyOverview = async (): Promise<any> => {
    return GET({path: "/api/clients/me/ai/overview/journey", private: true})
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to get journey overview:", err);
        });
};

export const createSummary = async (id: number): Promise<any> => {
    return POST({
        path: `/api/clients/me/ai/summary/${id}`, private: true
    }, {})
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to create summary:", err);
        });
};

export const getSummary = async (id: number, controller?: AbortController): Promise<any> => {
    return GET({
        path: `/api/clients/me/ai/summary/${id}`, private: true
    })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to get summary:", err);
        });
};


export const getRecommendations = async (ai_coach_id: number, controller?: AbortController): Promise<any> => {
    return GET({
        path: `api/clients/me/ai/${ai_coach_id}/recommended-contents/`, private: true
    }, {signal: controller?.signal})
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to get recommendations:", err);
        });
};
export const getSuggestions = async (id: number, controller?: AbortController): Promise<any> => {
    return POST({
        path: `/api/clients/me/ai/suggestions/${id}`, private: true
    }, {})
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to get ai chat suggestions:", err);
        });
};
export const createSchedule = async (journey_id: number, datetime: string, controller?: AbortController): Promise<any> => {
    return POST({
            path: `/api/clients/me/ai/schedule/${journey_id}`, private: true
        },
        {
            "datetime": datetime,
            "timezone": userTimeZone()
        }
        , {signal: controller?.signal})
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to schedule:", err);
        });
};
export const updateSchedule = async (id: number, completed: boolean): Promise<any> => {
    return PUT({
            path: `/api/clients/me/ai/schedule/${id}`, private: true
        },
        {
            "completed": completed,
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to schedule:", err);
        });
};
