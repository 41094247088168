type Props = {
  value?: number;
  max?: number;
};

const ReportRate = ({ max = 10, value = 0 }: Props) => {
  const a = value;
  const b = Math.abs(max - value);
  return (
    <div className="flex flex-row gap-1 items-center px-2">
      {new Array(a).fill(0).map((e, i) => (
        <div key={i} className="w-3 h-2 rounded bg-secondary"></div>
      ))}
      {new Array(b).fill(0).map((e, i) => (
        <div key={i} className="w-3 h-2 rounded bg-pink-light"></div>
      ))}
      <div className="text-lg font-bold ml-2">{value}</div>
    </div>
  );
};

export default ReportRate;
