import React from "react";
import {useTranslation} from "react-i18next";
import Spinner from "../../../shared/components/Spinner/Spinner";

const LoadingScreen: React.FC = () => {
    const {t} = useTranslation('commons')

    return <div
        className="z-10 fixed inset-0 w-full backdrop-blur-md flex flex-col items-center justify-end text-center p-7 pb-80 gap-2">
        <Spinner size={"40px"}/>
        <h4 className="md:text-sm text-xs">{t("loading_screen.text1")}</h4>
        <p className="md:text-sm text-xs">{t("loading_screen.text2")}</p>
    </div>
}

export default LoadingScreen;