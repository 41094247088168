import { ERouteType } from "../../../constants/enums";
import { PageLayout } from "../../../layouts";
import { IRoute } from "../../../models/IRoute";
import Coaching from "../pages/Coaching";

const CoachingRoutes: IRoute[] = [
  {
    path: "/coaching",
    layout: PageLayout,
    routeType: ERouteType.public,
    routes: [
      {
        path: ":companyName",
        component: Coaching,
        routeType: ERouteType.public,
      },
    ],
  },
];

export default CoachingRoutes;
