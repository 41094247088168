/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* URL utilities */


const URL_PARAM_EXP = /(\/{.+?})/g;

/**
 * Get an endpoint URL with mapped values from an URL params object.
 * "/endpoint/{someId}"" with url params object { someId: 'test' } would result in "/endpoint/test".
 * "/endpoint/{someId}"" with no url params object would result in "/endpoint".
 * @param {string} url - Endpoint URL template or regular endpoint
 * @param {Object} [pathParams] - URL parameters object
 * @returns {string} - Transformed URL with parsed URL params
 */
export const appendPathParams = (
  url: string,
  pathParams: Record<string, unknown> = {}
): string => {
  const names = url.match(URL_PARAM_EXP);
  if (!names || !names.length) return url;

  const transformedUrl = names.reduce((acc, name) => {
    const paramName = name.replace(/[/{}]/g, '');
    const paramValue = pathParams[paramName];

    const urlValue = `/${paramValue}`;

    if (!paramValue) {
      return acc.replace(name, '');
    }

    return acc.replace(name, urlValue);
  }, url);

  return transformedUrl;
};


