import {FC, useEffect, useState} from 'react'

import Coach from '../../Coach';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useSteps} from 'react-step-builder';
import * as microsoftTeams from "@microsoft/teams-js";
import {ITeamsAuthData} from "../../../../../models/ITeamsAuth";
import '../../../css/Searchbar.css'
import Fuse from "fuse.js";
import searchIcon from '../../../../../assets/img/teams/icons/searchIcon.png'
import questionIcon from '../../../../../assets/img/teams/icons/questionMark.png'
import noResultsIcon from '../../../../../assets/img/teams/icons/no-results.png'
import CoachSkeleton from '../../CoachSkeleton';
import AskCoach from "../../AskCoach";
import {useTranslation} from "react-i18next";
import ICoach from "../../../../../models/ICoach";
import {getCoaches} from "../../../../../services/api/teams/CoachActions";

interface Props {
    bookingData?: any
}

const SelectCoach: FC<Props> = (props) => {
    const {t} = useTranslation('booking')

    const [openAskCoach, setOpenAskCoach] = useState(false);
    const navigate = useNavigate()
    const {jump} = useSteps()
    const [coaches, setCoaches] = useState<ICoach[]>([])
    const [coachesFiltered, setCoachesFiltered] = useState<ICoach[]>([])
    const [recCoaches, setRecCoaches] = useState<ICoach[]>([])

    const [loading, setLoading] = useState(false)
    const [coachId, setCoachId] = useState()

    const [searchParams] = useSearchParams()
    let authUser: ITeamsAuthData = JSON.parse(localStorage.getItem('ch_user') || '{}') as ITeamsAuthData
    const [detailView, setDetailView] = useState(authUser?.company_name === 'HEC')
    console.log(authUser?.company_name === 'HEC')
    const handleOnSearch = (query: string) => {
        if (query == "") {
            setCoachesFiltered(coaches);
        } else {
            const options = {
                includeScore: true,
                keys: ['city', 'email', 'first_name', 'last_name']
            }
            const fuse = new Fuse(coaches, options)
            const results = fuse.search(query)
            const finalResult: ICoach[] = [];
            // if (results.length) {
            results.forEach((item) => {
                finalResult.push(item.item)
            });
            setCoachesFiltered(finalResult);
            // console.log(results);
            // }
            // else {
            //     setCoachesFiltered(coaches);
            // }

        }


    }

    const getCoachId = () => {
        return new Promise((resolve, reject) => {
            microsoftTeams.app.initialize().then(r => {
                microsoftTeams.app.getContext().then(context => {
                    console.log("context");
                    if (context.page.subPageId) {
                        const objStr = decodeURIComponent(context.page.subPageId)
                        const obj = JSON.parse(objStr)
                        console.log(obj)
                        resolve(obj);
                    } else {
                        resolve(null);
                    }
                });
            })

        })

    }

    useEffect(() => {
        setLoading(true);
        if (coachId) {
            if (searchParams.get('reschedule') && searchParams.get('meeting_id')) {
                navigate('/coaching?coach_id=' + coachId + "&reschedule=" + searchParams.get('reschedule') + "&meeting_id=" + searchParams.get('meeting_id'))
            } else {
                navigate('/coaching?coach_id=' + coachId)
            }
            return jump(2)
        }
        let authUser: ITeamsAuthData = JSON.parse(localStorage.getItem('ch_user') || '{}') as ITeamsAuthData
        const companyId = authUser?.company_id
        const clientId = authUser?.user_id

        getCoachId().then((obj: any) => {
            setLoading(false);
            if (obj?.coach_id) {
                setCoachId(obj.coach_id)
                if (obj.reschedule && obj.meeting_id) {
                    searchParams.set('reschedule', obj.reschedule)
                    searchParams.set('meeting_id', obj.meeting_id)
                }
            } else {
                setLoading(true);
                getCoaches(companyId, clientId)
                    .then(response => {
                        setLoading(false);
                        setCoaches(response)
                        setCoachesFiltered(response)
                        if (Object.keys(response).length >= 2) {
                            const tempCoach: ICoach = response[0];
                            if (tempCoach.recommended) {
                                setRecCoaches([response[0], response[1]])
                                if (authUser?.company_name==='HEC'){
                                    setDetailView(true)
                                }else{
                                    setDetailView(false)
                                }
                            } else {
                                setDetailView(true)
                            }
                        }
                    })
                    .catch(err => {
                        console.error(err.response)
                    })
            }
        })


    }, [coachId]);

    return (
        <>
            {loading
                ?
                <div className="inline-flex justify-center w-full py-5 text-center">

                    <div className="flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             style={{margin: "auto", background: "0 0", display: "block", shapeRendering: "auto"}}
                             width="24" height="24" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <circle cx="50" cy="50" fill="none" stroke="#fd0054" strokeWidth="8" r="35"
                                    strokeDasharray="164.93361431346415 56.97787143782138">
                                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite"
                                                  dur="1.1363636363636365s" values="0 50 50;360 50 50" keyTimes="0;1"/>
                            </circle>
                        </svg>
                        <p className="ml-3 font-light"> {t('select_coach.text_1')}</p>
                    </div>
                </div>
                :
                <div className="flex flex-col relative w-full">
                    <div onClick={() => setOpenAskCoach(true)}
                         className="bg-pink-200 hover:bg-pink-500 cursor-pointer rounded-full fixed w-14 h-14 flex justify-center items-center right-4 bottom-6"
                         style={{boxShadow: "0px 0px 20px rgba(253, 0, 84, 0.08)"}}>
                        <img src={questionIcon} className="h-7"/>
                    </div>
                    <div style={{display: openAskCoach ? "flex" : "none", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.4)"}}
                         className="fixed right-8 bottom-6 z-20  bg-white rounded">
                        <AskCoach setClose={setOpenAskCoach}/>
                    </div>
                    <div style={{display: detailView ? '' : 'none'}} className={"w-full flex justify-end"}>
                        <div className="searchbox">
                            <input onChange={(e) => handleOnSearch(e.target.value)} type="text"
                                   className="searchbox__input" placeholder="Filter by keywords"/>
                            <img src={searchIcon} className="searchbox__icon"/>

                        </div>
                    </div>
                    <div style={{display: detailView ? 'none' : ''}}
                         className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-8 sm:gap-x-6 ">
                        <div style={{
                            padding: "20px",
                            borderRadius: "15px 15px 10px 10px",
                            backgroundColor: "#F4D4D4"
                        }} className="flex flex-col">
                            <span className="font-bold text-center p-2 pb-4" style={{
                                color: "#FD0054"
                            }}>
                                {authUser?.company_name === 'HEC' ? t('select_coach.text_5_1') : t('select_coach.text_5')}
                            </span>
                            <ul className="grid w-full grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6">
                                {recCoaches.map((coach, index) => {
                                    return <li key={index} className="relative">
                                        <Coach coach={coach}/>
                                    </li>
                                })}
                            </ul>

                        </div>
                        <div style={{
                            padding: "20px"
                        }} className="flex flex-col">
                            <span className="font-bold text-center p-2 pb-4" style={{
                                color: "transparent"
                            }}>{authUser?.company_name === 'HEC' ? t('select_coach.text_5_1') : t('select_coach.text_5')}</span>
                            <ul className="h-full grid w-full grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6 relative">

                                <button onClick={() => setDetailView(true)} style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: '50%',
                                    transform: " translate(-50%, -50%)",

                                }}
                                        className="bg-black hover:opacity-75 absolute px-5 py-3 text-center text-white rounded z-10">
                                    {t('select_coach.text_6')}
                                </button>
                                {[1, 2].map((index) => {
                                    return <li key={index} className="relative">
                                        <CoachSkeleton/>
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>

                    <div style={{display: detailView ? '' : 'none'}}
                         className={coachesFiltered.length == 0 ? 'justify-between ' : 'justify-center ' + 'flex items-stretch  w-full py-5'}>
                        {coachesFiltered.length == 0 ? <>
                                <img width="300" height="300" className="mx-auto" src={noResultsIcon}/>
                                <h3 className="text-center mt-20">No results found</h3>:
                            </> :
                            <ul className="grid w-full grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 xl:gap-x-8">
                                {coachesFiltered.map((coach, index) => {
                                    return <li key={index} className="relative">
                                        <Coach coach={coach}/>
                                    </li>
                                })}

                            </ul>}
                    </div>
                </div>
            }
        </>
    )
}

export default SelectCoach;
