import React from "react";

interface TagProps {
    label: string;
    tags: string[];
    primary? : string;
}

const TagList: React.FC<TagProps> = ({ label, tags, primary = '' }) => (
    <div className="mb-4">
        <div className="flex flex-col md:flex-row md:space-x-4">
            <h6 className="font-semibold mb-2 md:mb-0 text-sm">{label}</h6>
            <div className="flex flex-wrap gap-2 mt-2 md:mt-0">
                {tags.map(tag => (
                    <span
                        key={tag}
                        className={`${tag == primary ? 'bg-purple-200' : 'bg-white'}  rounded py-0.5 px-2 text-xs font-normal`} >
                        {tag}
                    </span>
                ))}
            </div>
        </div>
    </div>
);

export default TagList;
