import React, {FC, useEffect, useState} from "react";

import {useTranslation} from "react-i18next";

import UsageCard from "../../components/UsageCard";

import i18n from "../../../../utils/lib/i18n";
import ENG from "../../locale/eng/performance.json";
import Fr from "../../locale/fr/performance.json";
import {useNavTitle} from "@hooks/useNavTitle";

import {
    getActions,
    getCategories,
    getGoalAchieved,
    getUsage,
} from "../../../../services/api/companyApi";
import {getMaxValueInInnerKey,} from "../../../../utils/helpers/object";
import useAuth from "@hooks/useAuth";

import attendanceImg from "@img/dashboard/attendance.png";
import satisfactionImg from "@img/dashboard/satisfaction.png";
import goalImg from "@icons/targetIcon.png";
import FilterBar from "../../components/FilterBar";
import {Filters} from "../../../../constants/types";
import CoacheeSection from "./Components/CoacheeSection";
import OrganizationSection from "./Components/OrganizationSection";
import PeerSection from "./Components/PeerSection";
import {downloadAsImage} from "../../../../utils/helpers/download";

enum DataState {
    NO_DATA = 'NO_DATA',
    NO_DATA_WITH_FILTERS = 'NO_DATA_WITH_FILTERS',
    DATA = 'DATA'
}

const Performance = ({...props}) => {
    i18n.addResourceBundle("en", "performance", ENG);
    i18n.addResourceBundle("fr", "performance", Fr);
    const {t} = useTranslation("performance");

    useNavTitle(t(`layout:sidebar.${props.navTitle}`));

    const [usage, setUsage] = useState<Record<any, any>>({
        attendance: 0,
        satisfaction: 0,
    });
    const [goalAchieved, setGoalAchieved] = useState<Record<any, any>>({
        goal_achieved: "",
    });

    const {user} = useAuth();

    const [categories, setCategories] = useState<Record<any, any>[]>([]);
    const [actions, setActions] = useState<Record<any, any>[]>([]);
    const [appliedActions, setAppliedActions] = useState<number>(0);
    const [filters, setFilters] = useState<Filters>({});
    const [isFiltered, setIsFiltered] = useState(false);

    const maxCategoryValue =
        categories.length > 0
            ? getMaxValueInInnerKey(categories, "count").count + 1
            : 10;

    const additonalCategories = [
        {
            "en": "Leadership",
            "fr": "Leadership",
            "category_en": "Leadership",
            "category_fr": "Authenticité",
            "count": 41,
            "key": "leadership"
        },
        {
            "en": "Stress & Anxiety",
            "fr": "Stress & Anxiété",
            "category_en": "Stress & Anxiety",
            "category_fr": "Résilience",
            "count": 35,
            "key": "stress_&_anxiety"
        },
        {
            "en": "Communication",
            "fr": "Communication",
            "category_en": "Communication",
            "category_fr": "Assertivité",
            "count": 22,
            "key": "management"
        },
        {
            "en": "Development",
            "fr": "Développement de Carrière\n",
            "category_en": "Development",
            "category_fr": "Sens au travail",
            "count": 18,
            "key": "development"
        },

    ]

    useEffect(() => {
        console.log(filters);
        getGoalAchieved(user?.companyId!, filters)
            .then((res) => {
                setGoalAchieved(res);
            })
            .catch((e) => {
                console.log("api error");
            });
        getUsage(user?.companyId!, filters)
            .then((res) => {
                setUsage(res);
            })
            .catch((e) => {
                console.log("api error");
            });
        getCategories(user?.companyId!, filters)
            .then((res) => {
                if (res && res.categories) {
                    setCategories(res.categories.slice(0, 5));
                } else {
                    setCategories([])
                }
            })
            .catch((e) => {
                console.log("api error");
            });
        getActions(user?.companyId!, filters)
            .then((res) => {
                setActions(res.actions);
                setAppliedActions(res.applied_actions);
            })
            .catch((e) => {
                console.log("api error");
            });
    }, [filters]);

    const SectionTitle: FC<{ title: string }> = ({title}) => {
        return (
            <div className="m-5 mb-0">
                <hr/>
                <span className="py-0 text-black font-lexend font-bold text-xl">
          {title}
        </span>
            </div>
        );
    };

    const get_section_grid = (charts: any[]) => {
        charts = charts.filter((component) => component);
        const rows: JSX.Element[] = [];

        for (let i = 0; i < charts.length; i += 2) {
            const pair = charts.slice(i, i + 2);
            const row = (
                <div key={i} className="flex py-5 pb-0 gap-5 lg:flex-row flex-col">
                    {pair.map((component, index) => (
                        <div key={index} className="md:w-1/2 items-center">
                            {component}
                        </div>
                    ))}
                    {i + 1 == charts.length && pair.length == 1 && (
                        <div className="md:w-1/2"/>
                    )}
                </div>
            );

            rows.push(row);
        }
        return rows;
    };

    const get_data_state = (isNoData: boolean, isFiltered: boolean) => {
        if (isNoData && !isFiltered) { return DataState.NO_DATA }
        if (isNoData && isFiltered) { return DataState.NO_DATA_WITH_FILTERS }
        else { return DataState.DATA }
    }

    return (
        // @ts-ignore
        <div className="" >
            <FilterBar toPDF={() => downloadAsImage('PerformanceContainer', 'Performance')} setFilters={setFilters} setisFiltered={setIsFiltered}/>
            <div id={'PerformanceContainer'}>
                <div className="flex gap-4 xl:flex-row flex-col">
                    <UsageCard
                        img={attendanceImg}
                        title={t("text1_1")}
                        percentage={usage?.attendance}
                        tooltip={t("text12", {attendance: usage?.attendance})}
                        displayInfo={true}
                        displayChart={true}
                        isFiltered={isFiltered}
                        noDataText={t("text38")}
                    />
                    <UsageCard
                        img={satisfactionImg}
                        title={t("text17")}
                        percentage={appliedActions}
                        tooltip={t("text37")}
                        displayInfo={true}
                        displayChart={true}
                        isFiltered={isFiltered}
                        noDataText={t("text38")}
                    />
                    <UsageCard
                        img={goalImg}
                        title={t("text32")}
                        percentage={goalAchieved?.goal_achieved}
                        tooltip={t("text33")}
                        displayInfo={true}
                        displayChart={true}
                        isFiltered={isFiltered}
                        noDataText={t("text38")}
                    />
                </div>

                <CoacheeSection filters={filters} isFiltered={isFiltered} user={user} actions={actions} categories={categories} maxCategoryValue={maxCategoryValue} SectionTitle={SectionTitle} getSectionGrid={get_section_grid} getDataState={get_data_state} />
                {!['Enedis-Pilot', 'Enedis'].includes(user?.companyName!) && <PeerSection filters={filters} isFiltered={isFiltered} user={user} maxCategoryValue={maxCategoryValue} SectionTitle={SectionTitle} getSectionGrid={get_section_grid} getDataState={get_data_state}/>}
                <OrganizationSection filters={filters} isFiltered={isFiltered} user={user} SectionTitle={SectionTitle} getSectionGrid={get_section_grid} getDataState={get_data_state}/>
            </div>
        </div>
    );
};

export default Performance;
