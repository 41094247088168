import {addDays} from "date-fns";
import moment from "moment";
 import React, {Dispatch, forwardRef, SetStateAction, useEffect, useState} from 'react'
import {ChevronLeftIcon, ChevronRightIcon, FilterIcon} from '@heroicons/react/solid'
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import DropdownWrapper from "./DropdownWrapper";
import CalenderButton from "./Table/CalenderButton";
import {CalendarSelectionType} from "../../models";
import {Colors} from "../../constants/enums";
import {enUS,fr,de} from "date-fns/locale";
import { useTranslation } from "react-i18next";
import ENG from "../../locales/eng/general.json";
import Fr from "../../locales/fr/general.json";

export default function MyDataRange({
                                        state,
                                        setState,
                                        onApply,
                                        placeholder,
                                        style
                                    }: {
    state: CalendarSelectionType[];
    setState: Dispatch<SetStateAction<CalendarSelectionType[]>>;
    onApply: (startDate: any, endDate: any) => void
    placeholder?: string;
    style?: string;
}) {
    const {i18n}=  useTranslation();
    i18n.addResourceBundle("en", "general", ENG);
    i18n.addResourceBundle("fr", "general", Fr);
    const { t } = useTranslation("general");
    const languageToLocaleMap: { [key: string]: Locale } =
    {en: enUS,
    de: de,
    fr: fr,}
    const currentLocale = languageToLocaleMap[i18n.language] || enUS;
    let value = moment(state[0].startDate?.toString()).format("DD-MM-YYYY") + " : " + moment(state[0].endDate?.toString()).format("DD-MM-YYYY")
    let placeholderText = state[0].startDate ? value : (placeholder ? placeholder : value)


    return (<>
        <div className="m-auto">
            <DropdownWrapper button={
                <div className="flex gap-3 sm:flex-row flex-col">
                    <CalenderButton placeholder="Date" type="text" className={style}
                                    value={placeholderText}
                                    readOnly/>

                </div>}
                             body={<div>
                                 <DateRange editableDateInputs={true}
                                            onChange={(item: any) => setState([item.selection])}
                                            moveRangeOnFirstSelection={false}
                                            rangeColors={[Colors.red]}
                                            shownDate={state[0].endDate!}
                                            locale={currentLocale}
                                            startDatePlaceholder={t("date_range.start_placeholder")}
                                            endDatePlaceholder={t("date_range.end_placeholder")}

                                     // @ts-ignore
                                            ranges={state}/>
                                 <div className="p-2">
                                     <button onClick={() => onApply(state[0].startDate!, state[0].endDate!)}
                                             className="bg w-full bg-black text-white rounded-md p-2 hover:shadow-lg">
                                        {t("date_range.apply")}
                                     </button>
                                 </div>
                             </div>}
                             bodyWrapperClassName=" "/>
        </div>

    </>)
}
