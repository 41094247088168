import React, {ReactNode, useCallback, useEffect, useRef, useState} from "react";


type Props = {
    children: ReactNode
    currentStep: number
    displayStep?: boolean
}
const Container = ({children, currentStep,displayStep=true}: Props) => {

    return <div className="h-full flex flex-col justify-center w-full gap-6 items-center py-4 p-10 bg-warm relative overflow-auto">
        {displayStep &&
            <div className="absolute flex items-center justify-center w-full h-2 top-0 p-8">
                <div className="grid w-full grid-cols-3 gap-3">
                    <button className={"h-2 rounded w-30 bg-[#BDACE8] " + (currentStep !== 3 ? 'opacity-10' : '')}></button>
                    <button className={"h-2 rounded w-30 bg-[#BDACE8] " + (currentStep !== 4 ? 'opacity-10' : '')}></button>
                    <button className={"h-2 rounded w-30 bg-[#BDACE8] " + (currentStep !== 5 ? 'opacity-10' : '')}></button>
                </div>
            </div>
        }
        <div className="flex items-center justify-center w-full h-full px-10 pt-4">
            {children}
        </div>
    </div>
}

export default Container
