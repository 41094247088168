import React, {ComponentType} from 'react';
import {Navigate, Route} from "react-router-dom";
import IAuthRouterProp from "../../models/IAuthRouter";

const PublicRoute  = ({Component,props}:IAuthRouterProp  ) => {
  const auth = true; //your logic

  return auth ? <Component {...props}/> : <Navigate to="/login" />
};

export default PublicRoute;
