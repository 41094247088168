import Select, {GroupBase, OptionsOrGroups, StylesConfig, components} from "react-select";
import React from "react";
import {FieldHookConfig, useField} from "formik";
import ReactTooltip from "react-tooltip";
import {MdOutlineRecordVoiceOver} from "react-icons/md";
//
// export interface ColourOption {
//     readonly value: string;
//     readonly label: string;
//     readonly color: string;
//     readonly isFixed?: boolean;
//     readonly isDisabled?: boolean;
// }
//
// export const colourOptions: readonly ColourOption[] = [
//     {value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true},
//     {value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true},
//     {value: 'purple', label: 'Purple', color: '#5243AA'},
//     {value: 'red', label: 'Red', color: '#FF5630', isFixed: true},
//     {value: 'orange', label: 'Orange', color: '#FF8B00'},
//     {value: 'yellow', label: 'Yellow', color: '#FFC400'},
//     {value: 'green', label: 'Green', color: '#36B37E'},
//     {value: 'forest', label: 'Forest', color: '#00875A'},
//     {value: 'slate', label: 'Slate', color: '#253858'},
//     {value: 'silver', label: 'Silver', color: '#666666'},
// ];
// const colourStyles: StylesConfig<ColourOption, true> = {
//     control: (styles) => ({...styles, backgroundColor: 'white'}),
//     option: (styles, {data, isDisabled, isFocused, isSelected}) => {
//
//         return {
//             ...styles,
//             backgroundColor: isDisabled
//                 ? undefined
//                 : isSelected
//                     ? data.color
//                     : isFocused
//                         ? data.color
//                         : undefined,
//             color: isDisabled
//                 ? '#ccc'
//                 : isSelected
//                     ? data.color
//                     : data.color,//dropdown background
//             cursor: isDisabled ? 'not-allowed' : 'default',
//
//             ':active': {
//                 ...styles[':active'],
//                 backgroundColor: !isDisabled
//                     ? isSelected
//                         ? data.color
//                         : data.color
//                     : undefined,
//             },
//         };
//     },
//     multiValue: (styles, {data}) => {
//
//         return {
//             ...styles,
//             backgroundColor: '#ccc', //chip background
//         };
//     },
//     multiValueLabel: (styles, {data}) => ({
//         ...styles,
//         color: data.color,
//     }),
//     multiValueRemove: (styles, {data}) => ({
//         ...styles,
//         color: data.color,
//         ':hover': {
//             backgroundColor: data.color,
//             color: 'white',
//         },
//     }),
// };

const colourStyles: StylesConfig<any, true> = {
    control: (styles) => ({...styles, backgroundColor: 'white'}),
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? 'rgba(231,93,93,0.84)'
                    : isFocused
                        ? 'rgba(231,93,93,0.35)'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'white'
                    : 'black'
            ,
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? 'rgba(203,61,61,0.84)'
                        : 'rgba(196,67,67,0.84)'
                    : undefined,
            },
        };
    },

    multiValue: (styles, {data}) => {
        return {
            ...styles,
            backgroundColor: 'rgba(255, 86, 48, 0.1)',
        };
    },
    multiValueLabel: (styles, {data}) => ({
        ...styles,
        color: 'rgb(0,0,0)',
    }),
    multiValueRemove: (styles, {data}) => ({
        ...styles,
        color: 'rgb(255, 86, 48)',
        ':hover': {
            backgroundColor: 'rgba(231,93,93,0.84)',
            color: 'white',
        },
    }),
};

interface Props {
    options: OptionsOrGroups<any, GroupBase<any>> | undefined;
    isMulti?: boolean;
    isDisabled?: boolean;
    labelKey?: string;
    valueKey?: string;
    placeholder?: string;
    primaryValueAvailable?: boolean
    primaryValueText?: string
    tooltip?: string
}


// we can use any object with even multiple keys, it will return with value


export default ({
                    options,
                    isDisabled = false,
                    isMulti = true,
                    labelKey = 'label',
                    valueKey = 'value',
                    primaryValueAvailable = false,
                    primaryValueText = '',
                    tooltip = '',
                    ...props
                }: Props & FieldHookConfig<string>) => {
    const [field, meta, helpers] = useField(props.name);

    const handleChange = (selected: any) => {
        const hasPrimary = selected.some((item: any) => item.primary);

        let updatedSelection = selected.map((item: any) => ({
            ...item,
            primary: item === selected.find((sel: any) => sel.primary) ? true : false
        }));

        if (!hasPrimary && selected.length > 0) {
            updatedSelection = updatedSelection.map((item: any, index: number) => ({
                ...item,
                primary: index === 0
            }));
        }

        helpers.setValue(updatedSelection);
    }


    const handleTagClick = (option: any, e: any) => {
        e.stopPropagation();
        const updatedSelection = field.value.map((lang: any) =>
            lang[valueKey] === option[valueKey]
                ? { ...lang, primary: true }
                : { ...lang, primary: false}
        );
        helpers.setValue(updatedSelection);
    }

    const CustomMultiValue = (props: any) => {
        const {data, removeProps} = props;
        const isPrimary = field.value.find((item: any) => item[valueKey] === data[valueKey])?.primary;

        return (
            <div
                {...removeProps}
                style={{
                    padding: '2px',
                    margin: '2px',
                    borderRadius: '3px',
                    backgroundColor: 'rgba(255, 86, 48, 0.1)',
                    color: 'black',
                    display: 'flex',
                    alignItems: 'center',
                }}

            >
                <div style={{fontSize: '85%'}} onClick={(e) => e.stopPropagation()}>
                    {data[labelKey]} {isPrimary &&  <span className="px-0.5 rounded text-center" style={{backgroundColor: "rgb(255, 86, 48)", color: 'white', fontSize: '80%'}}>
                  {primaryValueText}
                </span>}
                    <ReactTooltip
                        className="w-30 bg-black backdrop-opacity-100 whitespace-pre-line"
                        delayHide={300}
                        id="tooltip-native"
                        place="bottom"
                        effect="solid"
                        html={true}
                    >
                        {tooltip}
                    </ReactTooltip>
                </div>
                {!isPrimary && <div className="relative flex items-center" onClick={(e) => handleTagClick(data, e)}>
                    <div className="relative ml-1 p-0.5 bg-amber-500 rounded hover:bg-amber-700">
                        <MdOutlineRecordVoiceOver className="w-3.5 h-3.5" data-for="tooltip-native" data-tip={"hover on me will keep the tooltip"}/>
                    </div>
                </div>}
                <div className="p-1">
                    <components.MultiValueRemove {...props}>
                        <svg
                            width="14"
                            height="14"
                            viewBox="0 0 20 20"
                            fill='rgb(255, 86, 48)'
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
                        </svg>
                    </components.MultiValueRemove>
                </div>
            </div>
        );
    };

    const customComponents = {
        MultiValue: CustomMultiValue,
    };

    return <div className="">
        {primaryValueAvailable ? (<Select
            closeMenuOnSelect={false}
            {...field}
            isClearable
            onChange={handleChange}
            isLoading={options?.length == 0}
            // defaultValue={[colourOptions[0], colourOptions[1]]}
            getOptionLabel={(option) => option[labelKey]}
            getOptionValue={(option) => option[valueKey]}
            isDisabled={isDisabled}
            // @ts-ignore
            isMulti={isMulti}
            options={options}
            onBlur={() => helpers.setTouched(true)}
            placeholder={props.placeholder}
            components={customComponents}
        />) :
            (<Select
            closeMenuOnSelect={true}
            {...field}
            isClearable
            onChange={value => helpers.setValue(value)}
            isLoading={options?.length == 0}
            // defaultValue={[colourOptions[0], colourOptions[1]]}
            getOptionLabel={(option) => option[labelKey]}
            getOptionValue={(option) => option[valueKey]}
            isDisabled={isDisabled}
            // @ts-ignore
            isMulti={isMulti}
            options={options}
            styles={colourStyles}
            onBlur={()=>helpers.setTouched( true)}
            placeholder={props.placeholder}
            />)}
        {meta.touched && meta.error ? (
            <span className="text-red font-extralight">{meta.error}</span>
        ) : null}</div>

};
