import Resources from "../pages/Resources";
import CoachVideoPlayer from "../pages/CoachVideoPlayer";
import {ImpactAssessment} from "../../dashboard/components/ImpactAssessment";
import {AssessmentFr} from "../pages/AssessmentFr";
import FAQModal from "../pages/FAQModal";
import ConfirmationModal from "../pages/ConfirmationModal";
import {Assessment} from "../pages/Assessment";
import {IRoute} from "../../../models/IRoute";
import Reports from "../pages/Reports";
import {Feedback360Typeform} from "../pages/Feedback360Typeform";
import BookSession from "../pages/BookSession";
import ReviewModal from "../pages/ReviewModal";
import MyNote from "../pages/MyNote";
import AssessmentScore from "../pages/AssessmentScore";
import Feedback360Emails from "../pages/Feedback360Emails";
import NoAccessPopUp from "../pages/NoAccessPopUp";
import Welcome from "../pages/Welcome";
import Home from "../pages/Home";
import NoAccess from "../pages/NoAccess";
import {ERouteType} from "../../../constants/enums";
import BookingSuccess from "../pages/BookingSuccess";
import TeamsLayout from "../../../layouts/TeamsLayout";

const TeamsRoutes: IRoute[] = [
    {
        path: "/",
        layout: TeamsLayout,
        routeType: ERouteType.public,
        routes: [
            {
                path: 'resources',
                name: 'Resources',
                component: Resources,
                routeType: ERouteType.teamsPrivate,
            }, {
                path: 'play-video',
                name: 'Coach Video',
                component: CoachVideoPlayer,
                routeType: ERouteType.public,

            }, {
                path: 'no-access',
                name: 'No Access',
                component: NoAccess,
                routeType: ERouteType.public,

            },
            {
                path: 'no-access-popup',
                name: 'No Access popup',
                component: NoAccessPopUp,
                routeType: ERouteType.public,


            },
            {
                path: 'reports',
                name: 'Reports',
                component: Reports,
                routeType: ERouteType.teamsPrivate,
            },
            {
                path: 'faq',
                name: 'FAQ',
                component: FAQModal,
                routeType: ERouteType.public,

            },
            {
                path: 'review',
                name: 'Review',
                component: ReviewModal,
                routeType: ERouteType.public,

            },
            {
                path: 'coaching',
                name: 'Coaching',
                component: BookSession,
                routeType: ERouteType.teamsPrivate,
            },
            {
                path: 'booking/success',
                name: 'Booking Success',
                component: BookingSuccess,
                routeType: ERouteType.public,

            },
            {
                path: 'welcome',
                name: 'Welcome',
                component: Welcome,
                routeType: ERouteType.public,

            },
            {
                path: 'confirm',
                name: 'Confirmation Dialog',
                component: ConfirmationModal,
                routeType: ERouteType.public,

            }, {
                path: 'assessment',
                name: 'Assessment',
                component: Assessment,
                routeType: ERouteType.public,

            }, {
                path: 'assessment-fr',
                name: 'Assessment-FR',
                component: AssessmentFr,
                routeType: ERouteType.public,

            },
            {
                path: 'impact-assessment',
                name: 'Impact Assessment',
                component: ImpactAssessment,
                routeType: ERouteType.public,

            },
            {
                path: 'my-note',
                name: 'My Note',
                component: MyNote,
                routeType: ERouteType.public,

            },
            {
                path: 'assessment-score',
                name: 'Assessment Score',
                component: AssessmentScore,
                routeType: ERouteType.teamsPrivate,
            },
            {
                path: 'feedback-emails',
                name: 'FeedbackEmails',
                component: Feedback360Emails,
                routeType: ERouteType.teamsPrivate,
            },
            {
                path: 'feedback-form',
                name: 'Feedback Form',
                component: Feedback360Typeform,
                routeType: ERouteType.teamsPrivate,
            },

            {
                index: true,
                name: 'Home',
                component: Home,
                routeType: ERouteType.teamsPrivate,
            },
        ]
    }

]

export default TeamsRoutes;
