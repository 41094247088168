import moment from "moment";

import { CalendarIcon } from "@heroicons/react/outline";

interface Props {
  sessionId: number;
  datetime: Date;
  sessionNo: number;
  status?: string;
  statusColour?: string;
  selected: boolean;
  setSelected: (index: number) => void;
}

const ReportSessionItemMini = (props: Props) => {
  return (
    <div
      onClick={() => props.setSelected(props.sessionId)}
      className={`rounded-md bg-white p-2 border-2 border-transparent 
        ${
          props.selected
            ? "border-red after:top-4 after:border-2 triangle-nouch"
            : "hover:border-gray-400 hover:after:top-4 hover:after:border-2 hover:triangle-nouch"
        }`}
    >
      <div className="flex gap-3 justify-between">
        <p className="icon-text">
          <CalendarIcon className="text-red" />
          {moment(props.datetime).format("ll")}
        </p>
      </div>
      <hr className="-mx-2 border-[0.2px]" />
      <div className="">
        <h5 className="font-medium">Session {props.sessionNo}</h5>
      </div>
      <hr className="-mx-2 border-[0.2px]" />
      <p
        style={{ color: props.statusColour }}
        className={`flex items-center gap-1`}
      >
        <span
          style={{ backgroundColor: props.statusColour }}
          className={`rounded-full w-5 h-5 `}
        />
        {props.status}{" "}
      </p>
    </div>
  );
};

export default ReportSessionItemMini;
