type Props = {
  label?: string;
};

const ReportChip = ({ label }: Props) => {
  return (
    <div className="py-1 px-3 rounded-sm bg-pink-light w-fit">{label}</div>
  );
};

export default ReportChip;
