import {useEffect} from "react";

import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

import {ERole} from "../../constants/enums";
import useAuth from "@hooks/useAuth";
import {useConfig} from "../../utils/providers/AppConfigProvider";

const Default = () => {
    const navigate = useNavigate();
    const {user} = useAuth();

    useEffect(() => {
        const init = async () => {
            if (user?.role == ERole.HrAdmin || user?.role == ERole.GroupAdmin || user?.role == ERole.SuperAdmin) {
                navigate("/dashboard/coaching");
            } else if (user?.role == ERole.ItAdmin) {
                navigate("/dashboard/integration");
            } else if (user?.role == ERole.Coach) {
                navigate("/dashboard/reports");
            } else if (user?.role == ERole.Coachee) {
                if (user?.companyName == 'JulhietSterwenEU') {
                    navigate("/dashboard/coach/booking");
                } else {
                    navigate("/dashboard/home");
                }
            } else {
                toast.error(
                    "Permission denied.! \n Please login with your HR Admin account."
                );
            }
        }
        init()
    }, [user]);
    return <></>;
};

export default Default;
