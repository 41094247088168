import React from "react";

import { useTranslation } from "react-i18next";

import bookingSuccessImage from "@img/coaching/img/bookingSuccess.png";

type Props = {
  onDone: () => void;
  onScheduleAnother: () => void;
  externalVisit?: boolean;
  isReschedule?: boolean;
};

const BookingSuccess: React.FC<Props> = ({
  onDone,
  onScheduleAnother,
  externalVisit,
  isReschedule,
}) => {
  const { t } = useTranslation("booking");

  return (
    <div className="w-full flex flex-row justify-center items-center flex-1">
      <div className="flex flex-col justify-center items-center gap-8">
        <div className="flex justify-center items-center">
          <div className="basis-3/12">
            <img src={bookingSuccessImage} alt="Booking Successfull" />
          </div>
        </div>
        <p className="font-bold text-3xl font-dm_sans text-black text-center">
          {t("booking_success.thank_you")}
          <br />
          {t("booking_success.booking_confirmed")}
        </p>
        <div className="flex flex-row gap-4">
          {!externalVisit && (
            <button
              className="w-48 rounded-md py-3 text-white border-2 bg-black text-sm font-bold h-max"
              onClick={onDone}
            >
              {t("booking_success.done")}
            </button>
          )}
          {!isReschedule && (
            <button
              className="w-48 rounded-md py-3 text-white border-2 bg-black text-sm font-bold h-max"
              onClick={onScheduleAnother}
            >
              {t("booking_success.schedule_another")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingSuccess;
