import img_action_plan from "./../../../../../../assets/img/mentoring/Individual-coaching.png"
import {SummaryData} from "./types";
import {useTranslation} from "react-i18next";
import {createSummary, getSummary} from "../../../../../../services/api/AiBookingActions";
import React, {useEffect, useState} from "react";
import Loader from "./Loader";


type Props = {
    onClickNext: () => void,
    sessionId: number | undefined;
};

const SummaryContent = ({onClickNext, sessionId}: Props) => {
    const {t} = useTranslation("ai_booking");

    const [summary, setSummary] = useState<SummaryData>()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        loadSummary()
        return ()=>summaryAbortController.abort()
    }, []);

    let summaryAbortController = new AbortController();


    const action_plan = summary?.summary
    const actionPlanSteps = summary?.actions
    const loadSummary = () => {
        if (!sessionId) return
            setLoading(true)
            getSummary(sessionId, summaryAbortController).then((data) => {
                if(!data){
                    createSummary(sessionId, summaryAbortController).then(data => {
                        setSummary(data)
                    }).finally(() => setLoading(false))
                } else {
                     setSummary(data)
                    setLoading(false)
                }
            })
    }

    return (

        <div
            className="flex flex-col w-full h-full relative gap-4 p-5
            rounded-xl border-warm-dark text-black text-sm overflow-auto justify-between">
                {loading &&
                    <div
                        className={`absolute w-full h-full flex items-center transition-all ease-in-out duration-1000 bg-white z-40 bg-opacity-75`}>
                        <Loader isFullscreen={false} spinnerColor="red"/>
                    </div>
                }

            <div>
            <div className="flex flex-row items-start">
                    <p className="font-bold">{t("summary.title")}</p>
            </div>

            <div className="flex flex-col gap-5 pt-20 py-4">
                {
                    !summary && <p className="text-center">{t("summary.not_available")}</p>
                }


                {
                    action_plan &&
                    <div className="bg-warm-medium w-full px-3 py-3 text-left rounded-xl">
                        <p>{action_plan}</p>
                        {/*<p>{readMore ? action_plan.slice(0, 150) : action_plan}</p>*/}
                        {/*{*/}
                        {/*    readMore !== undefined && <p className="text-red" onClick={() => setReadMore(!readMore)}>{readMore ? t("summary.read_more") : t("summary.read_less")}</p>*/}
                        {/*}*/}
                    </div>
                }

                {
                    actionPlanSteps && actionPlanSteps.length !== undefined && actionPlanSteps.length > 0 &&
                    <div className="flex flex-1 flex-row items-center justify-start gap-5 w-full">
                        <img src={img_action_plan} className="h-32 w-auto" alt="action plan"/>
                        <div>
                            <p className="font-medium py-2">{t("summary.action_plan")}</p>
                            <ul className="list-disc text-left">
                                {
                                    actionPlanSteps.map((step) => <li>{step}</li>)
                                }
                            </ul>
                        </div>
                    </div>

                }
            </div>
            </div>

            <div className="w-full flex flex-row justify-end">
                <div
                    className={`flex flex-col gap-0.5 rounded py-3 bg-red w-fit`}
                    onClick={()=>onClickNext()}>
                    <p className={`flex flex-row cursor-pointer font-dm_sans m-0 text-xs px-4 font-semibold text-white`}>
                        {t('summary.next')}
                    </p>
                </div>
            </div>



        </div>
    );
};

export default SummaryContent;
