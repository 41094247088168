import React, {HTMLAttributes} from 'react';

interface Prop {
    img: string;
    className?: string
}

const BoxIcon = ({img, className = 'bg-warm-light'}: Prop) => {
    return (
        <div className={"p-5 rounded-2xl my-auto " + className}>
            <img width="35" height="35" src={img}/>
        </div>
    );
};

export default BoxIcon;
