import Card from "../../../components/Card";
import { useTranslation } from "react-i18next";

import date_icon from "@img/dashboard/reports/icons/date.svg";
import time_icon from "@img/dashboard/reports/icons/time.svg";

import "./ReportSideCard.css";

type Props = {
  date?: string;
  time?: string;
  session?: string;
  coach?: string;
  coachImage?: string | null;
  selected?: boolean;
  onClick?: () => void;
};

const ReportSideCard = ({
  coach,
  date,
  session,
  time,
  coachImage,
  selected = false,
  onClick,
}: Props) => {

  const { t } = useTranslation("reports");

  return (
    <Card
      noPadding
      className={`relative border-2 ${
        selected ? "border-red" : "border-white"
      }`}
      onClick={onClick}
    >
      {selected && <div className="side-arrow" />}
      <div className="flex flex-col divide-y">
        <div className="flex flex-row justify-between p-4">
          {date && (
            <div className="flex flex-row items-center gap-1">
              <img src={date_icon} alt="date" />
              {date}
            </div>
          )}
          {time && (
            <div className="flex flex-row items-center gap-1">
              <img src={time_icon} alt="time" />
              {time}
            </div>
          )}
        </div>
        <div className="text-lg font-serif font-bold p-4">
          {t("coaching_reports.session")} {session}
        </div>
        <div className="flex flex-row gap-1 p-4 items-center">
          {coachImage && (
            <img
              src={coachImage}
              className="h-6 w-6 object-cover rounded-full"
              alt="coach"
            />
          )}
          {coach}
        </div>
      </div>
    </Card>
  );
};

export default ReportSideCard;
