import {CgRemove} from "react-icons/cg";
import {FaUndo} from "react-icons/fa";

export const ActionCell = ({row, table}: any) => {
    const tableMeta = table.options.meta;
    // const {values, submitForm, initialValues, setFieldValue,} = useFormikContext();
    //
    // const [field, meta, helper] = useField('users');
    //

    const removeRow = () => {
        tableMeta?.removeRow(row.index);
    };
    return (
        <div className="flex gap-5">
            {row.original.edited && !row.original.new && (
                // tableMeta?.editedRows[row.original.id] && !row.original.new && (
                <div className="flex gap-5">
                    <button
                        className="p-3"
                        onClick={(event) => {
                            event?.stopPropagation();
                            tableMeta.revertData(row);
                        }}
                        type="button"
                    >
                        <FaUndo className="text-[#28a745]" size={13}/>
                    </button>
                </div>
            )}
            {row.original.new && (
                <div className="flex gap-4 w-full justify-center">
                    <button onClick={removeRow} name="remove" type="button">
                        <CgRemove className="text-[#FD0054]" size={15}/>
                    </button>
                    <input
                        type="checkbox"
                        checked={row.getIsSelected()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                </div>
            )}
        </div>
    );
};
