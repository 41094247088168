import DashboardRoutes from "../modules/dashboard/routes/routes";
import { IRoute } from "../models/IRoute";

import CommonRoutes, { TestNestedRoutes } from "./common";
import AuthRoutes from "../modules/auth/routes/routes";
import TeamsRoutes from "../modules/teams/routes/routes";
import CoachingRoutes from "../modules/coaching/routes/routes";

const routes: IRoute[] = [
  ...AuthRoutes,
  ...DashboardRoutes,
  ...CommonRoutes,
  ...CoachingRoutes,
  ...TestNestedRoutes,
  ...TeamsRoutes,
];

export default routes;
