import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import useLoadGsiScript from "@hooks/useLoadGsiScript";


const useGAuth = (onGAuthCallback: any) => {
    const scriptLoadedSuccessfully = useLoadGsiScript();
    const gLoginRef = useRef<any>();

    const onGAuthCallbackRef = useRef(onGAuthCallback);
    onGAuthCallbackRef.current = onGAuthCallback;

    const clientRef = useRef<any>();

    const handleCallbackResponse = (response: any) => {
        console.log(response)
    }


    useEffect(() => {
        if (!scriptLoadedSuccessfully) return;

        const client = window.google?.accounts.oauth2['initCodeClient']({
            client_id: process.env.REACT_APP_GOOGLE_APP_CLIENT_ID!,
            scope: `openid profile email https://www.googleapis.com/auth/calendar`,
            callback: (response: any) => {
                // console.log(response)
                return onGAuthCallbackRef.current?.(response)
            }
        });
        clientRef.current = client;
        window.google?.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_APP_CLIENT_ID!,
            callback: (response: any) => {
                // client.requestCode()
                return onGAuthCallbackRef.current?.(response)
            }

        });

        window.google?.accounts.id.renderButton(
            gLoginRef.current, {
                theme: 'filled_black', size: 'medium',
                type: 'standard'
            }
        );

    }, [scriptLoadedSuccessfully]);


    const loginImplicitFlow = useCallback(
        (overrideConfig?:any) =>
            clientRef.current.requestAccessToken(overrideConfig),
        [],
    );

    const loginAuthCodeFlow = useCallback(
        () => clientRef.current.requestCode(),
        [],
    );
    return {gLoginRef,loginImplicitFlow,loginAuthCodeFlow}
};

export default useGAuth;
