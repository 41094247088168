import {ChevronDownIcon, ChevronRightIcon} from '@heroicons/react/outline';
import {dialog} from "@microsoft/teams-js";
import React, {FC, useState} from 'react'
import IReport from '../../../../models/IReport';
import DiscreteProgressbar from "../../../../shared/components/DiscreteProgressbar";
import {useTranslation} from "react-i18next";
import noteImg from '../../../../assets/img/teams/icons/note.png'
import {showModal} from "../../../../utils/helpers/teams";
interface Props {
    report: IReport
    isOpen: boolean
}

const CollapseItem: FC<Props> = ({report, isOpen}) => {
    const {t} = useTranslation('reports')
    const [open, setOpen] = useState(isOpen)

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()

        setOpen(!open)
    }

    return (
        <>
            <div className="flex items-center justify-between px-4 py-4 sm:px-6 cursor-pointer">
                <div onClick={handleClick} className="flex items-center flex-1 min-w-0">
                    Session {report?.session_id} {t('collapse.text_17')} {report?.client_name} {t('collapse.text_18')} {report?.client_company}
                </div>
                <div style={{display: "inline-flex", gap: "30px"}}>
                    {(report.has_my_note && !open) && <button title={t('collapse.text_1')}
                        onClick={() => showModal("/my-note?meeting_id=" + report?.meeting_id, t('collapse.text_1'), null, 800, 500)}
                        className=""> <img height="24"  width="24" src={noteImg}/>
                    </button>}
                    <div onClick={handleClick}>
                        {(open) ? <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true"/> :
                            <ChevronRightIcon className="w-5 h-5 text-gray-400" aria-hidden="true"/>}
                    </div>
                </div>
            </div>
            <div className={'px-6 pt-4 pb-6 text-sm font-light ' + ((open) === false ? 'hidden' : '')}>
                <div className="mb-5">
                    <span className="font-normal">{t('collapse.text_2')}</span>
                    <div style={{color: "#FD0054"}}
                         className="py-2 font-bold text-gray-500 font-medium w-auto">{t('collapse.text_3')}
                    </div>
                </div>
                <div className="mt-3">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 lg:grid-cols-3">
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">{t('collapse.text_4')}</dt>
                            <dd className="font-bold mt-1 text-sm text-gray-900">{report.coaching_category}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="mb-2 text-sm font-medium text-gray-500">{t('collapse.text_5')}</dt>
                            <DiscreteProgressbar value={report.grade_prior!}/>
                            {/*<dd className="mt-1 text-sm text-gray-900">{report.grade_prior}</dd>*/}
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="mb-2 text-sm font-medium text-gray-500">{t('collapse.text_6')}</dt>
                            <DiscreteProgressbar value={report.grade_after!}/>
                            {/*<dd className="mt-1 text-sm text-gray-900">{report.grade_after}</dd>*/}
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">{t('collapse.text_7')}</dt>
                            <dd className="font-bold mt-1 text-sm text-gray-900">{report.action}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">{t('collapse.text_8')}</dt>
                            <dd className="font-bold mt-1 text-sm text-gray-900">{report.goal}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">{t('collapse.text_9')}</dt>
                            <dd className="font-bold mt-1 text-sm text-gray-900">{report.feeling}</dd>
                        </div>
                        {report.behavior && <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">{t('collapse.text_16')}</dt>
                            { report.behavior.map((behavior,i)=>{
                                return (
                                    <dd className="font-bold mt-1 text-sm text-gray-900">{behavior}</dd>
                                )
                            })}

                        </div>}

                        <div className="sm:col-span-2">
                            <dt className="text-sm font-medium text-gray-500">{t('collapse.text_10')}
                            </dt>
                            <dd className="font-bold mt-1 text-sm text-gray-900">{report.action_answer}</dd>
                        </div>
                        <div className="sm:col-span-2">
                            <dt className="text-sm font-medium text-gray-500">{t('collapse.text_11')}</dt>
                            <dd className="font-bold mt-1 text-sm text-gray-900">{report.goal_answer}</dd>
                        </div>
                        <div className="sm:col-span-2">
                            <dt className="text-sm font-medium text-gray-500">{t('collapse.text_12')}</dt>
                            <dd className="font-bold mt-1 text-sm text-gray-900">{report.todo_answer}</dd>
                        </div>
                        <div className="sm:col-span-2">
                            <dt className="text-sm font-medium text-gray-500">{t('collapse.text_13')}</dt>
                            <dd className="font-bold mt-1 text-sm text-gray-900 underline">{report.coaching_materials}</dd>
                        </div>
                        <div className="sm:col-span-2">
                            <div>
                                <dt className="text-sm font-medium text-gray-500">{t('collapse.text_14')}</dt>
                                <dd className="font-bold mt-1 text-sm text-gray-900">{report.coaching_notes}</dd>
                            </div>

                        </div>

                    </dl>
                    <div className='mt-3'>
                        <button
                            onClick={() => showModal("/my-note?meeting_id=" + report?.meeting_id, t('collapse.text_1'), null, 800, 500)}
                            className="px-8 py-2 text-white rounded bg-secondary flex-end">{t('collapse.text_15')}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CollapseItem
