import Button from "../../../../../../shared/components/Button";
import React from "react";

type SummaryBtnProps = {
    label: string;
    onClick: () => void
    disabled?: boolean
    loading?: boolean
};
const BlackButton = ({label, onClick,disabled,loading}: SummaryBtnProps, displayArrow = true) => {

    return <div className="w-fit">
        <Button
            className={`min-w-[144px] flex justify-center text-xs font-bold border-2 border-black px-4 h-9 text-black hover:warm-dark`}
            onClick={onClick}
            disabled={disabled}
            loading={loading}
        >
            {label}
        </Button>
    </div>
}

export default BlackButton;
