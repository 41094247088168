import {FC, useState} from 'react'

import locationIcon from '../../../assets/img/teams/icons/location.png'
import languageIcon from '../../../assets/img/teams/icons/language.png'
import playIcon from '../../../assets/img/teams/icons/play-button.svg'

import {useSteps} from 'react-step-builder'
import {useNavigate, useSearchParams} from 'react-router-dom'

import linkedinIcon from "../../../assets/img/teams/social/linkedin.png";
import '../css/LinkedinIconStyle.css'
import {useTranslation} from "react-i18next";
import ICoach from "../../../models/ICoach";
import {ESpecializationFr} from "../../../constants/meta/ESpecializationFr";
import {ELanguageFr} from "../../../constants/meta/ELanguageFr";
import {showModal} from "../../../utils/helpers/teams";

interface Props {
    coach: ICoach;
}

const Coach: FC<Props> = ({coach}) => {
    const {t} = useTranslation('booking')
    const {jump} = useSteps()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const [style, setStyle] = useState({display: 'none'});
    const lng = localStorage.getItem("I18N_LANGUAGE") || 'en'

    const handleBookClick = () => {
        searchParams.set('coach_id', coach.id.toString())
        navigate('?' + searchParams.toString())
        jump(2)
    }
    const openLink = (url: string) => {
        window.open(url, '_blank');
    }
    return (
        <div className="bg-white rounded-lg">
            {coach?.website &&
                <div className="absolute top-3 right-3 z-10">
                    {/*<a title="Open in a new tab" className="hover_image" href={coach.website} target="_blank">*/}
                    {/*    <img className="linkedinImg" src={linkedinIcon} alt=""/>*/}

                    {/*    <svg className="externalImg hover_image-hide" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"*/}
                    {/*         width="24" height="24"*/}
                    {/*         viewBox="0 0 24 24"*/}
                    {/*         style={{fill: "#fd0054"}}>*/}
                    {/*        <path*/}
                    {/*            d="M19,21H5c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h7v2H5v14h14v-7h2v7C21,20.1,20.1,21,19,21z"></path>*/}
                    {/*        <path d="M21 10L19 10 19 5 14 5 14 3 21 3z"></path>*/}
                    {/*        <path d="M6.7 8.5H22.3V10.5H6.7z" transform="rotate(-45.001 14.5 9.5)"></path>*/}
                    {/*    </svg>*/}
                    {/*</a>*/}
                    <a title="Open in a new tab" href={coach.website} target="_blank">
                    <span className="new-tab-span-container">
                    <img className="" src={linkedinIcon} alt=""/>
                        <span> <p> Open </p>
                    <svg className="" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                         width="24" height="24"
                         viewBox="0 0 24 24"
                         style={{fill: "#f8f4f4"}}>
                        <path
                            d="M19,21H5c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h7v2H5v14h14v-7h2v7C21,20.1,20.1,21,19,21z"></path>
                        <path d="M21 10L19 10 19 5 14 5 14 3 21 3z"></path>
                        <path d="M6.7 8.5H22.3V10.5H6.7z" transform="rotate(-45.001 14.5 9.5)"></path>
                    </svg>

                    </span>

                    </span>
                    </a>
                </div>
                // <img onClick={() => openLink(coach.website)} className="absolute top-3 right-3 z-10" src={linkedInIcon} alt=""/>
            }

            <div
                className="block w-full overflow-hidden bg-gray-100 rounded-t-lg group aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500">
                {coach?.image_url
                    ?
                    <div onClick={() => {
                        if (coach?.video_url) showModal("/play-video?url=" + coach?.video_url, "Coach Video")
                    }}
                         className="object-cover overflow-hidden bg-center bg-cover flex justify-end items-end"
                         style={{backgroundImage: `url(${coach?.image_url})`}}>
                        <div className="flex items-center justify-start mb-3">

                            {coach?.popular ?
                                <span style={{color: "#FD0054", background: "#FFEBF1"}}
                                      className="px-1 py-1 mr-2 text-xs font-bold bg-gray-100 rounded">{t('select_coach.card.text_1')}</span> : null}
                            {coach?.recommended && <span
                                className="px-1 py-1 mr-2 text-xs font-bold text-yellow-600 rounded bg-red-50">{t('select_coach.card.text_2')}</span>}
                        </div>
                    </div>
                    // <img className="mr-5 rounded" src={coach?.image_url} alt="" />
                    :
                    <span onClick={() => {
                        if (coach?.video_url) showModal("/play-video?url=" + coach?.video_url, "Coach Video")
                    }}
                          className="inline-block object-cover overflow-hidden bg-gray-100 rounded-md pointer-events-none group-hover:opacity-7">
                        <svg className="w-full h-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                            <path
                                d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                        </svg>
                    </span>
                }
                {coach.video_url
                    ?
                    <div onMouseEnter={e => {
                        setStyle({display: 'block'});
                    }}
                         onMouseLeave={e => {
                             setStyle({display: 'none'})
                         }}
                         onClick={() => {
                             if (coach?.video_url) showModal("/play-video?url=" + coach?.video_url, "Coach Video")
                         }}
                         className="absolute flex items-center justify-center cursor-pointer">

                        <img style={style} className="inline-flex mr-4" src={playIcon} alt=""/>

                    </div>
                    :
                    null
                }

            </div>
            <div className="px-4 py-4">
                <div className="flex items-center justify-start mb-3">
                    <p className="block py-2 mr-3 font-medium text-gray-900 truncate pointer-events-none">{coach?.first_name} {coach?.last_name}</p>
                    {/*{coach?.popular ?*/}
                    {/*    <span className="px-1 py-1 mr-2 text-xs font-light bg-gray-100 rounded">Popular</span> : null}*/}
                </div>
                <p className="mb-3 font-light">
                    <img className="inline-flex mr-3" src={locationIcon} alt=""/>
                    <span>{coach?.city}</span>
                </p>
                <p className="mb-2 font-light h-12">
                    <img className="inline-flex mr-3" src={languageIcon} alt=""/>
                    <span>{coach.languages.map((lang) => {
                        // @ts-ignore
                        return lng == 'fr' ? ELanguageFr[ lang.language] : lang.language
                    }).join(' / ')}</span>
                </p>
                <div className="py-3 h-36">
                    <div className="w-full flex flex-wrap relative">
                        {coach.specializations.map((spec, index) => {
                            return <span key={index}
                                         className="px-1 py-1 mb-2 mr-2 text-xs font-light text-yellow-600 rounded bg-red-50">
                                 {/*@ts-ignore*/}
                                {lng == 'fr' ? ESpecializationFr[spec.specialization] : spec.specialization}</span>
                        })}
                    </div>
                </div>
                <div className="flex items-center justify-center">
                    <button onClick={handleBookClick}
                            className="w-full px-5 py-3 text-center text-white rounded bg-red">{t('select_coach.card.text_3')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Coach
