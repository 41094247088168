/* This example requires Tailwind CSS v2.0+ */
import {FC} from 'react'

import '../css/ConfirmationModal.css'
import * as microsoftTeams from "@microsoft/teams-js";

import {useTranslation} from "react-i18next";
import {cancelBooking} from "../../../services/api/teams/BookingActions";
import i18n from "../../../utils/lib/i18n";
import ENG from "../locale/eng/home.json";
import Fr from "../locale/fr/home.json";


interface Props {

    onClose?: (e: any) => any,
    sessionId?: any
}

const ConfirmationModal: FC<Props> = (props) => {
    i18n.addResourceBundle('en', 'home', ENG    );
    i18n.addResourceBundle('fr', 'home', Fr    );
    const {t} = useTranslation('home')
    // console.log('');
    const submitConfirmation = (confirm = false) => {
        let result: any
        if (confirm) {
            result = {"confirm": true};
        } else {
            result = {"confirm": false};
        }


        microsoftTeams.app.initialize().then(r => {

            microsoftTeams.dialog.submit(result, process.env.REACT_APP_TEAMS_MANIFEST_APPID);
        })
    }


    const submitHandler = (result: any) => {
        if (result.confirm == true && props.sessionId) {
            cancelBooking(props.sessionId)
                .then(res => {
                    if (props.onClose) props.onClose(false);
                }).catch(err => {
                console.error(err.response)
            });
        }


    };
    return (
        <div className='modal-container'>
            <h1> {t('text_27')}</h1>
            <br/>
            <div className='button-container'>
                <button onClick={() => submitConfirmation(false)}
                        className="modal-button col-span-3 px-5 py-1 text-white rounded bg-red md:col-span-1">
                    {t('text_28')}
                </button>
                <button onClick={() => submitConfirmation(true)}
                        className="modal-button col-span-3 px-5 py-1 text-white rounded bg-red md:col-span-1">
                    {t('text_29')}
                </button>
            </div>
        </div>
    )
}

export default ConfirmationModal
