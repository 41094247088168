import { RefObject, useState } from "react";

import { useTranslation } from "react-i18next";

import HT from "./HighlightedText";
import Feedback360Emails from "../../../../teams/pages/Feedback360Emails";
import VerticalExpander from "./VerticalExpander";
import ChatGuide from "../../../../../shared/components/ChatGuide";
import InfoPopover from "../../../components/InfoPopover";

import reflectionRequest from "@img/dashboard/welcome/icon/360_reflection.svg";

type Props = {
  onChangeEmails?: (emails: string[]) => void;
  feedback360EmailsRef: RefObject<React.ElementRef<typeof Feedback360Emails>>;
};

const ReflectionEmailStep = ({
  onChangeEmails,
  feedback360EmailsRef,
}: Props) => {
  const { t } = useTranslation("welcome");
  const [showContent, setShowContent] = useState(false);

  return (
    <div className="flex flex-col flex-1">
      <ChatGuide
        title={<HT>{t("step_5.title")}</HT>}
        description={t("step_5.description")}
        descriptionClassName="font-medium"
        onShowContent={() => setShowContent(true)}
      />
      <VerticalExpander expanded={showContent}>
        <div className="h-max w-full flex flex-col p-6 rounded-md my-8 bg-white">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center gap-3">
              <img
                src={reflectionRequest}
                className="w-8"
                alt="Self-reflection quiz"
              />
              <HT className="text-xl font-bold font-lexend">
                {t("step_5.reflection_request")}
              </HT>
              <span
                className={`px-5 py-2 rounded-full text-xs text-black bg-[#E3F8EE]`}
              >
                {t("step_5.feedback")}
              </span>
            </div>
            <InfoPopover content={t("step_5.info")} />
          </div>
          <div className="h-full">
            <Feedback360Emails
              ref={feedback360EmailsRef}
              onChangeEmails={onChangeEmails}
              inputHeight="h-20"
              hideSendButton
            />
          </div>
        </div>
      </VerticalExpander>
    </div>
  );
};

export default ReflectionEmailStep;
