import {AxiosRequestConfig} from "axios";
import {GET, POST} from "../../utils/lib/axios";
import axios, {Method} from "axios";
import { userTimeZone } from "../../utils/helpers/time";

import {AiBooking} from "../../constants/endpoints/aiBooking";


axios.interceptors.response.use(undefined, (err) => {
    const {config, message} = err;
    if (!config || !config.retry) {
        return Promise.reject(err);
    }
    // retry while Network timeout or Network Error
    if (!(message.includes("timeout") || message.includes("Network Error"))) {
        return Promise.reject(err);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise((resolve) => {
        setTimeout(() => {
            console.log("retry the request", config.url);
            resolve(undefined);
        }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => axios(config));
});

interface RetryConfig extends AxiosRequestConfig {
    retry?: number;
    retryDelay?: number;
}

const retryConfig: RetryConfig = {
    retry: 3,
    retryDelay: 3000,
};


export const postAIPrompt = async (
    id: number,
    prompt: string
) => {
    return POST(AiBooking.post.postAIPrompt, {prompt}, {id}, )
        .then((response) => {
            return response.data;
            // return response.data;
        })
        .catch((err) => {
            console.log("Failed to get ai history:", err);
        });
};


export const createJourney = async (): Promise<any> => {
    return POST(AiBooking.post.createJourney, {},{},{ai_model:"journey"}).then((response) => {
        return response.data;
    })
        .catch((err) => {
            console.log("Failed to get ai history:", err);
        });
};


export const createSummary = async (chatId: number, controller: AbortController): Promise<any> => {
    return POST(AiBooking.post.createSummary,{},{id:chatId})
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to create summary:", err);
        });
};

export const getAIJourneyOverview = async (): Promise<any> => {
    return GET(AiBooking.get.getAIJourneyOverview)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to get ai overview:", err);
        });
};

export const getAIHistory = async (id: number): Promise<any> => {
    return GET(AiBooking.get.getAIHistory, {id})
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to get ai history:", err);
        });
};

export const getSummary = async (id: number, controller?: AbortController): Promise<any> => {
    return GET(AiBooking.get.getSummary,{id})
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to get session summary:", err);
        });
};
