import React, {useState} from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import i18n from "../../utils/lib/i18n";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/outline";


interface Props {
    currentMonth: Date;
    onChange: (date: Date) => void;
}
const MonthLabel = ({ value, onClick }: any) => (
    <button className="w-24" onClick={onClick}>{value}</button>
);
const MonthPicker: React.FC<Props> = ({currentMonth, onChange}) => {

    function handlePreviousMonth() {
        const prevMonth = new Date(currentMonth);
        prevMonth.setMonth(prevMonth.getMonth() - 1);
        onChange(prevMonth);
    }

    function handleNextMonth() {
        const nextMonth = new Date(currentMonth);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        onChange(nextMonth);
    }

    return (
        <div className="flex gap-2 items-center">
            <button className="rounded border border-black " onClick={handlePreviousMonth}>
                <ChevronLeftIcon className="h-7 w-7" aria-hidden="true"/>
            </button>
            <div className="">
                <DatePicker
                     popperClassName="!z-20"
                    selected={currentMonth}
                    onChange={date => {
                        onChange(date!);
                    }}
                    showMonthYearPicker
                    dateFormat='MMMM yyyy'
                    // locale={i18n.language}
                    customInput={ <MonthLabel/>}
                />
            </div>
            <button className="rounded border border-black" onClick={handleNextMonth}>
                <ChevronRightIcon className="h-7 w-7"
                                  aria-hidden="true"/>
            </button>
        </div>
    );
}

export default MonthPicker;


// This works but same thing can be done simpler than this

// import React, {createContext, useState} from 'react';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import {useDatepicker, useMonth, useDay} from '@datepicker-react/hooks'
// import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/solid";
//
// const datepickerContextDefaultValue = {
//     // focusedDate: null,
//     // isDateFocused: () => false,
//     // isDateSelected: () => false,
//     // isDateHovered: () => false,
//     // isDateBlocked: () => false,
//     // isFirstOrLastSelectedDate: () => false,
//     // onDateFocus: () => {},
//     // onDateHover: () => {},
//     // onDateSelect: () => {}
// };
//
// const DatepickerContext = createContext(datepickerContextDefaultValue);
// const Month = ({month, year}: { month: number, year: number }) => {
//     const {days, weekdayLabels, monthLabel} = useMonth({
//         month: month,
//         year: year
//     });
//     return <span>{monthLabel}</span>
// }
// const MonthPicker = () => {
//     const [startDate, setStartDate] = useState(new Date());
//     const {
//         firstDayOfWeek,
//         activeMonths,
//         isDateSelected,
//         isDateHovered,
//         isFirstOrLastSelectedDate,
//         isDateBlocked,
//         isDateFocused,
//         focusedDate,
//         goToDate,
//         onDateHover,
//         onDateSelect,
//         onDateFocus,
//         goToPreviousMonths,
//         goToNextMonths
//     } = useDatepicker({
//         startDate: startDate,
//         onDatesChange: handleDateChange,
//         endDate: null,
//         focusedInput: null,
//         numberOfMonths: 1
//
//     })
//     console.log(activeMonths[0].date);
//
//     function handleDateChange(data: any) {
//         console.log(data);
//         if (!data.focusedInput) {
//
//         } else {
//
//         }
//
//
//     }
//
//     const [isOpen, setIsOpen] = useState(false);
//     return (
//         <div className="flex gap-2 items-center">
//             <DatepickerContext.Provider
//                 value={{
//                     focusedDate,
//                     isDateFocused,
//                     isDateSelected,
//                     isDateHovered,
//                     isDateBlocked,
//                     isFirstOrLastSelectedDate,
//                     onDateSelect,
//                     onDateFocus,
//                     onDateHover
//                 }}>
//
//                 <button className="rounded border border-black " onClick={goToPreviousMonths}>
//                     <ChevronLeftIcon className="h-7 w-7"
//                                      aria-hidden="true"/>
//                 </button>
//                 <div className="">
//
//                     <DatePicker
//                         selected={activeMonths[0].date}
//                         onChange={(date) => {
//                             setStartDate(date!)
//                             goToDate(date!);
//                             setIsOpen(!isOpen);
//                         }}
//                         dateFormat="MM/yyyy"
//                         showMonthYearPicker
//                         customInput={
//                             <span className="h-8" onClick={() => setIsOpen(!isOpen)}>
//                <Month month={activeMonths[0].month} year={activeMonths[0].year}/>
// </span>
//                         }
//                     />
//
//                 </div>
//                 <button className="rounded border border-black" onClick={goToNextMonths}>
//                     <ChevronRightIcon className="h-7 w-7"
//                                       aria-hidden="true"/>
//                 </button>
//
//             </DatepickerContext.Provider>
//         </div>
//     );
// };
//
// export default MonthPicker;
