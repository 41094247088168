import {FC, useState} from "react";
import {Whisper} from "rsuite";
import {Sketch} from "@uiw/react-color";
import {ChevronDownIcon} from "@heroicons/react/outline";
import i18n from "../../../../../utils/lib/i18n";
import ENG from "../../../locale/eng/groups.json";
import Fr from "../../../locale/fr/groups.json";

type ColorPickerProps = {
    color?: string;
    onChange: (value: string) => void;
};

const ColorPickerField: FC<ColorPickerProps> = ({color, onChange}) => {
    i18n.addResourceBundle("en", "groups", ENG);
    i18n.addResourceBundle("fr", "groups", Fr);
    const [containerRef, setContainerRef] = useState<HTMLDivElement>();

    return (
        <div
            className="cursor-pointer relative flex-1"
            ref={(e) => setContainerRef(e as HTMLDivElement)}
        >
            <Whisper
                trigger="click"
                placement="bottomEnd"
                container={containerRef}
                speaker={
                    <div className="absolute mt-1 z-10">
                        <Sketch
                            color={color}
                            onChange={({hex}) => onChange(hex.toUpperCase())}
                        />
                    </div>
                }
            >
                <div className="flex flex-col gap-2 w-full">
                    <div
                        className="h-[40px] w-full px-2 border-[2px] border-[#E5E7EB] rounded-[5px] flex flex-row justify-between items-center hover:border-[#E5E7EB] active:border-[#B3B3B3]">
                        <div className="flex flex-row gap-2">
                            <div
                                className="h-[20px] w-[20px] border-[1px] border-[#f2ebe3]"
                                style={{backgroundColor: color}}
                            />
                            <p className="font-lexend text-sm font-normal text-black">
                                {color}
                            </p>
                        </div>
                        <ChevronDownIcon className="text-black h-[20px]"/>
                    </div>
                </div>
            </Whisper>
        </div>
    );
};

export default ColorPickerField;
