import {useEffect, useState} from "react";

import {Outlet, useNavigate} from "react-router-dom";

import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import LoaderContainer from "../shared/components/LoaderContainer";

import useGuideStatus from "../utils/hooks/useGuideStatus";
import useAuth from "../utils/hooks/useAuth";
import {ERole} from "../constants/enums";

const OnboardingLayout = () => {
    const {user} = useAuth();
    const navigate = useNavigate();
    const guideStatus = useGuideStatus();

    const isCoacheeUser = user?.role === ERole.Coachee;

    const [navTitle, setNavTitle] = useState("Coachello");
    const [isLoading, setIsLoading] = useState(isCoacheeUser);
    const [isOnboardingComplete, setIsOnboardingComplete] = useState<boolean>(
        !isCoacheeUser
    );

    const [isSidebarOpen, setSideBarOpen] = useState(true);
    const [sidebarShouldCollapse, setSidebarShouldCollapse] = useState(false);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (sidebarShouldCollapse) setSideBarOpen(false);
        }, 5000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [sidebarShouldCollapse]);

    const collapseSideBar = () => {
        // setSideBarOpen(!isSidebarOpen);
        // setSidebarShouldCollapse(false);
    };

    useEffect(() => {
        if (!user) return navigate("/");
        if (isCoacheeUser) {
            guideStatus.checkStatus(() => {
                setIsLoading(false);
                setIsOnboardingComplete(true);
            });
        }
    }, []);
    return <div className="flex flex-col h-screen">
                <div className="h-12">
                    <Navbar navTitle={navTitle}/>
                </div>
                <div className="flex-1 overflow-y-auto">
                    <Outlet context={{navTitle, setNavTitle}}/>
                </div>
            </div>
    // return (
    //   <div className="flex flex-col h-screen">
    //     {isLoading ? (
    //       <LoaderContainer />
    //     ) : (
    //       <>
    //         <div className="bg-warm flex flex-col w-full overflow-auto">
    //           <Navbar navTitle={navTitle} />
    //           <div className="bg-warm m-0 flex-1 flex flex-row">
    //             {/*{isSidebarOpen && <Sidebar isOpen={isSidebarOpen}/>}*/}
    //             <main
    //               className={
    //                 `pl-2 py-14 ml-10 flex-auto transform ease-in-out duration-700  `
    //               }
    //             >
    //               <div className="px-2">
    //                 <Outlet context={{ navTitle, setNavTitle }} />
    //               </div>
    //             </main>
    //           </div>
    //         </div>
    //       </>
    //     )}
    //   </div>
    // );
};

export default OnboardingLayout;
