const ScrollBouncer = () => {
  return (
    <span
      className="absolute m-auto right-0 left-0 top-[-40px] inline-block animate-bounce rounded-full
      p-4 bg-red text-white text-sm w-max"
    >
      <svg
        className="w-3 h-3"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
        />
      </svg>
    </span>
  );
};

export default ScrollBouncer;
