import React, {useEffect} from 'react';
import useAuth from "@hooks/useAuth";
import logoImg from "@img/coachelloLogo.png";
import Spinner from "../../../shared/components/Spinner/Spinner";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {EAuthType} from "../../../constants/enums";
import {LocationProps} from "../../../utils/providers/AuthProvider";

const TokenAuth = () => {
    const {login, gLoginRef, loginImplicitFlow, loginAuthCodeFlow} = useAuth();
    const {token} = useParams()
    const [searchParams] = useSearchParams()

    useEffect(() => {
        const email = searchParams.get('email')
        if (email && token) {
            if (searchParams?.get('mobile') == "True") {
                console.log(`Mobile app deep link: ${(`io.coachello.coachello://coachello.io/authorize?token=${token}&email=${email}`)}`)
                // @ts-ignore
                // window.location = "io.coachello.coachello://coachello.io/authorize?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoxNTc4LCJlbWFpbCI6InNoYWt0aGlAem90ZWNoLnh5eiIsImV4cCI6MTcxODY4NTIzNCwiaWF0IjoxNzE2MDkzMjM0LCJpc3MiOiJDb2FjaGVsbG8iLCJzdWIiOiJzbGFjayIsInJvbGVzIjpbImNvYWNoZWUiXSwicm9sZSI6ImNvYWNoZWUifQ.-yfCbUx-MQ2utcWW5GFHiQlm9GWulxE7OtCnOpnzF74&email=shakthi@zotech.xyz"
                window.location = `io.coachello.coachello://coachello.io/authorize?token=${token}&email=${email}`
            } else {
                console.log("validating ...")
                const redirectUrl = searchParams?.get('redirect_url')
                login(EAuthType.Token, redirectUrl, undefined, {email: email, token: token})
            }

        }
    }, [searchParams, token]);
    return (
        <div className="flex justify-center items-center w-full h-full">
            <div className="flex flex-col items-center gap-5">
                <div className="flex justify-center">
                    <img className={"w-1/2 m-8"} src={logoImg}/>
                </div>
                <Spinner size="48px"/>
                <p>Authenticating...</p>
            </div>
        </div>
    );
};

export default TokenAuth;
