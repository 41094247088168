import Card from "../../../components/Card";
import {useTranslation} from "react-i18next";
import "./ReportSideCard.css";

type Props = {
    selected?: boolean;
    onClick?: () => void;
};

const PendingRequestSideCard = ({
                                    selected = false,
                                    onClick,
                                }: Props) => {

    const {t} = useTranslation("reports");

    return (
        <Card
            noPadding
            className={`relative border-2 ${
                selected ? "border-red" : "border-white"
            }`}
            onClick={onClick}
        >
            {selected && <div className="side-arrow"/>}
            <div className="flex flex-col divide-y">
                <div className="text-lg font-serif font-bold p-4">
                    {t("pending_requests")}
                </div>

            </div>
        </Card>
    );
};

export default PendingRequestSideCard;
