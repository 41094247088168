import ISidebarMenu from "../../models/ISidebarMenu";
import {
    AcademicCapIcon,
    AdjustmentsIcon,
    BookOpenIcon,
    ChartBarIcon,
    ChartPieIcon,
    ChartSquareBarIcon,
    ChatAltIcon,
    DocumentTextIcon as DocumentTextIconOutlined,
    HomeIcon,
    KeyIcon,
    UserCircleIcon,
    IdentificationIcon,
    UsersIcon,
} from "@heroicons/react/outline";
import {
    AcademicCapIcon as AcademicCapIconSolid,
    AdjustmentsIcon as AdjustmentsIconSolid,
    BookOpenIcon as BookOpenIconSolid,
    BriefcaseIcon,
    ChartBarIcon as ChartBarIconSolid,
    ChartPieIcon as ChartPieIconSolid,
    ChartSquareBarIcon as ChartSquareBarIconSolid,
    ChatAltIcon as ChatAltIconSolid,
    ChipIcon,
    DocumentTextIcon,
    HomeIcon as HomeIconSolid,
    KeyIcon as KeyIconSolid,
    UserCircleIcon as UserCircleIconSolid,
    IdentificationIcon as IdentificationIconSolid,
    UsersIcon as UsersIconSolid,
} from "@heroicons/react/solid";

import {ERole} from "../enums";

export const SidebarData: ISidebarMenu[] = [
    {
        title: "text1",
        icon: <ChatAltIcon/>,
        icon_selected: <ChatAltIconSolid/>,
        path: "coaching",
        allowedRoles: [ERole.HrAdmin, ERole.SuperAdmin, ERole.GroupAdmin],
        // subMenu: [
        //     {
        //         icon: <FaIcons.FaDeezer/>,
        //         title: 'Tenants',
        //         path: "Tenants"
        //     }, {
        //         icon: <FaIcons.FaGripfire/>,
        //         title: 'Landlords',
        //         path: "Landlords",
        //     }, {
        //         icon: <FaIcons.FaMagento/>,
        //         title: 'Ponols',
        //         path: "Ponols",
        //     },
        // ]
    },
    {
        icon: <ChipIcon/>,
        icon_selected: <ChipIcon/>,
        path: "insights",
        title: "text17",
        allowedRoles: [ERole.SuperAdmin],
        disable: false,
    },
    {
        icon: <ChartPieIcon/>,
        icon_selected: <ChartPieIconSolid/>,
        path: "usage",
        title: "text2",
        allowedRoles: [ERole.HrAdmin, ERole.SuperAdmin, ERole.GroupAdmin],
        disable: false,
    },
    {
        icon: <ChartSquareBarIcon/>,
        icon_selected: <ChartSquareBarIconSolid/>,
        path: "performance",
        title: "text3",
        allowedRoles: [ERole.HrAdmin, ERole.SuperAdmin, ERole.GroupAdmin],
        disable: false,
    },
    {
        icon: <AcademicCapIcon/>,
        icon_selected: <AcademicCapIconSolid/>,
        path: "coaches",
        title: "text4",
        allowedRoles: [ERole.HrAdmin, ERole.SuperAdmin, ERole.GroupAdmin],
        disable: false,
    },
    {
        icon: <BriefcaseIcon/>,
        path: "integration",
        title: "text7",
        allowedRoles: [ERole.ItAdmin],
        disable: false,
    },
    {
        icon: <DocumentTextIcon/>,
        path: "reports",
        title: "text8",
        allowedRoles: [ERole.Coach],
        disable: false,
    },
    {
        icon: <BriefcaseIcon/>,
        path: "session-view",
        title: "text9",
        allowedRoles: [ERole.Coach],
        disable: false,
    },
    {
        icon: <UserCircleIcon/>,
        icon_selected: <UserCircleIconSolid/>,
        path: "profile",
        title: "text10",
        allowedRoles: [ERole.Coach],
        disable: false,
    },
    {
        icon: <KeyIcon/>,
        icon_selected: <KeyIconSolid/>,
        path: "tools",
        title: "text11",
        allowedRoles: [ERole.HrAdmin, ERole.SuperAdmin],
        disable: false,
    },
    {
        icon: <HomeIcon/>,
        icon_selected: <HomeIconSolid/>,
        path: "home",
        title: "text12",
        allowedRoles: [ERole.Coachee],
        disable: false,
    },
    {
        icon: <BookOpenIcon/>,
        icon_selected: <BookOpenIconSolid/>,
        path: "coach/booking",
        title: "text15",
        allowedRoles: [ERole.Coachee],
        disable: false,
    },
    {
        icon: <DocumentTextIconOutlined/>,
        icon_selected: <DocumentTextIcon/>,
        path: "resources",
        title: "text13",
        allowedRoles: [ERole.Coachee],
        disable: false,
    },
    {
        icon: <ChartBarIcon/>,
        icon_selected: <ChartBarIconSolid/>,
        path: "reports/view",
        title: "text14",
        allowedRoles: [ERole.Coachee],
        disable: false,
    },
    {
        icon: <ChatAltIcon/>,
        path: "ai/coaching",
        title: "text19",
        allowedRoles: [ERole.Coachee],
        disable: false,
    },
    {
        icon: <AdjustmentsIcon/>,
        icon_selected: <AdjustmentsIconSolid/>,
        path: "groups",
        title: "text16",
        allowedRoles: [ERole.HrAdmin, ERole.SuperAdmin, ERole.GroupAdmin],
        disable: false,
    },
    {
        icon: <IdentificationIcon/>,
        icon_selected: <IdentificationIconSolid/>,
        path: "manage-coaches",
        title: "text20",
        allowedRoles: [ERole.SuperAdmin],
        disable: false,
    },
    {
        icon: <UsersIcon/>,
        icon_selected: <UsersIconSolid/>,
        path: "coach-insights",
        title: "text21",
        allowedRoles: [ERole.SuperAdmin],
        disable: false,
    },
];
