import { useEffect, useState } from "react";

import { twMerge } from "tailwind-merge";
import { MdDoneAll } from "react-icons/md";

import Loader from "./Loader";

import useAuth from "@hooks/useAuth";

import AICoachello from "../../../../../../assets/img/mentoring/ai_coachello.svg";
import DP from "../../../../../../assets/img/mentoring/homeHeaderDp.png";
import defaultProfileImg from "@img/icons/default-profile.png";

type ChatBoxItemProps = {
  mine?: boolean;
  content: string;
  aiResponseLoading?: boolean;
  isLast: boolean;
  isLastUserMessage: boolean;
};

const ChatBoxItem = ({
  mine,
  content,
  aiResponseLoading,
  isLast,
  isLastUserMessage,
}: ChatBoxItemProps) => {
  const { user } = useAuth();
  const [displayContent, setDisplayContent] = useState<any[]>([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (aiResponseLoading) return;
    if (content && index < content.length) {
      setTimeout(() => {
        setDisplayContent([...displayContent, content[index]]);
        setIndex(index + 1);
      }, 10);
    }
  }, [index, aiResponseLoading]);

  const image_url = user?.imageUrl || defaultProfileImg
  const displayPicture = !mine
    ? AICoachello
    : image_url
    ? image_url
    : DP;

  return (
    <div
      className={twMerge("flex flex-row gap-2", mine ? "flex-row-reverse" : "")}
    >
      <div
        className={twMerge(
          "rounded-full h-max w-max",
          !mine ? "border-[1px] border-[#f4d4d4]" : ""
        )}
      >
        <img
          src={displayPicture}
          alt=""
          className="rounded-full object-cover pointer-events-none h-[41px] w-[41px]"
        />
      </div>
      <div
        className={twMerge(
          "relative px-3 py-2 rounded-2xl flex justify-center items-center max-w-[70%] min-w-[8%]",
          mine ? "bg-[#FCF4EA]" : "border-[1px] border-[#D6CAB3]",
          mine ? "pr-4" : "pl-4"
        )}
      >
        <div className="relative min-h-[18px] w-full flex items-center">
          {!isLast || aiResponseLoading === undefined ? (
            <p className="font-dm_sans m-0 text-sm text-black  whitespace-pre-line">
              {content}
            </p>
          ) : isLast && !aiResponseLoading ? (
            <p className="font-dm_sans m-0 text-sm text-black whitespace-pre-line">
              {displayContent}
            </p>
          ) : (
            <Loader isFullscreen={false} spinnerColor="#D6CAB3" />
          )}
        </div>
        {isLastUserMessage && (
          <MdDoneAll className="absolute right-1 bottom-1" />
        )}
      </div>
    </div>
  );
};

export default ChatBoxItem;
