import axios from 'axios';
import {userTimeZone} from "../../../utils/helpers/time";

export const getCoaches = (companyId: number, clientId: number) => {
    return axios.get<any>('api/coach?company=' + companyId + '&client=' + clientId + '&time_zone=' + userTimeZone())
        .then(response => {
            return response.data.data;
        });
}
export const getCoach = (coachId: string) => {
    return axios.get<any>('api/coach/' + coachId)
        .then(response => {
            return response.data.data;
        });
}
