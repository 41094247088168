import { useTranslation } from "react-i18next";

import linkedInIcon from "@img/coaching/icons/linkedin.png";

type Props = {
  link: string;
};

const CoachWebsite = ({ link }: Props) => {
  const { t } = useTranslation("general");

  return (
    <a
      title={t("open_in_new_tab")}
      href={link}
      target="_blank"
      rel="noreferrer"
      className=""
    >
      <span className="new-tab-span-container">
        <img className="" src={linkedInIcon} alt="" />
        <span className="items-center">
          <p> {t("open")} </p>
          <svg
            className=""
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style={{ fill: "#f8f4f4" }}
          >
            <path d="M19,21H5c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h7v2H5v14h14v-7h2v7C21,20.1,20.1,21,19,21z"></path>
            <path d="M21 10L19 10 19 5 14 5 14 3 21 3z"></path>
            <path
              d="M6.7 8.5H22.3V10.5H6.7z"
              transform="rotate(-45.001 14.5 9.5)"
            ></path>
          </svg>
        </span>
      </span>
    </a>
  );
};

export default CoachWebsite;
