import axios from "axios";
import { IClientFeedback, IClientReport } from "../../../models/IReport";
import { userTimeZone } from "../../../utils/helpers/time";
import { axiosPrivate } from "../../../utils/lib/axios";

export const getPastSessions = (clientId: number) => {
  const lng = localStorage.getItem("I18N_LANGUAGE") || "en";
  return axios
    .get(
      `/api/meeting/${clientId}/past-reports?time_zone=${userTimeZone()}&role=client&lng=${lng}`
    )
    .then((response) => {
      return response.data;
    });
};
export const saveClientFeedback = (
  meetingId: number,
  data: IClientFeedback
) => {
  return axios
    .post(`/api/meeting/report/${meetingId}/feedback`, data)
    .then((response) => {
      return response.data;
    });
};

export const getMyNote = (meetingId: number) => {
  return axios
    .get(`/api/meeting/report/my-note/${meetingId}`)
    .then((response) => {
      return response.data;
    });
};
export const saveMyNote = (meetingId: number, data: object) => {
  return axios
    .post(`/api/meeting/report/my-note/${meetingId}`, data)
    .then((response) => {
      return response.data;
    });
};

export const getClientReports = async (
  clientId: number
): Promise<IClientReport[]> => {
  return axiosPrivate
    .get<any>(`api/meeting/clients/${clientId}/reports`)
    .then((response) => {
      return response.data;
    });
};
