import { getAllRecommendations } from "src/services/api/aiBookingApi";

export const AiBooking = {
    get: {
        getAIJourneyOverview: {
            private: true,
            path: '/api/clients/me/ai/overview/journey'
        },
        getAIOverview: {
            private: true,
            path: '/api/clients/me/ai/overview'
        },
        getAIHistory: {
            private: true,
            path: '/api/clients/me/ai/history/{id}'
        },
        getSummary: {
            private: true,
            path: '/api/clients/me/ai/summary/{id}'
        },
        getRecommendations: {
            private: true,
            path: 'api/clients/me/ai/{ai_coach_id}/recommended-contents/'
        },
        getAllRecommendations:{
            private: true,
            path: 'api/clients/me/ai/user-recommended-articles/{client_id}'
        }
    },
    post: {
        createJourney: {
            private: true,
            path: '/api/clients/me/ai/create'
        },
        postNewAIPrompt: {
            private: true,
            path: '/api/clients/me/ai/create?ai_model=flash'
        },
        postAIPrompt: {
            private: true,
            path: '/api/clients/me/ai/process-chat/{id}'
        },
        postFeedback: {
            private: true,
            path: '/api/clients/me/ai/feedback/{chatId}'
        },
        createSummary: {
            private: true,
            path: '/api/clients/me/ai/summary/{id}'
        },
        getSuggestions: {
            private: true,
            path: '/api/clients/me/ai/suggestions/{id}'
        },
    },
    delete:{
        deleteSession: {
            private: true,
            path: '/api/clients/me/ai/delete/{id}'
        },
    },
    put: {
        updateContentState: {
            private: true,
            path: 'api/clients/me/ai/recommended-contents/{content_id}'
        }
    }
};
