import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import ReportCreationForm from "../../forms/ReportCreationForm";
import ReportSessionItemMini from "./components/ReportSessionItemMini";
import { Loader } from "@components";

import useAuth from "@hooks/useAuth";
import { getSessionsOverview } from "../../../../services/api/coachApi";
import { isEmpty } from "../../../../utils/helpers/object";
import useScrollTo from "@hooks/useScrollTo";
import selfReflectionData from "../../../../assets/selfReflection.json";
import {TSelfReflection} from "../../../../constants/types";

const CreateReport = () => {
  const { user } = useAuth();
  let { sessionId } = useParams();
  const [selectedRef, scrollToSelected] = useScrollTo<HTMLDivElement>();
  const [loading, setLoading] = useState<Boolean>(false);
  const [selected, setSelected] = useState<number>(Number(sessionId!));
  const [sessions, setSessions] = useState<Record<any, any>[]>([]);
  const [selectedSession, setSelectedSession] = useState<Record<any, any>>();
  console.log(selectedSession);

  useEffect(() => {
    scrollToSelected(true);
  }, [selectedRef.current && sessions]);

  useEffect(() => {
    setSelectedSession(
      sessions[sessions?.findIndex((item: any) => item["id"] === selected)]
    );
  }, [selected, sessionId, sessions]);
  const loadSessionOverviewData = () => {
    console.log("loading sessionOverview ... ");
    setLoading(true);
    getSessionsOverview(user?.id!, Number(sessionId!))
      .then((res) => {
        setSessions(res);
        setSelected(Number(sessionId!));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    loadSessionOverviewData();
  }, []);

  return (
    <div className="flex gap-1 flex-col lg:flex-row py-5 px-2">
      {loading && (
        <div className="flex-auto justify-center p-5">
          <Loader />
        </div>
      )}
      {!isEmpty(sessions) && (
        <>
          <div
            className={`basis-1/4 flex flex-col gap-5 pr-2 overflow-auto h-screen lg:-mb-7 lg:sticky lg:top-2`}
          >
            {sessions?.map((ses, i) => {
              return (
                <div key={i} ref={ses.id == selected ? selectedRef : undefined}>
                  <ReportSessionItemMini
                    sessionId={ses.id}
                    sessionNo={ses.session_no}
                    datetime={ses.meeting_date}
                    selected={ses.id == selected}
                    status={ses.status}
                    statusColour={ses.status_colour}
                    setSelected={setSelected}
                  />
                </div>
              );
            })}
            <br />
          </div>
          <div className="basis-full">
            <ReportCreationForm
              sessionId={selectedSession?.id}
              datetime={selectedSession?.meeting_date}
              profile={selectedSession?.coachee_profile}
              coacheeName={selectedSession?.coachee_name}
              attended={selectedSession?.attended}
              companyName={selectedSession?.company_name}
              companyId={selectedSession?.company_id}
              sessionNo={selectedSession?.session_no}
              reload={loadSessionOverviewData}
              selfReflections={selfReflectionData as TSelfReflection[] }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CreateReport;
