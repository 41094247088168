import React from 'react';
import actionImg from './../../../../../assets/img/dashboard/insights/action.png'
import marketplaceApi from "../../../../../services/api/marketplaceApi";
import metaApi from "../../../../../services/api/metaApi";
import useAuth from "@hooks/useAuth";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

interface Props {
    icon?: any
    topic: string
    title: string
    content: any
    img: any
    action?: any
}

const InsightCard = () => {
    const {user} =useAuth()
    const navigate = useNavigate();

    const send = () => {
        const body = {
            topic: "Workshop",
            data: {
                name: user?.firstName,
                email: user?.email,

            },
        };
        metaApi.passToCoachello(body).then(() => {
                toast.success('Your account is activated!')
                navigate('./../')
            }
        )
    }
    return (
        <div className="rounded-md bg-white text-black p-5 w-full">
            <div className="flex flex-col md:flex-row">
                <div className="rounded-md bg-white p-1 w-2/3 flex gap-5 flex-col">
                    <p>To empower employees to recognize, understand, and overcome feelings of imposter syndrome,
                        thereby enhancing self-confidence and job performance.</p>

                    <ul className="list-disc list-outside flex flex-col gap-1 ml-5">
                        <li>Introduction to Imposter Syndrome: Defining and recognizing the signs.</li>
                        <li>Personal Experience Sharing: Insights from senior leaders on overcoming imposter syndrome.
                        </li>
                        <li> Interactive Group Activities: Identifying personal instances and triggers of imposter
                            syndrome.
                        </li>
                        <li> Coping Strategies Workshop: Practical exercises to build self-esteem and combat
                            self-doubt.
                        </li>
                        <li>Action Plan Development: Creating personalized strategies to address imposter feelings.</li>
                        <li> Follow-Up Mechanisms: Establishing support systems and progress checks.</li>


                    </ul>
                    <p>This workshop has a significant positive impact. Participants can expect a 25% boost in
                        self-confidence, potentially reducing anxiety and stress symptoms by up to 30%. Job performance
                        is likely to improve, with individual metrics seeing a 15-20% increase, and overall employee
                        engagement and teamwork may see up to a 20% enhancement.</p>

                </div>
                <div className="rounded-md bg-white p-5 w-1/3 flex items-center">
                    <img className="w-full" src={actionImg}/>
                </div>
            </div>
            <br/>
            <button onClick={() => send()} className="dashboard-button bg-red w-full flex justify-center">Schedule a
                Workshop
            </button>
        </div>
    );
};

export default InsightCard;
