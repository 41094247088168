export const COMPANY = {
    get: {
        getLabels: {
            private: true,
            path: '/api/company/{company_id}/labels'
        },
        getPrograms: {
            private: true,
            path: '/api/company/{company_id}/programs'
        },
        getUsage: {
            private: true,
            path: '/api/company/{company_id}/usage'
        },
        getPerformance: {
            private: true,
            path: '/api/company/{company_id}/performance'
        },
        getCategories: {
            private: true,
            path: '/api/company/{company_id}/categories'
        },
        getFeelings: {
            private: true,
            path: '/api/company/{company_id}/feelings'
        },
        getActions: {
            private: true,
            path: '/api/company/{company_id}/actions'
        },
        getObjectives: {
            private: true,
            path: '/api/company/{company_id}/objectives'
        },
        getAllObjectives: {
            private: true,
            path: '/api/company/{company_id}/all-objectives'
        },
        getBehaviors: {
            private: true,
            path: '/api/company/{company_id}/behaviors'
        },
        getPeerObjectives: {
            private: true,
            path: '/api/company/{company_id}/peer-objectives'
        },
        getPeerBehaviours: {
            private: true,
            path: '/api/company/{company_id}/peer-behaviour'
        },
        getBookingsSummary: {
            private: true,
            path: '/api/company/{company_id}/bookings-summary'
        },
        getOnboardingSummary: {
            private: true,
            path: '/api/company/{company_id}/onboarding-summary'
        },
        getCustomFields: {
            private: true,
            path: '/api/company/{company_id}/custom-fields'
        },
        getRemainCredits: {
            private: true,
            path: '/api/company/{company_id}/remaining-credits'
        },
        getCreditStats: {
            private: true,
            path: '/api/company/{company_id}/credit-stats'
        },
        getUtilisation: {
            private: true,
            path: '/api/company/{company_id}/utilisation'
        },
        getUtilisationSessionView: {
            private: true,
            path: '/api/company/{company_id}/sessions'
        },
        getGoalAchieved: {
            private: true,
            path: '/api/company/{company_id}/goal-achieved'
        },
        getCoaches: {
            private: true,
            path: 'api/coach'
        },
        getCoachesConsole: {
            private: true,
            path: 'api/coach/console'
        },
        getReviews: {
            private: true,
            path: 'api/company/{company_id}/reviews'
        },
        getId: {
            private: false,
            path: 'api/company/{company_name}/id'
        },
        getGroups: {
            private: true,
            path: 'api/company/{company_id}/groups'
        },
        getGroup: {
            private: true,
            path: 'api/company/{company_id}/groups/{id}'
        },
        getGroupAdmin: {
            private: true,
            path: 'api/company/{company_id}/groups/{id}/admin'
        },
        getActivePeriod: {
            private: true,
            path: 'api/company/{company_id}/active_period'
        },
        getInsights: {
            private: true,
            path: 'api/company/{company_id}/get_insights',
            queryParams: ['language', 'month']
        },
        getInsightsLabels: {
            private: true,
            path: 'api/company/{company_id}/get_insights_labels'
        },
        getProgramCoaches: {
            private: true,
            path: 'api/company/{company_id}/program-coaches'
        },
        getExcludeProgramCoaches: {
            private: true,
            path: 'api/coach/admin/coaches/exclude-program'
        },

    },
    put: {
        deactivateUser: {
            private: true,
            path: 'api/company/{client_id}/deactivate'
        },
        updateGroup: {
            private: true,
            path: 'api/company/{company_id}/groups/{id}'
        },
        updateGroupCoachees: {
            private: true,
            path: 'api/company/{company_id}/groups/{id}/coachees'
        },
        updateProgramCoaches: {
            private: true,
            path: 'api/company/{company_id}/coach-to-program'
        }
    },
    post: {
        createGroup: {
            private: true,
            path: 'api/company/{company_id}/groups'
        },
        upsertGroupAdmin: {
            private: true,
            path: 'api/company/{company_id}/groups/{id}/admin'
        },
    },
    delete: {
        deleteGroup: {
            private: true,
            path: 'api/company/{company_id}/groups/{id}'
        },
        deleteCoachFromProgram: {
            private: true,
            path: 'api/coach/program'
        }
    }
};

// export default {
//
//     get: {
//         all: {
//             private: true,
//             path: 'companies'
//         },
//         details: '/companies/{companyId}',
//     },
//     post: {
//         create: '/companies',
//     },
//     put: {
//         editCompany: '/companies/{companyId}',
//         changeStatus: '/companies/{companyId}/status',
//     },
// };
