import mem from "mem";
import {GET, POST} from "../../utils/lib/axios";
import {COMPANY} from "../../constants/endpoints/company";
import {AUTH} from "../../constants/endpoints/auth";
import {ILoginRequest} from "../../models/IAuth";
import {MARKETPLACE} from "../../constants/endpoints/marketplace";
const resolveSubscription = (marketplaceId:string) => {
    return POST(MARKETPLACE.post.resolveSubscription, {marketplace_id:marketplaceId})
        .then(response => {
            return response.data;
        });
}
const activateSubscription = (schema:Record<string, any>) => {
    return POST(MARKETPLACE.post.activateSubscription, schema)
        .then(response => {
            return response.data;
        });
}

export default {resolveSubscription,activateSubscription}
