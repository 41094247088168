import {FC, useEffect, useMemo, useRef, useState} from "react";

import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import moment from "moment";
import {FormikProps, FormikValues} from "formik";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

import EmailStep from "./components/EmailStep";
import BookingSuccess from "./components/BookingSuccess";
import StepIndicator from "../../../../shared/components/StepIndicator";
import Coaches from "./components/Coaches";
import ChooseTimeSlot from "./components/ChooseTimeSlot";
import ConfirmBooking from "./components/ConfirmBooking";
import FrameModal from "../../../../shared/components/FrameModal";

import {getCoachesConsole, getCompanyId} from "../../../../services/api/companyApi";
import {createBooking, getCoach} from "../../../../services/api/coachApi";
import storage from "../../../../services/storage/localStorage";

import ICoach from "../../../../models/ICoach";
import {IOccupiedEvents, ITimeSlot} from "../../../../models/IBooking";
import {IAuthUser} from "../../../../models/IAuth";
import {TConfirmBookingForm} from "../../../../constants/types";
import {EConfirmBookingFormField} from "../../../../constants/enums";
import {AxiosError} from "axios";

enum ParamField {
    email = "email",
    coach_id = "coach_id",
    date = "date",
    start = "start",
    end = "end",
    timezone = "timezone",
}

enum BookingMedium {
    web = "website",
    slack = "slack",
}

type Props = {
    variant?: "modal";
    handleBookingSubmission?: () => void;
    coachId?: number;
    meetingId?: number;
    isCreditDeducted?: boolean;
};

const Coaching: FC<Props> = (props) => {
    const {t} = useTranslation("booking");

    const [searchParams, setSearchParams] = useSearchParams();

    const frameModalRef = useRef<React.ElementRef<typeof FrameModal>>(null);

    const navigate = useNavigate();

    const {companyName} = useParams();

    const authUser = useMemo(() => storage.get("user") as IAuthUser, []);

    const externalVisit = !authUser && companyName;

    const [visibleStep, setVisibleStep] = useState(1);

    const formikRef = useRef<FormikProps<FormikValues>>(null);

    const [email, setEmail] = useState("");

    const [coaches, setCoaches] = useState<ICoach[]>([]);

    const [selectedCoach, setSelectedCoach] = useState<ICoach | null>(null);
    const [tripartiteEmail, setTripartiteEmail] = useState<string | undefined>(undefined);

    const [confirmBookingForm, setConfirmBookingForm] =
        useState<TConfirmBookingForm>({
            firstName: {
                value: "",
                error: false,
                errorText: t("first_name_required"),
            },
            lastName: {
                value: "",
                error: false,
                errorText: t("last_name_required"),
            },
            noteForCoach: {
                value: "",
                error: false,
                errorText: t("note_is_required"),
            },
        });

    const [timeSlot, setTimeSlot] = useState<ITimeSlot | null>(null);

    const [occupiedEvents, setOccupiedEvents] = useState<IOccupiedEvents>({});

    const [meetingId, setMeetingId] = useState<number | null>(null);

    const [bookingSuccessVisible, setBookingSuccessVisibility] = useState(false);

    const isCreditDeducted = props.isCreditDeducted;

    const company_id = authUser?.companyId;
    const client_id = authUser?.id;

    const selectCoach = (coachId: number) => {
        getCoach(coachId)
            .then((coach) => {
                if (coach) {
                    setSelectedCoach(coach);
                    setVisibleStep(2);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        if (!externalVisit) {
            setConfirmBookingForm({
                ...confirmBookingForm,
                firstName: {
                    ...confirmBookingForm.firstName,
                    value: authUser.firstName,
                },
                lastName: {
                    ...confirmBookingForm.lastName,
                    value: authUser.lastName,
                },
            } as TConfirmBookingForm);
        }

        props.coachId && selectCoach(props.coachId);
        props.meetingId && setMeetingId(props.meetingId);

        getCoachesConsole(company_id, client_id)
            .then((res) => {
                setCoaches(res);
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        if (!externalVisit) return;
        const step = handleDataSyncWithParams();
        setVisibleStep(step);
    }, [coaches]);

    const handleDataSyncWithParams = () => {
        const params = Object.fromEntries(searchParams.entries());
        let step = 1;
        const email = params[ParamField.email];
        const coach_id = params[ParamField.coach_id];
        const date = params[ParamField.date];
        const start = params[ParamField.start];
        const end = params[ParamField.end];
        const timezone = params[ParamField.timezone];

        if (email) {
            step = 2;
            setEmail(email);
            formikRef.current?.setFieldValue(ParamField.email, email);
        }
        if (coach_id) {
            step = 3;
            const coach = coaches.find((coach) => coach.id === +coach_id);
            setSelectedCoach(coach!);
        }
        if (date && start && end && timezone) {
            setTimeSlot({
                date,
                coachTimezone: timezone,
                time: {date, start, end},
            });
        }

        return step;
    };

    const handleConfirmBooking = async () => {
        const errorField = Object.keys(confirmBookingForm).find((key) => {
            const fieldName = key as EConfirmBookingFormField;
            return !externalVisit
                ? key !== EConfirmBookingFormField.firstName &&
                key !== EConfirmBookingFormField.lastName &&
                confirmBookingForm[fieldName]?.value === ""
                : confirmBookingForm[fieldName]?.value === "";
        }) as EConfirmBookingFormField;

        if (errorField && errorField !== EConfirmBookingFormField.noteForCoach) {
            return setConfirmBookingForm({
                ...confirmBookingForm,
                [errorField]: {
                    ...confirmBookingForm[errorField],
                    error: true,
                },
            });
        }

        try {
            const bookingData = await contructBookingData(
                timeSlot,
                selectedCoach,
                confirmBookingForm.noteForCoach?.value,
                meetingId,
                tripartiteEmail,
                isCreditDeducted
            );
            console.log(bookingData);

            if (!bookingData) return;
            const res = await createBooking(bookingData);
            if (res.error === false) {
                setBookingSuccessVisibility(true);
            } else {
                toast.error(t("slot_occupied"));
            }
        } catch (err) {
            const e = err as AxiosError<any>
            toast.error(e?.response?.data.message)
            console.log(err);
        }
    };

    const contructBookingData = async (
        timeSlot: ITimeSlot | null,
        coach: ICoach | null,
        note?: string,
        meetingId?: number | null,
        tripartiteEmail?: string | undefined,
        isCreditDeducted?: boolean
    ) => {
        const startTime = moment(
            new Date(`${timeSlot?.time.date}T${timeSlot?.time.start}`).toUTCString()
        );
        const endTime = moment(
            new Date(`${timeSlot?.time.date}T${timeSlot?.time.end}`).toUTCString()
        );

        const medium = BookingMedium.web;

        const first_name = externalVisit
            ? confirmBookingForm.firstName?.value
            : authUser.firstName;
        const last_name = externalVisit
            ? confirmBookingForm.lastName?.value
            : authUser.lastName;
        const userEmail = externalVisit ? email : authUser.email;

        let company_id = authUser && authUser.companyId;

        if (externalVisit) {
            try {
                company_id = (await getCompanyId(companyName)).data;
            } catch (e) {
                console.log(e);
            }
        }
        if (!company_id) return;

        return {
            user: {
                first_name,
                last_name,
                user_comment: note,
                email: userEmail,
                medium,
            },
            event: {
                start_time: formatUTC(startTime.format("YYYY-MM-DD HH:mm")),
                end_time: formatUTC(endTime.format("YYYY-MM-DD HH:mm")),
            },
            company_id,
            coach_id: coach?.id,
            user_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            coach_time_zone: timeSlot?.coachTimezone,
            is_consented: false,
            reschedule: !!meetingId,
            meeting_id: meetingId ? meetingId : undefined,
            tripartite_email: tripartiteEmail,
            is_credit_deducted: isCreditDeducted,
        };
    };

    const formatUTC = (date: string) => {
        return moment(date).format("YYYY-MM-DDTHH:mm:ssZ");
    };

    const handleChooseTimeSlotNext = () => {
        if (!timeSlot || !timeSlot.time) return;
        handleAddQueryParams({
            [ParamField.date]: timeSlot.time.date,
            [ParamField.start]: timeSlot.time.start,
            [ParamField.end]: timeSlot.time.end,
            [ParamField.timezone]: timeSlot.coachTimezone,
        });
        handleStepNavigation(true);
    };

    const handleSetConfirmBookingForm = (key: string, value: string) => {
        const updatedForm: any = {...confirmBookingForm};
        if (value === "" && key === EConfirmBookingFormField.noteForCoach) {
            updatedForm[key] = {
                ...updatedForm[key],
                value,
                error: false,
            };
        } else if (value === "") {
            updatedForm[key] = {
                ...updatedForm[key],
                value,
                error: true,
            };
        } else {
            updatedForm[key] = {...updatedForm[key], value, error: false};
        }
        setConfirmBookingForm(updatedForm);
    };

    let stepArr = [
        {
            step: 1,
            component: (
                <Coaches
                    coaches={coaches}
                    selectedCoach={selectedCoach}
                    handleSelectCoach={(coach: ICoach) => {
                        setOccupiedEvents({});
                        setTimeSlot(null);
                        setSelectedCoach(coach);
                        handleAddQueryParams({[ParamField.coach_id]: String(coach.id)});
                        handleStepNavigation(true);
                    }}
                    playCoachVideo={(url: string, title: string) => {
                        frameModalRef.current?.open(url, title);
                    }}
                />
            ),
        },
        {
            step: 2,
            component: (
                <ChooseTimeSlot
                    selectedCoach={selectedCoach}
                    timeSlot={timeSlot}
                    setTimeSlot={setTimeSlot}
                    occupiedEvents={occupiedEvents}
                    setOccupiedEvents={setOccupiedEvents}
                    handleNext={handleChooseTimeSlotNext}
                    onGetOccupiedEventsFail={() => {
                        toast.error(t("choose_time_slot.cannot_access_coach_calendar"));
                        handleStepNavigation();
                    }}
                />
            ),
        },
        {
            step: 3,
            component: (
                <ConfirmBooking
                    selectedCoach={selectedCoach}
                    timeSlot={timeSlot}
                    confirmBookingForm={confirmBookingForm}
                    handleSetConfirmBookingForm={handleSetConfirmBookingForm}
                    handleConfirmBooking={handleConfirmBooking}
                    externalVisit={!!externalVisit}
                    tripartiteEmail={tripartiteEmail}
                    setTripartiteEmail={setTripartiteEmail}
                />
            ),
        },
    ];

    if (externalVisit) {
        stepArr.unshift({
            step: 1,
            component: (
                <EmailStep
                    onMount={() => formikRef.current?.setFieldValue("email", email)}
                    formikRef={formikRef}
                    setEmail={(value: string) => setEmail(value)}
                    handleNext={(value) => {
                        handleAddQueryParams({[ParamField.email]: value});
                        handleStepNavigation(true);
                    }}
                />
            ),
        });

        stepArr = stepArr.map((step, index) => ({
            ...step,
            step: index + 1,
        }));
    }

    const handleStepNavigation = (nextStep?: boolean) => {
        const stepNo = visibleStep + (nextStep ? +1 : -1);
        if (!stepNo || stepNo > stepArr.length) return;
        setVisibleStep(stepNo);
    };

    const handleDirectStepChange = (step: number) => {
        const visibleStepIndex = stepArr.findIndex(
            (stepElement) => stepElement.step === step
        );
        const updatedVisibleStep = visibleStepIndex + 1;
        setVisibleStep(updatedVisibleStep);

        if (externalVisit) {
            let removingParams: ParamField[] = [];
            if (updatedVisibleStep <= 2) {
                removingParams = [
                    ParamField.date,
                    ParamField.start,
                    ParamField.end,
                    ParamField.timezone,
                    ParamField.coach_id,
                ];
                setSelectedCoach(null);
            }
            handleRemoveQueryParams(removingParams);
        } else {
            if (updatedVisibleStep === 1) {
                setSelectedCoach(null);
            }
        }
    };

    const handleScheduleAnother = () => {
        setTimeSlot(null);
        setConfirmBookingForm({
            ...confirmBookingForm,
            firstName: {
                ...confirmBookingForm.firstName,
                value: externalVisit ? "" : authUser.firstName,
                error: false,
            },
            lastName: {
                ...confirmBookingForm.lastName,
                value: externalVisit ? "" : authUser.lastName,
                error: false,
            },
            noteForCoach: {
                ...confirmBookingForm.noteForCoach,
                value: "",
                error: false,
            },
        } as TConfirmBookingForm);
        if (externalVisit) {
            setSearchParams({
                [ParamField.email]: email,
                [ParamField.coach_id]: String(selectedCoach?.id),
            });
        }

        setBookingSuccessVisibility(false);
        setVisibleStep(externalVisit ? 3 : 2);
    };

    const handleAddQueryParams = async (params: {
        [key in ParamField]?: string;
    }) => {
        if (!externalVisit) return;
        const existingParams = Object.fromEntries(searchParams.entries());
        setSearchParams({...existingParams, ...params});
    };

    const handleRemoveQueryParams = (paramNames: ParamField[]) => {
        const updatedParams = Object.fromEntries(searchParams.entries());
        paramNames.forEach((name) => delete updatedParams[name]);
        setSearchParams(updatedParams);
    };

    return (
        <div
            className={`bg-linen_gradient flex flex-col items-start h-full w-full overflow-hidden ${
                externalVisit ? "pt-10 px-10" : ""
            }`}
        >
            <FrameModal ref={frameModalRef}/>
            {bookingSuccessVisible ? (
                <BookingSuccess
                    onDone={() => {
                        if (props.variant === "modal") {
                            return (
                                props.handleBookingSubmission && props.handleBookingSubmission()
                            );
                        }
                        navigate("/dashboard/home");
                    }}
                    onScheduleAnother={handleScheduleAnother}
                    externalVisit={!!externalVisit}
                    isReschedule={!!props.meetingId}
                />
            ) : (
                <>
                    <StepIndicator
                        currentStep={stepArr[visibleStep - 1].step}
                        totalSteps={stepArr.length}
                        directStepChangeHandler={handleDirectStepChange}
                    />

                    <div className="flex flex-col flex-1 overflow-scroll scrollbar-hide w-full h-full">
                        {stepArr[visibleStep - 1].component}
                    </div>
                </>
            )}
        </div>
    );
};

export default Coaching;
