import React from "react";
import {
    AcademicCapIcon,
    BadgeCheckIcon, BanIcon,
    BriefcaseIcon,
    CalendarIcon,
    ChatAlt2Icon,
    ChatAltIcon, ChatIcon,
    ClipboardCheckIcon,
    ClipboardListIcon, DocumentReportIcon,
    EmojiHappyIcon, ExclamationCircleIcon, ExclamationIcon, FireIcon,
    FlagIcon,
    HeartIcon, HomeIcon,
    LightBulbIcon,
    LightningBoltIcon,
    MicrophoneIcon, OfficeBuildingIcon, CheckCircleIcon,
    SparklesIcon, SwitchHorizontalIcon, ThumbUpIcon, TrendingUpIcon, UserCircleIcon, UserGroupIcon, UserIcon, UsersIcon
} from "@heroicons/react/outline";

export const CoacheeCategoryIcon = {
    internal_career_transition: {
        icon: <LightningBoltIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    meaning_at_work: {
        icon: <ChatAlt2Icon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    strengths_and_values: {
        icon: <HeartIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    feedback: {
        icon: <ChatAltIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    communication: {
        icon: <ChatIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    performance_review: {
        icon: <DocumentReportIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    skills_and_productivity: {
        icon: <AcademicCapIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    leadership: {
        icon: <OfficeBuildingIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    management: {
        icon: <BriefcaseIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    relationships: {
        icon: <UserGroupIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    burnout_prevention: {
        icon: <BanIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    motivation: {
        icon: <FireIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    stress_and_anxiety: {
        icon: <ExclamationCircleIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    quality_of_life: {
        icon: <HomeIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    public_speaking: {
        icon: <MicrophoneIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    confidence: {
        icon: <BadgeCheckIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    default_icon: {
        icon: <LightningBoltIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    }
}

export const Coachee_assessmentScore = {
    assertivity: {
        icon: <FlagIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    }
    ,
    intellect: {
        icon: <LightBulbIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    self_esteem: {
        icon: <EmojiHappyIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    meaning: {
        icon: <SparklesIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    worklife: {
        icon: <BriefcaseIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    management: {
        icon: <ClipboardListIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    communication: {
        icon: <ChatAlt2Icon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    leadership: {
        icon: <BadgeCheckIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    career: {
        icon: <ClipboardCheckIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    organisation: {
        icon: <CalendarIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    career_development: {
        icon: <BriefcaseIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    work_environment:  {
        icon: <UserIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    job_performance: {
        icon: <TrendingUpIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    self_awareness: {
        icon: <UserCircleIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    quality_of_life: {
        icon: <HeartIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    stress_and_anxiety: {
        icon: <ExclamationIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    default_icon: {
        icon: <LightningBoltIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    }
}


export const PeerGrowthObjectiveIcon = {
    getting_results: {
        icon: <TrendingUpIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    communication: {
        icon: <ChatIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    organisation_and_time_management: {
        icon: <CalendarIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    self_awareness: {
        icon: <UserIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    problem_solving: {
        icon: <LightBulbIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    teamwork: {
        icon: <UsersIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    leadership: {
        icon: <BadgeCheckIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    interpersonal_skills: {
        icon: <UserGroupIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    adaptability_and_flexibility: {
        icon: <SwitchHorizontalIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    feedback: {
        icon: <ThumbUpIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    conflict_resolution: {
        icon: <CheckCircleIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    motivation: {
        icon: <FireIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    },
    default_icon: {
        icon: <LightningBoltIcon width="23" height="23" x="0" y="0" color="#ACC7E8"/>
    }
}