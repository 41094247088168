import {FC, useEffect, useState} from 'react';

// Components
import LastSession from '../components/session/LastSession';
import NoLastSession from '../components/session/NoLastSession';
import UpcomingSession from '../components/session/UpcomingSession';


// Actions
// Interfaces
import ISession from '../../../models/ISession';

// Assets
import reportIcon from '../../../assets/img/teams/icons/report.png';
import resourcesIcon from '../../../assets/img/teams/icons/tools.png';
import FAQIcon from '../../../assets/img/teams/icons/information.png';
import FeedbackIcon from '../../../assets/img/teams/360.png';
import HECLogo from '../../../assets/img/teams/HECLogo.png';
import EnedisLogo from '../../../assets/img/teams/EnedisLogo.png';

import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ITeamsAuthData} from "../../../models/ITeamsAuth";
import {jumpToDeepLink, showModal} from "../../../utils/helpers/teams";
import {getLastSession, getUpcomingSession} from "../../../services/api/teams/SessionActions";
import i18n from "../../../utils/lib/i18n";
import ENG from "../locale/eng/home.json";
import Fr from "../locale/fr/home.json";

const Home: FC = () => {
    i18n.addResourceBundle('en', 'home', ENG    );
    i18n.addResourceBundle('fr', 'home', Fr    );
    const {t} = useTranslation('home')
    const [lastSessionLoading, setLastSessionLoading] = useState(true)
    const [lastSession, setLastSession] = useState<ISession>()
    const [totalSessionCount, setTotalSessionCount] = useState<number>()
    const [upcomingSessionLoading, setUpcomingSessionLoading] = useState(true)
    const [upcomingSession, setUpcomingSession] = useState<ISession>()
    let authUser: ITeamsAuthData = JSON.parse(localStorage.getItem('ch_user') || '{}') as ITeamsAuthData
    const first_name = authUser?.first_name
    const navigate = useNavigate()

    const lng = localStorage.getItem("I18N_LANGUAGE") || 'en'
    useEffect(() => {
        if (!authUser.teams_state) {
            authUser.teams_state = true;
            localStorage.setItem('ch_user', JSON.stringify(authUser));
            navigate('/welcome')
        }
        loadUpcomingSession();
        loadLastSession();
    }, []);

    const loadLastSession = () => {
        setLastSessionLoading(true)
        getLastSession().then(response => {
            setLastSessionLoading(false)
            if (Object.keys(response).length) {
                setLastSession(response);
            }
        }).catch(err => {
            console.log(err.response);
        })
    }

    const loadUpcomingSession = () => {
        setUpcomingSessionLoading(true)
        getUpcomingSession().then(response => {
            setUpcomingSessionLoading(false)
            console.log(response);
            if (Object.keys(response).length) {
                setUpcomingSession(response);
                setTotalSessionCount(response.total_meeting_count);
            }
        }).catch(err => {
            console.log(err.response);
        })
    }

    return (
        <>

                <div className="mt-2 flex justify-between w-full items-center">
                    <h1 className="text-2xl font-bold">{t('text_1')} {first_name}!</h1>
                    {authUser?.company_name === 'HEC' && <img className="h-12" src={HECLogo} alt=""/>}
                    {['Enedis-Pilot', 'Enedis'].includes(authUser?.company_name) &&
                        <img className="h-12" src={EnedisLogo} alt=""/>}
                </div>


                <div className="grid w-full grid-cols-12 gap-6">
                    {/* Home content */}
                    <div className={upcomingSession ? 'col-span-12 lg:col-span-9' : 'col-span-12'}>
                        <div className="w-full py-5">
                            <h3 className="mb-3 text-lg font-bold">{t('text_2')}</h3>
                            <div className="grid grid-cols-3 gap-6">
                                <button
                                    onClick={() => jumpToDeepLink("booking")}
                                    className="col-span-3 px-5 py-4 text-white rounded bg-red md:col-span-1"> {authUser?.company_name === 'HEC' ? t('text_3_1') : t('text_3')}
                                </button>
                                <button
                                    onClick={() => jumpToDeepLink("conversations")}
                                    className="col-span-3 px-5 py-4 text-white rounded bg-red md:col-span-1">{t('text_4')}
                                </button>


                                    <button disabled={authUser?.company_name === 'HEC'}
                                            onClick={() => showModal("/assessment", t('text_5'))}
                                        className={lng !== 'fr' ? "disabled:opacity-50 col-span-3 px-5 py-4 text-white rounded bg-red md:col-span-1" : "hidden col-span-3 px-5 py-4 text-white rounded bg-red md:col-span-1"}
                                    >{t('text_5')}
                                    </button>
                                    <button disabled={authUser?.company_name === 'HEC'}
                                            onClick={() => showModal("/assessment-fr", t('text_5'))}
                                        className={lng == 'fr' ? "disabled:opacity-50 col-span-3 px-5 py-4 text-white rounded bg-red md:col-span-1" : "hidden col-span-3 px-5 py-4 text-white rounded bg-red md:col-span-1"}>{t('text_5')}
                                    </button>
                            </div>
                        </div>
                        <div className="w-full py-5">
                            <h3 className="mb-3 text-lg font-bold">{t('text_6')}</h3>
                            <div className="grid grid-cols-4 gap-6">
                                <button disabled={authUser?.company_name === 'HEC'} onClick={() => jumpToDeepLink("reports")}
                                        className="disabled:opacity-50 flex items-center justify-start col-span-2 px-5 py-6 bg-white rounded-md shadow-lg md:col-span-1 text-grey-800">
                                    <div className="p-2 mr-3 bg-red-100 rounded sm:mr-2 md:mr-5">
                                        <img src={reportIcon} alt=""/>
                                    </div>
                                    <div>{authUser?.company_name === 'HEC' ? t('text_7_1') : t('text_7')}</div>
                                </button>
                                <button disabled={authUser?.company_name === 'HEC'} onClick={() => jumpToDeepLink("resources")}
                                        className="disabled:opacity-50 flex items-center justify-start col-span-2 px-5 py-6 bg-white rounded-md shadow-lg md:col-span-1 text-grey-800">
                                    <div className="p-2 mr-3 bg-red-100 rounded sm:mr-2 md:mr-5">
                                        <img src={resourcesIcon} alt=""/>
                                    </div>
                                    <div>{t('text_8')}</div>
                                </button>

                                {/*{authUser?.company_name ==='TestCompany'?*/}
                                <button disabled={['HEC', 'Enedis', 'Enedis-Pilot'].includes(authUser?.company_name)}
                                        onClick={() => showModal("/feedback-emails", "Feedback360", null, 400)}
                                        className="disabled:opacity-50 flex items-center justify-start col-span-2 px-5 py-6 bg-white rounded-md md:col-span-1 drop-shadow-lg text-grey-800">
                                    <div className="p-2 mr-3 bg-red-100 rounded sm:mr-2 md:mr-5">
                                        <img width={36} height={36} src={FeedbackIcon} alt=""/>
                                    </div>
                                    <div>360 Speed</div>
                                </button>

                                {/*:*/}
                                <button disabled={authUser?.company_name === 'HEC'} onClick={() => showModal("/faq", "FAQ")}
                                        className="disabled:opacity-50 flex items-center justify-start col-span-2 px-5 py-6 bg-white rounded-md md:col-span-1 drop-shadow-lg text-grey-800">
                                    <div className="p-2 mr-3 bg-red-100 rounded sm:mr-2 md:mr-5">
                                        <img src={FAQIcon} alt=""/>
                                    </div>
                                    <div>{t('text_9')}</div>
                                </button>

                                {/*}*/}


                            </div>
                        </div>

                        {/*<div className={ showReports == false ? `hidden` : '' }>*/}
                        {/*  <ReportCollapse/>*/}
                        {/*</div>*/}

                        <div className="w-full py-5">
                            <h3 className="mb-3 text-lg font-bold">{t('text_10')}</h3>

                            {lastSession ? <LastSession session={lastSession} company_name={authUser?.company_name}/> :
                                <NoLastSession loading={lastSessionLoading && upcomingSessionLoading}
                                               sessionCount={totalSessionCount}/>}

                        </div>
                    </div>

                    {/* Upcoming session */}
                    {upcomingSession
                        ?
                        <div className="col-span-12 sm:col-span-6 pt-4 lg:col-span-3 ">
                            <h2 className="mb-3 text-lg font-bold">{t('text_21')}</h2>
                            <UpcomingSession session={upcomingSession} loading={upcomingSessionLoading}/>
                        </div>
                        :
                        null
                    }
                </div>

        </>
    )
}

export default Home;
