import React, {ReactElement, useEffect, useState} from 'react';

interface Props {
    delay?: number;
    // playPaused?: ()=>void;
}

const useTimer = ({delay = 1000}: Props) => {
    const timeoutRef = React.useRef<NodeJS.Timeout>();
    const [index, setIndex] = useState(0);
    const [pause, setPause] = useState(false);

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () => {
                // if (!paused) {
                    setIndex((prevIndex) => prevIndex + 1)
                // }
            }, delay);

        return () => {
            resetTimeout();
        };
    }, [index]);

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    return index;
};

export default useTimer;
