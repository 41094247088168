import React from "react";

import { IMeta } from "../../../../../models/IMeta";

import checkmarkIcon from "@img/dashboard/welcome/icon/checkmark.png";

type Props = {
  objective: IMeta;
  onClick: () => void;
  selectedObjectives: IMeta[];
  lng?: string;
};

const CoachingObjective: React.FC<Props> = ({
  objective,
  selectedObjectives,
  onClick,
  lng,
}) => {
  const selected = selectedObjectives.find((obj) => obj.id === objective.id);

  return (
    <div
      className={`py-2 px-3 border-2 rounded-md flex cursor-pointer ${
        selected ? "bg-warm-medium" : ""
      }`}
      onClick={onClick}
    >
      <p className="font-dm_sans basis-5/6 text-sm">
        {lng === "fr" ? objective.name_fr : objective.name}
      </p>
      <div className="flex justify-end items-center basis-1/6">
        {selected ? (
          <img
            src={checkmarkIcon}
            alt=""
            className="object-contain pointer-events-none h-5 w-5"
          />
        ) : null}
      </div>
    </div>
  );
};

export default CoachingObjective;
