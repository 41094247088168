import React from 'react';
interface Props {
    value:number;
    setValue?:(v:number)=>void
}
const ProgressDivs = ({backgroundColorStyle, flexValue,index,setValue}: any) => {
    return (
        <div onClick={setValue?()=>setValue(index+1):undefined}
            style={{
                display: "flex",
                backgroundColor: backgroundColorStyle,
                // flex: flexValue,
                height: "7px",
                width: "12px",
                borderRadius: "100px"
            }} className="cursor-pointer"
        />
    );
};

const DiscreteProgressbar = ({value,setValue}:Props) => {
    const totalProgressBars = 1;
    const progressStatusArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const progressStatusArrayLength = progressStatusArray.length;
    return (
        <div style={{display: "flex", flex: 1, justifyContent: "flex-start", gap: "5px"}}>
            {progressStatusArray.map((item, index) => {
                return (
                    <ProgressDivs
                        key={item}
                        index={index}
                        setValue={setValue}
                        backgroundColorStyle={index < value ? "#FD0054" : "#FD005433"}
                        flexValue={totalProgressBars / progressStatusArrayLength - 0.005}
                    />
                );
            })}
        </div>
    );
};

export default DiscreteProgressbar;
