import {twMerge} from "tailwind-merge";

import {IChatItem} from "../../utils/AICoachingContext";
import BOTIcon from "../../../../../../assets/img/aiCoaching/ai_coachello.svg";
import defaultProfileImg from "@img/icons/default-profile.png";

import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const ChatItem = ({
                      id,
                      content,
                      role,
                      header,
                      footer,
                      showProfile,
                      profile,
                  }: IChatItem) => {

    return (
        <div
            className={twMerge(
                role === "me" ? "flex-row-reverse ml-auto" : "flex-row mr-auto",
                "flex gap-4 items-center"
            )}
        >
            <div
                className={twMerge(role === "assistant" && "border",
                    "rounded-full h-max  ",
                    showProfile ? "opacity-100" : " opacity-0"
                )}
            >
                <img
                    src={role === 'assistant' ? BOTIcon :
                        (profile || defaultProfileImg)}
                    alt=""
                    className=" rounded-full object-cover pointer-events-none h-[41px] min-w-[41px]"
                />
            </div>
            <div
                className={twMerge(
                    "relative px-3 rounded-2xl flex justify-center items-center ",
                    role === "me" ? "bg-[#FCF4EA] max-w-[70%] min-w-[8%]" : `border-[1px] border-[#D6CAB3] bg-white max-w-[70%] min-w-[8%] `,
                )}>
                <div className={twMerge(" relative", "")}>
                    {/*{isLoading?<Loader isFullscreen={false} spinnerColor="red"/>:content}*/}
                    <Markdown className="markdown" remarkPlugins={[remarkGfm]}>{content}</Markdown >
                    {/*duration: {getDuration(index)}*/}
                </div>
            </div>
        </div>
    );
};

export default ChatItem;
