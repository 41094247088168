import {Widget} from '@typeform/embed-react'
import useAuth from "@hooks/useAuth";
import {useEffect, useState} from "react";

export const Assessment = () => {
    const {user} = useAuth();
    const [typeformId, setTypeformId] = useState("TjGf5Xk3");

    useEffect(() => {
        if (user?.companyName === "Automattic") {
            setTypeformId("m4mSSNSb")
        }
    }, []);

    if (!user) {

        return <h2>Please try again !</h2>
    } else {
        return <Widget hidden={{
            useremail: user?.email,
            userlastname: user?.lastName,
            userfirstname: user?.firstName
        }} id={typeformId} style={{width: '100%', height: '60vh', flex: '1 1 auto'}}/>
    }

}
