interface Props {
    text: string | number;
    icon: any;
}

export const NameCell = (props:Props) => {
    return (

            <div
                className="flex items-center gap-1.5">
                <img className="w-10 h-10 rounded-full"
                     src={props.icon}/>
                <span
                    className="">{props.text} </span>


            </div>


    )


}
