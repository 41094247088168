import {withSwitchByCompany} from "../../../../shared/components/withSwitchByCompany";
import OnboardingRegular from "../OnboardingRegular";
import OnboardingAI from "../Mentoring/OnboardingAI";

const DynamicOnBording = withSwitchByCompany(OnboardingRegular, OnboardingAI, 'Accenture');
const Onboarding = () => {
    return (
        <DynamicOnBording/>
    );
};

export default Onboarding;
