import React, { useEffect } from 'react';
import {useTranslation} from "react-i18next";
import i18n from "../../../../utils/lib/i18n";
import ENG from "../../locale/eng/misc.json";
import Fr from "../../locale/fr/misc.json";
import {useNavigate, useSearchParams} from 'react-router-dom';
import useAuth from '@hooks/useAuth';
import {postSlackCode} from "../../../../services/api/slackApi";
import {toast} from "react-toastify";

const Success: React.FC = () => {
    i18n.addResourceBundle("en", "misc", ENG);
    i18n.addResourceBundle("fr", "misc", Fr);
    const {t} = useTranslation("misc");
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { user } = useAuth();

    const handleClick = () => {
        navigate("/dashboard/coaching");
    }

    useEffect(() => {
        const code = searchParams.get("code");
        const state = searchParams.get("state");
        const companyId = user?.companyId;

        if (code && companyId) {
            const data = {
                code: code,
                state: state,
                company_id: companyId
            };
            postSlackCode(data)
                .then((res) => {
                    if (res.error) {
                        toast.error(res.message);
                    } else {
                        toast.success(res.message);
                        searchParams.delete("code");
                        setSearchParams(searchParams);
                    }
                })
                .catch((e) => {
                    toast.error(e.response.data.message);
                });
        }
    }, []);

    return (
        <div className="flex flex-col gap-5 py-20 justify-center items-center bg-white mr-2 mt-2 rounded-md">
            <h2 className="font-bold text-black font-dm_sans  pb-3">
                {t("success_page.title")}
            </h2>
            <div className="w-7/12">
                <p className="text-lg pb-6 font-dm_sans ">
                    {t("success_page.header_text")}
                </p>
                <h3 className="font-dm_sans font-bold py-2">
                    {t("success_page.subtitle")}
                </h3>
                <ul className="list-disc py-3">
                    <li>
                        <p className="pb-2">
                            <span className="font-bold pr-1 ">{t("success_page.item1_header")}: </span>
                            {t("success_page.item1_text")}
                        </p>
                    </li>
                    <li>
                        <p className="pb-2">
                            <span className="font-bold pr-1 ">{t("success_page.item2_header")} : </span>
                            {t("success_page.item2_text")}
                        </p>
                    </li>
                </ul>
                <p className="text-base pb-5 font-dm_sans">
                    {t("success_page.bottom_text")}
                </p>
            </div>
            <button className="hover:shadow-lg bg-red rounded-md p-2  text-white flex gap-2 items-center" onClick={handleClick}>
                {t("success_page.button_text")}
            </button>
        </div>
    );
};

export default Success;
