import {msalInstance} from "../lib/microsoft";
import {AccountInfo, AuthenticationResult, EndSessionRequest, RedirectRequest} from "@azure/msal-browser";
import {useEffect, useState} from "react";

interface MSUser {
    account: AccountInfo | null;
    accessToken: null | string;

}

const useMSAuth = () => {
    // const [msUser, setMsUser] = useState<MSUser>()
    //

    const loginRequest = {
        scopes: ["User.Read"]
    };
    /**
     * Handles the response from a popup or redirect. If response is null, will check if we have any accounts and attempt to sign in.
     * @param response
     */
    // const handleResponse = (response: AuthenticationResult | null) => {
    //     if (response !== null) {
    //         setMsUser({
    //             account: response.account,
    //             accessToken: response.accessToken
    //         })
    //     } else {
    //
    //     }
    //
    //
    // }
    /**
     * Calls getAllAccounts and determines the correct account to sign into, currently defaults to first account found in cache.
     * TODO: Add account chooser code
     *
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    const getAccount = (): AccountInfo | null => {
        // need to call getAccount here?
        const currentAccounts = msalInstance.getAllAccounts();
        if (currentAccounts === null) {
            console.log("No accounts detected");
            return null;
        }

        if (currentAccounts.length > 1) {
            // Add choose account code here
            console.log("Multiple accounts detected, need to add choose account code.");
            return currentAccounts[0];
        } else if (currentAccounts.length === 1) {
            return currentAccounts[0];
        }

        return null;
    }
    const loginMS = (from?: string | null) => {
        const loginRedirectRequest: RedirectRequest = {
            ...loginRequest,
            // redirectStartPage: "/dashboard/coaching", // this will redirect to provided url after login
            state: from || undefined,
            redirectUri: from == 'marketplace' ? process.env.REACT_APP_MARKETPLACE_REDIRECT_URI : window.location.origin + process.env.REACT_APP_MSAL_REDIRECT_URI
        }
        console.log(from);
        console.log(loginRedirectRequest);
        // if (from=='marketplace'){
        //
        // }else{
        //
        // }
        msalInstance.loginRedirect(
            loginRedirectRequest
        ).then(r => console.log("this didn't execute: ", r));
    }


    const authenticated = async () => {
        try {
            const authResult = await msalInstance.handleRedirectPromise();
            return authResult
        } catch (error) {
            console.log('An error occurred during authentication:', error);
            // Handle the error
        }
    };

    const logoutMS = (): void => {
        let account: AccountInfo | null = getAccount();
        console.log(account);
        const logOutRequest: EndSessionRequest = {
            account,
            // postLogoutRedirectUri:"http://localhost:4200/auth/login",
        };

        msalInstance.logoutRedirect(logOutRequest);
    }
    return {
        loginMS,
        logoutMS,
        // msUser
        authenticated
    }
};

export default useMSAuth;
