import {useTranslation} from "react-i18next";
import BlackButton from "./BlackButton";


type SummaryBtnProps = {
    setSummaryModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SummaryButton = ({setSummaryModalOpen}: SummaryBtnProps) => {
    const {t} = useTranslation("ai_booking");

    return <div className="flex w-full justify-end mb-4">
        <BlackButton label={t("journey.history.summary")} onClick={() => setSummaryModalOpen(true)}/>
    </div>
}

export default SummaryButton;
