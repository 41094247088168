import React, {createRef, useCallback, useContext, useEffect, useMemo, useState} from "react";

import ChatInput from "./ChatInput";

import {CHAT_TYPE, ChatItem, END_JOURNEY_ACTION, OverviewItem, SESSION_STATE} from "./types";
import {BookingDataContext} from "./ChatDataContext";
import {
    getAIHistory,
    postAIPrompt,
    createJourney, getAIJourneyOverview
} from "../../../../../../services/api/AiBookingActions";
import {useTranslation} from "react-i18next";
import JourneyHistory from "./JourneyHistory";
import Loader from "./Loader";
import SummaryContent from "./SummaryContent";
import {ITimeSlot} from "../../../../../../models/IBooking";

type ChatBoxProps = {
     handleJump:()=>void
    journeyId:number|undefined
    setJouneyId:React.Dispatch<React.SetStateAction<number | undefined>>
};


let suggestionsAbortController = new AbortController();
let processChatAbortController = new AbortController();
let createChatAbortController = new AbortController();
let getScheduleAbortController = new AbortController();
let scheduleAbortController = new AbortController();

const ChatBox = ({handleJump, journeyId, setJouneyId}: ChatBoxProps) => {

    const {t} = useTranslation("ai_booking");
    const ref = useMemo(() => createRef<HTMLDivElement>(), []);
    const {selectedOverviewItem, summary
    } = useContext(BookingDataContext)!

    const [sessionCreating, setSessionCreating] = useState(false);
    const [historyLoading, setHistoryLoading] = useState(false);
    const [question, setQuestion] = useState("");
    const [aiResponseLoading, setAiResponseLoading] = useState<boolean>();
    const [chatItems, setChatItems] = useState<ChatItem[]>([]);
    const [promptOnProcess, setPromptOnProcess] = useState(false);
    const [endOfSessionState, setIsEndOfSessionState] = useState<SESSION_STATE>(SESSION_STATE.DEFAULT);
    const [progress, setProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isAtBottom, setIsAtBottom] = useState(true);

    useEffect(() => {
        resetStates()
        getAIJourneyOverview().then(res=>{
            if (res && res?.length && res?.length > 0){
                const id = res[res.length-1].id
                if(id){
                    setJouneyId(id)
                    loadHistory(id)
                }else{
                    startJourney()
                }
            }else{
                startJourney()
            }
        })
    }, []);

    const resetStates = () => {
        scrollToBottom()
        abortNetworkCalls()

        setChatItems([]);
        setQuestion("");
        setIsEndOfSessionState(SESSION_STATE.DEFAULT)
        setProgress(0)
        setIsAtBottom(true)

        setIsLoading(false)
        setAiResponseLoading(undefined);
        setHistoryLoading(false);
        setPromptOnProcess(false)
        setSessionCreating(false);
    }

    const abortNetworkCalls = () => {
        createChatAbortController.abort()
        processChatAbortController.abort()
        suggestionsAbortController.abort()
        getScheduleAbortController.abort()
        scheduleAbortController.abort()

        createChatAbortController = new AbortController()
        processChatAbortController = new AbortController()
        suggestionsAbortController = new AbortController()
        getScheduleAbortController = new AbortController()
        scheduleAbortController = new AbortController()
    }

    const updateSessionState = (currentChatItems?: ChatItem[]) => {
        if (!currentChatItems) currentChatItems = chatItems
        setChatItems([
                    ...currentChatItems,
                    {
                        role: "end_of_session",
                        content: ""
                    }
                ])
        setIsEndOfSessionState(SESSION_STATE.JOURNEY_ENDED)
    }


    const loadHistory = useCallback((chat_id:number) => {
        if (historyLoading) return

        setHistoryLoading(true);
        let newChatItems: ChatItem[]
        chat_id && getAIHistory(chat_id)
            .then((data) => {
                newChatItems = [
                    ...data?.history,
                ]
                if (data.last_step) {
                    updateSessionState(newChatItems)
                }else{
                    setChatItems(newChatItems)
                }
                setProgressByHistory(data?.history)
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setHistoryLoading(false)
                if (ref && ref.current) {
                    scrollToBottom()
                }
            });

    }, [])

    const setProgressByHistory = (history: any[]) => {
        const steps = history?.map((item: any) => item?.step)
        const step = steps.length ? Math.max(...steps) : 0
        setProgress(Math.max(step, 0))
    }
    const setProgressByPrompt = (step: number) => {
        if (step > progress) setProgress(step)
    }

    const startJourney = () => {
        setSessionCreating(true)
        const newPrompt = "Start"
        createJourney()
            .then((data) => {
                const newChatItems:ChatItem[] = [
                    ...chatItems,
                    {role: "assistant", content: ""},
                ]
                chatItems && setChatItems(newChatItems);
                postPrompt(data.id, newPrompt,newChatItems);
                setJouneyId(data.id)
            })
            .catch((err) => {
                console.log(err)
                setAiResponseLoading(undefined)
            }).finally(() => setSessionCreating(false))
    }

    const handleNewPrompt = (newPrompt: string) => {
        if (aiResponseLoading) return;
        if (journeyId) {
            const newChatItems:ChatItem[] = [
                ...chatItems,
                {role: "user", content: newPrompt},
                {role: "assistant", content: ""},
            ]
            chatItems && setChatItems(newChatItems);
            setQuestion("");
            postPrompt(journeyId, newPrompt,newChatItems);
        }
    }

    const postPrompt = (id: number, prompt: string, currentChatItems:ChatItem[]) => {
        setAiResponseLoading(true);
        abortNetworkCalls()
        setPromptOnProcess(true)
        postAIPrompt(id, prompt)
            .then((data) => {
                const newItems = appendReply(data,currentChatItems)
                if (data?.last_step) {
                    updateSessionState(newItems)
                }
                setAiResponseLoading(false);
                setPromptOnProcess(false)
                setProgressByPrompt(data?.step)
            })
            .catch((err) => {
                console.log(err)
                setAiResponseLoading(undefined)
            })
    }

    const appendReply = (data: any, currentChatItems:ChatItem[]) => {
        if (currentChatItems?.length > 0) {
            const items = currentChatItems.map((chatItem, index) => {
                    if (index === currentChatItems.length - 1) {
                        return {...chatItem, content: data?.reply};
                    }
                    return chatItem;
                });
            setChatItems(items);
            return items
        } else {
            const items:ChatItem[] = [{role: "assistant", content: data?.reply, },]
            setChatItems(items);
            setSessionCreating(false)
            return items
        }
    }


    const scrollToBottom = useCallback(() => {
        // scrolls to the bottom when a new message is pushed to the chat
        if (!(ref && ref.current)) return;
        const scrollHeight = ref.current.scrollHeight;
        const height = ref.current.clientHeight;
        const maxScrollTop = scrollHeight - height;
        ref.current.scrollTo({
            top: maxScrollTop > 0 ? maxScrollTop : 0,
            behavior: 'smooth',
        })
    }, [ref])

    useEffect(() => {
            setTimeout(() => scrollToBottom(), 500)
        },
        [promptOnProcess]
    );
    useEffect(() => {
            setTimeout(() => scrollToBottom(), 10)
        },
        [endOfSessionState]
    );

    const maxProgress =  11
    return (
        <div className="flex flex-col w-full h-full relative gap-4 justify-end">
            {
                progress > 0 &&
                <div className="absolute -top-2 w-full bg-gray-200 rounded-full h-1.5 mb-2 z-10">
                    <div className="bg-red h-1.5 rounded-full"
                         style={{width: `${100 * progress / maxProgress || 0}%`}}/>
                </div>
            }
            <div
                ref={ref}
                className={`flex flex-col gap-4 pb-4 h-full overflow-y-scroll scrollbar-hide px-3`}
            >
                {historyLoading &&
                    <div
                        className={`mt-4 relative w-full flex items-center transition-all duration-500 ${historyLoading ? "h-[18px]" : "h-0"}`}>
                        <Loader isFullscreen={false} spinnerColor="#D6CAB3"/>
                    </div>
                }
                {isLoading &&
                    <div
                        className={`absolute w-full h-full flex items-center transition-all ease-in-out duration-1000 bg-white z-40 bg-opacity-75`}>
                        <Loader isFullscreen={false} spinnerColor="red"/>
                    </div>
                }
                <JourneyHistory historyLoading={historyLoading || sessionCreating}
                                aiResponseLoading={aiResponseLoading}
                                chatItems={chatItems}
                                parentHeight={ref && ref.current && ref.current.clientHeight || 200}
                                setSummaryModalOpen={handleJump}
                                setSummaryLoading={(val) => {
                                    setTimeout(() => {
                                        setIsLoading(val)
                                    }, 200)
                                }}
                                isAtBottom={isAtBottom}
                />
            </div>
            {
                endOfSessionState === SESSION_STATE.DEFAULT && <ChatInput
                    onNewPrompt={handleNewPrompt}
                    question={question}
                    setQuestion={setQuestion}
                    isDisabled={promptOnProcess || sessionCreating}
                    placeholder={t("chat_placeholder")}
                />
            }
        </div>
    );
};

export default ChatBox;
