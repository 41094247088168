import React from 'react';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './AcceleratorChart.css'

interface Props {
    percentage: number;
    range?: [number,number];
    isPositive?: boolean
}

const AcceleratorChart = ({percentage,range=[0,10], isPositive = false}: Props) => {

    let color = isPositive ?  "rgba(180, 225, 198, 1)" : "rgba(254, 203, 196, 1)"
    let color_trail = isPositive ? "rgba(180, 225, 198, 0.23)" : "rgba(254, 203, 196, 0.23)"

    return (<div className="flex flex-col">
            <div className="overflow-hidden aspect-video">
                <CircularProgressbar
                    value={percentage}

                    strokeWidth={12}
                    circleRatio={0.5}
                    styles={{
                        // Customize the root svg element
                        root: {transform: "rotate(0.75turn)"},
                        // Customize the path, i.e. the "completed progress"
                        path: {
                            // Path color
                            stroke: color,
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: 'round',
                            // Customize transition animation
                            transition: 'stroke-dashoffset 0.5s ease 0s',
                            // Rotate the path
                            // transform: 'rotate(0.25turn)',
                            transformOrigin: 'center center',
                        },
                        // Customize the circle behind the path, i.e. the "total progress"
                        trail: {
                            // Trail color
                            stroke: color_trail,
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: 'round',
                            // Rotate the trail
                            // transform: 'rotate(0.25turn)',
                            transformOrigin: 'center center',
                        },

                        // Customize background - only used when the `background` prop is true
                        background: {
                            fill: '#FD0054',
                        },
                    }}
                />
            </div>
            <div className="flex justify-between px-2 mt-1">
                <p className="opacity-25 text-black">{range[0]}</p>
                <p className="opacity-25 text-black -mt-1">{range[1]}</p>
            </div>
        </div>
    );
};

export default AcceleratorChart;