import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import CoachCard from "./CoachCard";
import CoachCardSkeleton from "./CoachCardSkeleton";
import LoaderContainer from "../../../../../../shared/components/LoaderContainer";
import ChatGuide from "../../../../../../shared/components/ChatGuide";
import InfoPopover from "../../../../../dashboard/components/InfoPopover";
import HT from "../../../../../dashboard/pages/OnboardingRegular/components/HighlightedText";

import { getCoach } from "../../../../../../services/api/coachApi";
import ICoach from "../../../../../../models/ICoachAI";

type Props = {
  coaches: ICoach[];
  // selectedCoach: ICoach | null;
  handleSelectCoach: (coach: ICoach) => void;
  playCoachVideo: (url: string, title: string) => void;
  onMount?: () => void;
};

const Coaches = ({
  coaches,
  handleSelectCoach,
  playCoachVideo,
  onMount,
}: Props) => {
  const { t } = useTranslation("booking");

  const [isAllCoaches, setIsAllCoaches] = useState(true);

  const [recommendedCoaches, setRecommendedCoaches] = useState<ICoach[]>([]);
  const [recommendedMentors, setRecommendedMentors] = useState<ICoach[]>([]);

  const [previouslyBookedCoach, setPreviouslyBookedCoach] = useState<ICoach>();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    onMount?.();
  }, []);

  useEffect(() => {
    const foundReservedCoach = coaches.find((coach) => coach.reserved);
    const filteredCoaches = coaches.filter((coach) => coach.recommended && !coach.isMentor);
    const filteredMentors = coaches.filter((coach) => coach.recommended && coach.isMentor);
    console.log("filteredCoaches",filteredCoaches)
    console.log("filteredMentors",filteredMentors)
    if (coaches.length) {
      if (filteredCoaches.length || foundReservedCoach) {
        setRecommendedCoaches([filteredCoaches[0]]);
        setRecommendedMentors([filteredMentors[0]]);
        setIsAllCoaches(false);
      } else {
        setIsAllCoaches(true);
      }
      setIsLoading(false);
    }
  }, [coaches]);

  const handleOrientationSession = () => {
    getCoach(47).then((coach) => handleSelectCoach(coach));
  };

  const highlightedTextEventHandlers = [
    undefined,
    undefined,
    undefined,
    handleOrientationSession,
  ];

  const regEx = /(\*[^*]+\*|[^*]+)/g;
  const parts = t("coaches.description").split(regEx);

  const getStepOneDescription = () => {
    let currentHighlightTextNo = 1;
    return parts.map((part, index) => {
      if (part.includes("*")) {
        const clickHandler =
          highlightedTextEventHandlers[currentHighlightTextNo - 1];
        currentHighlightTextNo += 1;
        return (
          <HT
            key={index}
            className={`${clickHandler !== undefined ? "cursor-pointer" : ""}`}
            onClick={clickHandler}
          >
            {part.split("*")[1].split("*")[0]}
          </HT>
        );
      }
      return part;
    });
  };
  const step_1_desc = useMemo(() => getStepOneDescription(), []);

  const skeletons = [1, 2];

  const getRecommendedCoaches = (recommendedCoaches : ICoach[], isMentor:boolean) => {
    return <div
              className={`flex flex-col px-4 pt-3 pb-4 rounded-md h-max  ${
                recommendedCoaches.length === 2 ? "col-span-2" : "col-span-1"
              } ${isMentor?"bg-[#4478FF] bg-opacity-20":"bg-[#F4D4D4]"}`}
            >
              <p className="font-bold text-center mb-3 text-black text-sm h-10">
                {isMentor? "Recommended Mentor for you":"Recommended Coach for you"}
              </p>

              <ul
                className={`h-full grid w-full gap-x-4 gap-y-8 relative ${
                  recommendedCoaches.length === 2
                    ? "grid-cols-2"
                    : "grid-cols-1"
                }`}
              >
                {recommendedCoaches.map((coach) => (
                  <CoachCard
                    key={coach.id}
                    coach={coach}
                    onSelect={() => handleSelectCoach(coach)}
                    isSelected={false}
                    playCoachVideo={playCoachVideo}
                  />
                ))}
              </ul>
            </div>
  }

  return (
    <>
      {isLoading ? (
        <LoaderContainer />
      ) : !isAllCoaches ? (
        <div className="grid w-full grid-cols-4 gap-y-5 gap-x-5 mt-5 pb-8 h-full">
          {recommendedCoaches.length ? (
            getRecommendedCoaches(recommendedCoaches, false)
          ) : null}
          {recommendedMentors.length ? (
            getRecommendedCoaches(recommendedMentors, true)
          ) : null}

          <div
            className={`flex flex-col pt-3 pb-2 rounded-md h-max relative col-span-2`}
          >
            <p className="font-bold text-center mb-3 text-transparent text-sm h-10">
              {t("coaches.based_on_your_assessment")}
            </p>
            <div
              className="bg-white absolute px-10 border-black border-2 cursor-pointer hover:bg-black group
                    rounded z-10 right-0 top-0 bottom-0 left-0 h-10 w-max m-auto flex justify-center items-center"
              onClick={() => setIsAllCoaches(true)}
            >
              <p className="text-black font-bold font-dm_sans m-0 text-sm group-hover:text-white">
                {t("coaches.load_more_coaches")}
              </p>
            </div>
            <ul
              className={`h-full grid w-full gap-x-4 gap-y-8 relative grid-cols-2`}
            >
              {skeletons.map((num) => (
                <CoachCardSkeleton key={num} />
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-4 w-full gap-x-5 gap-y-5 mt-5 pb-8">
          {coaches.map((coach) => (
            <CoachCard
              key={coach.id}
              coach={coach}
              onSelect={() => handleSelectCoach(coach)}
              isSelected={false}
              playCoachVideo={playCoachVideo}
              displayExpertType={true}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default Coaches;
