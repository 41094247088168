import jwt_decode from 'jwt-decode';

import {loginWithTeams} from "./LoginAction";
import * as microsoftTeams from "@microsoft/teams-js";
import {getLocale} from "../../../utils/helpers/teams";
import i18n from "../../../utils/lib/i18n";
import {ITeamsAuthData, ITeamsLoginRequestData} from "../../../models/ITeamsAuth";


export const authenticate = async () => {
  const teamsToken = await getClientSideToken();
  // console.log(teamsToken);
  const tokenDecoded = getDecodedAccessToken(teamsToken);
  const teamsLoginRequestData: ITeamsLoginRequestData = {
    teams_token: teamsToken,
    email: tokenDecoded.upn || tokenDecoded.preferred_username,
    tid: tokenDecoded.tid,
    aad_id: tokenDecoded.oid,
    first_name: tokenDecoded.name,
  };

  // console.log(tokenDecoded);

  await loginWithTeams(teamsLoginRequestData)
    .then(res => {
      const teamsAuthData: ITeamsAuthData = {
        email: res.data.email,
        first_name: res.data.first_name,
        last_name: res.data.last_name,
        company_id: res.data.company_id,
        company_name: res.data.company_name,
        role: res.data.role,
        user_id: res.data.user_id,
        image_url: res.data.image_url,
        teams_token: teamsToken,
        tid: tokenDecoded.tid,
        teams_state:res.data.teams_state
      };

      localStorage.setItem('ch_user', JSON.stringify(teamsAuthData));
      // console.log(teamsAuthData);
      const language = localStorage.getItem("I18N_LANGUAGE")
      if (!language) {
        getLocale().then((lang:any) => {
          if (lang) {
            if (  ['en', 'fr'].includes(lang)) {
              if (teamsAuthData?.company_name === 'HEC'){
                lang = 'en'
              }
              i18n.changeLanguage(lang);

              // console.log(teamsAuthData);

              localStorage.setItem("I18N_LANGUAGE", lang);
            }
          }

        })
      }
    }).catch(err => {
      console.log(err.response);
    })
}

const getDecodedAccessToken = (token: string): any => {
  try {
    return jwt_decode(token);
  } catch (Error) {
    return null;
  }
}

const getClientSideToken = (): any => {
  return new Promise((resolve, reject) => {
    microsoftTeams.app.initialize().then(r => {
      microsoftTeams.authentication.getAuthToken().then(result =>
      {

        resolve(result);

      }).catch(err => {
        console.error(err.response)
        reject('Error getting token: ' + err);
      });
    })



  });
}
