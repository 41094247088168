import Button from "../../../components/Button";

import HumanCoach1 from "../../../../../assets/img/aiCoaching/human_coach_1.svg";
import HumanCoach2 from "../../../../../assets/img/aiCoaching/human_coach_2.svg";
import HumanCoach3 from "../../../../../assets/img/aiCoaching/human_coach_3.svg";
import {useNavigate} from "react-router-dom";
import i18n from "../../../../../utils/lib/i18n";
import ENG from "../../../locale/eng/aibooking.json";
import Fr from "../../../locale/fr/aibooking.json";
import {useTranslation} from "react-i18next";
import {Dispatch, SetStateAction} from "react";

type Props = {
    setIsHumanCoach: Dispatch<SetStateAction<boolean>>
}
const HumanCoachCard = ({setIsHumanCoach}: Props) => {
    const nav = useNavigate()
    i18n.addResourceBundle("en", "aibooking", ENG);
    i18n.addResourceBundle("fr", "aibooking", Fr);
    const {t} = useTranslation("aibooking");
    return (
        <div
            className="bg-white rounded p-4 gap-4 border-v2_grey-300 h-max w-full flex flex-col justify-between
                     lg:lg:border-[1px] lg:w-[450px] lg:h-full overflow-auto"
        >
            <div className="relative flex flex-row justify-center items-center gap-16 my-10 h-full">
                <img
                    src={HumanCoach2}
                    alt=""
                    className="object-cover pointer-events-none"
                />
                <img
                    src={HumanCoach1}
                    alt=""
                    className="absolute right-0 left-0 m-auto object-cover pointer-events-none"
                />
                <img
                    src={HumanCoach3}
                    alt=""
                    className="object-cover pointer-events-none"
                />
            </div>
            <div className="flex flex-col gap-5">
                <p className="font-dm_sans m-0 text-base text-center font-semibold text-black">
                    {t("human_coach.text0")}
                </p>
                <p className="font-dm_sans m-0 text-sm text-black">
                    {t("human_coach.text1")}
                </p>
                <Button
                    onClick={() => setIsHumanCoach(true)}
                    className="absolute w-full h-[48px] flex items-center justify-center text-xs px-5 py-2.5 mb-2.5
                    border-2 border-red bg-red text-white font-bold lg:mb-0"
                >
                    {t("human_coach.text0")}
                </Button>
            </div>
        </div>
    );
};

export default HumanCoachCard;
