import {useTranslation} from "react-i18next";

import ToolCard from "./components/ToolCard";

import {useNavTitle} from "@hooks/useNavTitle";
import i18n from "../../../../utils/lib/i18n";
import ENG from "../../locale/eng/misc.json";
import Fr from "../../locale/fr/misc.json";

import teamsImg from "@img/dashboard/teams.png";
import slackImg from "@img/dashboard/slack.png";
import React from "react";
import useAuth from "@hooks/useAuth";

const Tools = ({...props}) => {
    i18n.addResourceBundle("en", "misc", ENG);
    i18n.addResourceBundle("fr", "misc", Fr);
    const {t} = useTranslation("misc");
    useNavTitle(t(`layout:sidebar.${props.navTitle}`));
    const {user} = useAuth();

    return (
        <div className="m-5">
            <div className="flex gap-5 flex-col lg:flex-row">
                <ToolCard
                    img={teamsImg}
                    title={t("text1")}
                    text={"https://resources.coachello.io/teams-client-manual"}
                    label={t("text2")}
                    cta={<a target="_blank"
                            className="rounded-md flex gap-2 items-center bg-white font-normal text-black px-2 py-1 justify-center disabled:bg-gray-400 "
                            href={process.env.REACT_APP_TEAMS_APP_LINK || '#'}>
                        <img src={teamsImg} className="w-8 h-8"/>
                        Teams app
                    </a>}
                />

                <ToolCard
                    img={slackImg}
                    title={t("text0")}
                    text={"https://resources.coachello.io/slack-client-manual"}
                    label={t("text2")}
                    cta={<div className="flex flex-col gap-5">

                        <div className="flex gap-3 items-center text-left">
                            <p>{t("text3")} <b>{t("text3_1")}</b> {t("text4")}</p>
                            <a target="_blank" className="w-32 hover:border-red shrink-0"
                               href={`https://slack.com/oauth/v2/authorize?client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&scope=channels:read,chat:write,chat:write.customize,groups:history,groups:read,groups:write,im:history,im:write,users:read,users:read.email&user_scope=`}
                            >
                                <img
                                    alt="Add to Slack"
                                    height="40"
                                    width="139"
                                    src="https://platform.slack-edge.com/img/add_to_slack.png"
                                    srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x,
                                                https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                                />
                            </a>
                        </div>

                        <div className="flex gap-3 items-center  text-left">
                            <p>{t("text3")} <b>{t("text3_2")}</b> {t("text4")}</p>
                            <a target="_blank" className="w-32 hover:border-red shrink-0"
                               href={`https://slack.com/oauth/v2/authorize?client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&scope=channels:read,chat:write,chat:write.customize,groups:history,groups:read,groups:write,im:history,im:write,users:read,users:read.email&state=create_new&user_scope=`}
                            >
                                <img
                                    alt="Add to Slack"
                                    height="40"
                                    width="139"
                                    src="https://platform.slack-edge.com/img/add_to_slack.png"
                                    srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x,
                                                https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                                />
                            </a>
                        </div>
                    </div>}
                />
            </div>
        </div>
    );
};

export default Tools;
