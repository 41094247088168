import {CSSProperties} from "react";

import {Widget} from "@typeform/embed-react";
import useAuth from "@hooks/useAuth";

type Props = {
  style?: CSSProperties;
};

export const AssessmentFr = ({ style }: Props) => {
    const {user} = useAuth()
    if (!user) {
    return <h2>Please try again !</h2>;
  } else {
    return (
      <Widget
        hidden={{
            useremail: user?.email,
            userlastname: user?.lastName,
            userfirstname: user?.firstName,
        }}
        id="gFLdbFYl"
        style={{width: "100%", height: "60vh", flex: "1 1 auto"}}
      />
    );
  }
};
