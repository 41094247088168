import React, {useState} from 'react';
import DatePicker from "react-datepicker";

import moment from "moment/moment";
import './datepicker.css'
import {useTranslation} from "react-i18next";

interface DatePopupProps {
    onSelectDate?: (date: Date) => void;
}

const DatePopup: React.FC<DatePopupProps> = ({onSelectDate}) => {
    const {t} = useTranslation("ai_coaching");
    const [value, onChange] = useState(new Date());

    return (
        <div className="">

            <div className="flex gap-2 items-center">

                <DatePicker
                    inline
                    showTimeSelect
                    popperClassName="!z-20"
                    selected={value}
                    onChange={(date) => {
                        onChange(date!);
                    }}
                    minDate={new Date()} // Set minimum date to current date
                    // showMonthYearPicker
                    dateFormat="MMMM d, yyyy h:mm aa"
                    // locale={i18n.language}
                    // customInput={ <MonthLabel/>}
                />


            </div>
            <br/>
            <div className="flex items-center gap-5 ">
                <p className="ml-auto"> {moment(value).format("DD-MM-YYYY h:mm a")}</p>
                <button disabled={!value}
                        className="dashboard-button bg-black"
                        onClick={() => {
                            onSelectDate && onSelectDate(value)
                        }}>
                    {t("journey.schedule")}
                </button>
            </div>

        </div>
    );
};

export default DatePopup;
