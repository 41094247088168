import {FC, useEffect, useState} from 'react';

// Components

import LastSession from '../components/session/LastSession';
import NoLastSession from '../components/session/NoLastSession';
import UpcomingSession from '../components/session/UpcomingSession';

// Actions

// Interfaces
import ISession from '../../../models/ISession';

// Assets
import reportIcon from '../../assets/img/icons/report.png';
import IntellectualIcon from '../../../assets/img/teams/assessmentScore/Intellectual.png';
import SelfEsteemIcon from '../../../assets/img/teams/assessmentScore/Self-esteem.png';
import MeaningIcon from '../../../assets/img/teams/assessmentScore/Meaning.png';
import WorkLifeIcon from '../../../assets/img/teams/assessmentScore/Work-life.png';
import ManagementIcon from '../../../assets/img/teams/assessmentScore/Management.png';
import CommunicationIcon from '../../../assets/img/teams/assessmentScore/Communication.png';
import LeadershipIcon from '../../../assets/img/teams/assessmentScore/Leadership.png';
import AssertivenessIcon from '../../../assets/img/teams/assessmentScore/Assertiveness.png';
import CareerIcon from '../../../assets/img/teams/assessmentScore/Career.png';
import OrganisationIcon from '../../../assets/img/teams/assessmentScore/Organisation.png';



import AssessmentScoreCard from "../components/AssessmentScoreCard";

import IAssessmentScore from "../../../models/IAssessmentScore";

import {useTranslation} from "react-i18next";
import {ITeamsAuthData} from "../../../models/ITeamsAuth";
import {getAssessmentScore} from "../../../services/api/teams/AssessmentActions";
import {showModal} from "../../../utils/helpers/teams";
import i18n from "../../../utils/lib/i18n";
import ENG from "../locale/eng/reports.json";
import Fr from "../locale/fr/reports.json";

const AssessmentScore: FC = () => {
    i18n.addResourceBundle('en', 'reports', ENG    );
    i18n.addResourceBundle('fr', 'reports', Fr    );
    const {t} = useTranslation('reports')

    let authUser: ITeamsAuthData = JSON.parse(localStorage.getItem('ch_user') || '{}') as ITeamsAuthData
    const first_name = authUser?.first_name
    const client_id = authUser?.user_id
    const lng = localStorage.getItem("I18N_LANGUAGE") || 'en'

    const [score, setScore] = useState<IAssessmentScore>()
    const [isFilled, setIsFilled] = useState<boolean>(true)
    useEffect(() => {
        getAssessmentScore(client_id).then(response => {
            if (Object.keys(response).length) {
                setIsFilled(true)
                setScore(response)
            } else {
                setIsFilled(false)
            }


        }).catch(err => {
            console.log(err.response);
        })
    }, []);


    return (
        <>
            <div className={"justify-center flex items-center"}>
                <div className="">
                    {/*{isFilled &&*/}
                    {/*    <h1 className="mt-8 text-2xl font-bold">{t('assessmentscore.welcome')}, {first_name}!</h1>}*/}

                    <div className="grid w-full grid-cols-12 gap-6">
                        {/* AssessmentScore content */}
                        {isFilled ? <div className={'col-span-12 lg:col-span-12'}>
                                <div className="w-full py-5">
                                    <h3 className="mb-3 text-lg font-bold my-4">{t('assessmentscore.title')}</h3>
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                                        <AssessmentScoreCard icon={IntellectualIcon} percentage={score?.intellect || 0}
                                                             text={t('assessmentscore.text_1')}
                                                             toolTip={t('assessmentscore.tooltip_text_1')}/>
                                        <AssessmentScoreCard icon={SelfEsteemIcon} percentage={score?.self_esteem || 0}
                                                             text={t('assessmentscore.text_2')}
                                                             toolTip={t('assessmentscore.tooltip_text_2')}/>
                                        <AssessmentScoreCard icon={MeaningIcon} percentage={score?.meaning || 0}
                                                             text={t('assessmentscore.text_3')}
                                                             toolTip={t('assessmentscore.tooltip_text_3')}/>
                                        <AssessmentScoreCard icon={WorkLifeIcon} percentage={score?.worklife || 0}
                                                             text={t('assessmentscore.text_4')}
                                                             toolTip={t('assessmentscore.tooltip_text_4')}/>
                                        <AssessmentScoreCard icon={ManagementIcon} percentage={score?.management || 0}
                                                             text={t('assessmentscore.text_5')}
                                                             toolTip={t('assessmentscore.tooltip_text_5')}/>
                                        <AssessmentScoreCard icon={CommunicationIcon} percentage={score?.communication || 0}
                                                             text={t('assessmentscore.text_6')}
                                                             toolTip={t('assessmentscore.tooltip_text_6')}/>
                                        <AssessmentScoreCard icon={LeadershipIcon} percentage={score?.leadership || 0}
                                                             text={t('assessmentscore.text_7')}
                                                             toolTip={t('assessmentscore.tooltip_text_7')}/>
                                        <AssessmentScoreCard icon={AssertivenessIcon} percentage={score?.assertivity || 0}
                                                             text={t('assessmentscore.text_8')}
                                                             toolTip={t('assessmentscore.tooltip_text_8')}/>
                                        <AssessmentScoreCard icon={CareerIcon} percentage={score?.career || 0}
                                                             text={t('assessmentscore.text_9')}
                                                             toolTip={t('assessmentscore.tooltip_text_9')}/>
                                        <AssessmentScoreCard icon={OrganisationIcon} percentage={score?.organisation || 0}
                                                             text={t('assessmentscore.text_10')}
                                                             toolTip={t('assessmentscore.tooltip_text_10')}/>
                                    </div>
                                </div>

                            </div> :
                            <div className='col-span-12 lg:col-span-12 my-5 flex flex-col'>

                                <p style={{fontSize: "36px", color: "#FD0054"}}
                                   className={"m-5 text-center"}>{t('assessmentscore.text_11')}</p>
                                <button
                                    onClick={() => showModal("/assessment", "Assess yourself")}
                                    className={lng !== 'fr' ? "mx-auto my-2 col-span-3 px-5 py-3 text-white rounded bg-red md:col-span-1" : "hidden mx-auto my-2 col-span-3 px-5 py-3 text-white rounded bg-red md:col-span-1"}>
                                    {t('assessmentscore.text_12')}
                                </button>
                                <button
                                    onClick={() => showModal("/assessment-fr", "Assess yourself")}
                                    className={lng == 'fr' ? "mx-auto my-2 col-span-3 px-5 py-3 text-white rounded bg-red md:col-span-1" : "hidden mx-auto my-2 col-span-3 px-5 py-3 text-white rounded bg-red md:col-span-1"}>
                                    {t('assessmentscore.text_12')}
                                </button>


                            </div>


                        }
                    </div>

                </div>
            </div>

        </>
    )
}

export default AssessmentScore;
