import ChatBoxItem from "./ChatBoxItem";
import {ChatItem} from "./types";
import {ReactNode, useEffect, useState} from "react";
import SummaryButton from "./SummaryButton";

type ChatBoxProps = {
    historyLoading: boolean;
    aiResponseLoading?: boolean;
    chatItems: ChatItem[];
    parentHeight: number;
    setSummaryModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSummaryLoading?: (isLoading:boolean) => void
    children?: ReactNode
    isAtBottom: boolean
};

const JourneyHistory = ({
                            historyLoading,
                            chatItems,
                            aiResponseLoading,
                            parentHeight,
                            setSummaryModalOpen,
                            setSummaryLoading,
                            children,
                            isAtBottom
                        }: ChatBoxProps) => {

    const [spacerLocation, setSpacerLocation] = useState(-1)
    const [spacerHeight, setSpacerHeight] = useState(0)
    const [lastUserMessageIndex, setLastUserMessageIndex] = useState(0)


    useEffect(() => {
        if (!chatItems || !chatItems.length) return
        setSpacerLocation(-1)
        setSpacerHeight(0)

        const numSessions = chatItems.reduce((count, obj) => {
            return obj.role === "seperator" ? count + 1 : count;
        }, 0);

        // if (numSessions <= 1) {
        let count = 1
        let contentWeight = 0
        for (let i = chatItems.length - 1; i >= 0; i--) {
            if (count > 5) break;
            if (chatItems[i].role === "seperator") {
                setSpacerLocation(i)
                setSpacerHeight(parentHeight - contentWeight - 60)
                break;
            } else {
                const lines = 1 + chatItems[i].content.length / 70
                contentWeight += lines * 20 + 40
            }
            count++
        }
        if (numSessions === 0) {
            setSpacerHeight(parentHeight - contentWeight - 60)
        }
        // }


        let index = -1;
        chatItems?.forEach((item, i) => {
            if (item.role === "user") index = i;
        });
        setLastUserMessageIndex(index)
    }, [chatItems]);

    return (
        <>
            {chatItems.length <= 0 && historyLoading ? (
                <div className="flex flex-row justify-center items-center">
                    {/*<RsLoader size="md"/>*/}
                </div>
            ) : (
                <div className="flex flex-col gap-4" style={{minHeight: parentHeight + 2}}>
                    {chatItems?.map((item, index) => {

                        return (
                                item.role === "end_of_session" ?
                                    <div key={index}
                                         className={`flex flex-col my-2 py-3 items-center justify-center text-xs`}>
                                        {index !== 0 &&
                                            <SummaryButton setSummaryModalOpen={setSummaryModalOpen}/>
                                        }
                                    </div>
                                    :
                                    <div className={"flex flex-col"} key={index}>
                                        {index === spacerLocation + 1 && <div style={{height: spacerHeight}}
                                                                              className="w-full transition-all ease-in-out duration-500"/>}
                                        <ChatBoxItem
                                            key={index}
                                            mine={item.role === "user"}
                                            content={item.content}
                                            aiResponseLoading={aiResponseLoading}
                                            isLast={index === chatItems.length - 1}
                                            isLastUserMessage={lastUserMessageIndex === index}
                                        />
                                    </div>
                        )
                    })}
                    {children}
                </div>
            )}
        </>
    );
};

export default JourneyHistory;
