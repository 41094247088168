import img_career_dev from "../../../../assets/img/aiCoaching/career_development.png";
import img_work_env from "../../../../assets/img/aiCoaching/work_environment.png";
import img_job_per from "../../../../assets/img/aiCoaching/job_performance.png";
import leadership from "../../../../assets/img/aiCoaching/leadership.png";
import communication from "../../../../assets/img/aiCoaching/communication.png";
import stress_and_anxiety from "../../../../assets/img/aiCoaching/stress_and_anxiety.png";
import self_awareness from "../../../../assets/img/aiCoaching/self_awareness.png";
import quality_of_life from "../../../../assets/img/aiCoaching/quality_of_life.png";

export const ObjectiveCategory = {
    career_development: {
        img: img_career_dev,
    },
    work_environment: {
        img: img_work_env,
    },
    job_performance: {
        img: img_job_per,
    },
    leadership: {
        img: leadership,
    },
    communication: {
        img: communication,
    },
    stress_and_anxiety: {
        img: stress_and_anxiety,
    },
    self_awareness: {
        img: self_awareness,
    },
    quality_of_life: {
        img: quality_of_life,
    },
    career_transition: {
        img: img_career_dev,
    },
    meaning_at_work: {
        img: img_career_dev,
    },
    career_planning: {
        img: img_career_dev,
    },
    imposter_syndrome: {
        img: img_career_dev,
    },
    hybrid_work_environment: {
        img: img_work_env,
    },
    influence_and_network: {
        img: img_work_env,
    },
    collective_intelligence: {
        img: img_work_env,
    },
    adaptability_to_change: {
        img: img_work_env,
    },
    public_speaking: {
        img: img_job_per,
    },
    productivity: {
        img: img_job_per,
    },
    time_management: {
        img: img_job_per,
    },
    "feedback_&_recognition": {
        img: img_job_per,
    },
    natural_leadership_posture: {
        img: leadership,
    },
    management_skills: {
        img: leadership,
    },
    authenticity: {
        img: leadership,
    },
    manager___coach_posture: {
        img: leadership,
    },
    assertivity: {
        img: communication,
    },
    empathy: {
        img: communication,
    },
    listening_skills: {
        img: communication,
    },
    conflict_management: {
        img: communication,
    },
    stress_and_anxiety_management: {
        img: quality_of_life,
    },
    well_being_improvement: {
        img: quality_of_life,
    },
    resilience: {
        img: quality_of_life,
    },
    burnout_prevention: {
        img: quality_of_life,
    },
    needs_to_perform: {
        img: self_awareness,
    },
    values_identification: {
        img: self_awareness,
    },
    strengths_exploring: {
        img: self_awareness,
    },
    blind_spots_uncovering: {
        img: self_awareness,
    },
    interests: {
        img: stress_and_anxiety,
    },
    motivation: {
        img: stress_and_anxiety,
    },
    relationships_at_work: {
        img: stress_and_anxiety,
    },
    work_life_balance: {
        img: stress_and_anxiety,
    },
    default: {
        img: quality_of_life,
    },
};