import { ButtonHTMLAttributes, MouseEventHandler } from "react";

import { Loader } from "rsuite";
import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({
  disabled = false,
  loading,
  children,
  className,
  onClick,
  ...rest
}: Props) => {
  return (
    <button
      {...rest}
      onClick={disabled || loading ? undefined : onClick}
      disabled={disabled}
      className={twMerge(
        "relative flex items-center justify-center gap-2 rounded text-sm disabled:opacity-50 focus:outline-none",
        className,
        loading ? "cursor-not-allowed" : ""
      )}
    >
      {loading ? <Loader /> : children}
    </button>
  );
};

export default Button;
