import { useState } from "react";

import { useTranslation } from "react-i18next";

import ChatGuide from "../../../../../shared/components/ChatGuide";
import VerticalExpander from "../../../../dashboard/pages/OnboardingRegular/components/VerticalExpander";
import HT from "../../../../dashboard/pages/OnboardingRegular/components/HighlightedText";

import bookingConfirmedImage from "@img/coaching/img/booking_confirmed.png";

const BookingConfirmed: React.FC = () => {
  const { t } = useTranslation("booking");
  const [showContent, setShowContent] = useState(false);

  return (
    <>
      <ChatGuide
        title={<HT>{t("booking_confirmed.title")}</HT>}
        description={t("booking_confirmed.description")}
        descriptionClassName="font-normal"
        onShowContent={() => setShowContent(true)}
      />
      <div className="w-full flex flex-row justify-center items-center flex-1">
        <VerticalExpander expanded={showContent}>
          <div className="w-[180px] 2xl:w-[200px] my-2">
            <img src={bookingConfirmedImage} alt="Booking Confirmed" />
          </div>
        </VerticalExpander>
      </div>
    </>
  );
};

export default BookingConfirmed;
