import React from 'react';

interface Props {
    name: string;
    rating: number;
    feedbacks: string[];
    reviews: string[];
}

const getStarColor = (rating: number) => {
    const colors = [
        'rgb(254 249 195)',
        'rgb(254 240 138)',
        'rgb(253 224 71)',
        'rgb(250 204 21)',
        'rgb(234 179 8)'
    ];

    const index = Math.min(Math.floor(rating) - 1, colors.length - 1);
    return colors[index];
};

const Star = ({rating}: { rating: number }) => {

    return (
        <div className="relative w-10 h-10 flex items-center justify-center">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="w-full h-full"
                fill={getStarColor(rating)}
            >
                <path d="M12 17.27L18.18 21 16.54 13.97 22 9.24l-7.19-.62L12 2 9.19 8.62 2 9.24l5.46 4.73L5.82 21z"/>
            </svg>
            <span className="absolute text-white font-semibold text-xs">{rating.toFixed(1)}</span>
        </div>
    );
};

const CompanyReviewCard = (props: Props) => {
    const {name, rating, feedbacks, reviews} = props;

    return (
        <div className="flex flex-col justify-start bg-white rounded">
            <div className="flex justify-between items-center">
                <h5 className="font-semibold">{name}</h5>
                <Star rating={rating}/>
            </div>
            <div className="flex flex-col gap-2">
                {reviews.map((review, index) => (
                    <p key={index} className="text-sm text-gray-700 shadow rounded-md p-2">{review}</p>
                ))}
                {feedbacks.map((feedback, index) => (
                    <p key={index} className="text-sm text-gray-700 shadow rounded-md p-2">{feedback}</p>
                ))}
            </div>
        </div>
    );
};

export default CompanyReviewCard;
