import {FC} from 'react'


import '../css/LinkedinIconStyle.css'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const CoachSkeleton: FC = () => {

    return (
        <div style={{
            height: " 100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        }} className="bg-white rounded-lg h-100">

            <div className="absolute top-3 right-3 z-10">
                <Skeleton width={24} height={24}/>
            </div>


            <div
                className="block w-full overflow-hidden bg-gray-100 rounded-t-lg group aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500">
                <Skeleton style={{top: '-5px'}} height={"100%"} width={"100%"}/>
            </div>
            <div className="px-4 py-4">
                <div className="flex items-center justify-start mb-3">
                    <p className="block py-2 mr-3 font-medium text-gray-900 truncate pointer-events-none">< Skeleton
                        width={100}/></p>

                    <span className="px-1 py-1 mr-2 text-xs font-light bg-gray-100 rounded"> < Skeleton
                        width={50}/></span>
                </div>
                <p className="mb-3 font-light">
                    <div className="flex gap-3 mb-3">< Skeleton width={24} height={24} />
                        <Skeleton width={200} height={24}/>
                    </div>
                </p>
                <p className="mb-2 font-light h-12">
                    <div className="flex gap-3 mb-3">
                        < Skeleton width={24} height={24}/>
                        <Skeleton width={200} height={24}/>
                    </div>
                </p>
                <div className="py-3 h-36">
                    <div className="w-full flex flex-wrap relative">
                        {[1, 2, 3, 4, 5].map((spec, index) => {
                            return <span key={index}
                                         className="px-1 py-1 mb-2 mr-2 text-xs font-light text-yellow-600 rounded bg-red-50">
                                < Skeleton width={50}/></span>
                        })}
                    </div>
                </div>
                <div className="flex items-center justify-center">
                    <Skeleton
                        className="w-full px-5 py-3 text-center text-white rounded bg-red" width={200}/>
                </div>
            </div>
        </div>
    )
}

export default CoachSkeleton;
