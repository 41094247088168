import moment from "moment";
import { Link } from "react-router-dom";
import defaultProfileImg from "@img/icons/default-profile.png";
import {
  CalendarIcon,
  ClockIcon,
  DocumentReportIcon,
  InformationCircleIcon,
  LocationMarkerIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import { FlagIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import ENG from "../locale/eng/reports.json";
import Fr from "../locale/fr/reports.json";
import i18n from "../../../utils/lib/i18n";
interface Props {
  sessionId: number;
  coacheeNote?: string;
  categories?: Array<string>;
  datetime: Date;
  sessionNo: number;
  profile?: any;
  coacheeName: string;
  location?: string;
  locale?: string;
}

const ReportSessionOverview = (props: Props) => {
  i18n.addResourceBundle("en", "reports", ENG);
  i18n.addResourceBundle("fr", "reports", Fr);
  const { t } = useTranslation("reports");
  return (
    <div>
      <div className="card-container mt-5 p-4">
        <div className="flex gap-5 justify-between">
          <p className="font-medium">{t("report_session_overview.session")} {props.sessionNo}</p>
          <div className="flex gap-5">
            <p className="icon-text">
              <CalendarIcon className="text-red" />
              {moment(props.datetime).format("ll")}{" "}
            </p>
            <p className="icon-text">
              <ClockIcon className="text-red" />
              {moment(props.datetime).format("HH:mm")}{" "}
            </p>
          </div>
        </div>
        <hr className="-mx-4" />
        <div className="p-5 flex gap-5 w-full">
          <img
            className="w-12 h-12"
            src={
              props.profile
                ? props.profile
                : defaultProfileImg
            }
            alt=""
          />

          <div className="w-full">
            <h4> {props.coacheeName}</h4>
            <ul className="w-full columns-2 mt-5">
              {props.location && (
                <li className="icon-text">
                  <LocationMarkerIcon className="text-red" />
                  <span>{props.location}</span>
                </li>
              )}
              {props.locale && (
                <li className="icon-text">
                  <FlagIcon className="text-red" />
                  <span>{props.locale}</span>
                </li>
              )}
            </ul>
            <br />
            <ul className="">
              {props.coacheeNote && (
                <li className="icon-text">
                  <InformationCircleIcon className="text-red" />
                  <span>{props.coacheeNote}</span>
                </li>
              )}
            </ul>
            <br />
            <div className="flex gap-5">
              {props.categories?.map((category, i) => (
                <span
                  key={i}
                  className="flex gap-2 rounded bg-red-50 p-1.5 text-sm "
                >
                  <RefreshIcon className="w-4" />
                  {category}
                </span>
              ))}
            </div>
          </div>
        </div>
        <hr className="-mx-4" />
        <div className="flex gap-5 justify-end">
          <Link to={`./create/${props.sessionId}`}>
            <button className="dashboard-button bg-red ">
              <DocumentReportIcon className="w-6" />
              {t("report_session_overview.create_report")}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ReportSessionOverview;
