import { FC } from "react";
import { IProgressSchema } from "src/models/IHome";

const DashboardProgress: FC<{
  progress: IProgressSchema;
}> = ({ progress }) => (
  <div>
    <ProgressBar percentage={progress?.progress ?? 0} />
  </div>
);

const ProgressBar = ({ percentage }: { percentage: number }) => {
  return (
    <div className="w-full rounded bg-[#F2F2F2]">
      <div
        className="rounded font-dm_sans text-white bg-black text-center text-xs py-0.5"
        style={{ width: `${percentage}%` }}
      >
        {percentage.toFixed(1)}%
      </div>
    </div>
  );
};

export default DashboardProgress;
