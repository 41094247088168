
import { userTimeZone } from '../../../utils/helpers/time';
import {ITeamsAuthData} from "../../../models/ITeamsAuth";
import ISession from "../../../models/ISession";
import axios from "axios";

export const getLastSession = () => {
    let authUser:ITeamsAuthData = JSON.parse(localStorage.getItem('ch_user') || '{}') as ITeamsAuthData
    const client_id = authUser?.user_id
    return axios.get<ISession>(`api/meeting/${client_id}/last-meeting?time_zone=${userTimeZone()}&role=client`)
                .then(response => {
                    return response.data;
                });
}

export const getUpcomingSession = () => {
    let authUser:ITeamsAuthData = JSON.parse(localStorage.getItem('ch_user') || '{}') as ITeamsAuthData
    const client_id = authUser?.user_id
    return axios.get<ISession>(`api/meeting/${client_id}/upcoming-meeting?time_zone=${userTimeZone()}&role=client`)
                .then(response => {
                    return response.data;
                });
}
