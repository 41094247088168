import React, {useEffect} from 'react';
import session_in_my_journey from "../../../../../assets/img/aiCoaching/session_in_my_journey.svg";
import ad_hoc_session from "../../../../../assets/img/aiCoaching/ad_hoc_session.svg";
import DetailCard from './DetailCard';
import {useAICoaching} from "../utils/AICoachingContext";
import {useTranslation} from 'react-i18next';

const AiModelPicker = () => {

    const {
        setState, overviewJourneyItems,
        setIsStartState,
        setMessages, setSelectedThreadItem,
        isJourneyVisible
    } = useAICoaching()
    const {t} = useTranslation("ai_coaching");
    const data = [
        {
            title: (!overviewJourneyItems || overviewJourneyItems.length == 0) ? t("model_picker.text1") : t("model_picker.text2"),
            description: "",
            onClick: () => {
                if (!overviewJourneyItems || overviewJourneyItems.length == 0) {
                    setState("journey")
                    setIsStartState(true)
                } else {
                    const overviewItem = overviewJourneyItems.at(0)
                    if (overviewJourneyItems && overviewItem?.subSessions && overviewItem.subSessions.length > 0) {
                        // @ts-ignore
                        const item = overviewJourneyItems.at(0)?.subSessions.at(-1)
                        console.log(item);
                        if (item) {
                            setMessages([])
                            setSelectedThreadItem(item)
                            setState("thread")
                        }
                    } else {
                        setSelectedThreadItem(overviewJourneyItems.at(0))
                        setState("thread")
                        setMessages([])
                    }
                }

            },
            image: session_in_my_journey,

        },
        {
            title: t("model_picker.text3"),
            description: "",
            onClick: () => {
                setState("flash");
                setIsStartState(true);
            },
            image: ad_hoc_session,
        }
    ];

    useEffect(() => {
        // if (!overviewJourneyItems || overviewJourneyItems.length==0) {
        data.unshift({
            title: t("model_picker.text1"),
            description: "",
            onClick: () => {
                setState("journey")
                setIsStartState(true)
            },
            image: session_in_my_journey,

        });
        // }
    }, [overviewJourneyItems]);

    return (
        <div
            className="flex flex-col overflow-y-scroll md:overflow-y-hidden md:flex-row p-10 gap-10 justify-center items-center h-full">
            {(isJourneyVisible ? data : [data[data.length-1]]).map((data, index) => {

                return (
                    <DetailCard
                        key={index}
                        title={data.title}
                        description={data.description}
                        // tooltip={data.tooltip}
                        image={data.image}
                        // timeImg={data.timeImg}
                        onClick={data.onClick}
                        darkMode={true}
                    />
                );
            })}
        </div>
    );
};

export default AiModelPicker;
