export type Language = {
  id: number;
  language: string;
  language_code: string;
};

export type Specialization = {
  id: number;
  specialization: string;
};

export type OverviewItem = {
  id: number;
  title: string | null
  datetime: string;
  ai_model?: string | null;
  image?: string;
  session_no?: number;
  sub_session_ids?: number[];
};

export type ChatItem = {
  role: "user" | "assistant" | "seperator" | "end_of_session" | "scheduled_session";
  content: string;
  prev_session_id?: number;
  create_summary?: boolean;
};
export enum SESSION_STATE {
  JOURNEY_ENDED,
  SESSION_ENDED,
  SCHEDULED,
  DEFAULT, //paused or continuing chat
};

export type Objective = {
  id: number;
  name: string;
  name_fr?: string | null;
  key?: string | null;
};

export type Suggestion = {
  title: string;
  subTitle?: string;
  end?: boolean | undefined;
};

export type SummaryData = {
  summary?: string;
  refMaterials?: string[];
  objectives?: string[];
  actions?: string[];
};

export type BookingCustomizationCardData = {
  title: string;
  description: string;
  onClick: () => void;
  tooltip?: string;
  image: string;
  timeImg?: string;
}[];

export enum CHAT_TYPE {
  UNDECIDED = "undecided",
  JOURNEY = "journey",
  SESSION = "session",
  FLASH = "flash",
  DEEP = "deep",
  FLASH_OR_DEEP = "adhoc" //temp value until flash or deep is selected
}
export enum STANDALONE_CHAT_STATE {
  Level1,
  Level2,
  ONBOARDING,
  CHAT_BOX,
  RATING,
  SUMMARY
}
export enum END_JOURNEY_ACTION {
  CONTINUE,
  SCHEDULE,
}


export type Coach = {
  id: number;
  image_url: string;
  website: string;
  popular: boolean;
  recommended: boolean;
  video_url: string;
  first_name: string;
  last_name: string;
  city: string;
  languages: Language[];
  specializations: Specialization[];
  reserved?: boolean;
};

export type Time = {
  date: string;
  start: string;
  end: string;
};

export type TimeSlot = {
  date: string;
  time?: Time;
  coachTimezone: string;
};

export type OccupiedEvents = {
  [key: string]: string[];
};

export enum SessionSlotSize {
  Sixty = "Sixty",
  Fifteen = "Fifteen",
}