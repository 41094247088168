import info_icon from "@img/dashboard/reports/icons/info_icon.png";

type Props = {
  label?: string;
  tooltip?: string;
};

const FieldLabel = ({ tooltip, label }: Props) => {
  return (
    <div className="flex flex-row py-2 gap-1 items-center text-sm">
      <div>{label}</div>
      {tooltip && (
        <img
          src={info_icon}
          className="w-4 h-4 cursor-pointer"
          alt="info"
          title={tooltip}
        />
      )}
    </div>
  );
};

export default FieldLabel;
