import { useTranslation } from "react-i18next";

import UsageTable from "../../../../shared/components/Table/UsageTable";

import i18n from "../../../../utils/lib/i18n";
import ENG from "../../locale/eng/usage.json";
import Fr from "../../locale/fr/usage.json";
import { useNavTitle } from "@hooks/useNavTitle";

const Usage = ({ ...props }) => {
  i18n.addResourceBundle("en", "usage", ENG);
  i18n.addResourceBundle("fr", "usage", Fr);
  const { t } = useTranslation("usage");
  useNavTitle(t(`layout:sidebar.${props.navTitle}`));

  return (
    <div className="m-5">
      <UsageTable />
    </div>
  );
};

export default Usage;
