import React, {useEffect, useState} from 'react';
import IReport from "../../../models/IReport";
import * as microsoftTeams from "@microsoft/teams-js";
import {ITeamsAuthData} from "../../../models/ITeamsAuth";
import CollapseItem from "./report/CollapseItem";
import Feedback360Item from "./Feedback360Item";
import IFeedback360 from "../../../models/IFeedback360";
import {useTranslation} from "react-i18next";
import {get360FeedbackReports} from "../../../services/api/teams/Feedback360Actions";
import useAuth from "@hooks/useAuth";

const Feedback360 = () => {
    const [loading, setLoading] = useState(false)
    const [feedbacks360, setFeedbacks360] = useState<IFeedback360[]>([])
    const {t} = useTranslation('reports')
    const {user} = useAuth()



    useEffect(() => {
        const userId = user?.id
        setLoading(true)
        get360FeedbackReports(userId!).then(res => {
            console.log(res);
            setLoading(false)
            setFeedbacks360(res);
        })


    }, [])

    return (
        <>
            {!loading && feedbacks360.length>0 && <h3 className="mb-3 text-lg font-bold mt-5  "> {t('feedback_360.text_1')}</h3>}
            {!loading && <div className="bg-white divide-y rounded shadow my-5">

                {feedbacks360.map((feedback360: IFeedback360, index: number) => {
                    // console.log(feedback360);
                    return <Feedback360Item key={index} feedback360={feedback360}/>
                })
                }
            </div>}

        </>
    )
};

export default Feedback360;
