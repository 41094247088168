import {useTranslation} from "react-i18next";

type QuestionsProps = {
    onSelectQuestion: (value: string) => void;
};

const Questions = ({onSelectQuestion}: QuestionsProps) => {

    const {t} = useTranslation("ai_coaching");
    const questions = [
        {
            title: t("questions.q1.title"),
            subTitle: t("questions.q1.sub_title"),
        },
        {
            title: t("questions.q2.title"),
            subTitle: t("questions.q2.sub_title"),
        },
        {
            title: t("questions.q3.title"),
            subTitle: t("questions.q3.sub_title"),
        },
    ];

    return (
        <div className="flex flex-col w-full items-end">
            <div className="grid grid-cols-1 gap-2">
                {questions.map(({title, subTitle}, index) => (
                    <div
                        key={index}
                        className="flex flex-col gap-0.5 rounded-xl border-[1px] border-red"
                        onClick={() => onSelectQuestion(title.slice(0, -3))}
                    >
                        <p className="cursor-pointer font-dm_sans m-0 text-xs px-4 pt-2 text-red font-semibold">
                            {title}
                        </p>
                        <p className="cursor-pointer font-dm_sans m-0 text-xs px-4 pb-2 text-red font-medium opacity-70">
                            {subTitle}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Questions;
