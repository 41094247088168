import Tutorial from "./components/Tutorial";
import ENG from "./locale/en/ai_onboarding.json";
import FR from "./locale/fr/ai_onboarding.json";
import i18n from "../../../../utils/lib/i18n";


const AiOnboarding = () => {
  i18n.addResourceBundle("en", "ai_onboarding", ENG);
  i18n.addResourceBundle("fr", "ai_onboarding", FR);

  return (
    <div className="flex flex-col items-start h-full px-4 py-5 mx-auto sm:px-16 lg:px-20 max-w-8xl bg-linen_gradient ">
      <div className="flex flex-col items-start h-full w-full">
        <Tutorial />
      </div>
    </div>
  );
};

export default AiOnboarding;
