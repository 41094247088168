import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

import CoachWebsite from "./CoachWebsite";

import { ELocationFr } from "../../../../../constants/meta/ELocationFr";

import ICoach from "../../../../../models/ICoach";

import playIcon from "@img/coaching/icons/play-button.png";
import resumeIcon from "@img/icons/resume.png";

type Props = {
  coach: ICoach;
  isSelected: boolean;
  onSelect?: () => void;
  playCoachVideo: (url: string, title: string) => void;
};

const OrientationCoachCard = ({
  coach,
  isSelected,
  onSelect,
  playCoachVideo,
}: Props) => {
  const lng = localStorage.getItem("I18N_LANGUAGE") || "en";
  const { t } = useTranslation("booking");

  let city = coach.city ? coach.city : "-";

  if (lng === "fr" && ELocationFr[city as keyof typeof ELocationFr]) {
    city = ELocationFr[city as keyof typeof ELocationFr];
  }

  const textColor = coach.orientation ? "text-[#26934a]" : "text-red";
  const bgColor = coach.orientation ? "bg-[#26934a]" : "bg-red";
  const borderColor = coach.orientation ? "border-[#26934a]" : "border-red";

  return (
    <div className="bg-white rounded-md relative h-max">
      <div className="block w-full overflow-hidden bg-gray-100 rounded-t-lg group h-[200px]">
        <div
          className="object-contain overflow-hidden bg-center bg-cover flex justify-end items-end group h-full relative"
          style={{ backgroundImage: `url(${coach.image_url})` }}
        >
          <div className="flex absolute top-2 right-2 z-10 gap-2   ">
            {coach.cv_url ? (
              <a
                title="Open this CV"
                data-tip={"hover on me will keep the tooltip"}
                data-for={"cv"}
                target="_blank"
                href={coach.cv_url}
                className="cursor-pointer"
              >
                <img
                  src={resumeIcon}
                  className={twMerge("w-6 h-6  hover:bg-white rounded")}
                  alt=""
                />
              </a>
            ) : null}
            {coach.website ? (
              <div className="cursor-pointer  ">
                <CoachWebsite link={coach.website} />
              </div>
            ) : null}
          </div>

          {coach.video_url ? (
            <div
              className="absolute hidden items-center justify-center cursor-pointer
                    right-0 left-0 top-0 bottom-0 group-hover:flex bg-transparent"
              onClick={() =>
                playCoachVideo(coach.video_url, t("coach_card.coach_video"))
              }
            >
              <img className="object-contain w-8 h-8" src={playIcon} alt="" />
            </div>
          ) : null}
        </div>
      </div>
      <div className="px-4 py-4 flex-1 flex flex-col justify-between h-max min-h-[315px]">
        <div>
          <div className="flex items-center justify-start mb-2">
            <p className="block pb-2 mr-3 font-bold text-black font-dm_sans text-sm truncate pointer-events-none">
              {coach.first_name} {coach.last_name}
            </p>
          </div>

          <p className=" p-3 text-gray font-dm_sans">
            <b className="font-semibold">Optional</b>: In case you need some
            guidance which coach to choose, you have the option to book a
            15-minute orientation call with our coaching manager
          </p>
        </div>
        <div className="flex items-center justify-center mt-3">
          <button
            className={twMerge(
              "w-full py-3 text-center font-bold font-dm_sans text-xs rounded border-2",
              isSelected ? `${textColor} bg-white` : `text-white ${bgColor}`,
              borderColor,
            )}
            onClick={onSelect}
          >
            {isSelected
              ? t("coach_card.selected")
              : coach.orientation
              ? t("coach_card.orientation_session")
              : t("coach_card.book_session")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrientationCoachCard;
