import {dialog} from "@microsoft/teams-js";
import {FC, useState} from 'react';

import reportIcon from '../../../../assets/img/teams/icons/report.png';
import favoriteIcon from '../../../../assets/img/teams/icons/favorite.png';
import receptionIcon from '../../../../assets/img/teams/icons/reception.png';
import Session from '../../../../models/ISession';


import ReviewModal from "../../pages/ReviewModal";
import {useTranslation} from "react-i18next";
import {resolveSrv} from "dns";
import {saveClientFeedback} from "../../../../services/api/teams/ReportActions";
import {jumpToDeepLink, showModal} from "../../../../utils/helpers/teams";
import {IClientFeedback} from "../../../../models/IReport";

interface Props {
    session?: Session;
    company_name?: string;
}

const LastSession: FC<Props> = ({session,company_name}) => {

    const {t} = useTranslation('home')

    const [reviewModalOpen, setReviewModalOpen] = useState(false)
    const [meetingId, setMeetingId] = useState<number | undefined>()

    const submitHandler: dialog.DialogSubmitHandler = (res: dialog.ISdkResponse) => {
        const {err, result }  = res;
        const results = result as any
        if (results) {
            let thumbs = results.rating;

            const clientReview: IClientFeedback = {
                thumbs_reply: thumbs,
                review: results.comment ? results.comment : ""
            }
            console.log(`Submit handler - result: ${result}\r`);
            saveClientFeedback(results.meeting_id, clientReview)
                .then(res => {
                    console.log(res.message);
                })
        }


        console.log(`Submit handler - err: ${err}\r`);

    };
    return (
        <>
            <div
                className="flex flex-col sm:flex-row items-start sm:items-center justify-between px-5 py-5 bg-white rounded-md shadow-lg text-grey-800">
                <div className="flex flex-col sm:flex-row items-start sm:items-center justify-start">
                    {session?.coach_avatar
                        ?
                        <img className="sm:w-16 mr-5 rounded" src={session?.coach_avatar} alt=""/>
                        :
                        <span className="inline-block w-16 h-16 mr-5 overflow-hidden bg-gray-100 rounded-md">
                    <svg className="w-full h-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                        <path
                            d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                    </svg>
                    </span>
                    }
                    <h3 className="text-lg font-bold  mt-3 sm:mt-0 mb-4 sm:mb-0">{session?.coach_name}</h3>
                </div>
                <div className="sm:px-3 flex flex-col sm:flex-row items-start justify-start">
                    <a onClick={() => jumpToDeepLink("reports", null, null, false, session?.id)}
                       className="inline-flex sm:mr-4 mb-2 sm:mb-0 text-sm font-light text-gray-800" href="#">
                        <img className="w-5 h-5 mr-2" src={reportIcon} alt=""/>
                        <span>{company_name==='HEC'?t('text_11_1'):t('text_11')}</span>
                    </a>

                        <button className="inline-flex sm:mr-4 mb-2 sm:mb-0 text-sm font-light text-gray-800"
                                onClick={() => showModal("/review?meeting_id=" + session?.id, "Leave a Review", submitHandler, 500)}>
                            <img className="w-5 h-5 mr-2" src={favoriteIcon} alt=""/>
                            <span>{t('text_12')}</span>
                        </button>


                    <a href="#" className="inline-flex sm:ml-1 text-sm font-light text-gray-800"
                       onClick={() => jumpToDeepLink("booking", session?.coach_id, '/booking?coach_id=' + session?.coach_id)}>
                        <img className="w-5 h-5 mr-2" src={receptionIcon} alt=""/>
                        <span>{t('text_13')}</span>
                    </a>
                </div>
            </div>

        </>
    )
}

export default LastSession
