import ReactTooltip from "react-tooltip";
import {TrashIcon} from "@heroicons/react/solid";
import {deactivateUser} from "../../services/api/companyApi";
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

interface Props {
    text: number;
    icon: any;
}

const IconCell = (props: Props) => {
    const {t} = useTranslation('commons')
    const submit = () => {
        confirmAlert({
            title: '',
            message: t("text3"),
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deactivateData(props.text)
                },
                {
                    label: 'No',
                    onClick: () => console.log('click no')
                }
            ]
        });
    }
    const deactivateData = (clientId: number) => {
        deactivateUser(clientId)
            .then(res => {
                toast.success(t('text5') as string)
            }).catch((e) => {
            console.log("api error");
        })
    }
    return (
        <div
            className="flex items-center gap-1.5">
            <button data-tip data-for="registerTip" onClick={submit}>
                <TrashIcon className="text-red w-5 h-6" data-for='preserve'
                           data-tip={"hover on me will keep the tooltip"}/>
            </button>
            <ReactTooltip delayHide={100} id={'preserve'} place="top" effect="solid">
                {t('text4')}
            </ReactTooltip>
        </div>
    )
}
export default IconCell;
