import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

import CoachCardSkeleton from "../CoachCardSkeleton";

type SkeletonsProps = {
  skeletons: number[];
  showAllCoaches: () => void;
};

const Skeletons = ({ skeletons, showAllCoaches }: SkeletonsProps) => {
  const { t } = useTranslation("booking");

  return (
    <div
      className={twMerge(
        "flex flex-col pt-3 pb-2 rounded-md h-max relative",
        `col-span-${skeletons.length}`,
      )}
    >
      <p className="font-bold text-center mb-3 text-transparent text-sm h-10">
        {t("coaches.based_on_your_assessment")}
      </p>
      <div
        className="bg-white absolute px-10 border-black border-2 cursor-pointer hover:bg-black group
      rounded z-10 right-0 top-0 bottom-0 left-0 h-10 w-max m-auto flex justify-center items-center"
        onClick={showAllCoaches}
      >
        <p className="text-black font-bold font-dm_sans m-0 text-sm group-hover:text-white">
          {t("coaches.load_more_coaches")}
        </p>
      </div>
      <ul
        className={twMerge(
          "h-full grid w-full gap-x-4 gap-y-8 relative",
          `grid-cols-${skeletons.length}`,
        )}
      >
        {skeletons.map((num) => (
          <CoachCardSkeleton key={num} />
        ))}
      </ul>
    </div>
  );
};

export default Skeletons;
