import React, {FC, useMemo} from "react";
import CreatableSelect from "react-select/creatable";
import {IMeta} from "src/models/IMeta";

import {useTranslation} from "react-i18next";
import i18n from "../../../../../utils/lib/i18n";
import ENG from "../../../locale/eng/groups.json";
import Fr from "../../../locale/fr/groups.json";

type ProgramPickerProps = {
    value?: IMeta | null;
    error?: string;
    onChange: (date: IMeta | null) => void;
    programOptions: IMeta[];
    onCreateProgramOption: (value: string) => void;
};

const ProgramPickerField: FC<ProgramPickerProps> = ({
                                                        error,
                                                        value,
                                                        onChange,
                                                        programOptions,
                                                        onCreateProgramOption,
                                                    }) => {
    const { t } = useTranslation("groups:coachees");
    i18n.addResourceBundle("en", "groups:coachees", ENG);
    i18n.addResourceBundle("fr", "groups:coachees", Fr);
    const lng = i18n.language;

    const handleChange = (value: string | null) => {
        if (!value) return onChange(null);
        const labelOption =
            programOptions.find((programMeta) => programMeta.name === value) ?? null;
        if (labelOption) return onChange(labelOption);
        onCreateProgramOption(value);
        onChange({
            name: value,
        });
    };

    const options = programOptions.map((programMeta) => ({
        label: programMeta.name,
        value: programMeta.name,
    }));

    const selectorValue = useMemo(() => {
        return value
            ? {
                label: lng =='fr'? value.name_fr : value.name,
                value: lng == 'fr' ? value.name_fr : value.name,
            }
            : null;
    }, [value,lng]);

    return (
        <div className="flex-1 flex flex-col gap-2">
            <div className="flex flex-col justify-center w-full">
                <CreatableSelect
                    closeMenuOnSelect={true}
                    isClearable
                    placeholder="Select..."
                    noOptionsMessage={() => t("coachees.text7")}
                    onCreateOption={handleChange}
                    onChange={(value) => handleChange(value ? value.value : null)}
                    value={selectorValue}
                    getOptionLabel={(option) => option["label"]}
                    getOptionValue={(option) => option["value"]}
                    options={options}
                    formatCreateLabel = {(inputValue) => `${t("coachees.text13")} "${inputValue}"`}
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            height: "40px",
                            minHeight: "40px",
                            width: "100%",
                            border: "2px solid #E5E7EB",
                            fontSize: "14px",
                            fontWeight: "normal",
                        }),
                    }}
                />
                {error && (
                    <span className="absolute bottom-0 text-xs h-[16px] text-red-500 font-lexend font-normal">
            {error}
          </span>
                )}
            </div>
        </div>
    );
};

export default ProgramPickerField;
