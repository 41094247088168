import {useEffect, useState} from "react";

import {Outlet, useNavigate} from "react-router-dom";

import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import LoaderContainer from "../shared/components/LoaderContainer";

import useGuideStatus from "../utils/hooks/useGuideStatus";
import useAuth from "../utils/hooks/useAuth";
import {ERole} from "../constants/enums";
import {twMerge} from "tailwind-merge";

const DashboardLayout = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const guideStatus = useGuideStatus();

  const isCoacheeUser = user?.role === ERole.Coachee;

  const [navTitle, setNavTitle] = useState("Coachello");
  const [isLoading, setIsLoading] = useState(isCoacheeUser);
  const [isOnboardingComplete, setIsOnboardingComplete] = useState<boolean>(
    !isCoacheeUser
  );

  const [isSidebarOpen, setSideBarOpen] = useState(true);
  const [sidebarShouldCollapse, setSidebarShouldCollapse] = useState(false);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (sidebarShouldCollapse) setSideBarOpen(false);
    }, 5000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [sidebarShouldCollapse]);

  const collapseSideBar = () => {
    // setSideBarOpen(!isSidebarOpen);
    // setSidebarShouldCollapse(false);
  };

  useEffect(() => {
    if (!user) return navigate("/");
    if (isCoacheeUser) {
      guideStatus.checkStatus(() => {
        setIsLoading(false);
        setIsOnboardingComplete(true);
      });
    }
  }, []);

  return (
    <div className="flex h-screen w-screen">
      {isLoading ? (
        <LoaderContainer />
      ) : (
        <>
          <div className="bg-warm flex flex-col w-full overflow-auto bg-linen_gradient">
            {isOnboardingComplete && <Navbar navTitle={navTitle} />}
            <div className="bg-warm m-0 flex-1 flex flex-row bg-linen_gradient">
              {/*{isSidebarOpen && <Sidebar isOpen={isSidebarOpen}/>}*/}
              {isOnboardingComplete && (
                <Sidebar
                  isOpen={isSidebarOpen}
                  onClickSideMenu={collapseSideBar}
                />
              )}
              <main
                className={
                  twMerge(`mt-14`, isOnboardingComplete
                      ? `pl-2 ml-10 flex-auto transform ease-in-out duration-700 ${
                        isSidebarOpen ? "md:ml-52 w-5/6" : "md:ml-20"
                      }`
                      : "bg-warm m-0 flex-1")
                }
              >
                <div className="px-2">
                  <Outlet context={{ navTitle, setNavTitle }} />
                </div>
              </main>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DashboardLayout;
