import {FC, useEffect, useState} from 'react'

import IReport from '../../../../models/IReport'
import CollapseItem from './CollapseItem';
import * as microsoftTeams from "@microsoft/teams-js";
import noResultIcon from '../../../assets/img/noReports.png'
import {ITeamsAuthData} from "../../../../models/ITeamsAuth";

import {useTranslation} from "react-i18next";
import {getPastSessions} from "../../../../services/api/teams/ReportActions";

const ReportCollapse: FC = () => {
    const {t} = useTranslation('reports')

    const [loading, setLoading] = useState(false)
    const [reports, setReports] = useState<IReport[]>([])
    const [meetingId, setMeetingId] = useState<number | null>(null)


    const getMeetingId = () => {
        return new Promise((resolve, reject) => {
            microsoftTeams.app.initialize().then(r =>
                {
                    microsoftTeams.app.getContext().then( context => {
                        console.log("context");
                        if (context.page.subPageId) {
                            resolve(context.page.subPageId);
                        } else {
                            resolve(null);
                        }
                    });
                }

            );

        })
    }
    const getPastReports = (userId: number) => {
        return new Promise((resolve, reject) => {
            getPastSessions(userId)
                .then(res => {
                    console.log("reports");
                    setReports(res);
                    resolve(null);
                })
        })
    }
    useEffect(() => {
        let authUser: ITeamsAuthData = JSON.parse(localStorage.getItem('ch_user') || '{}') as ITeamsAuthData
        const userId = authUser?.user_id
        setLoading(true)
        const promise1 = getPastReports(userId);
        const promise2 = getMeetingId();

        Promise.all([promise1, promise2]).then(data => {
            console.log("all resolve");

            if (data[1]) {
                setMeetingId(data[1] as number);
            }
            setLoading(false)
            console.log(data[1]);
        })
        console.log("promise end");

    }, [])

    return (
        <>  {!loading && reports.length>0 && <h3 className=" text-lg font-bold my-4">{t('report_title')}</h3>}
            {!loading && <div className="bg-white divide-y rounded shadow my-4">

                {reports.map((report: IReport, index: number) => {
                    let isOpen = true;
                    if (report.meeting_id == meetingId) {
                        isOpen = true;
                    } else {
                        isOpen = false;
                    }
                    return <CollapseItem key={index} report={report} isOpen={isOpen}/>


                })
                }
            </div>}
            <div className={"mb-3"} style={{textAlign: "center"}}>
                {/*{(!loading && reports.length == 0) &&*/}
                {/*    <div>*/}
                {/*        <img style={{*/}
                {/*            margin: "auto",*/}
                {/*            width: " 20%",*/}
                {/*            marginTop: "1%"*/}
                {/*        }} className="inline-flex mr-4" src={noResultIcon} alt=""/>*/}
                {/*        <p style={{fontSize: "36px", color: "#FD0054"}} className={"m-7"}>You’re only a few clicks away*/}
                {/*            from seeing your first report</p>*/}
                {/*        <div className="flex items-center justify-center">*/}
                {/*            <button onClick={() => jumpToDeepLink("booking")}*/}
                {/*                    className="w-60 px-5 py-3 text-center text-white rounded bg-red">Book a*/}
                {/*                session*/}
                {/*            </button>*/}
                {/*        </div>*/}

                {/*    </div>*/}
                {/*}*/}
            </div>
        </>
    )
}

export default ReportCollapse
