import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import i18n from "../../../../../utils/lib/i18n";
import ENG from "../../../locale/eng/groups.json";
import Fr from "../../../locale/fr/groups.json";
import {useFormikContext} from "formik";
import InputFormik from "../../../../../shared/components/formik/InputFormik/InputFormik";
import {IMeta} from "../../../../../models/IMeta";
import {CreateGroupFormFields} from "../../GroupCreate.old/validation/groupFormSchema";
import {IGroupBasic} from "../../../../../models/IGroup";
import ExpirationDatePickerField from "../../GroupCreate.old/components/ExpirationDatePickerField";
import ColorPickerField from "../../GroupCreate.old/components/ColorPickerField";
import style from "../../../../../shared/components/formik/InputFormik/InputFormik.module.css";
import ProgramPickerField from "../../GroupCreate.old/components/ProgramPickerField";
import useAuth from "../../../../../utils/hooks/useAuth";
import {getPrograms} from "../../../../../services/api/companyApi";
import MetaApi from "../../../../../services/api/metaApi";
import SelectFormik from "../../../../../shared/components/formik/SelectFormik";
import InformationIcon from "../../../../../shared/components/InformationIcon";
import ReactTooltip from "react-tooltip";
import {twMerge} from "tailwind-merge";

interface IGroupDetailsFormProps {
    groupDetails: IGroupBasic;
    editMode?: boolean;
}

const GroupDetailsForm: React.FC<IGroupDetailsFormProps> = ({
                                                                groupDetails,
                                                                editMode,
                                                            }) => {
    i18n.addResourceBundle("en", "groups:create_group", ENG);
    i18n.addResourceBundle("fr", "groups:create_group", Fr);
    const {t} = useTranslation("groups:create_group");
    const {user} = useAuth();
    const {setFieldValue, values, errors} = useFormikContext<IGroupBasic>();

    const [programMetaData, setProgramMetaData] = useState<IMeta[]>([]);
    const [programOptions, setProgramOptions] = useState<IMeta[]>([]);
    const [languagesOptions, setLanguagesOptions] = useState<IMeta[]>();
    const [isAIOnlyCoachingSelected, setIsAIOnlyCoachingSelected] = useState(false);

    const localLanguage = i18n.language;

    useEffect(() => {
        handleGetMeta();
    }, []);

    const handleGetMeta = () => {
        const lng = MetaApi.getLanguages()
        const programs = getPrograms(user!.companyId)

        Promise.all([lng, programs]).then(([lng, programs]) => {
                setLanguagesOptions(lng)
                setProgramMetaData(programs);
            }
        ).catch((e) => {
            console.log("api error getting meta");
        })


    };

    useEffect(() => {
        setProgramOptions(() => {
            return programMetaData.map((programMeta) => {
                return {name: ((localLanguage == "fr") ? programMeta.name_fr : programMeta.name), id: programMeta.id!};
            });
        })
    }, [programMetaData, localLanguage])

    const handleCreateProgramOption = (value: string) => {
        if (value.trim() === "") return;
        setProgramOptions((currVal) => {
            const alreadyExists = currVal.some(
                (programMeta) => programMeta.name.toLowerCase() === value.toLowerCase()
            );
            if (alreadyExists) return currVal;
            return [
                ...currVal,
                {
                    name: value,
                },
            ];
        });
    };

    useEffect(() => {
        const aiCoachType = values.ai_coach_type?.value;
        if (aiCoachType === "standalone") {
            setIsAIOnlyCoachingSelected(true);
        } else {
            setIsAIOnlyCoachingSelected(false);
        }
    }, [values]);

    return (
        <div className="flex flex-col gap-3 mx-8 py-4 ">
            <p className="font-lexend font-bold text-black text-base">
                {t("create_group.text1")}
            </p>

            <div className="flex flex-col gap-3 px-5">
                <div className="flex gap-3">
                    {!editMode ? (
                        <div className="flex flex-col flex-1 pt-[5px]">
                          <span className={style.label}>
                            {t(CreateGroupFormFields.name.label)}
                          </span>
                            <p className="font-lexend font-light text-black text-sm">
                                {groupDetails.name}
                            </p>
                        </div>
                    ) : (
                        <InputFormik
                            placeholder={t(CreateGroupFormFields.name.placeholder ?? "")}
                            label={t(CreateGroupFormFields.name.label)}
                            name={"name"}
                        />
                    )}

                    {!editMode ? (
                        <div className="flex flex-col flex-1 pt-[5px] pb-2">
                                    <span className={style.label}>
                                      {t(CreateGroupFormFields.description.label)}
                                    </span>
                            <p className="font-lexend font-light text-black text-sm">
                                {groupDetails.description == '' ? "-" : groupDetails.description}
                            </p>
                        </div>
                    ) : (
                        <InputFormik
                            placeholder={t(CreateGroupFormFields.description.placeholder ?? "")}
                            label={t(CreateGroupFormFields.description.label)}
                            name={"description"}
                        />
                    )}
                    <div className="flex flex-col flex-1 pt-[5px]">
                        <span className={style.label}>
                          {t(CreateGroupFormFields.color_code.label)}
                        </span>
                        {!editMode ? (
                            <div className="flex items-center">
                                <div
                                    className="h-[20px] w-[20px] rounded-full mr-1"
                                    style={{backgroundColor: values.color_code ?? ""}}
                                />
                                <p className="font-lexend font-normal text-black text-sm">
                                    {values.color_code == '' ? "-" : values.color_code}
                                </p>
                            </div>
                        ) : (
                            <ColorPickerField
                                color={values.color_code ?? ""}
                                onChange={(value) => {
                                    setFieldValue("color_code", value);
                                }}
                            />
                        )}
                    </div>

                </div>

                <div className="flex gap-3">
                    {!['Enedis-Pilot', 'Enedis'].includes(user?.companyName!) &&
                        <div className="flex flex-col flex-1 pt-[5px]">
                          <span className={style.label + " pb-1"}>
                            {t("create_group.text8_2")}
                        </span>
                            {!editMode ? (

                                <p className="font-lexend font-light text-black text-sm">
                                    {groupDetails.ai_coach_type?.label ?? "-"}
                                </p>

                            ) : (
                                <div className="h-[66px] min-h-[66px] -mt-1">
                                    <SelectFormik isMulti={false} name="ai_coach_type" labelKey="label" valueKey="value"
                                                  options={[
                                                      {
                                                          label: "AI augmented human coaching",
                                                          value: "regular"
                                                      },
                                                      {
                                                          label: "Leadership AI coaching",
                                                          value: "standalone"
                                                      },
                                                      // {
                                                      //     label: "None",
                                                      //     value: "None"
                                                      // }
                                                  ]}
                                        // placeholder={t("create_group.text8_1")}
                                    />
                                </div>
                            )}
                        </div>
                    }
                    {!['Enedis-Pilot', 'Enedis'].includes(user?.companyName!) &&
                        <div className="flex flex-col flex-1 pt-[5px]">
                        <span className={style.label}>
                          {t(CreateGroupFormFields.group_expiration.label)}
                        </span>
                            {!editMode ? (
                                <p className="font-lexend font-light text-black text-sm">
                                    {groupDetails.group_expiration ?? "-"}
                                </p>
                            ) : (
                                <ExpirationDatePickerField
                                    placeholder={t(
                                        CreateGroupFormFields.group_expiration.placeholder ?? ""
                                    )}
                                    value={values.group_expiration}
                                    onChange={(date: string) => {
                                        setFieldValue("group_expiration", date);
                                    }}
                                />
                            )}
                        </div>
                    }
                    {!['Enedis-Pilot', 'Enedis'].includes(user?.companyName!) &&
                        <div className="flex flex-col flex-1 pt-[5px]">
                        <span className={style.label + " pb-1"}>
                            {t("create_group.text8_1")}
                        </span>
                            {!editMode ? (

                                <p className="font-lexend font-light text-black text-sm">
                                    {groupDetails.language?.name ?? "-"}
                                </p>

                            ) : (
                                <div className="-mt-1">
                                <SelectFormik isMulti={false} name="language" labelKey="name" valueKey="id"
                                              options={languagesOptions}
                                    // placeholder={t("create_group.text8_1")}
                                />
                                </div>
                            )}
                        </div>
                    }
                </div>

                <div className="flex gap-3 ">
                    <div className="flex flex-col flex-1 pt-[5px]">
                        <span className={style.label}>{t("create_group.text38")}</span>
                        {!editMode ? (
                            <p className="font-lexend font-light text-black text-sm">
                                {localLanguage == 'fr' ? groupDetails.program?.name_fr : (groupDetails.program?.name ?? "-")}
                            </p>
                        ) : (
                            <ProgramPickerField
                                value={values.program}
                                onChange={(program: IMeta | null) => {
                                    setFieldValue("program", program);
                                }}
                                programOptions={programOptions}
                                onCreateProgramOption={handleCreateProgramOption}
                                error={
                                    typeof errors.program === "string" ? "Invalid value" : ""
                                }
                            />
                        )}
                    </div>
                    <div className=" flex-col flex-1 pt-[5px]">
                        {!isAIOnlyCoachingSelected && (
                            <>
                                <span className={twMerge(style.label, 'flex gap-2 items-center')}>
                            {t(CreateGroupFormFields.credits.label)} <InformationIcon
                                    data-for={'credits'}
                                    data-tip={"hover on me will keep the tooltip"}
                                    className={"w-4 h-4"}/>
                            <ReactTooltip
                                className={"w-60"}
                                delayHide={500}
                                id={'credits'}
                                place="top"
                                effect="solid"
                            >
                              <span>{t("create_group.text3_1")} </span>
                            </ReactTooltip>
                          </span>
                                {!editMode ? (
                                    <div className="flex flex-col flex-1 pt-[5px]">

                                        <p className="font-lexend font-light text-black text-sm">
                                            {Number(groupDetails.credits) > 0 ? groupDetails.credits : "-"}
                                        </p>
                                    </div>
                                ) : (
                                    <div className={"-mt-[5px]"}>
                                        <InputFormik
                                            placeholder={t(CreateGroupFormFields.credits.placeholder ?? "")}
                                            // label={t(CreateGroupFormFields.credits.label)}
                                            name={"credits"}
                                            type="number"
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                    <div className="flex-1">
                        {!isAIOnlyCoachingSelected && (
                            <>
                                {!editMode ? (
                                    <div className="flex flex-col flex-1 pt-[5px]">
                            <span className={style.label}>
                              {t(CreateGroupFormFields.credit_limit_per_user.label)}
                            </span>
                                        <p className="font-lexend font-light text-black text-sm">
                                            {Number(groupDetails.credit_limit_per_user) > 0
                                                ? groupDetails.credit_limit_per_user
                                                : "-"}
                                        </p>
                                    </div>
                                ) : (
                                    <InputFormik
                                        placeholder={t(
                                            CreateGroupFormFields.credit_limit_per_user.placeholder ?? ""
                                        )}
                                        label={t(CreateGroupFormFields.credit_limit_per_user.label)}
                                        name={"credit_limit_per_user"}
                                        type="number"
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>


            </div>
        </div>
    );
};

export default GroupDetailsForm;
