import React, {ChangeEvent, FC, InputHTMLAttributes} from 'react';
import {useEffect, useRef, useState} from 'react';
import {Formik, Form, useField, FieldHookConfig, FieldInputProps, useFormikContext} from 'formik';
import style from "./TextareaFormik.module.css";
import {setClasses} from "../../../../utils/helpers/css";

interface Props {
    label: string;
}

const TextareaFormik: FC<Props & (FieldHookConfig<string>)> = ({label, ...props}) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)

    const [field, meta] = useField(props);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const handleRowCount = () => {
        if (textAreaRef.current){
        const textArea = textAreaRef.current;
        textArea.style.height = "auto";
        textArea.style.height = `${textArea.scrollHeight}px`;
        }
    };
    return (

        <div className={setClasses(style.wrapper, (meta.touched && meta.error) && style.error)}>
            <label className={style.label} htmlFor={props.id || props.name}>{label}</label>

            <textarea className={style.textarea + " pl-2"} rows={3} ref={textAreaRef}
                      onInput={handleRowCount} {...field} {...props as InputHTMLAttributes<HTMLTextAreaElement>}/>

            {meta.touched && meta.error ? (
                <span className="text-red font-extralight">{meta.error}</span>
            ) : null}

        </div>

    );

};

export default TextareaFormik;
