import {twMerge} from "tailwind-merge";
import {motion} from "framer-motion";

import Spinner from "./Spinner";
import BOTIcon from "../../../../../../assets/img/aiCoaching/ai_coachello.svg";
import Loading from "./Loading";
import {useTranslation} from "react-i18next";

interface ITypingItem {
    role: "me" | "assistant";
    isLoading: boolean;
    showProfile: boolean;
    isStartState: boolean;
}

const notifVariants = {
    conditionA: {
        opacity: [0, 1],
    },
    conditionB: {
        opacity: [1, 0],
    },
};

const ChatLoading = ({role, isLoading, showProfile, isStartState}: ITypingItem) => {
    // const [conditionIsMet, setConditionIsMet] = useState(false);

    const {t} = useTranslation("ai_coaching");

    const spinMessages = [
        t("spin_message.analyzing_your_organization_data_and_frameworks"),
        t("spin_message.checking_for_your_assessment_and_peer_feedback"),
        t("spin_message.preparing_a_personalised_coaching_path"),
        t("spin_message.finalizing_your_coaching_session")
    ];

    return (
        <motion.div
            animate={isLoading ? "conditionA" : "conditionB"}
            variants={notifVariants}
            className={twMerge(
                role === "me" ? "flex-row-reverse ml-auto" : "flex-row mr-auto",
                "flex gap-4 items-center"
            )}
        >
            <div
                className={twMerge(
                    "rounded-full h-max",
                    showProfile ? "opacity-100" : " opacity-0"
                )}
            >
                <img
                    src={BOTIcon}
                    alt=""
                    className="border rounded-full object-cover pointer-events-none h-[41px] w-[41px]"
                />
            </div>
            {isLoading && (
                <span className="bg-white px-2 py-1 rounded-xl ">
                    <Loading messages={isStartState ? spinMessages : []} intervalTime={1000} spinner_size="22" />
                </span>
            )}
        </motion.div>
    );
};

export default ChatLoading;
