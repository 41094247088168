import React from 'react';
import {Outlet} from "react-router-dom";

const TeamsLayout  = () => {
    return (
        <div className={"flex flex-col items-start h-full lg:h-screen px-2 py-2 mx-auto sm:px-6 lg:px-8 max-w-8xl bg-warm "}>
            <Outlet/>
        </div>
    );
};

export default TeamsLayout;
