export const mergeEventWithUnavailableSlots = (
    existingEvents: { [key: string]: string[] },
    notAvailableSlots: { [key: string]: string[] }
) => {
    const combined: {[key: string]: string[]} = { ...existingEvents };

    Object.keys(notAvailableSlots).forEach((date) => {
        if (combined[date]) {
            combined[date] = mergeIntervals(combined[date], notAvailableSlots[date][0]);
        } else {
            combined[date] = notAvailableSlots[date];
        }
    });
    return combined;
}

const mergeIntervals = (existing: string[], newInterval: string) => {
    const merged: string[] = [];

    const parseInterval = (interval: string) => {
        const [start, end] = interval.split('-');
        return {
            start: start,
            end: end
        };
    };

    const toMinutes = (time: string) => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    };

    const compareEndTimes = (interval1: string , interval2: string) => {
        return toMinutes(parseInterval(interval1).end) - toMinutes(parseInterval(interval2).end);
    };

    const filtered = existing.filter((interval) => {
        return compareEndTimes(interval, newInterval) > 0;
    })

    let { start: newStart, end: newEnd } = parseInterval(newInterval);

    filtered.forEach((interval) => {
        const { start: existingStart, end: existingEnd } = parseInterval(interval);
        if (toMinutes(existingStart) < toMinutes(newEnd) && toMinutes(existingEnd) > toMinutes(newEnd)) {
            newEnd = existingEnd;
        } else {
            merged.push(interval);
        }
    })

    merged.push(`${newStart}-${newEnd}`);

    return merged;
};