import React from 'react';
import ReportCollapse from "../components/report/ReportCollapse";
import AssessmentScore from "./AssessmentScore";
import {ITeamsAuthData} from "../../../models/ITeamsAuth";
import noResultIcon from '../../../assets/img/teams/noReports.png'
import Feedback360 from "../components/Feedback360";
import {useTranslation} from "react-i18next";
import i18n from "../../../utils/lib/i18n";
import ENG from "../locale/eng/reports.json";
import Fr from "../locale/fr/reports.json";

const Reports = () => {
    i18n.addResourceBundle('en', 'reports', ENG    );
    i18n.addResourceBundle('fr', 'reports', Fr    );
    let authUser: ITeamsAuthData = JSON.parse(localStorage.getItem('ch_user') || '{}') as ITeamsAuthData
    const {t} = useTranslation('reports')
    const first_name = authUser?.first_name

    return (
        <div>
            {authUser?.company_name === 'HEC' ?
                <div className="flex justify-center mt-14 flex-col text-center">
                            <img style={{
                                margin: "auto",
                                width: " 20%",
                                marginTop: "1%"
                            }} className="inline-flex mr-4" src={noResultIcon} alt=""/>
                   <h2 className="mt-5 px-6">No reports are available for this version</h2>
                </div> : <>
                    <h1 className="mt-8 text-2xl font-bold">{t('welcome')}, {first_name}!</h1>
                    <ReportCollapse/>
                    {/*{authUser?.company_name === 'TestCompany' && <Feedback360/>}*/}
                    <Feedback360/>
                    <AssessmentScore/>
                    </>}

        </div>
    );
};

export default Reports;
