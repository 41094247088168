import * as Yup from "yup";

export enum CreateGroupFormField {
    name = "name",
    credits = "credits",
    //TODO: Uncomment this when introducing back the medium input
    // medium = "medium",
    credit_limit_per_user = "credit_limit_per_user",
    program = "program",
    group_expiration = "group_expiration",
    color_code = "color_code",
    description = "description",
    logo = "logo",
    coachees = "coachees",
}

export type FormField = {
    key: CreateGroupFormField;
    label: string;
    placeholder?: string;
};

export type FormFields = {
    [key in CreateGroupFormField]: FormField;
};

export const CreateGroupFormFields: FormFields = {
    [CreateGroupFormField.name]: {
        key: CreateGroupFormField.name,
        label: "create_group.text2",
        placeholder: "create_group.text33",
    },
    [CreateGroupFormField.credits]: {
        key: CreateGroupFormField.credits,
        label: "create_group.text3",
        placeholder: "create_group.text29",
    },
    //TODO: Uncomment this when introducing back the medium input
    // [CreateGroupFormField.medium]: {
    //   key: CreateGroupFormField.medium,
    //   label: "Medium",
    // },
    [CreateGroupFormField.credit_limit_per_user]: {
        key: CreateGroupFormField.credit_limit_per_user,
        label: "create_group.text4",
        placeholder: "create_group.text30",
    },
    [CreateGroupFormField.program]: {
        key: CreateGroupFormField.program,
        label: "create_group.text5",
    },
    [CreateGroupFormField.group_expiration]: {
        key: CreateGroupFormField.group_expiration,
        label: "create_group.text6",
        placeholder: "create_group.text31",
    },
    [CreateGroupFormField.color_code]: {
        key: CreateGroupFormField.color_code,
        label: "create_group.text7",
    },
    [CreateGroupFormField.description]: {
        key: CreateGroupFormField.description,
        label: "create_group.text8",
        placeholder: "create_group.text9",
    },
    [CreateGroupFormField.logo]: {
        key: CreateGroupFormField.logo,
        label: "Logo",
    },
    [CreateGroupFormField.coachees]: {
        key: CreateGroupFormField.coachees,
        label: "create_group.text15",
    },
};

export type CreateGroupForm = { [key in CreateGroupFormField]: any };

export const createGroupFormSchema = Yup.object<CreateGroupForm>().shape({
    name: Yup.string()
        .label(CreateGroupFormFields.name.label)
        .trim()
        .required("Group Name is required"),
    credits: Yup.lazy((value) => {
        return value === ""
            ? Yup.string()
            : Yup.number()
                .typeError("Invalid value")
                .label(CreateGroupFormFields.credits.label);
    }),
    credit_limit_per_user: Yup.lazy((value) => {
        return value === ""
            ? Yup.string()
            : Yup.number()
                .typeError("Invalid value")
                .label(CreateGroupFormFields.credit_limit_per_user.label);
    }),
    program: Yup.object()
        .shape({
            id: Yup.number().nullable(),
            name: Yup.string().nullable(),
            name_fr: Yup.string().nullable(),
        })
        .label(CreateGroupFormFields.program.label)
        .nullable()
        .default(null),
    group_expiration: Yup.string()
        .label(CreateGroupFormFields.group_expiration.label)
        .nullable()
        .default(null),
    color_code: Yup.string()
        .label(CreateGroupFormFields.color_code.label)
        .trim()
        .nullable(),
    description: Yup.string()
        .label(CreateGroupFormFields.description.label)
        .trim(),
    logo: Yup.mixed()
        .label(CreateGroupFormFields.logo.label)
        .nullable()
        .default(null),
    coachees: Yup.array()
        .of(
            Yup.object().shape({
                email: Yup.string(),
                first_name: Yup.string().trim().nullable().default(null),
                last_name: Yup.string().trim().nullable().default(null),
                label: Yup.object()
                    .shape({
                        id: Yup.number().nullable(),
                        name: Yup.string().nullable(),
                        name_fr: Yup.string().nullable(),
                    })
                    .nullable()
                    .default(null),
            })
        )
        .label(CreateGroupFormFields.coachees.label),
    // .required("text21"),
});
