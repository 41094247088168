import { FC, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import ResourceCard from "../components/ResourceCard";
import FrameModal from "../../../../../shared/components/FrameModal";

import { TResource } from "../../../../../constants/types";
import ENG from "../../../locale/eng/resources.json";
import FR from "../../../locale/fr/resources.json";
import i18n from "../../../../../utils/lib/i18n";

import careerDevelopmentImage from "../../../../../assets/img/dashboard/resources/img/Career-development.jpg";
import jobPerformanceImage from "../../../../../assets/img/dashboard/resources/img/Job-performance.jpg";
import leadershipImage from "../../../../../assets/img/dashboard/resources/img/Leadership.jpg";
import workEnvironmentImage from "../../../../../assets/img/dashboard/resources/img/Work-environment.jpg";
import qualityOfLifeImage from "../../../../../assets/img/dashboard/resources/img/Quality-of-life.jpg";
import confidenceImage from "../../../../../assets/img/dashboard/resources/img/Confidence.jpg";
import relationshipsImage from "../../../../../assets/img/dashboard/resources/img/Relationships.jpg";
import stressAnxietyImage from "../../../../../assets/img/dashboard/resources/img/Stress-Anxiety.jpg";
import { useNavTitle } from "@hooks/useNavTitle";

enum FilterState {
    ShowAll = "ShowAll",
    Unread = "Unread",
}

interface ResourcesProps {
    navTitle: String
}

const StandardResources: FC<ResourcesProps> = (props) => {
    i18n.addResourceBundle("en", "resources", ENG);
    i18n.addResourceBundle("fr", "resources", FR);

    const { t } = useTranslation("resources");
    useNavTitle(t(`layout:sidebar.${props.navTitle}`));

    const frameModalRef = useRef<React.ElementRef<typeof FrameModal>>(null);
    const [filterState, setFilterState] = useState<FilterState>(
        FilterState.ShowAll
    );

    const resourceArr: TResource[] = [
        {
            title: t("career_development"),
            image: careerDevelopmentImage,
            link: "/career-development",
        },
        {
            title: t("work_environment"),
            image: workEnvironmentImage,
            link: "/work-environment",
        },
        {
            title: t("job_performance"),
            image: jobPerformanceImage,
            link: "/job-performance",
        },
        { title: t("leadership"), image: leadershipImage, link: "/leadership" },
    ];

    const libraryArr: TResource[] = [
        {
            title: t("relationships"),
            image: relationshipsImage,
            link: "/relationships",
        },
        {
            title: t("quality_of_life"),
            image: qualityOfLifeImage,
            link: "/quality-of-life",
        },
        { title: t("confidence"), image: confidenceImage, link: "/confidence" },
        {
            title: t("stress_and_anxiety"),
            image: stressAnxietyImage,
            link: "/stress-and-anxiety",
        },
    ];

    const openModal = (link: string, title: string) => {
        frameModalRef.current?.open(link, title, 'iframe');
    };

    const activeFilterButtonClassName =
        "flex justify-center items-center basis-1/2 bg-black rounded-full cursor-pointer";
    const inactiveFilterButtonClassName =
        "flex justify-center items-center basis-1/2 bg-white rounded-full cursor-pointer";

    return (
        <div className="bg-linen_gradient flex flex-col py-6 px-6 flex-1 m-auto">
            <div className="w-full h-full overflow-y-scroll scrollbar-hide pr-6">
                <div className="w-full flex justify-between items-center mb-6">
                    <p className="font-bold text-xl">{t("your_resources")}</p>
                    <div className="w-52 h-10 flex flex-row bg-white rounded-full p-1">
                        <div
                            className={
                                filterState === FilterState.ShowAll
                                    ? activeFilterButtonClassName
                                    : inactiveFilterButtonClassName
                            }
                            onClick={() => setFilterState(FilterState.ShowAll)}
                        >
                            <p
                                className={`text-xs font-medium font-dm_sans ${filterState === FilterState.ShowAll
                                        ? "text-white"
                                        : "text-black"
                                    }`}
                            >
                                {t("show_all")}
                            </p>
                        </div>
                        <div
                            className={
                                filterState === FilterState.Unread
                                    ? activeFilterButtonClassName
                                    : inactiveFilterButtonClassName
                            }
                            onClick={() => setFilterState(FilterState.Unread)}
                        >
                            <p
                                className={`text-xs font-medium font-dm_sans ${filterState === FilterState.Unread
                                        ? "text-white"
                                        : "text-black"
                                    }`}
                            >
                                {t("unread")}
                            </p>
                        </div>
                    </div>
                </div>
                <ul className="grid w-full grid-cols-2 gap-x-5 gap-y-5 sm:grid-cols-3 lg:grid-cols-4">
                    {resourceArr.map((resource, index) => (
                        <ResourceCard
                            key={index}
                            resource={resource}
                            openModal={openModal}
                        />
                    ))}
                </ul>
                <div className="w-full h-12 flex items-center mt-10 mb-6">
                    <p className="font-bold text-xl">{t("library")}</p>
                </div>
                <ul className="grid w-full grid-cols-2 gap-x-5 gap-y-5 sm:grid-cols-3 lg:grid-cols-4">
                    {libraryArr.map((resource, index) => (
                        <ResourceCard
                            key={index}
                            resource={resource}
                            openModal={openModal}
                        />
                    ))}
                </ul>
            </div>
            <FrameModal ref={frameModalRef} />
        </div>
    );
};

export default StandardResources;
