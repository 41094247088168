import {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";

import HT from "../pages/OnboardingRegular/components/HighlightedText";
import VerticalExpander from "../pages/OnboardingRegular/components/VerticalExpander";
import ChatGuide from "../../../shared/components/ChatGuide";
import SelfReflectionCard from "../pages/OnboardingRegular/components/SelfReflectionCard";

import {TAssessmentScore, TSelfReflection} from "../../../constants/types";
import {IMeta} from "../../../models/IMeta";
import i18n from "../../../utils/lib/i18n";
import WelcomeENG from "../locale/eng/welcome.json";
import WelcomeFR from "../locale/fr/welcome.json";

type Props = {
  hideChatGuide?: boolean;
  selfReflections: TSelfReflection[];
  assessmentScore: TAssessmentScore;
  handleGetAssessmentScore: () => void;
  onValidated?: (validated: boolean) => void;
  selectedObjectives: IMeta[];
  setSelectedObjectives: (objective: IMeta[]) => void;
  setScrollBouncerVisibility?: (visible: boolean) => void;
};

const ObjectiveStep: React.FC<Props> = ({
  hideChatGuide,
  selfReflections,
  assessmentScore,
  handleGetAssessmentScore,
  onValidated = () => {},
  selectedObjectives,
  setSelectedObjectives,
  setScrollBouncerVisibility,
}) => {
  i18n.addResourceBundle("en", "welcome", WelcomeENG);
  i18n.addResourceBundle("fr", "welcome", WelcomeFR);
  const { t } = useTranslation("welcome");
  const lng = localStorage.getItem("I18N_LANGUAGE") || "en";
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setShowContent(!!hideChatGuide);
    handleGetAssessmentScore();
    return () => {
      setScrollBouncerVisibility?.(false);
    };
  }, []);

  const handleSelectObjective = (objective: IMeta) => {
    const objIndex = selectedObjectives.findIndex(
      (obj) => obj.id === objective.id
    );
    const updatedObjectives = [...selectedObjectives];

    if (objIndex > -1) {
      updatedObjectives.splice(objIndex, 1);
    } else if (selectedObjectives.length !== 3) {
      updatedObjectives.push(objective);
    }
    setSelectedObjectives(updatedObjectives);
    if(!hideChatGuide) {
      localStorage.setItem("objectives", JSON.stringify(updatedObjectives));
    }
  };

  useEffect(() => {
    if (selectedObjectives.length >= 1 && selectedObjectives.length <= 3) {
      return onValidated(true);
    }
    onValidated(false);
  }, [onValidated, selectedObjectives]);

  return (
    <>
      {!hideChatGuide ? (
        <ChatGuide
          title={<HT>{t("step_7.title")}</HT>}
          description={t("step_7.description")}
          descriptionClassName="font-medium"
          onShowContent={() => {
            setShowContent(true);
            setScrollBouncerVisibility?.(true);
          }}
        />
      ) : ( <p className="font-dm_sans"> {t("step_7.description_modal")} </p> )}
      <VerticalExpander expanded={showContent}>
        <ul className="h-max grid w-full grid-cols-2 gap-x-5 gap-y-5 sm:grid-cols-3 lg:grid-cols-4 mt-10 pb-10">
          {selfReflections.map((selfReflection, index) => (
            <SelfReflectionCard
              key={index}
              {...selfReflection}
              assessmentScore={assessmentScore}
              selectedObjectives={selectedObjectives}
              onSelectObjective={handleSelectObjective}
              lng={lng}
            />
          ))}
        </ul>
      </VerticalExpander>
    </>
  );
};

export default ObjectiveStep;
