import axios from "axios";
import {ITeamsLoginRequestData} from "../../../models/ITeamsAuth";


export const loginWithTeams = (data: ITeamsLoginRequestData) => {

    return axios.post<any>('api/auth/login/teams', data)
        .then(response => {
            return response.data;
        });
};
