import { useEffect, useState } from "react";
import { motion, usePresence, AnimatePresence } from "framer-motion";
import ReviewCard from "../ReviewCard";
import useTimer from "@hooks/useTimer";
import defaultProfileImg from "@img/icons/default-profile.png";

const initialState = [
  {
    id: 1,
    name: "Paul Boisson",
    comment: "“Very quick and easy! Great service, thanks!”",
  },
  {
    id: 2,
    name: "Saran Diakana Kanté",
    comment:
      "“Amazing service! Claire helped me to reduce the shipping price a little and shipped it" +
      " immediately after purchasing. The amethyst cave I got is a beauty and I c...“",
  },
  {
    id: 3,
    name: "Clémence Créton",
    comment:
      "“Just came back to home and should say that it is definitely a great experience." +
      " I would recommend it for everyone who needs a bike for a short term or want to t...”",
  },
  {
    id: 4,
    name: "Paul Boisxaxson",
    comment: "“Very quick and easy! Great service, thanks!”",
  },
  {
    id: 5,
    name: "Saran Diakcana Kanté",
    comment:
      "“Amazing service! Claire helped me to reduce the shipping price a little and shipped it" +
      " immediately after purchasing. The amethyst cave I got is a beauty and I c...“",
  },
  {
    id: 6,
    name: "Clécmence Créton",
    comment:
      "“Just came back to home and should say that it is definitely a great experience." +
      " I would recommend it for everyone who needs a bike for a short term or want to t...”",
  },
];

export default function LiveFeedback({ feeds }) {
  const [items, setItems] = useState(feeds);
  const [removedFeed, setRemovedFeed] = useState();
  const trigger = useTimer({ delay: 4000 });

  useEffect(() => {
    setItems(feeds);
  }, [feeds])

  const addAtStart = (r) => {
    setItems([r, ...items]);
  };
  const removeAtStart = () => {
    const r = items.pop();
    setRemovedFeed(r);
    setItems([...items]);
    addAtStart(r);
  };
  const removeSelf = (n) => {
    setItems([...items.filter((name) => name.name !== n)]);
  };
  useEffect(() => {
    if (trigger % 2 === 0) {
      removeAtStart();
    } else {
      if (removedFeed) {
        // addAtStart()
      }
    }
  }, [trigger]);

  return (
    <div className="w-full">
      <AnimatePresence>
        <ul className="w-full">
          {[...items].map((item, i) => (
            <ListItem key={item.id} onClick={() => removeSelf(item.id)}>
              <ReviewCard
                avatar={defaultProfileImg}
                name={item?.name}
                comment={item?.review}
                rating={item?.rating}
              />
              <hr className="w-5/6" />
            </ListItem>
          ))}
        </ul>
      </AnimatePresence>
    </div>
  );
}

const transition = { type: "spring", stiffness: 500, damping: 50, mass: 1 };

function ListItem({ children, onClick }) {
  const [isPresent, safeToRemove] = usePresence();

  const animations = {
    layout: true,
    initial: "out",
    style: {
      position: isPresent ? "static" : "absolute",
    },
    animate: isPresent ? "in" : "out",
    whileTap: "tapped",
    variants: {
      in: { scaleY: 1, opacity: 1 },
      out: { scaleY: 0, opacity: 0, zIndex: -1 },
      tapped: { scale: 0.98, opacity: 0.5, transition: { duration: 0.5 } },
    },
    onAnimationComplete: () => !isPresent && safeToRemove(),
    transition,
  };

  return (
    <motion.li {...animations} className="w-full">
      {children}
    </motion.li>
  );
}
