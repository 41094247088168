import {createColumnHelper, flexRender, getCoreRowModel, Row, useReactTable,} from "@tanstack/react-table";
import React, {useEffect, useState} from "react";
import {FieldArrayRenderProps, useField, useFormikContext} from "formik";
import {ActivityCell, SelectCell, TableCell} from "./table/TableCell";
import {IMeta} from "../../../../../models/IMeta";
import i18n from "../../../../../utils/lib/i18n";
import ENG from "../../../locale/eng/groups.json";
import Fr from "../../../locale/fr/groups.json";
import {useTranslation} from "react-i18next";
import {IGroupCoachee} from "../../../../../models/IGroup";
import {ActionCell} from "./table/ActionCell";
import {v4 as uuidv4} from "uuid";
import {getLabels} from "../../../../../services/api/companyApi";
import useAuth from "../../../../../utils/hooks/useAuth";
import {twMerge} from "tailwind-merge";
import UserListDropzone from "./UserListDropzone";
import {MinusCircleIcon} from "@heroicons/react/outline";
import {PlusSmIcon} from "@heroicons/react/solid";

const columnHelper = createColumnHelper<IGroupCoachee>();

const columns = [
    columnHelper.accessor("id", {
        header: "ID",
        cell: TableCell,
    }),
    columnHelper.accessor("email", {
        header: "text1",
        cell: TableCell,
    }),
    columnHelper.accessor("first_name", {
        header: "text2",
        cell: TableCell,
    }),
    columnHelper.accessor("last_name", {
        header: "text3",
        cell: TableCell,
    }),
    columnHelper.accessor("label", {
        header: "text4",
        cell: (cell) => {
            const meta = cell.table.options.meta as any;
            return (
                cell.row.original.edited ? <SelectCell
                    {...cell}
                    options={meta?.labelOptions}
                    setOptions={meta?.setLabelOptions}
                /> : cell.getValue() ? <div className="flex pl-1 w-full"><span
                    className="p-0.5 px-2 bg-purple-150 text-xs rounded-full">{cell.getValue()?.name}</span>
                </div> : null
            );
        },
        meta: {},
    }),
    columnHelper.accessor("active", {
        header: "text10",
        cell: ActivityCell,
    }),
    columnHelper.display({
        id: "action",
        cell: ActionCell,
    }),
];
export const FooterCell = ({table}: any) => {
    const tmeta = table.options.meta;
    const selectedRows = table.getSelectedRowModel().rows;
    i18n.addResourceBundle("en", "groups", ENG);
    i18n.addResourceBundle("fr", "groups", Fr);
    const {t} = useTranslation("groups");
    const [field, meta, helper,] = useField<IGroupCoachee[]>("coachees");
    // console.log(meta.error);
    const removeRows = () => {
        // return console.log(table.getSelectedRowModel().rows.map((row: any) => row.index));
        // table.getSelectedRowModel().rows.forEach((row: any) => meta.removeRow(row.index))
        tmeta.removeSelectedRows(
            table.getSelectedRowModel().rows.map((row: any) => row.index)
        );
        table.resetRowSelection();
    };
    return (
        <div className="footer-buttons py-2 flex flex-wrap gap-2 items-center justify-between">
            {/*<div className="flex flex-auto gap-2 items-center justify-end">*/}
            {/*    {meta.error && typeof meta.error === "string" ? (*/}
            {/*        <p className="text-red">{meta.error}</p>*/}
            {/*    ) : null}*/}
            {/*</div>*/}
            <div className="flex flex-1 gap-2 items-center justify-end">
                {selectedRows.length > 0 && (
                    <button
                        className="w-max remove-button px-2 py-0.5 flex items-center gap-1 justify-center text-red border-2 border-red text-sm font-normal
                        hover:bg-red hover:text-white transition-colors duration-500 rounded-md"
                        onClick={removeRows}
                    >
                        <MinusCircleIcon className="text-red w-4 h-4"/> {t("create_group.text36")}
                    </button>
                )}
                <button
                    className=" w-max add-button px-2 py-0.5 flex justify-center items-center gap-1 text-black border-2 border-black text-sm font-normal
                        hover:bg-black hover:text-white transition-colors duration-500 rounded-md"
                    onClick={tmeta?.addRow}
                >
                    <PlusSmIcon className="text-green-700 w-4 h-4"/> {t("create_group.text34")}
                </button>

            </div>


        </div>
    );
};

export const GroupUserTable = ({
                                   helper,
                               }: {
    helper: FieldArrayRenderProps;
}) => {
    i18n.addResourceBundle("en", "groups:create_group", ENG);
    i18n.addResourceBundle("fr", "groups:create_group", Fr);
    const {t} = useTranslation("groups");

    const {user} = useAuth();
    // const [editedRows, setEditedRows] = useState<Record<string, boolean>>({});
    const [field, meta] = useField<IGroupCoachee[]>("coachees");
    const {validateForm, setTouched, setFieldTouched, touched, status} = useFormikContext();
    const [originalData, setOriginalData] = useState<IGroupCoachee[]>(
        () => meta.initialValue || []
    );
    const [labelOptions, setLabelOptions] = useState<IMeta[]>([]);
    useEffect(() => {
        console.log("resetting......",);
        setOriginalData(meta.initialValue || [])
    }, [meta.initialValue]);

    const table = useReactTable({
        data: field.value,
        columns,
        // getRowId: row => Math.floor(Math.random() * 100000).toString(),
        getCoreRowModel: getCoreRowModel(),
        enableRowSelection: true,
        meta: {
            // editedRows,
            // setEditedRows,
            labelOptions,
            setLabelOptions,
            // updateData: (rowIndex: number, columnId: string, value: string) => {
            //     setData((old) =>
            //         old.map((row, index) => {
            //             if (index === rowIndex) {
            //                 return {
            //                     ...old[rowIndex],
            //                     [columnId]: value,
            //                 };
            //             }
            //             return row;
            //         })
            //     );
            // },
            setFieldValue: (name: string, value: string) => {
                helper.form.setFieldValue(name, value);
            },
            addRow: () => {
                const newRow: IGroupCoachee = {
                    id: uuidv4(),
                    email: "",
                    first_name: "",
                    last_name: "",
                    label: null,
                    active: true,
                    new: true,
                    edited: true,
                };

                helper.unshift(newRow);

                // setEditedRows((old) => ({
                //   ...old,
                //   [newRow.id]: true,
                // }));
                const setFunc = (old: IGroupCoachee[]) => [newRow, ...old];
                setOriginalData(setFunc);
            },
            revertData: (row: Row<IGroupCoachee>) => {
                helper.replace(row.index, originalData[row.index]);
                // setFieldValue(`users[${row.index}]`, meta.initialValue[row.index])

                // setEditedRows((old) => ({
                //   ...old,
                //   [row.original.id]: false,
                // }));
            },
            removeRow: (rowIndex: number) => {
                // return console.log(rowIndex);
                const setFilterFunc = (old: IGroupCoachee[]) =>
                    old.filter(
                        (_row: IGroupCoachee, index: number) => index !== rowIndex
                    );
                helper.remove(rowIndex);
                setOriginalData(setFilterFunc);
                // setData(setFilterFunc);
            },
            removeSelectedRows: (selectedRows: number[]) => {
                const setFilterFunc = (old: IGroupCoachee[]) =>
                    old.filter((_row, index) => !selectedRows.includes(index));
                // console.log(setFilterFunc(helper.form.values.coachees));
                helper.form.setFieldValue(`coachees`, setFilterFunc(helper.form.values.coachees));
                setOriginalData(setFilterFunc);
                // setData(setFilterFunc);
            },
        },
    });

    useEffect(() => {
        fetchLabels()
    }, []);

    const fetchLabels = () => {
        getLabels(user!.companyId).then((data: IMeta[]) => {
            setLabelOptions(data);
        });
    }
    const handleEditedRows = (row: any) => {
        const meta: any = table.options.meta;
        // console.log("handle row click", row);
        // helper.replace(row.index, originalData[row.index]);
        meta.setFieldValue(`coachees[${row.id}].[edited]`, true)
        // meta?.setEditedRows((old: []) => ({
        //   ...old,
        //   [row.original.id]: true,
        // }));
    };

    return (
        <>
            <div className="mx-8 pt-3 flex flex-col gap-2">
                <div className="flex flex-col gap-0.5">
                    <div className="flex flex-row gap-4 justify-between items-center pb-2">
                        <div className="flex-auto">
                            <p className="font-lexend font-bold text-black text-base whitespace-nowrap">
                                {t("create_group.text15")} ({field.value.length})
                            </p>
                        </div>
                        <div className="flex gap-10 items-center w-full justify-end">
                            <div className="flex flex-auto gap-2 items-center justify-center">
                                {meta.error && typeof meta.error === "string" ? (
                                    <p className="text-red  font-normal">{meta.error}</p>
                                ) : null}
                            </div>
                            <UserListDropzone
                                onDropCsv={(value) => {
                                    console.log(value);
                                    const addRow = (coachee: IGroupCoachee) => {
                                        const newRow: IGroupCoachee = {
                                            id: uuidv4(),
                                            email: coachee.email,
                                            first_name: coachee.first_name,
                                            last_name: coachee.last_name,
                                            label: null,
                                            active: true,
                                            new: true,
                                            edited: true,
                                        };

                                        helper.unshift(newRow);

                                        const setFunc = (old: IGroupCoachee[]) => [newRow, ...old];
                                        setOriginalData(setFunc);
                                    }
                                    const labelOptionsList: IMeta[] = [];
                                    value.forEach((coachee) => {
                                        addRow(coachee)
                                        if (!coachee.label) return;
                                        labelOptionsList.push(coachee.label);
                                    });
                                    setLabelOptions((currVal) => {
                                        return [...currVal, ...labelOptionsList];
                                    });

                                    // Trigger validation after updating the form state
                                    setTimeout(async () => {
                                        console.log('validating...')
                                        // Set touched for all coachees at once
                                        setTouched(Object.assign(meta.touched, {
                                            coachees: value.map(() => ({email: true, first_name: true}))
                                        }));
                                    }, 2000);
                                }
                                }
                                labelOptions={labelOptions}
                            />
                            <FooterCell table={table}/>

                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col gap-2 bg-white px-8 lg:overflow-visible overflow-x-scroll pb-12">
                <table className="">
                    <thead className="cursor-default ">
                    {/*<tr>*/}
                    {/*    <th colSpan={table.getCenterLeafColumns().length} align="right">*/}
                    {/*        <FooterCell table={table}/>*/}
                    {/*    </th>*/}
                    {/*</tr>*/}
                    {table.getHeaderGroups().map((headerGroup, index) => (
                        <tr className="bg-[#000000]" key={headerGroup.id}>
                            {headerGroup.headers
                                .filter((header) => header.id !== "id")
                                .map((header, columnIndex) => {

                                    const headerText = flexRender(
                                        header.column.columnDef.header,
                                        header.getContext())

                                    return <th
                                        key={header.id}
                                        className={`font-lexend font-bold text-white text-sm pl-2 py-2 
                                ${
                                            index === 0 &&
                                            columnIndex === 0 &&
                                            "rounded-tl-lg"
                                        } ${

                                            columnIndex === headerGroup.headers.length - 2 &&
                                            "rounded-tr-lg"
                                        }`}
                                    >
                                        <p className="font-lexend font-bold text-white text-sm text-start">
                                            {
                                                header.isPlaceholder
                                                    ? null
                                                    : header.column.id == "action" ? "" : t(`coachees.${headerText}`)}
                                        </p>
                                    </th>
                                })}
                        </tr>
                    ))}

                    </thead>
                    <tbody className="">
                    {table.getRowModel().rows.map((row) => (
                        <tr title={t("create_group.text48")} key={row.id}
                            className={twMerge('hover:bg-neutral-100 border-b border-neutral-200 ',
                                // editedRows[String(row.original.id)] && 'border-l-4',
                                row.original.edited ? 'border-l-4' : '',
                                `border-l-green-600/50`)} onClick={() => handleEditedRows(row)}>
                            {row
                                .getVisibleCells()
                                .filter((cell) => cell.column.id !== "id")
                                .map((cell) => (
                                    <td
                                        key={cell.id}
                                        className={twMerge('', `${cell.column.id !== "active" && "pr-1 align-baseline"}`)}
                                    >
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )}
                                    </td>
                                ))}
                        </tr>
                    ))}
                    </tbody>
                    <tfoot>
                    {/*<tr>*/}
                    {/*    <th colSpan={table.getCenterLeafColumns().length} align="right">*/}
                    {/*        <FooterCell table={table}/>*/}
                    {/*    </th>*/}
                    {/*</tr>*/}
                    </tfoot>
                </table>
            </div>
        </>
    );
};
