import { MouseEventHandler } from "react";
import Loader from "../pages/AICoaching/components/Loader";

type Props = {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
};

const Button = ({
  disabled = false,
  loading,
  children,
  className,
  onClick,
}: Props) => {
  return (
    <button
      onClick={disabled || loading ? undefined : onClick}
      disabled={disabled}
      className={`relative flex items-center gap-2 rounded text-sm disabled:opacity-50 focus:outline-none ${className}`}
    >
      {loading && <Loader isFullscreen={false} />}
      {children}
    </button>
  );
};

export default Button;
