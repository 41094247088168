import RegularCoach from "./RegularCoach";
import {useTranslation} from "react-i18next";
import {useNavTitle} from "@hooks/useNavTitle";
import {BookingDataProvider} from "./ChatDataContext";
import {Dispatch, SetStateAction} from "react";

type Props = {
    setIsHumanCoach: Dispatch<SetStateAction<boolean>>
}
const AICoaching = ({setIsHumanCoach}:Props) => {

  return (
      <BookingDataProvider>
        <RegularCoach setIsHumanCoach={setIsHumanCoach}/>
      </BookingDataProvider>
  );
};

export default AICoaching;
