import React from "react";

import { useTranslation } from "react-i18next";

import { TResource } from "../../../../../constants/types";

import tickIcon from "../../../../../assets/img/dashboard/resources/icon/tick.svg";

type Props = {
  resource: TResource;
  openModal: (link: string, title: string) => void;
};

const ResourceCard: React.FC<Props> = ({ resource, openModal }) => {
  const { t } = useTranslation("resources");

  const { title, image, link } = resource;

  const language = localStorage.getItem("I18N_LANGUAGE");
  const baseResources =
    language === "fr"
      ? "https://resources.coachello.io/resources-french"
      : "https://resources.coachello.io";

  return (
    <li className="relative group overflow-hidden">
      <div className="bg-white rounded-md relative group">
        <div
          className="h-6 rounded-full flex flex-row justify-center items-center gap-2
                 bg-pink absolute z-10 right-[-100%] top-2 cursor-pointer w-max px-3 overflow-hidden  
                 transition-all duration-500 group-hover:right-2"
        >
          <img
            src={tickIcon}
            alt=""
            className="object-cover pointer-events-none group-hover:opacity-75"
          />
          <p className="text-xs text-black font-medium font-dm_sans">
            {t("mark_as_read")}
          </p>
        </div>
        <div
          onClick={() => openModal(baseResources + link, title)}
          className="cursor-pointer"
        >
          <div className="block w-full overflow-hidden bg-gray-100 rounded-t-lg aspect-w-10 aspect-h-7">
            <img
              src={image}
              alt=""
              className="object-cover pointer-events-none"
            />
          </div>
          <div className="h-12 flex justify-center items-center">
            <p className="font-dm_sans text-xs font-bold text-black pointer-events-none">
              {title}
            </p>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ResourceCard;
