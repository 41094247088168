import React, {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import i18n from "../../../../utils/lib/i18n";
import ENG from "../../locale/eng/misc.json";
import Fr from "../../locale/fr/misc.json";
import {useTranslation} from "react-i18next";
import {useNavigate} from 'react-router-dom';

const Integration = () => {
    const [searchParams] = useSearchParams();
    i18n.addResourceBundle("en", "misc", ENG);
    i18n.addResourceBundle("fr", "misc", Fr);
    const {t} = useTranslation("misc");
    const navigate = useNavigate();

    useEffect(() => {
        const queryString = searchParams.toString();
        if (queryString) {
            navigate(`/dashboard/integration/success?${queryString}`);
        }
    }, [searchParams, navigate]);

    return (
        <div className="flex gap-5 p-20 justify-center items-center">
            <div
                className="flex flex-col gap-3 justify-center border rounded-xl px-5 py-10 bg-white shadow-xl text-center">
                <p>{t("text3")} <b>{t("text3_1")}</b> {t("text4")}</p>
                <a target="_blank" className="mx-auto"
                   href={`https://slack.com/oauth/v2/authorize?client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&scope=channels:read,chat:write,chat:write.customize,groups:history,groups:read,groups:write,im:history,im:write,users:read,users:read.email`}
                >
                    <img
                        alt="Add to Slack"
                        height="40"
                        width="139"
                        src="https://platform.slack-edge.com/img/add_to_slack.png"
                        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x,
                                                https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                    />
                </a>
            </div>


            <div className="flex flex-col gap-3 justify-center border rounded-xl px-5 py-10 bg-white shadow-xl">
                <p>{t("text3")} <b>{t("text3_2")}</b> {t("text4")}</p>
                <a target="_blank" className="mx-auto"
                   href={`https://slack.com/oauth/v2/authorize?client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&scope=channels:read,chat:write,chat:write.customize,groups:history,groups:read,groups:write,im:history,im:write,users:read,users:read.email&state=create_new`}
                >
                    <img
                        alt="Add to Slack"
                        height="40"
                        width="139"
                        src="https://platform.slack-edge.com/img/add_to_slack.png"
                        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x,
                                                https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                    />
                </a>
            </div>


            {/*<div className="m-auto flex justify-center p-10">*/}
            {/*    <a*/}
            {/*        href={`https://slack.com/oauth/v2/authorize?client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&scope=channels:read,channels:join,chat:write,chat:write.customize,groups:history,incoming-webhook, groups:read,groups:write,im:history,im:write,users:read,users:read.email&user_scope=`}*/}
            {/*        // incoming-webhook,*/}
            {/*    >*/}
            {/*        <img*/}
            {/*            alt="Add to Slack"*/}
            {/*            height="40"*/}
            {/*            width="139"*/}
            {/*            src="https://platform.slack-edge.com/img/add_to_slack.png"*/}
            {/*            srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x,*/}
            {/*                                    https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"*/}
            {/*        />*/}
            {/*    </a>*/}
            {/*</div>*/}
        </div>
    );
};

export default Integration;
