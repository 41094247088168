import React from "react";

import Card from "../../../components/Card";

type Props = {
  title?: string;
  children?: React.ReactNode;
};

const ReportCard = ({ children, title }: Props) => {
  return (
    <Card noPadding>
      <div className="p-4 border-b font-serif text-lg font-bold">{title}</div>
      <div className="flex flex-col p-4 gap-6">{children}</div>
    </Card>
  );
};

export default ReportCard;
