import React, {InputHTMLAttributes} from 'react';
import {InputProps} from "./Input";

export type InputWithIconProps = InputProps & {
    icon: any;
    right?: boolean;
};

const InputWithIcon = ({label, className, error, icon,right, ...props}: InputWithIconProps) => {
    return (
        <div className="">
            {label && <label className=" flex py-2 font-light gap-2 w-full">{label} </label>}
            <div className={`relative [&>*:first-child]:top-2 [&>*:first-child]:w-6 [&>*:first-child]:h-6 [&>*:first-child]:absolute ${right?`[&>*:first-child]:right-1.5`:`[&>*:first-child]:left-1.5 `}`}>
                {icon}
                <input className={`w-full border-gray-400 border leading-5 p-2 rounded focus-visible:outline-blackBlue ${className}`} {...props}/>
            </div>
            {error && <p className="text-red-500 text-xs italic">{error}</p>}
        </div>

    );
};

export default InputWithIcon;
