import Button from "../../../../../shared/components/Button";

import emptyImage from "@img/dashboard/reports/icons/empty.png";

type Props = {
  title: string;
  buttonText: string;
  onButtonClick?: () => void;
};

const EmptyResults = ({ title, buttonText, onButtonClick }: Props) => {
  return (
    <div className="flex flex-col gap-10 items-center pb-14">
      <img className="w-64" src={emptyImage} alt="empty" />
      <div className="flex flex-col gap-5 items-center">
        <p className="font-dm_sans text-xl font-normal">{title}</p>
        <Button
          className="px-14 text-white border-2 border-black bg-black text-xs font-bold h-max py-2"
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default EmptyResults;
