import {Widget} from '@typeform/embed-react'
import {ITeamsAuthData} from "../../../models/ITeamsAuth";
import {useSearchParams} from "react-router-dom";

export const Feedback360Typeform = () => {
    const [searchParams] = useSearchParams()
    const senderEmail = searchParams.get('senderEmail')
    const senderName = searchParams.get('senderName')
    let authUser: ITeamsAuthData = JSON.parse(localStorage.getItem('ch_user') || '{}') as ITeamsAuthData
    if (!authUser) {
        return <h2>Please try again !</h2>
    } else {
        return <Widget hidden={{
            senderemail: senderEmail || '',
            sendername: senderName || '',
            useremail: authUser?.email

        }} id="YNEngPKu" style={{width: '100%', height: '100vh', flex: '1 1 auto'}}/>
    }

}
