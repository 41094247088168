import React from 'react'
import { useConfig } from '../../../../utils/providers/AppConfigProvider';
import AIResources from './AIResources/AIResources';
import StandardResources from './StandardResources/StandardResources';
import { BookingDataProvider } from '../AICoaching/ChatDataContext';
import useAuth from '@hooks/useAuth';

function Resources() {
  const { config } = useConfig();
  const { user } = useAuth();

  return config?.ai_generated_materials ? (
    <BookingDataProvider>
      <AIResources client_id={user?.id} />
    </BookingDataProvider>
  ) : (
    <StandardResources navTitle="text13" />
  )
}

export default Resources;