// CustomComponent.js
import React from 'react';

const Backdrop = () => {
  return (
    <div className="z-0 rounded-2xl absolute inset-0 flex flex-col items-center justify-center text-center overflow-hidden">
      <svg
        className="absolute inset-0 z-0"
        viewBox="0 0 394 160"
        preserveAspectRatio="none"
        fill="none"
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M-139 74.5157L-109.375 70.9539C-79.75 67.392 -20.5
                60.2683 38.75 70.9539C98 81.6394 157.25 110.134 216.5 95.8868C275.75 81.6394 335 24.6499 394.25
                6.84066C453.5 -10.9686 512.75 10.4025 542.375 21.088L572 31.7736V160H542.375C512.75 160 453.5 160
                394.25 160C335 160 275.75 160 216.5 160C157.25 160 98 160 38.75 160C-20.5 160 -79.75 160 -109.375
                160H-139V74.5157Z" fill="#ACC7E8" fill-opacity="0.3"/>
      </svg>
    </div>
  );
};

export default Backdrop;
