import {FC, useEffect} from 'react';
import {StepsProvider} from 'react-step-builder';
import BookingSteps from '../components/BookingSteps';

// Components

import {Toaster} from "react-hot-toast";


const BookSession: FC = () => {
    console.log('');
    return (
        <>
            <Toaster
                position="top-center"
                reverseOrder={true}
                toastOptions={{duration:9000}}
            />
            <StepsProvider>
                <BookingSteps/>
            </StepsProvider>
        </>
    )
}

export default BookSession;
