import ArrowRight from "./ArrowRight";

type SummaryBtnProps = {
    label: string;
    onClick: () => void
    displayArrow?: boolean
};
const BlackButton = ({label, onClick}: SummaryBtnProps, displayArrow=true) => {

    return <div
            className={`flex flex-col gap-0.5 rounded-xl border-[1px] py-2 bg-black border-black`}
            onClick={onClick}>
            <p className={`flex flex-row cursor-pointer font-dm_sans m-0 text-xs px-4 font-semibold text-white gap-3`}>
                {label}
                {displayArrow && " "}
                {displayArrow && <ArrowRight/>}
            </p>
        </div>
}

export default BlackButton;
