import {CSSProperties, useCallback, useEffect, useState} from "react";

import { Widget } from "@typeform/embed-react";
import { useTranslation } from "react-i18next";
import useAuth from "../../utils/hooks/useAuth";

type Props = {
  style?: CSSProperties;
  onSubmit?: () => void;
};

const AssessmentV2 = ({ style, onSubmit }: Props) => {
  const { t } = useTranslation("general");
  const { user } = useAuth();
  const [typeformId, setTypeformId] = useState("TjGf5Xk3");

  useEffect(() => {
      if (user?.companyName === "Automattic") {
          setTypeformId("m4mSSNSb")
      }
  }, []);

  const handleFormSubmit = useCallback((event: any) => {
    onSubmit?.();
    // Perform actions with the form data
    // event sample =>  {
    //     "response_id": "vee9ixyemvk0vo1xuvee9i69d79ueifq",
    //     "responseId": "vee9ixyemvk0vo1xuvee9i69d79ueifq",
    //     "formId": "TjGf5Xk3"
    // }
  }, []);

  if (!user) {
    return <h2>{t("try_again")}</h2>;
  } else {
    return (
      <Widget
        onSubmit={handleFormSubmit}
        hidden={{
          useremail: user?.email,
          userlastname: user?.lastName,
          userfirstname: user?.firstName,
        }}
        id={typeformId}
        style={
          style ? style : { width: "100%", height: "100vh", flex: "1 1 auto" }
        }
      />
    );
  }
};

export default AssessmentV2;
