import i18n from "../../../../utils/lib/i18n";
import ENG from "../../locale/eng/coachInsights.json";
import Fr from "../../locale/fr/coachInsights.json";
import {useTranslation} from "react-i18next";
import {useNavTitle} from "@hooks/useNavTitle";
import {useEffect, useState} from "react";
import ICoach from "../../../../models/ICoach";
import CoachCard from "./components/CoachCard";
import {getAllCoaches} from "../../../../services/api/coachApi";
import LoaderContainer from "../../../../shared/components/LoaderContainer";
import {getCompanies} from "../../../../services/api/metaApi";
import FilterBar from "./components/FilterBar";
import Pagination from "../../../../shared/components/Pagination";

type FilterOption = {
    label: string;
    value: number;
}

enum SORT_OPTION {
    CLIENT_COUNT = 'client_count',
    AVG_RATING = 'avg_rating',
    NONE = ''
}

const CoachInsights = ({...props}) => {
    i18n.addResourceBundle("en", "coachInsights", ENG);
    i18n.addResourceBundle("fr", "coachInsights", Fr);
    const {t} = useTranslation("coachInsights");
    useNavTitle(t(`layout:sidebar.${props.navTitle}`));
    const [allCoaches, setAllCoaches] = useState<ICoach[]>([]);
    const [coaches, setCoaches] = useState<ICoach[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [companies, setCompanies] = useState<FilterOption[]>([]);
    const [selectedCompanies, setSelectedCompanies] = useState<FilterOption[]>([{label: "Coachello", value: 1}]);
    const [sortOption, setSortOption] = useState<SORT_OPTION>(SORT_OPTION.NONE);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const [globalFilter, setGlobalFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [paginatedCoaches, setPaginatedCoaches] = useState<ICoach[]>([]);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [canNextPage, setCanNextPage] = useState(false);
    const [canPreviousPage, setCanPreviousPage] = useState(false);

    useEffect(() => {
        const filtrationCompanies = selectedCompanies.map((company) => company.value);

        setIsLoading(true);
        getAllCoaches(filtrationCompanies)
            .then((res) => {
                setAllCoaches(res);
                setIsLoading(false);
            })
            .catch((e) => {
                console.log("api error");
                setIsLoading(false);
            });
    }, [selectedCompanies]);

    useEffect(() => {
        const filteredAndSortedCoaches = allCoaches.filter((coach: ICoach) =>
            coach.first_name.toLowerCase().includes(globalFilter.toLowerCase()) ||
            coach.last_name.toLowerCase().includes(globalFilter.toLowerCase())
        ).sort((a: any, b: any) => {
            if (sortOption === SORT_OPTION.CLIENT_COUNT) {
                return sortOrder === 'asc' ? a.coachee - b.coachee : b.coachee - a.coachee;
            } else if (sortOption === SORT_OPTION.AVG_RATING) {
                return sortOrder === 'asc' ? a.avg_rating - b.avg_rating : b.avg_rating - a.avg_rating;
            }
            return 0;
        });

        setCoaches(filteredAndSortedCoaches);
        setCurrentPage(0);
    }, [allCoaches, sortOrder, sortOption, globalFilter]);

    useEffect(() => {
        const totalPages = Math.ceil(coaches.length / pageSize);
        setTotalPageCount(totalPages);

        setCanNextPage(currentPage + 1 < totalPages);
        setCanPreviousPage(currentPage > 0);

        const start = currentPage * pageSize;
        const end = start + pageSize;
        setPaginatedCoaches(coaches.slice(start, end));
    }, [coaches, currentPage, pageSize])

    useEffect(() => {
        getCompanies().then((res) => {
            setCompanies(
                res.map((obj: any) => {
                    return {
                        label: obj.name,
                        value: obj.id,
                    };
                })
            );
        }).catch((e) => {
            console.log("api error");
        });
    }, [])

    const resetFilters = () => {
        setSelectedCompanies([]);
        setSortOption(SORT_OPTION.NONE);
        setSortOrder('desc');
        setGlobalFilter("");
    };

    return (
        <div>
            <FilterBar
                companies={companies}
                selectedCompanies={selectedCompanies}
                setSelectedCompanies={setSelectedCompanies}
                sortOption={sortOption}
                setSortOption={setSortOption}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                onReset={resetFilters}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
            />
            <Pagination
                currentPage={currentPage}
                totalPageCount={totalPageCount}
                pageSize={pageSize}
                gotoPage={setCurrentPage}
                setPageSize={setPageSize}
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
            />
            {isLoading && <LoaderContainer/>}
            {paginatedCoaches.map((coach, index) =>
                <CoachCard key={index} coach={coach}/>
            )}
        </div>
    );
}

export default CoachInsights;
