import moment from "moment";
import { useTranslation } from "react-i18next";

import ArrowRight from "./ArrowRight";
import Button from "../../../../../shared/components/Button";
import Modal from "../../../components/Modal";

import { ISessionSchema } from "../../../../../models/IHome";
import { EHomeDropdownAction } from "../../../../../constants/enums";

import calendar from "../../../../../assets/img/dashboard/home/icons/calendar.svg";
import clock from "../../../../../assets/img/dashboard/home/icons/clock.svg";
import viewReportIcon from "../../../../../assets/img/dashboard/home/icons/view_report.png";
import messageIcon from "../../../../../assets/img/dashboard/home/icons/message.png";

type Props = {
  onClose?: () => void;
  session: ISessionSchema | undefined;
  handleAction: (
    action: string | EHomeDropdownAction,
    session?: ISessionSchema
  ) => void;
};

const PreviousSessionModal = ({ onClose, session, handleAction }: Props) => {
  const { t } = useTranslation("home");

  if (!session) return <></>;

  const date = moment(session.datetime).format("dddd, DD MMMM");
  const time = moment(session.datetime).format("HH:mm");
  const coachName = `${session.coach_first_name} ${
    session.coach_last_name ?? ""
  }`;

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      title={t("previous_session_modal.previous_session")}
    >
      <div className="flex flex-col justify-center items-stretch gap-6">
        <div className="flex align-center mt-3">
          <img
            src={
              "https://i.ibb.co/4KdB4sd/01-Amelie-Papin-Portrait-…9-compressed-Ame-lie-Papin-1-1-Blackand-White.jpg"
            }
            className="rounded-full object-cover mr-2 inline-block h-12 w-12"
            alt="Coach avatar"
          />
          <div className="inline-flex flex-col justify-center gap-0.5">
            <h2 className="font-lexend text-xs">{coachName}</h2>
            <div className="font-dm_sans text-gray font-light flex gap-4 items-center">
              <span className="flex gap-1 text-xs items-center">
                <img src={calendar} alt="" className="inline-block h-4 w-4" />
                {date}
              </span>
              <span className="flex gap-1 text-xs items-center">
                <img src={clock} alt="" className="inline-block h-4 w-4" />
                {time}
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-2">
          <Button
            className="bg-white text-black border-2 border-black flex gap-3 max-h-10 text-xs h-9 w-1/2 justify-center"
            onClick={() => handleAction(EHomeDropdownAction.Message)}
          >
            <img src={messageIcon} alt="" className="w-4" />
            <span>{t("message")}</span>
          </Button>
          <Button
            className="bg-white text-red border-2 border-red flex gap-3 max-h-10 text-xs h-9 w-1/2 justify-center"
            onClick={() =>
              handleAction(EHomeDropdownAction.CheckReport, session)
            }
          >
            <img src={viewReportIcon} alt="" className="w-3" />
            <span>{t("previous_session_modal.view_report")}</span>
            <ArrowRight />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PreviousSessionModal;
