import {FC, useState} from 'react';

// Interfaces
import ISession from '../../../../models/ISession';
import '../../css/LinkedinIconStyle.css'
// Assets
import linkedinIcon from '../../../../assets/img/teams/social/linkedin.png';
import calendarIcon from '../../../../assets/img/teams/icons/calendar.png';
import minuteIcon from '../../../../assets/img/teams/icons/minute.png';
import cancelIcon from '../../../../assets/img/teams/icons/cancel.png';
import playIcon from '../../../../assets/img/teams/icons/play-button.png';
import moment from 'moment';


import {useTranslation} from "react-i18next";
import 'moment/locale/fr';
import {dialog} from "@microsoft/teams-js";
import {cancelBooking} from "../../../../services/api/teams/BookingActions";
import {jumpToDeepLink, showModal} from "../../../../utils/helpers/teams";

interface Props {
    loading?: boolean;
    session?: ISession;
}

const UpcomingSession: FC<Props> = ({session}) => {
    const lng = localStorage.getItem("I18N_LANGUAGE") || 'en'
    moment.locale(lng)
    const {t} = useTranslation('home')
    let sessionTime = moment(session?.meeting_start_time);
    const [reviewModalOpen, setReviewModalOpen] = useState(false)


    function showChangeMeeting() {
        if (session?.meeting_start_time) {
            const start = moment(session?.meeting_start_time);
            const end = moment();
            const hours = moment.duration(start.diff(end)).asHours();
            if (hours > 24) {
                return true
            }
        }
        return false
    }

    const submitHandler: dialog.DialogSubmitHandler = (res: dialog.ISdkResponse) => {
        const {err, result} = res;
        const results = result as any
        if (results) {

            if (results.confirm == true && session?.id) {
                cancelBooking(session?.id)
                    .then(res => {
                        if (res.error === false) {
                            window.location.reload();
                        }
                    }).catch(err => {
                    console.error(err.response)
                });
            }
            console.log(`Submit handler - result: ${result}\r`);

        }
        console.log(`Submit handler - err: ${err}\r`);
    };


    return (
        <div className="p-5 bg-white rounded-md shadow-lg">
            <div className="avatarImg relative flex items-start justify-between">
                {session?.coach_avatar
                    ?
                    <img className="rounded w-full" src={session?.coach_avatar} alt=""/>
                    :
                    <span className="inline-block overflow-hidden bg-gray-100 rounded-md">
                    <svg className="w-full h-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                    <path
                        d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                    </svg>
                </span>
                }
                {session?.coach_linkedin && <div className="absolute z-10 right-3 top-3">
                    {/*<a title="Open in a new tab" className="hover_image" href={session?.coach_linkedin} target="_blank">*/}
                    {/*    <img className="linkedinImg" src={linkedinIcon} alt=""/>*/}
                    {/*    /!*<img className="externalImg hover_image-hide" src={externalLinkIcon} alt=""/>*!/*/}
                    {/*    <svg className="externalImg hover_image-hide" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"*/}
                    {/*         width="24" height="24"*/}
                    {/*         viewBox="0 0 24 24"*/}
                    {/*         style={{fill: "#fd0054"}}>*/}
                    {/*        <path*/}
                    {/*            d="M19,21H5c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h7v2H5v14h14v-7h2v7C21,20.1,20.1,21,19,21z"></path>*/}
                    {/*        <path d="M21 10L19 10 19 5 14 5 14 3 21 3z"></path>*/}
                    {/*        <path d="M6.7 8.5H22.3V10.5H6.7z" transform="rotate(-45.001 14.5 9.5)"></path>*/}
                    {/*    </svg>*/}
                    {/*</a>*/}
                    <a title="Open in a new tab" className="" href={session?.coach_linkedin} target="_blank">
                    <span className="new-tab-span-container">
  <img className="" src={linkedinIcon} alt=""/>
                        <span> <p> Open </p>
                    <svg className="" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                         width="24" height="24"
                         viewBox="0 0 24 24"
                         style={{fill: "#f8f4f4"}}>
                        <path
                            d="M19,21H5c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h7v2H5v14h14v-7h2v7C21,20.1,20.1,21,19,21z"></path>
                        <path d="M21 10L19 10 19 5 14 5 14 3 21 3z"></path>
                        <path d="M6.7 8.5H22.3V10.5H6.7z" transform="rotate(-45.001 14.5 9.5)"></path>
                    </svg>

 </span>

                    </span>
                    </a>
                </div>}
            </div>
            <div className="my-5">
                <p className="font-light text-md">{t('text_22')}</p>
                <p className="text-lg font-bold">{session?.coach_name}</p>
            </div>
            <div className="pt-3 mb-5 text-sm font-light">
                <p className="mb-3">
                    <img className="inline-flex mr-3" src={calendarIcon} alt=""/>
                    <span>{sessionTime?.format('dddd, DD MMMM')}</span>
                </p>
                <p className="mb-3">
                    <img className="inline-flex mr-3" src={minuteIcon} alt=""/>
                    <span>{sessionTime?.format('hh:mm')}</span>
                </p>
            </div>
            <div className="text-sm font-light">
                {showChangeMeeting() &&
                    <>
                        <a href="# "
                                      onClick={() => showModal("/confirm", "Session Cancellation", submitHandler, 300, 150)}>
                                <p className="mb-3">
                                    <img className="inline-flex mr-3" src={cancelIcon} alt=""/>
                                    <span>{t('text_23')}</span>
                                </p>
                            </a>
                        <a href="# "
                           onClick={() => jumpToDeepLink("booking", session?.coach_id, '/booking?coach_id=' + session?.coach_id, true, session?.id)}>
                            <p className="mb-3">
                                <img className="inline-flex mr-3" src={calendarIcon} alt=""/>
                                <span>{t('text_24')}</span>
                            </p>
                        </a></>
                }
                <a href="#"
                   onClick={() => {
                       showModal("/play-video?url=" + (lng == 'fr'?"https://www.youtube.com/watch?v=izjfHO7ezR8":"https://www.youtube.com/watch?v=xd9Th7U4IhE"), t('text_25'))
                   }}
                >
                    <p className="mb-3">
                        <img className="inline-flex mr-3" src={playIcon} alt=""/>
                        <span>{t('text_26')}</span>
                    </p>
                </a>
            </div>

        </div>

    )
}

export default UpcomingSession;
