import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import NoDataWrapper from "../../../../../shared/components/NoDataWrapper";
import ArrowRight from "../../../components/ArrowRight";
import { dummyObjectives } from "../dummyData";
import { ObjectiveCategory } from "../keyAssestMapping";
import { Objective } from "../../AICoaching/types";

type Props = {
    objectives: Objective[] | null;
    onEditObjectives: () => void;
};

const Objectives = ({ objectives, onEditObjectives }: Props) => {
    const { t } = useTranslation("home");
    const displayObjectives =
        objectives && objectives?.length && objectives?.length > 0
            ? objectives
            : dummyObjectives;

    return (
        <div className="bg-white p-4 pt-3 rounded border-v2_grey-300 border-[1px]">
            <h2 className="text-lg font-bold font-dm_sans text-black mt-2 mb-4">
                {t("objectives.title")}
            </h2>
            <div className="relative">
                {(!objectives || !objectives?.length || objectives?.length < 1) && (
                    <NoDataWrapper />
                )}
                <div className="p-1">
                    <ul className="grid w-full grid-cols-3 gap-x-6 gap-y-6">
                        {displayObjectives.length &&
                            displayObjectives?.map((objective, index) => (
                                <Card key={index} objective={objective} />
                            ))}
                    </ul>

                    <button
                        onClick={() => {
                            onEditObjectives();
                        }}
                        className=" px-5 py-2.5 whitespace-nowrap rounded flex items-center h-[40px] justify-center gap-2
                    border-2 border-black text-black text-sm "
                    >
                        {t("objectives.change_obj")} <ArrowRight />
                    </button>
                </div>
            </div>
        </div>
    );
};

type CardProps = {
    objective: Objective;
};

type ObjectiveCategoryKey = keyof typeof ObjectiveCategory;

const Card = ({ objective }: CardProps) => {
    const lng = localStorage.getItem("I18N_LANGUAGE") || "en";
    const { t } = useTranslation("home");

    const getImage = useCallback((key: string) => {
        if (key in ObjectiveCategory) {
            return (ObjectiveCategory as any)[key].img;
        } else {
            return ObjectiveCategory.default.img;
        }
    }, []);

    const getTitle = useCallback(
        (key: string) => {
            if (key in ObjectiveCategory) {
                return t(`objectives.categories.${key}`);
            } else {
                return t("objectives.categories.default");
            }
        },
        [lng],
    );

    const key = objective.key || 'default';

    return (
        <div className="flex flex-col gap-2 w-min-[100px]">
            <div className="h-20">
                <img
                    src={getImage(key)}
                    alt="coach"
                    className="object-cover rounded w-auto h-20"
                />
            </div>

            <div className="flex flex-col gap-1 py-2">
                <p className="font-dm_sans text-v2_grey-200 m-0 text-sm">
                    {objective.key && getTitle(objective.key)}
                </p>
                {/*<p className="text-sm font-dm_sans text-v2_grey-200 m-0">*/}
                {/*  {lng === "fr" ? objective.name_fr : objective.name}*/}
                {/*</p>*/}
            </div>
        </div>
    );
};

export default Objectives;