import { ITeamsAuthData } from "src/models/ITeamsAuth";
import { DELETE } from "../../utils/lib/axios";
import { MEETING } from "../../constants/endpoints/meeting";

export const cancelSession = async (sessionId: number, is_credit_deducted?: boolean) => {
  const user = JSON.parse(
    localStorage.getItem("ch_user") || "{}"
  ) as ITeamsAuthData;

  return DELETE(
    MEETING.delete.cancelSession,
    undefined,
    {
      sessionId,
    },
    {
      sso_token: user.teams_token,
      is_credit_deducted: is_credit_deducted
    },  
  ).then((response) => {
    return response.data;
  });
};
