import React from 'react';
import Img from "../../../shared/components/Img";
import logoImgSm from "@img/coachello.svg";
import logoImg from "@img/coachello.svg";
import {Link, useNavigate} from "react-router-dom";


const MarketplaceThanks = () => {
    const navigate = useNavigate()
    return (
        <div className="p-24">
            <div className="w-full flex justify-center">
                <div className="m-auto p-2 md:p-5">
                    <Img className="w-auto" triggerPx={768} srcSmall={logoImgSm} srcLarge={logoImg}/>
                </div>
            </div>
            <div className="w-full gap-3 flex flex-col justify-center items-center">
                <h4>Thank you!</h4>
                <div className=" gap-3 flex flex-col justify-center items-center">
                    <h3>Your subscription is activated now. </h3>
                    <p>You have now access to Coachello management console <br/>and to the Coachello Teams app.
                         Use buttons below.</p>
                    <br/>
                    <div className=" flex gap-5 text-black/60">
                        <button className="dashboard-button bg-black justify-center disabled:bg-gray-400"
                                onClick={() => navigate('/auth/login')}>
                            Management console
                        </button>
                        <a className="dashboard-button bg-black justify-center disabled:bg-gray-400"
                              href= {process.env.REACT_APP_TEAMS_APP_LINK || '#'}>
                            Teams app
                        </a>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default MarketplaceThanks;
