export const META = {
    get: {
        companies: {
            private: true,
            path: 'api/company'
        },
        languages: {
            private: true,
            path: 'api/meta/language'
        },
        specializations: {
            private: true,
            path: 'api/meta/specialization'
        },
        reportOptions: {
            private: true,
            path: 'api/meta/report-options'
        },
        getAppConfig: {
            private: true,
            path: "api/meta/{company_id}/app-config",
        },
    },
    post: {
        passToCoachello: {
            path: 'api/sns/pass-to-coachello'
        },

    }
};
