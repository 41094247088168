import {Fragment, ReactNode, useEffect, useState} from "react";

import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import img_step1 from "../../../../../assets/img/aiCoaching/onboarding_step1.png";
import img_step2 from "../../../../../assets/img/aiCoaching/onboarding_step2.png";
import img_step3 from "../../../../../assets/img/aiCoaching/onboarding_step3.png";
import img_step4 from "../../../../../assets/img/aiCoaching/onboarding_step4.png";
import img_step5 from "../../../../../assets/img/aiCoaching/onboarding_step5.png";
import img_step6 from "../../../../../assets/img/aiCoaching/onboarding_step6.png";

import {twMerge} from "tailwind-merge";
import useAuth from "../../../../../utils/hooks/useAuth";
import {setGuideStatus} from "../../../../../services/api/teams/GuideActions";
import Feedback360Emails from "../../../../teams/pages/Feedback360Emails";
import LanguageSelector from "../../../../../shared/components/LanguageSelector/LanguageSelector";
import Button from "../../../../../shared/components/Button";
import ArrowRight from "../../../components/ArrowRight";
import AssessmentAI from "../../../../teams/AssessmentAI";
import selfReflectionQuiz from "@img/dashboard/welcome/icon/map.svg";
import HT from "../../OnboardingRegular/components/HighlightedText";
import InfoPopover from "../../../components/InfoPopover";
import useGuideStatus from "@hooks/useGuideStatus";

type StepData = {
    step: number;
    title?: string;
    image?: string;
    description?: string;
    text_highlight?: string;
    actionLabel?: string;
    content?: ReactNode;
};

const Tutorial = () => {
    const {t} = useTranslation("ai_onboarding");
    const lng = localStorage.getItem("I18N_LANGUAGE") || "en";
    const nav = useNavigate();
    const {user} = useAuth();
    const guideStatus = useGuideStatus();

    const [step, setStep] = useState<StepData>();
    const [stateChanging, setStateChanging] = useState(false);

    const handleNext = () => {
        if (!step) return;
        setStateChanging(true);
        if (step.step === 1) {
            setGuideStatus(1, false);
            setStep(Content[step.step]);
        } else if (step.step < 7) {
            setStep(Content[step.step]);
        }
        setTimeout(() => setStateChanging(false), 200);
    };

    const handleAssessmentSubmission = () => {
        setStep((currVal) => {
            if (!currVal) return currVal;
            return Content[currVal.step];
        });
    };

    const handleFeedback360SuccessEvent = () => {
        setGuideStatus(4, true).then(() => {
            nav("/");
        });
    };

    const Content: StepData[] = [
            {
                step: 1,
                title: "titles.step1",
                image: img_step1,
                description: "descriptions.step1",
                actionLabel: "action_labels.step1",
            },
            {
                step: 2,
                title: "titles.step2",
                image: img_step2,
                description: "descriptions.step2",
                actionLabel: "action_labels.step2",
            },
            {
                step: 3,
                title: "titles.step3",
                image: img_step3,
                description: "descriptions.step3",
                text_highlight: "descriptions.step3_highlight",
                actionLabel: "action_labels.step3",
            },
            {
                step: 4,
                title: "titles.step4",
                image: img_step4,
                description: "descriptions.step4",
                text_highlight: "descriptions.step4_highlight",
                actionLabel: "action_labels.step4",
            },
            {
                step: 5,
                title: "titles.step5",
                image: img_step5,
                description: "descriptions.step5",
                actionLabel: "action_labels.step5",
            },
            {
                step: 6,
                title: "titles.step6",
                image: img_step6,
                description: "descriptions.step6",
                actionLabel: "action_labels.step6",
            },
            {
                step: 7,
                description: "descriptions.step7",
                content: <div className="w-full h-full flex flex-col gap-2 ">
                    <div className="w-full h-full">
                        <div className="w-full h-full flex flex-col rounded-md bg-white ">
                            <div className="flex justify-between items-center mb-4">
                                <div className="flex items-center gap-3">
                                    <img
                                        src={selfReflectionQuiz}
                                        className="w-8"
                                        alt="Self-reflection quiz"
                                    />
                                    <HT className="text-xl font-bold font-lexend">
                                        {t("titles.step7")}
                                    </HT>
                                    {/*  <span*/}
                                    {/*      className={`px-5 py-2 rounded-full text-xs text-black bg-[#E3F8EE]`}*/}
                                    {/*  >*/}

                                    {/*</span>*/}
                                </div>
                                <InfoPopover content={t("descriptions.step7_info")}/>
                            </div>
                            <div className="h-full">
                                <AssessmentAI
                                    onSubmit={handleAssessmentSubmission}
                                    style={{height: "100%", width: "100%"}}
                                />
                            </div>
                        </div>
                    </div>
                    <p
                        className={`font-lexend text-black text-base text-center filter ease-in-out duration-300 ${
                            "text-justify"
                        }`}
                        style={{animation: "flash 4s 1s"}}
                    >
                        {t("descriptions.step7")}
                    </p>
                    <ActionArrowButton
                        className="ml-auto relative"
                        text={t("skip")}
                        onClick={handleAssessmentSubmission}
                    />
                </div>,
            },
        {
            step: 8,
            description: "descriptions.step8",
            content: (<div className="w-full h-full flex flex-col gap-2 ">
                    <div className="w-full h-full">
                        <div className="w-full h-full flex flex-col rounded-md bg-white ">
                            <div className="flex justify-between items-center mb-4">
                                <div className="flex items-center gap-3">
                                    <img
                                        src={selfReflectionQuiz}
                                        className="w-8"
                                        alt="Self-reflection quiz"
                                    />
                                    <HT className="text-xl font-bold font-lexend">
                                        {t("titles.step8")}
                                    </HT>
                                    {/*  <span*/}
                                    {/*      className={`px-5 py-2 rounded-full text-xs text-black bg-[#E3F8EE]`}*/}
                                    {/*  >*/}

                                    {/*</span>*/}
                                </div>
                                <InfoPopover content={t("descriptions.step8_info")}/>
                            </div>
                            <p
                                className={`font-lexend px-5 text-black text-base text-center filter ease-in-out duration-300 ${
                                    "text-justify"
                                }`}
                                style={{animation: "flash 4s 1s"}}
                            >
                                {t("descriptions.step8")}
                            </p>
                            <div className="h-full z-50">
                                <Feedback360Emails
                                    onNotificationSuccess={handleFeedback360SuccessEvent}
                                    sendButtonText={t("action_labels.step8")}
                                />
                            </div>

                        </div>
                    </div>


                </div>

            ),
        },
        ]
    ;

    useEffect(() => {
        guideStatus.checkStatus(() => {
            nav("/");
        });

        setStep(Content[0]);
    }, []);

    const renderTextSections = (text: string, hightedText: string) => {
        const parts = text.split("HIGHLIGHT");
        return parts.map((part, index) => (
            <Fragment key={index}>
                {part}
                {index !== parts.length - 1 && (
                    <span className="text-red-500">{hightedText}</span>
                )}
            </Fragment>
        ));
    };

    return (
        <div className="h-full flex justify-center w-full flex-col gap-6 items-center">
            <div className="relative bg-white rounded h-[100vh] lg:w-2/3 lg:h-5/6 lg:min-h-[400px] shadow">
                {['DemoCompany', 'TestCompany'].includes(user?.companyName!) &&
                    <div className="fixed bottom-4 right-4">
                        <LanguageSelector/>
                    </div>}
                <div
                    className={`absolute bg-white h-full w-full transition-opacity ease-in-out duration-300 blur ${
                        stateChanging ? "opacity-50" : "opacity-0"
                    }`}
                />

                {step && (
                    <div className="flex flex-col gap-10 items-center justify-center py-6 px-6 h-full w-full">
                        {step.content ? (
                            <>
                                {step.title && (
                                    <h1
                                        className="font-gambetta text-black text-xl font-bold"
                                        style={{animation: "flash 2s .2s"}}
                                    >
                                        {t(step.title)}
                                    </h1>
                                )}

                                {step.content}
                            </>
                        ) : (
                            <>
                                <div
                                    className={`flex flex-col h-fit w-full ${
                                        step.step === 1 || step.step === 6
                                            ? "items-center"
                                            : "items-start"
                                    }`}
                                >
                                    <h1
                                        className="font-gambetta text-black text-xl font-bold"
                                        style={{animation: "flash 2s .2s"}}
                                    >
                                        {step.title ? t(step.title) : ""}
                                    </h1>
                                </div>

                                <div
                                    className={`flex items-center justify-center ${
                                        step.step === 3 || step.step === 4 ? "h-40" : "h-32"
                                    }`}
                                >
                                    {!stateChanging && (
                                        <img className={`${step.step === 3 || step.step === 4
                                            ? ""
                                            : "h-full"} w-full`} alt={"image"} src={step.image}/>
                                    )}
                                </div>

                                {step.text_highlight ? (
                                    <p
                                        className={`font-lexend text-black text-base ${
                                            step.step === 1 || step.step === 6
                                                ? "text-center"
                                                : "text-justify"
                                        }`}
                                        style={{animation: "flash 4s 1s"}}
                                    >
                                        {renderTextSections(
                                            step.description ? t(step.description) : "",
                                            t(step.text_highlight)
                                        )}
                                    </p>
                                ) : (
                                    <p
                                        className={`font-lexend text-black text-base text-center filter ease-in-out duration-300 ${
                                            step.step === 1 || step.step === 6
                                                ? "text-center"
                                                : "text-justify"
                                        }`}
                                        style={{animation: "flash 4s 1s"}}
                                    >
                                        {step.description ? t(step.description) : ""}
                                    </p>
                                )}

                                <div
                                    className={`flex flex-col h-fit w-full ${
                                        step.step === 1 || step.step === 6
                                            ? "items-center"
                                            : "items-end"
                                    }`}
                                >
                                    <ActionArrowButton
                                        text={step.actionLabel ? t(step.actionLabel) : ""}
                                        onClick={handleNext}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

type ActionArrowButtonProps = {
    text: string;
    className?: string;
    onClick: () => void;
};

const ActionArrowButton = ({
                               text,
                               className,
                               onClick,
                           }: ActionArrowButtonProps) => {
    return (
        <Button
            className={twMerge(
                "absolute font-dm_sans w-max h-[43px] flex items-center justify-center gap-2 text-xs px-5 py-2.5",
                "bg-red text-white font-bold rounded",
                className
            )}
            onClick={onClick}
        >
            <div className="h-full w-full flex flex-row justify-between items-center gap-2">
                <div className="h-full flex flex-row gap-4 items-center">
                    <p className="text-xs font-dm_sans font-bold h-[18px]">{text}</p>
                </div>
                <ArrowRight/>
            </div>
        </Button>
    );
};

export default Tutorial;
