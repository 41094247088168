import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";

import * as microsoftTeams from "@microsoft/teams-js";
import {getMyNote, saveMyNote} from "../../../services/api/teams/ReportActions";
import i18n from "../../../utils/lib/i18n";
import ENG from "../locale/eng/mynote.json";
import Fr from "../locale/fr/mynote.json";


const MyNote = () => {
    i18n.addResourceBundle('en', 'mynote', ENG    );
    i18n.addResourceBundle('fr', 'mynote', Fr    );
    const [searchParams] = useSearchParams()
    const meetingId = searchParams.get('meeting_id')
    const [loading, setLoading] = useState(false)
    const [note, setNote] = useState('')
    const {t} = useTranslation('mynote')

    useEffect(() => {

        if (meetingId) {
            getMyNote(parseInt(meetingId))
                .then(res => {
                    if (res) {
                        setNote(res);
                    } else {
                        setNote('');
                    }
                }).catch((e) => {
                console.log("api error");
            })


        }
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNote(e.target.value)
    }

    const handleSubmit = () => {
        if (meetingId) {
            setLoading(true)
            saveMyNote(parseInt(meetingId), {"data": note})
                .then(res => {
                    setLoading(false)
                    microsoftTeams.app.initialize().then(r => {
                        microsoftTeams.dialog.submit('result', process.env.REACT_APP_TEAMS_MANIFEST_APPID);
                    })
                })
                .catch((e) => {
                    console.log("api error");
                    setLoading(false)
                })
        }
    }

    return (
        <div className="flex flex-col w-full items-center h-screen">
            <div className="flex justify-between w-full h-full">
                <div
                    className="flex flex-col items-center justify-start w-full pt-3 pb-16 bg-white rounded-lg shadow px-5">

                    <form action="src/modules/teams/pages/MyNote" className="w-full gap-5 lg:w-8/12 h-full">


                        <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700">{t('text_1')}</label>
                            <div className="mt-1">
                                <textarea value={note} maxLength={1600} onChange={handleInputChange} rows={13}
                                          name="comment"
                                          placeholder={t('text_2')}
                                          id="first-name" autoComplete="given-name"
                                          className="block w-full px-3 py-2 border border-gray-600 rounded shadow-sm sm:text-sm placeholder:font-light font-light"></textarea>

                            </div>
                        </div>
                        <div className="flex items-center justify-end mt-4 py-12">
                            <button type="button" onClick={handleSubmit}
                                    className="px-10 py-2 text-white rounded bg-red placeholder:font-light"
                                    disabled={loading}>{loading ?  t('text_3')  :  t('text_4')}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default MyNote;
