import React from 'react';
import askCoachesImg from '../../../assets/img/teams/askCoaches.png'
import closeIcon from '../../../assets/img/teams/icons/closeIcon.png'
import {useTranslation} from "react-i18next";
import {jumpToDeepLink} from "../../../utils/helpers/teams";

interface Props {
    setClose: (b: boolean) => void
}

const AskCoach = (prop: Props) => {
    const {t} = useTranslation('booking')
    return (
        <div className="m-5 flex flex-col">
            <span className="flex justify-end  ">  <button onClick={() => prop.setClose(false)}><img className="h-6"
                                                                                                     src={closeIcon}/></button></span>
            <div className="flex flex-col gap-3">
                <h1 style={{fontSize: "24px"}} className="font-bold">{t('select_coach.text_2')}</h1>
                <p>{t('select_coach.text_3_1')} <br/>{t('select_coach.text_3_2')} </p>


                <div>
                    <img className="h-12 mb-5" src={askCoachesImg}/>
                </div>


                <button onClick={() => jumpToDeepLink("conversations")} style={{

                    backgroundColor: "black"
                }}
                        className=" px-5 py-3 text-center text-white rounded z-10"> {t('select_coach.text_4')}
                </button>

            </div>
        </div>
    );
};

export default AskCoach;
