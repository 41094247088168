import {Widget} from '@typeform/embed-react'
import {useSearchParams, useParams} from "react-router-dom";

const Typeform = () => {
    const [searchParams] = useSearchParams();
    const { typeformId } = useParams();

    const userParams: Record<string, string> = {};
    searchParams.forEach((value, key) => {
        userParams[key] = value;
    });

    return <Widget hidden={userParams} id={typeformId || 'TjGf5Xk3'} style={{width: '100%', height: '100vh', flex: '1 1 auto'}}/>
}

export default Typeform;