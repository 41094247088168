import {FC, useState, useEffect} from "react";

import {Modal as RSuiteModal} from "rsuite";

type Props = {
    isOpen: boolean;
    title?: any;
    onClose?: (e: any) => void;
    children?: React.ReactNode;
    className?: string;
    closeHandler?: () => void;
};

const Modal: FC<Props> = ({
                              isOpen,
                              onClose,
                              closeHandler,
                              title,
                              children,
                              className,
                          }) => {
    const [open, setOpen] = useState(isOpen);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleClose = () => {
        setOpen(false);

        onClose && onClose(false);
    };

    return (
        <RSuiteModal
            overflow={false}
            open={open}
            onClose={closeHandler ?? handleClose}
            size="full"
        >
            <RSuiteModal.Header>
                <RSuiteModal.Title>{title}</RSuiteModal.Title>
            </RSuiteModal.Header>
            <RSuiteModal.Body className={`p-0 ${className}`}>
                {children}
            </RSuiteModal.Body>
        </RSuiteModal>
    );
};

export default Modal;
