import {FC, useCallback} from "react";

import {UploadIcon} from "@heroicons/react/solid";
import {useDropzone} from "react-dropzone";
import Papa from "papaparse";
import {toast} from "react-toastify";

import {IGroupCoachee} from "../../../../../models/IGroup";
import {IMeta} from "../../../../../models/IMeta";
import i18n from "../../../../../utils/lib/i18n";
import ENG from "../../../locale/eng/groups.json";
import Fr from "../../../locale/fr/groups.json";
import {useTranslation} from "react-i18next";

type UploadUserListProps = {
    onDropCsv: (value: IGroupCoachee[]) => void;
    labelOptions: IMeta[];
};

const UserListDropzone: FC<UploadUserListProps> = ({
                                                       onDropCsv,
                                                       labelOptions,
                                                   }) => {

    i18n.addResourceBundle("en", "groups", ENG);
    i18n.addResourceBundle("fr", "groups", Fr);
    const {t} = useTranslation('groups');

    const onDrop = useCallback(async (files: File[]) => {
        if (!files.length)
            return toast.error(t("create_group.text22"));

        const file = files[0];

        const INVALID_CSV_ERR_MSG = t("create_group.text24");

        Papa.parse<string[]>(file, {
            worker: true,
            complete({data}) {
                if (!data.length) return toast.error(INVALID_CSV_ERR_MSG);

                const headers = data[0];
                const rows = data.slice(1);

                const emailIndex = headers.findIndex((header) => header === "email");
                const firstNameIndex = headers.findIndex(
                    (header) => header === "first_name"
                );
                const lastNameIndex = headers.findIndex(
                    (header) => header === "last_name"
                );

                const labelIndex = headers.findIndex((header) => header === "label");

                if (emailIndex < 0) return toast.error(INVALID_CSV_ERR_MSG);

                const csvData: IGroupCoachee[] = [];
                rows.forEach((row) => {
                    if (row[0] !== "") {
                        let label = labelIndex >= 0 ? row[labelIndex].trim() : undefined;
                        const labelMetaObj = labelOptions.find(
                            (labelMeta) => labelMeta.name === label
                        );
                        if (label && !label.length) label = undefined;
                        csvData.push({
                            email: row[emailIndex],
                            first_name: firstNameIndex >= 0 ? row[firstNameIndex] : undefined,
                            last_name: lastNameIndex >= 0 ? row[lastNameIndex] : undefined,
                            label: labelMetaObj
                                ? labelMetaObj
                                : label
                                    ? {name: label}
                                    : undefined,
                        });
                    }
                });

                onDropCsv(csvData.filter((item) => item.email));
            },
        });
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: {
            "text/*": [".csv"],
        },
    });

    return (
        <div className="flex flex-col gap-2 border-[#f2ebe3]">
            <div className="flex flex-col gap-0.5">
                <div
                    className="relative cursor-pointer p-2 flex flex-row justify-center items-center gap-2 bg-[#fff2f6] rounded-[5px] border-2 border-[#f56e9b] border-dashed"
                    {...getRootProps()}
                >
                    <input className="dropzone-input" {...getInputProps()} />

                    <UploadIcon className="h-[20px] text-[#4F4F4F]"/>
                    <p className="font-lexend text-sm font-medium text-[#4F4F4F]">
                        {isDragActive ? (
                            <>
                                {t("create_group.text18")} <span
                                className="font-bold">.CSV</span> {t("create_group.text12")}
                            </>
                        ) : (
                            <>
                                {t("create_group.text10")} <span
                                className="font-bold">.CSV</span> {t("create_group.text12")}
                            </>
                        )}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default UserListDropzone;
