import jwt_decode from 'jwt-decode';


export const decodeToken = (token: string): any => {
    try {
        return jwt_decode(token);
    } catch (Error) {
        return null;
    }
}
