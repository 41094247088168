import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import Select from "react-select";

import CoachCard from "./components/CoachCard";

import i18n from "../../../../utils/lib/i18n";
import ENG from "../../locale/eng/coaches.json";
import Fr from "../../locale/fr/coaches.json";
import { useNavTitle } from "@hooks/useNavTitle";
import { getCoaches, getPrograms } from "../../../../services/api/companyApi";
import useAuth from "@hooks/useAuth";

import ICoach from "../../../../models/ICoach";
import { IMeta } from "../../../../models/IMeta";

type FilterOption = {
  label: string;
  value: number;
};

const Coaches = ({ ...props }) => {
  i18n.addResourceBundle("en", "coaches", ENG);
  i18n.addResourceBundle("fr", "coaches", Fr);
  const { t } = useTranslation("coaches");
  useNavTitle(t(`layout:sidebar.${props.navTitle}`));
  const [coaches, setCoaches] = useState<ICoach[]>([]);
  const { user } = useAuth();

  const [programs, setPrograms] = useState<FilterOption[]>([]);
  const [selectedPrograms, setSelectedPrograms] = useState<FilterOption[]>([]);
  useEffect(() => {
    if (!user) return;
    getPrograms(user.companyId).then((data: IMeta[]) => {
      setPrograms(() => {
        return data.map((programMeta) => {
          return { label: programMeta.name, value: programMeta.id! };
        });
      });
    });
  }, []);

  useEffect(() => {
    const filtrationPrograms = selectedPrograms.map((program) => program.value);

    getAllCoaches(filtrationPrograms);
  }, [selectedPrograms]);

  const getAllCoaches = (filtrationPrograms: Array<number>) => {
    if (!user) return;
    getCoaches(user.companyId, undefined, filtrationPrograms)
      .then((response) => {
        setCoaches(response);
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  return (
    <div className="m-5 flex flex-col gap-4">
      <Select
        isClearable
        isSearchable={false}
        className="w-[300px] z-20"
        placeholder={t("text9")}
        noOptionsMessage={() => t("text10")}
        value={selectedPrograms}
        options={programs}
        onChange={(value) => {
          setSelectedPrograms(value as FilterOption[]);
        }}
        menuPlacement="bottom"
        isMulti
      />
      <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
        {coaches.map((coach, index) => (
          <CoachCard key={index} coach={coach} />
        ))}
      </div>
    </div>
  );
};

export default Coaches;
