import {FC} from 'react'
import {useTranslation} from "react-i18next";
import i18n from "../../../utils/lib/i18n";
import ENG from "../locale/eng/faq.json";
import Fr from "../locale/fr/faq.json";


interface Props {
    isOpen: boolean,
    onClose?: (e: any) => any
}

const FAQModal: FC<Props> = (props) => {
    i18n.addResourceBundle('en', 'faq', ENG    );
    i18n.addResourceBundle('fr', 'faq', Fr    );
    const {t} = useTranslation('faq')
    return (
        // <Modal title="FAQ" isOpen={props.isOpen} onClose={props.onClose}>
        <div style={{margin: "4%"}} className="mt-5">
            <div className="mb-3">
                <h2 className="mb-2">{t('text_1')}</h2>
                <p className="text-sm font-light text-justify">{t('text_2')}</p>
            </div>
            <div className="mb-3">
                <h2 className="mb-2">{t('text_3')} </h2>
                <p className="text-sm font-light text-justify">{t('text_4')}</p>
            </div>
            <div className="mb-3">
                <h2 className="mb-2">{t('text_5')} </h2>
                <p className="text-sm font-light text-justify">{t('text_6')}</p>
            </div>
            <div className="mb-3">
                <h2 className="mb-2">{t('text_7')}</h2>
                <p className="text-sm font-light text-justify">{t('text_8')}</p>
            </div>
            <div className="mb-3">
                <h2 className="mb-2">{t('text_9')}</h2>
                <p className="text-sm font-light text-justify">{t('text_10')}</p>
            </div>
            <div className="mb-3">
                <h2 className="mb-2">{t('text_11')}</h2>
                <p className="text-sm font-light text-justify">{t('text_12')}</p>
            </div>
            <div className="mb-3">
                <h2 className="mb-2">{t('text_13')}</h2>
                <p className="text-sm font-light text-justify">{t('text_14')}</p>
            </div>
            <div className="mb-3">
                <h2 className="mb-2">{t('text_15')}</h2>
                <p className="text-sm font-light text-justify">{t('text_16')}</p>
            </div>
        </div>
        // </Modal>
    )
}

export default FAQModal;
