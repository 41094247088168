export const MARKETPLACE = {
    post: {
        resolveSubscription: {
            path: 'api/marketplace/resolve'
        },
        activateSubscription: {
            path: 'api/marketplace/activate'
        },

    },
};
