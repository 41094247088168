import React, {ReactElement} from 'react';
import {ERole} from "../../constants/enums";

interface Props {
    allowedRoles?: ERole[];
    children: ReactElement;
    userRole?: ERole;
}

const UserDropdownItemWrapper = ({allowedRoles, children, userRole}: Props) => {
    if (!(allowedRoles && userRole && (allowedRoles?.indexOf(userRole) === -1))) {
        return (
           children
        );
    } else {
        return null
    }


};

export default UserDropdownItemWrapper;
