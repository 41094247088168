import React, {useState} from "react";
import {NameCell} from "../../shared/components/Table/NameCell";
import moment from "moment/moment";
import i18next from "i18next";
import {capitalizeSentence} from "../../utils/helpers/str";
import IconCell from "../../shared/components/DeactivateIcon";
import ReportIconCell from "../../shared/components/Table/ReportIcon"
import {CheckIcon, LockClosedIcon, PencilIcon, SaveIcon, XCircleIcon, XIcon} from "@heroicons/react/outline";
import {toast} from "react-toastify";
import {setSessionLimit} from "../../services/api/clientApi";
import DynamicColorSpan from "../../shared/components/DynamicColorSpan";
import {ITeamsAuthData} from "../../models/ITeamsAuth";
import {IAuthUser} from "../../models/IAuth";
import storage from "../../services/storage/localStorage";
import RandomColorSpan from "../../shared/components/RandomColorSpan";

import {CellContext, ColumnDef, ColumnDefTemplate, SortingFn} from "@tanstack/react-table";
import defaultProfileImg from "@img/icons/default-profile.png";
 

const AttendedCellComponent: ColumnDefTemplate<CellContext<any, any>> = (cell) => {
        const data = cell;
        let authUser:IAuthUser =storage.get('user')
        const [isEditing, setIsEditing] = useState(false);
        const [tempValue, setTempValue] = useState(data.row.original.allowed || data.row.original.attended);
        // console.log(cell);
        // console.log(authUser);

        const handleEdit = () => {
            setIsEditing(true);
        };

        const handleSave = () => {
            if (tempValue !== data.row.original.allowed && data.row.original.attended <= tempValue) {
                // Update the value in the data array here
                // You can use the tableData.ts file to export and import the data array
                setSessionLimit(data.row.original.id, tempValue)
                    .then(res => {
                        toast.success(i18next.t("commons:text7") as String)
                        console.log('Value changed, saving...');
                        data.row.original.allowed = tempValue;
                        // data.row.values.attended = `${data.row.original.attended}/${tempValue}`;
                        // data.row.cells[1].value = `${data.row.original.attended}/${tempValue}`;
                        setIsEditing(false);
                    }).catch((e) => {
                    toast.error(i18next.t("commons:text8") as String)
                    console.log("api error");
                })
                setTempValue(data.row.original.allowed || data.row.original.attended)
            }
        };

        const handleCancel = () => {
            setTempValue(data.row.original.allowed);
            setIsEditing(false);
        };

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setTempValue(parseInt(event.target.value));
        };
        return (
            <>
                {isEditing ? (
                    <><span className="flex items-center">{data.row.original.attended}/<span
                        className="flex items-center gap-2">
                            <input type="number" value={tempValue}
                                   className="w-16 border rounded pl-2"
                                   min={data.row.original.attended}
                                   onChange={handleChange}/>
                         <CheckIcon className="w-5 h-5 text-red cursor-pointer hover:text-black"
                                    onClick={handleSave}>Save</CheckIcon>
                            <XIcon className="w-5 h-5 text-red cursor-pointer hover:text-black"
                                   onClick={handleCancel}>Cancel</XIcon>
                        </span>
                        </span>


                    </>
                ) : (
                    <>
                            <span className="flex items-center">{data.row.original.attended}/<span
                                className="flex items-center gap-2">{authUser?.companyName == 'DemoCompany'?<p className="text-md">∞</p>:(data.row.original.allowed || '-')}<PencilIcon
                                className={`w-5 h-5 text-red cursor-pointer hover:text-black ${authUser?.companyName == 'DemoCompany'? 'hidden':''}`}
                                onClick={handleEdit}>Edit</PencilIcon></span></span>

                    </>
                )}
            </>
        );
    };

const sortGroupFn = (rowA: any, rowB: any) => {
    const groupA = rowA.original.group?.name || "";
    const groupB = rowB.original.group?.name || "";
    return groupA.localeCompare(groupB);
};

const sortProgramFn = (rowA: any, rowB: any) => {
    const programA = rowA.original.program?.name || "";
    const programB = rowB.original.program?.name || "";
    return programA.localeCompare(programB);
}

const sortStatusFn = (rowA: any, rowB: any) => {
    const statusA = rowA.original.status?.name || "";
    const statusB = rowB.original.status?.name || "";
    return statusA.localeCompare(statusB);
}

export const UsageColumns: ColumnDef<any, any>[] = [
    {
        header: "text1",
        accessorKey: "name",
        cell: (cell: CellContext<any, any>) => {
            const data = cell;
            const value = cell.getValue();
            return (
            <NameCell
                // icon={data.row.original.img_url ? data.row.original.img_url : 'https://xsgames.co/randomusers/avatar.php?g=male'}
                icon={data.row.original.image_url ? data.row.original.image_url : defaultProfileImg}
                text={value}
                // disabled={data.row.original.inactive}
            />)
        }
    },
    {
        header: "text12",
        accessorKey: "status",
        cell: (cell: CellContext<any, any>) => {
            const data = cell.getValue();
            return data ?
                <DynamicColorSpan
                    backgroundColor={data?.color_code}>{capitalizeSentence(data?.name)}</DynamicColorSpan> :
                <span className={``}>_</span>
        },
        sortingFn: sortStatusFn
    },
    {
        header: "text2",
        accessorKey: "attended",
        // <>{cell.row.original.attended + (cell.row.original.allowed ? ("/" + cell.row.original.allowed) : null)}</>,
        cell: AttendedCellComponent,
    },
    {
        header: "text6",
        accessorKey: "assessment_status",
        cell: (cell: CellContext<any, any>) => {
            const data = cell.getValue();
            return (
            (data == 'completed') ?
                <div className="flex justify-start">
                    <span
                        className="bg-[#DDF6EF] p-2 text-[#1FC191] rounded text-center font-normal capitalize">
                    {complete}
                    </span>
                </div> :
                <div className="flex justify-start">
                    <span
                        className="bg-[#FFF5F8] p-2 text-[#FE7BA6] rounded text-center font-normal capitalize">
                    {inComplete}</span></div>)
        }
    },
    {
        header: "text10",
        accessorKey: "group",
        cell: (cell: CellContext<any, any>) => {
            const data = cell.getValue();
            return (
                data ?
                <DynamicColorSpan
                    backgroundColor={data?.color_code}>{data?.name}</DynamicColorSpan> :
                <span className={``}>_</span>)
        },
        sortingFn: sortGroupFn
    },
    {
        header: "text3",
        accessorKey: "program",
        cell: (cell: CellContext<any, any>) => {
            const data = cell.getValue();
            return data ?
                <DynamicColorSpan
                    backgroundColor={data?.color_code}>{data?.name}</DynamicColorSpan> :
                <span className={``}>_</span>
        },
        sortingFn: sortProgramFn
    },
    {
        header: "text11",
        accessorKey: "label",
        cell: (cell: CellContext<any, any>) => {
            const data = cell.getValue();
            return data == null ? <></> : <RandomColorSpan text={data} />
        }
    },
    {
        header: "text14",
        accessorKey: "created_at",
        cell: (cell: CellContext<any, any>) => {
            const data = cell.getValue();
            return (
                <>{moment(data).format("ll")}</>)
        }
    },
    {
        header: "text9",
        accessorKey: "id",
        cell: (cell: CellContext<any, any>) => {
            const data = cell.row;
            return(
            data.original.inactive ? <></> : <IconCell
                icon={data.original.id}
                text={data.original.id}/>)
        }

    },

]

moment.locale("en")
const complete = i18next.t("tables:usage.text7")
const inComplete = i18next.t("tables:usage.text8")

export const UsageSessionViewColumns: ColumnDef<any, any>[] = [
    {
        header: "text1",
        accessorKey: "name",
        cell: (cell: CellContext<any, any>) => {
            const data = cell;
            const value = cell.getValue();
            return(
            <NameCell
                // icon={cell.row.original.img_url ? cell.row.original.img_url : 'https://xsgames.co/randomusers/avatar.php?g=male'}
                icon={data.row.original.image_url ? data.row.original.image_url : defaultProfileImg}
                text={value}/>)
        }

    },
    {
        header: "text4",
        accessorKey: "meeting_time",
        cell: (cell: CellContext<any, any>) => {
            const data = cell.getValue();
            return(
            <>
                {
                    moment(data).format("ll")
                }
            </>
            )
        }

    },
    {
        header: "text5",
        accessorKey: "meeting_no",
        cell: (cell: CellContext<any, any>) => {
            const data = cell;
            return (
                <>{data.row.original.orientation ? <DynamicColorSpan
                        backgroundColor={"#68e041"}>Orientation</DynamicColorSpan>
                    : (data.row.original.meeting_no + (data.row.original.allowed ? ("/" + data.row.original.allowed) : null))}</>)
        },
    },
    {
        header: "text10",
        accessorKey: "group",
        cell: (cell: CellContext<any, any>) => {
            const data = cell;
            return (
                data.row.original.group ?
                <DynamicColorSpan
                    backgroundColor={data.row.original.group?.color_code}>{data.row.original.group?.name}</DynamicColorSpan> :
                <span className={``}>_</span>)
        },
        sortingFn: sortGroupFn
    },
    {
        header: "text3",
        accessorKey: "program",
        cell: (cell: CellContext<any, any>) => {
            const data = cell;
            return data.row.original.program ?
                <DynamicColorSpan
                    backgroundColor={data.row.original.program?.color_code}>{data.row.original.program?.name}</DynamicColorSpan> :
                <span className={``}>_</span>
        },
        sortingFn: sortProgramFn
    },
    {
        header: "text11",
        accessorKey: "label",
        cell: (cell: CellContext<any, any>) => {
            const data = cell;
            return (
                data.row.original.label == null ? <></> : <RandomColorSpan text={data.row.original.label}/>)
        }
    },
]

export const SessionViewColumns: ColumnDef<any, any>[]  = [
    {
        header: "text1",
        accessorKey: "client_name",
        cell: (cell: CellContext<any, any>) => {
            const data = cell.getValue();
            return (
            <NameCell
                // icon={cell.row.original.img_url ? cell.row.original.img_url : 'https://xsgames.co/randomusers/avatar.php?g=male'}
                icon={cell.row.original.image_url ? cell.row.original.image_url : defaultProfileImg}
                text={data}/>)
        }

    },
    {
        header: "text2",
        accessorKey: "client_company",
    },
    {
        header: "text3",
        accessorKey: "meeting_date",
    },
    {
        header: "text4",
        accessorKey: "meeting_no",
        cell: (cell: CellContext<any, any>) => {
            return (
            <>{`${cell.row.original.session_no || '-'} ${(cell.row.original.allowed ? ("/" + cell.row.original.allowed) : '')}`}</>)
        }
    },
    {
        header: "text5",
        accessorKey: "status",
        cell: (cell: CellContext<any, any>) => {
            return cell.row.original.status ?
                <RandomColorSpan text={capitalizeSentence(cell.row.original.status)}></RandomColorSpan> :
                <span className={``}>_</span>
        }
        // Cell: (cell: any) => <>{capitalizeSentence(cell.value)}</>

    },
    {
        header: "text8",
        accessorKey: "session_type",
        cell: (cell: CellContext<any, any>) => {
            return cell.row.original.orientation ? 
                <span style={{backgroundColor: 'green', color: 'white', padding: '5px', borderRadius: '5px'}}>Orientation</span> :
                <span style={{backgroundColor: 'gray', color: 'white', padding: '5px', borderRadius: '5px'}}>Session</span>
        }
    },
    {
        header: "text6",
        accessorKey: "id",
        cell: (cell: CellContext<any, any>) => {
            return (
            !cell.row.original.orientation &&    
            <ReportIconCell
                icon={cell.row}
                session_Id={cell.row.original.id}/>)
        }
    },
]
