import {filterDict} from "./object";

export const range = (start: number, end: number) => {
    let length = end - start + 1;
    /*
        Create an array of certain length and set the elements within it from
      start value to end value.
    */
    return Array.from({length}, (_, idx) => idx + start);
};
export const filterInnerDictArr = (arr:Array<Record<any, any>>, allowedKeys:  string[]) => {
    return arr.map(item=>filterDict(item,allowedKeys))
}

export const getOrderIndexArr = (array: number[]) => {
    const sortedIndex = (arr: number[]) => arr.map((val, ind) => {
        return {ind, val}
    })
        .sort((a, b) => {
            return a.val > b.val ? 1 : a.val == b.val ? 0 : -1
        })
        .map((obj) => obj.ind);
    return sortedIndex(sortedIndex(array))
};
