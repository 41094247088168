import ReactTooltip from "react-tooltip";
import {DocumentReportIcon} from "@heroicons/react/solid";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {useTranslation} from "react-i18next";
import { useNavigate} from 'react-router-dom';

interface Props {
    icon: any;
    session_Id: number;
}
const ReportIconCell = (props: Props) => {
    const {t} = useTranslation('commons')
    const navigate = useNavigate()
    
    const submit = () => {
        navigate(`/dashboard/reports/create/${props.session_Id}`) 
    }
    return (
        <div
            className="flex items-center gap-1.5">
            <button data-tip data-for="registerTip" onClick={submit}>
                <DocumentReportIcon className="text-red w-5 h-6" data-for='preserve'
                           data-tip={"hover on me will keep the tooltip"}/>
            </button>
            <ReactTooltip delayHide={100} id={'preserve'} place="top" effect="solid">
                {t('text6')}
            </ReactTooltip>
        </div>
    )
}
export default ReportIconCell;
