import { axiosPrivate } from "../../../utils/lib/axios";

export const getGuideStatus = () => {
  return axiosPrivate
    .get<any>("/api/clients/me/guide")
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const setGuideStatus = (step: number, completed: boolean = false) => {
  return axiosPrivate
    .put<any>("/api/clients/me/guide", {
      step,
      completed,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getClientObjectives = () => {
  return axiosPrivate
    .get<any>("/api/clients/me/objectives")
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const setClientObjectives = (data: any) => {
  return axiosPrivate
    .post<any>("/api/clients/me/objectives", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
