const ArrowRight = ({ color }: { color?: string }) => {
  return (
    <svg width="18" height="19" viewBox="0 0 24 25" fill="none">
      <path
        d="M20 12.5H4"
        stroke={color ? color : "currentColor"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 17.5C15 17.5 20 13.8176 20 12.5C20 11.1824 15 7.5 15 7.5"
        stroke={color ? color : "currentColor"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowRight;
