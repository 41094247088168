import {FC} from 'react'
import "../css/Welcome.css"
import illustration from "../../../assets/img/teams/welcome.png"
import classified from "../../../assets/img/teams/icons/classified.svg"
import incognito from "../../../assets/img/teams/icons/incognito.svg"
import {useTranslation} from "react-i18next";
// Components

import {useNavigate} from "react-router-dom";
import {showModal} from "../../../utils/helpers/teams";
import i18n from "../../../utils/lib/i18n";
import ENG from "../locale/eng/welcome.json";
import Fr from "../locale/fr/welcome.json";


const Welcome: FC = () => {
    const navigate = useNavigate()

    i18n.addResourceBundle('en', 'welcome', ENG    );
    i18n.addResourceBundle('fr', 'welcome', Fr    );
    const {t} = useTranslation('welcome')

    const gotoHome = () => {
        navigate('/')
    }
    return (

            <div className="welcome-container">
                <div className="welcome-textContainer">
                    <h1 className="welcome-title">  {t('title')}</h1>
                    <p className="welcome-description"> {t('des')}</p>
                    <div className="welcome-sectionContainer">
                        <div className="welcome-subSection">
                            <h1><img src={classified}/> {t('confidential')}</h1>
                            <p>  {t('wewill')}</p>
                        </div>

                        <div className="welcome-subSection">
                            <h1><img onClick={() => showModal("/no-access-popup", "", false, 300, 350)}
                                     src={incognito}/>  {t('employersonly')}</h1>
                            <p> {t('theycan')} </p>
                        </div>
                    </div>
                    <div className="welcome-footer">
                        <button onClick={() => gotoHome()}>
                            {t('continue')}
                        </button>
                        <p>
                            {t('byproceeding')}
                        </p>
                    </div>
                </div>

                <div className="welcome-imgContainer">
                    <img src={illustration}/>
                </div>
            </div>

    )
}

export default Welcome;
