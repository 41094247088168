import InfoPopover from "../../../components/InfoPopover";
import ArrowRight from "../../../components/ArrowRight";

type DetailCardProps = {
    title: string;
    description: string;
    onClick: () => void;
    tooltip?: string;
    image: string;
    timeImg?: string;
    darkMode?: boolean;
};

const DetailCard = ({
                        title,
                        description,
                        onClick,
                        tooltip = "",
                        image,
                        timeImg, darkMode = false
                    }: DetailCardProps) => {

    return (
        <div
            className={`cursor-pointer flex flex-col justify-between gap-4 rounded-2xl p-7 w-[266px] min-h-[330px] ${darkMode ? 'bg-[#FFE6EE]' : 'bg-white'} border-2 border-transparent hover:border-red`}
            onClick={onClick}>
            <div className="flex flex-row justify-between items-center">
                <div className="relative w-16 h-16">
                    <img
                        src={image}
                        alt=""
                        className="object-cover pointer-events-none h-full"
                    />
                </div>

                {tooltip && (
                    <InfoPopover
                        // isdark={darkMode}
                        content={tooltip}
                        trigger="hover"
                        placement="bottomEnd"
                    />
                )}
            </div>
            <div className="flex flex-col gap-1">
                <p className="font-bold font-lexend text-black">{title}</p>
                <p className="font-lexend text-black leading-[21px]">
                    {description}
                </p>
            </div>
            <div
                className="cursor-pointer flex items-center justify-between"
            >
                <ArrowRight color="#000000" size={25}/>
                {/*{timeImg && <img src={timeImg} className="w-8 h-8" alt="" />}*/}
            </div>
        </div>
    );
};

export default DetailCard;
