import {
  BriefcaseIcon,
  CogIcon,
  DesktopComputerIcon,
  FlagIcon,
  InformationCircleIcon,
  LocationMarkerIcon,
  OfficeBuildingIcon,
  SearchIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import ENG from "../locale/eng/reports.json";
import Fr from "../locale/fr/reports.json";
import i18n from "../../../utils/lib/i18n";
import { useTranslation } from "react-i18next";
import defaultProfileImg from "@img/icons/default-profile.png";

interface Props {
  maxCredits?: number;
  logo?: any;
  name: string;
  medium: string;
  location?: string;
  challenges?: string;
  headCount?: string;
  seniority?: string;
  other_info?: string;
  locale?: string;
}

const ReportCompanyOverview = (props: Props) => {
  i18n.addResourceBundle("en", "reports", ENG);
  i18n.addResourceBundle("fr", "reports", Fr);
  const { t } = useTranslation("reports");
  return (
    <div className="p-5 flex gap-5 w-full card-container">
      <img
        className="w-12 h-12"
        src={
          props.logo
            ? props.logo
            : defaultProfileImg
        }
        alt=""
      />
      <div className="w-full">
        <h4> {props.name}</h4>
        <ul className="w-full columns-2 mt-5 [&>li]:mb-2">
          <li className="icon-text">
            <OfficeBuildingIcon className="text-red" />
            <span>{props.name}</span>
          </li>
          {props.medium && (
            <li className="icon-text">
              <CogIcon className="text-red" />
              <span className="capitalize">{props.medium}</span>
            </li>
          )}
          {props.location && (
            <li className="icon-text">
              <LocationMarkerIcon className="text-red" />
              <span>{props.location}</span>
            </li>
          )}
          {props.headCount && (
            <li className="icon-text">
              <UserGroupIcon className="text-red" />
              <span>{props.headCount}</span>
            </li>
          )}
          {props.maxCredits && (
            <li className="icon-text">
              <DesktopComputerIcon className="text-red" />
              <span>{props.maxCredits} {t("report_overview.session_each")}</span>
            </li>
          )}
          {props.locale && (
            <li className="icon-text">
              <FlagIcon className="text-red" />
              <span>{props.locale}</span>
            </li>
          )}
        </ul>
        <br />
        <ul className="[&>li]:mb-2">
          {props.seniority && (
            <li className="icon-text">
              <BriefcaseIcon className="text-red" />
              <span>{props.seniority}</span>
            </li>
          )}
          {props.challenges && (
            <li className="icon-text">
              <SearchIcon className="text-red" />
              <span>{props.challenges}</span>
            </li>
          )}
          {props.other_info && (
            <li className="icon-text">
              <InformationCircleIcon className="text-red" />
              <span>{props.other_info}</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ReportCompanyOverview;
