import { FC } from "react";

import { Toaster } from "react-hot-toast";

import ObjectiveModal from "../components/ObjectiveModal";

const withObjectiveModal = (WrappedComponent: FC) => {
  const WithObjectiveModal = ({ ...props }) => {
    return (
      <>
        <Toaster
          position="top-center"
          reverseOrder={true}
          toastOptions={{ duration: 9000 }}
        />
        <ObjectiveModal />
        <WrappedComponent {...props} />
      </>
    );
  };
  return WithObjectiveModal;
};

export default withObjectiveModal;
