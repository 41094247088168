// axios base functions
import axios, {Method} from "axios";
import IEndpoint from "../../models/IEndpoint";
import {stringify} from "query-string";
import {appendPathParams} from "../helpers/apiUtils";
import useAuth from "@hooks/useAuth";
import storage from "../../services/storage/localStorage";
import {useEffect} from "react";
import {toast} from "react-toastify";
import {serverErr} from "../helpers/components";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL

// without authorization header
const axiosPublic = axios.create({
    headers: {
        "Content-Type": "application/json",
    },
});
// with authorization header
const axiosPrivate = axios.create({
    headers: {
        "Content-Type": "application/json",
    },
});

const cancelTokenSources: any[] = [];

axiosPrivate.interceptors.request.use(
    async (config) => {
        const source = axios.CancelToken.source();
        config.cancelToken = source.token;
        cancelTokenSources.push(source); // Store the source

        const user = storage.get('user');

        if (user?.accessToken) {
            config.headers = {
                ...config.headers,
                authorization: `Bearer ${user?.accessToken}`,
            };
        }
        // console.log(config)
        return config;
    },
    (error) => Promise.reject(error)
);

axiosPrivate.interceptors.response.use(undefined, (err) => {
    const {config, message} = err;
    if (!config || !config.retry) {
        return Promise.reject(err);
    }
    // retry while Network timeout or Network Error
    if (!(message.includes("timeout") || message.includes("Network Error"))) {
        return Promise.reject(err);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise((resolve) => {
        setTimeout(() => {
            console.log("retry the request", config.url);
            resolve(undefined);
        }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => axios(config));
});

axiosPrivate.interceptors.response.use(
    (response) => response,
    async (error) => {
        const config = error?.config;

        if (error?.response && error?.response.status == 0) {
            console.log(error);
            toast((t) => serverErr());
            cancelTokenSources.forEach((source) => source.cancel('Cancellation message'));
            cancelTokenSources.length = 0; // Clear the store after cancellation

        } else if ((error?.response?.status === 401 || error?.response?.status === 403) && !config?.sent) {
            config.sent = true;

            storage.clear()
            window.location.replace('/auth/login');
            // const result = await memoizedRefreshToken();

            // if (result?.accessToken) {
            //     config.headers = {
            //         ...config.headers,
            //         authorization: `Bearer ${result?.accessToken}`,
            //     };
            // }

            // return axios(config);
        }
        return Promise.reject(error);
    }
);

const apiCall = (
    method: Method,
    endpoint: IEndpoint,
    pathParams: Record<string, unknown> | undefined = undefined,
    queryParams: Record<string, unknown> | undefined = undefined,
    data: object | undefined = undefined,
    configs: object | undefined = undefined,
) => {
    const url = createUrl(appendPathParams(endpoint.path, pathParams), queryParams);
    const options = createOptions(url, method, data, configs);
    let axiosInstance = axiosPublic

    // console.log(endpoint.private);
    if (endpoint.private) {
        axiosInstance = axiosPrivate
    }
    return axiosInstance
        .request(options)
        .then((response) => {
            return (response)
        })
};


const createUrl = (
    endPoint: string,
    queryParams?: Record<any, any>
): string => {
    if (!queryParams) {
        return endPoint;
    }

    const queryString = stringify(queryParams, {skipNull: true, arrayFormat: undefined});

    return endPoint + '?' + queryString;
};

const createOptions = (
    url: string,
    method: Method,
    data: object | undefined,
    configs: object | undefined,
) => {
    const options = {
        method,
        url,
        data,
    };

    // Merge additional configs if provided
    if (configs) {
        Object.assign(options, configs);
    }

    return options;
};

const GET = (
    endpoint: IEndpoint,
    pathParams?: Record<any, any>,
    queryParams?: Record<any, any>,
    configs: Record<string, unknown> | undefined = undefined,
) => {

    return apiCall('GET', endpoint, pathParams, queryParams, configs).then(r => r)

}


const POST = (
    endpoint: IEndpoint,
    data: object,
    pathParams?: Record<any, any>,
    queryParams: Record<string, unknown> | undefined = undefined,
    configs: Record<string, unknown> | undefined = undefined,
) =>
    apiCall('POST', endpoint, pathParams, queryParams, data, configs);

const PUT = (
    endPoint: IEndpoint,
    data: object,
    pathParams?: Record<any, any>,
    queryParams: Record<string, unknown> | undefined = undefined,
) =>
    apiCall('PUT', endPoint, pathParams, queryParams, data);

const DELETE = (
    endPoint: IEndpoint,
    data?: object,
    pathParams?: Record<any, any>,
    queryParams: Record<string, unknown> | undefined = undefined,
) =>
    apiCall('DELETE', endPoint, pathParams, queryParams, data);

const PATCH = (
    endPoint: IEndpoint,
    data: object,
    pathParams?: Record<any, any>,
    queryParams: Record<string, unknown> | undefined = undefined,
) =>
    apiCall('PATCH', endPoint, pathParams, queryParams, data);

export {axiosPrivate, axiosPublic, GET, PUT, POST, DELETE, PATCH};
