import {FC} from "react";
import DatePicker from "../../../../../shared/components/DatePicker";
import {CalendarIcon} from "@heroicons/react/outline";

type ExpirationDatePickerProps = {
    value?: string;
    error?: string;
    onChange?: (date: string) => void;
    placeholder?: string;
};

const ExpirationDatePickerField: FC<ExpirationDatePickerProps> = ({
                                                                      value,
                                                                      error,
                                                                      onChange,
                                                                      placeholder,
                                                                  }) => {
    return (
        <DatePicker
            onChange={onChange}
            className="flex-1 flex flex-col"
            value={value}
        >
            <div className="flex flex-col gap-2">
                <div className="flex flex-col justify-center w-full">
                    <div
                        className="cursor-pointer w-full h-[40px] flex flex-row items-center justify-between gap-2 px-2 pl-3 border-[2px] border-[#E5E7EB] rounded-[5px] hover:border-[#E5E7EB] active:border-[#B3B3B3]">
                        <p
                            className={`font-lexend ${
                                value ? "text-black font-normal" : "text-[#bfbfbf] font-light"
                            }  text-sm`}
                        >
                            {value ?? placeholder}
                        </p>
                        <CalendarIcon className="h-[20px] text-black"/>
                    </div>
                    {error && (
                        <span className="absolute bottom-0 text-xs h-[16px] text-red-500 font-lexend font-normal">
              {error}
            </span>
                    )}
                </div>
            </div>
        </DatePicker>
    );
};

export default ExpirationDatePickerField;
