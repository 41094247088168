import React, { useState } from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";

import FieldLabel from "../components/FieldLabel";
import FieldValue from "../components/FieldValue";
import ReportCard from "../components/ReportCard";
import ReportChip from "../components/ReportChip";
import ReportSideCard from "../components/ReportSideCard";
import Section from "../components/Section";
import EmptyResults from "../components/EmptyResults";

import { I360FeedbackReport } from "../../../../../models/IReport";
import { EAssessmentType } from "../../../../../constants/enums";
import PendingRequestSideCard from "../components/PendingRequestSideCard";
import { ISentFeedbackRequestData } from "../../../../../constants/types";
import FeedbackRequestCard from "../components/FeedbackRequestCard";

type Props = {
  data: I360FeedbackReport[];
  openModal: (key: EAssessmentType, title: string) => void;
  sentRequests: ISentFeedbackRequestData[];
};

const Feedback360Section = ({ data, openModal, sentRequests }: Props) => {
  const { t } = useTranslation("reports");
  const [currentReport, setCurrentReport] = useState<I360FeedbackReport>();
  const [pendingRequestClicked, setPendingRequestClicked] = useState(true);

  return (
    <Section
      title={t("speed_360_reports.title")}
      sidePanel={
        <>
          <PendingRequestSideCard
              selected={pendingRequestClicked}
              onClick={() => {
                  setPendingRequestClicked(true);
                  setCurrentReport(undefined);
              }}
          />
          {data.length
              ? data.map((report, index) => (
                  <ReportSideCard
                      key={index}
                      coach={report.name}
                      date={moment(report.date).format("MMM D, YYYY")}
                      time={moment(report.date).format("HH:MM")}
                      session={report.feedback_no.toString()}
                      coachImage={report.profile}
                      selected={report === currentReport}
                      onClick={() => {
                          setCurrentReport(report)
                          setPendingRequestClicked(false);
                      }}
                  />
              ))
              : null
            }
        </>
      }
    >
      {!data.length ? (
        <EmptyResults
          title={t("speed_360_reports.empty_results.title")}
          buttonText={t("speed_360_reports.empty_results.button_text")}
          onButtonClick={() =>
            openModal(
              EAssessmentType.Reflection360,
              t("speed_360_reports.feedback360")
            )
          }
        />
      ) : (
          <>
            {pendingRequestClicked ? (
                  <ReportCard title={t("pending_requests")}>
                      <FeedbackRequestCard sentRequests={sentRequests} />
                  </ReportCard>
              ) : (
        <ReportCard
          title={`${t("speed_360_reports.feedback")} ${
            currentReport?.feedback_no
          }`}
        >
          {currentReport &&
            Object.entries(currentReport.fields).map(([key, value], index) => (
              <div key={index}>
                  <FieldLabel label={key}/>
                {typeof value === "string" ? (
                  <FieldValue value={value} />
                ) : (
                  <div className="flex flex-row flex-wrap gap-1 h-8">
                    {value?.map((item, i) => (
                      <ReportChip key={i} label={item} />
                    ))}
                  </div>
                )}
              </div>
            ))}
        </ReportCard>
        )}
          </>
      )}
    </Section>
  );
};

export default Feedback360Section;
