import React, { useEffect, useState } from "react";
import {
    useReactTable,
    getCoreRowModel,
    flexRender,
    getSortedRowModel,
    getPaginationRowModel,
    getFilteredRowModel,
    SortingState,
    PaginationState,
} from '@tanstack/react-table'
import { useTranslation } from "react-i18next";

import { SessionViewColumns } from "../../../../../constants/data/TableData";
import Search from "../../../../../shared/components/Table/Search";
import { DocumentDownloadIcon } from "@heroicons/react/solid";

import Pagination from "../../../../../shared/components/Pagination";
import MonthPicker from "../../../../../shared/components/MonthPicker";
import { DropdownWrapper } from "@components";
import ToggleButton from "../../../../../shared/components/ToggleButton";
import NoDataMessage from "../../../../../shared/components/NoDataMessage";

import useAuth from "@hooks/useAuth";
import { isEmpty } from "../../../../../utils/helpers/object";
import { exportToCsv } from "../../../../../utils/helpers/download";
import { filterInnerDictArr } from "../../../../../utils/helpers/arr";
import { getCoachSessions } from "../../../../../services/api/coachApi";

const SessionViewTable = () => {
  const { t } = useTranslation("sessions_view");
  const { user } = useAuth();

  const [sessions, setSessions] = useState<Record<any, any>[]>([]);
  const [withAll, setWithAll] = useState(() => {
    const storedValue = localStorage.getItem("allSessionState");
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });

  const [withUpcoming, setWithUpcoming] = useState(() => {
    const storedValue = localStorage.getItem("upComingSessionsState");
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });

  const columns = React.useMemo(() => SessionViewColumns, [])

  const data = React.useMemo(
      () => (!isEmpty(sessions) ? sessions : []),
    [sessions]
  );

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [pagination, setPagination] = React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
  });

  const getSessionsData = () => {
    getCoachSessions(user?.id!, currentMonth, withAll, withUpcoming)
      .then((res) => {
        console.log(res.sessions);
        setSessions(res.sessions);
      })
      .catch((e) => {
        console.log("api error");
      });
  };
  useEffect(() => {
    getSessionsData();
  }, [currentMonth, withUpcoming, withAll]);

  useEffect(() => {
    localStorage.setItem("upComingSessionsState", JSON.stringify(withUpcoming));
  }, [withUpcoming]);

  useEffect(() => {
    localStorage.setItem("allSessionState", JSON.stringify(withAll));
  }, [withAll]);


  const tableInstance = useReactTable({
      getCoreRowModel: getCoreRowModel(),
      columns,
      data: data,
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      onPaginationChange: setPagination,
      state: {
          sorting,
          pagination,
          globalFilter,
      },
      onSortingChange: setSorting,
      onGlobalFilterChange: setGlobalFilter,
  });

  return (
    <div className="pb-5">
      <div className="flex gap-3 bg-white justify-between items-center py-2 pl-3 rounded-t-md flex-col sm:flex-row">
        <div className="flex gap-5 items-center">
          <h4>{t("text0")}</h4>
            <Search
                value={globalFilter ?? ''}
                onChange={value => setGlobalFilter(String(value))}
            />
        </div>

        <div className="flex items-end gap-3 lg:items-center pr-3">
          <MonthPicker currentMonth={currentMonth} onChange={setCurrentMonth} />
          <DropdownWrapper
            button={
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                  />
                </svg>
              </button>
            }
            body={
              <div className="p-3 ">
                <span className="flex gap-2">
                  {t("text1")}{" "}
                  <ToggleButton enabled={withAll} setEnabled={setWithAll} />
                </span>
                <span className="flex gap-2">
                  {t("text2")}
                  <ToggleButton
                    enabled={withUpcoming}
                    setEnabled={setWithUpcoming}
                  />
                </span>
                <span
                  onClick={() =>
                    exportToCsv(
                      filterInnerDictArr(sessions, [
                        "client_name",
                        "client_company",
                        "meeting_date",
                        "session_no",
                        "allowed",
                        "status",
                        "orientation",
                      ]),
                      [
                        "Client Name",
                        "Client Company",
                        "Meeting Date",
                        "Session",
                        "Eligible",
                        "Status",
                        "Is Orientation",
                      ],
                      "Usage.csv"
                    )
                  }
                  className="hover:shadow-lg bg-white rounded-md text-red flex gap-2 items-center"
                >
                  <DocumentDownloadIcon className="text-red w-5 h-5" />{" "}
                  {t("text3")}
                </span>
              </div>
            }
          />
        </div>
      </div>

      <table className={`dashboard-table ${
          isEmpty(sessions) ? 'min-h-[200px] overflow-hidden' : 'overflow-auto'
      }`}>
        {isEmpty(sessions) && <NoDataMessage />}
        <thead className="dashboard-thead">
        {tableInstance.getHeaderGroups().map((headerGroup) => (
            <tr>
                {headerGroup.headers.map((header) => {
                    return (
                        <th colSpan={header.colSpan} key={header.id}>
                            <div
                                // className="flex flex-row items-center gap-2 hover:cursor-pointer"
                                onClick={header.column.getToggleSortingHandler()}
                            >
                              {t(`tables:session_view.${flexRender(header.column.columnDef.header, header.getContext())}`)}
                              <span>
                                {{desc: " ▼", asc: " ▲"}[header.column.getIsSorted() as string] ?? null}
                              </span>
                            </div>
                        </th>
                    )
                })}
            </tr>
        ))}
        </thead>

        <tbody className="dashboard-tbody">
        {tableInstance.getRowModel().rows.map((row, i) => {
            return (
                <tr
                    className="dashboard-tr last:[&>td]:text-green-700 last:[&>td]:font-semibold"
                    key={i}
                >
                    {row.getVisibleCells().map((cell) => {
                        return (
                            <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                        );
                    })}
                </tr>
            )
        })}
        </tbody>
      </table>

      <Pagination
        canNextPage={tableInstance.getCanNextPage()}
        canPreviousPage={tableInstance.getCanPreviousPage()}
        setPageSize={tableInstance.setPageSize}
        pageSize={tableInstance.getState().pagination.pageSize}
        gotoPage={tableInstance.setPageIndex}
        totalPageCount={tableInstance.getPageCount()}
        currentPage={tableInstance.getState().pagination.pageIndex}
      />
    </div>
  );
};

export default SessionViewTable;
