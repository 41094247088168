import {useTranslation} from "react-i18next";

import {IArticleSchema} from "../../../../../models/IHome";
import {ReferenceData, ReferenceItem} from "../../AICoaching/types";
import React, {useEffect} from "react";
import InfoCard from "../../../../../shared/components/InfoCard/InfoCard";

type RecommendedProps = {
    articles: ReferenceData;
};

const AIRecommended = ({articles}: RecommendedProps) => {
    const {t} = useTranslation("home");

// Function to shuffle an array
    function shuffleArray(array: ReferenceItem[]): ReferenceItem[] {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

// Function to combine and shuffle reference items
    function getShuffledReferences(data: ReferenceData): ReferenceItem[] {
        const combinedReferences: ReferenceItem[] = [
            ...(data.articles || []),
            ...(data.youtubes || []),
            ...(data.podcasts || [])
        ];

        return shuffleArray(combinedReferences);
    }

    const shuffledReferences = getShuffledReferences(articles);

    if (!shuffledReferences.length) return <></>;
    return (
        <div className="bg-white p-4 pt-3 rounded border-gray-50 border-[1px]">
            <h2 className="text-lg font-bold font-dm_sans text-black mt-2 mb-4">
                {t("recommended.recommended_by_ai")}
            </h2>
            <ul className="grid w-full md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-6">
                {shuffledReferences.slice(0, 6).map((item, index) => (
                    <InfoCard
                        title={item.title}
                        imageUrl={item.thumbnail}
                        linkUrl={item.url}
                        type={item.media_type}
                        className={{img: "w-full h-24", card: "shadow hover:bg-warm/50"}}
                        contentId={item.id}
                    />))}
            </ul>
        </div>
    );
};

type CardProps = {
    article: ReferenceItem;
};

const Card = ({article}: CardProps) => {
    const {t} = useTranslation("home");

    return (
        <div className="flex flex-col gap-2 basis-1/2">
            <div
                className="cursor-pointer aspect-w-2 aspect-h-1 border-gray-50 rounded relative"
                onClick={() => window.open(article.url, "_blank")}
            >
                {article.thumbnail && (
                    <img
                        src={article.thumbnail}
                        alt="AI coach"
                        className="object-cover rounded"
                    />
                )}
            </div>

            <div className="flex flex-col gap-1">
                <p className="font-bold text-xs font-dm_sans text-black m-0">
                    {t("recommended.suggestion_from_session")}
                </p>
                <p className="text-xs font-dm_sans text-gray-400 m-0">
                    {article.title}
                </p>
            </div>
        </div>
    );
};

export default AIRecommended;
