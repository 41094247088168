import { axiosPrivate } from "../../../../utils/lib/axios";

export const getAllSchedule = async (): Promise<any> => {
  return axiosPrivate
    .get<any>(`/api/clients/me/ai/schedules`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Failed to get schedules:", err);
    });
};
