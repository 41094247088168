import {RefObject, useEffect, useRef, useState} from 'react';

const useScrollTo = <T extends Element>(): [
    RefObject<T>,
    (val: boolean) => void
] => {
    const ref = useRef<T>(null);
    const [shouldScrollTo, setShouldScrollTo] = useState(false);

    useEffect(() => {
        // console.log(shouldScrollTo,ref.current)
        if (ref.current) {
            if (shouldScrollTo) {
                setShouldScrollTo(false);
                ref.current.scrollIntoView({behavior: 'smooth', block: "center", inline: "nearest"});
            }
        } else {
            setShouldScrollTo(false);
        }
    }, [shouldScrollTo, ref.current]);

    return [ref, setShouldScrollTo];
};

export default useScrollTo;
