import {FC} from 'react'
import "../css/NoAccessPopUp.css"
import TeamsLogo from "../../../assets/img/teams/icons/Microsoft_Teams-Logo.png"
// Components

import * as microsoftTeams from "@microsoft/teams-js";
import {useTranslation} from "react-i18next";
import i18n from "../../../utils/lib/i18n";
import ENG from "../locale/eng/noaccess.json";
import Fr from "../locale/fr/noaccess.json";

const NoAccessPopUp: FC = () => {
    i18n.addResourceBundle('en', 'noaccess', ENG    );
    i18n.addResourceBundle('fr', 'noaccess', Fr    );
    const {t} = useTranslation('noaccess')
    return (

            <div className="popup-container">
                <div className="popup-imgContainer">
                    <img src={TeamsLogo}/>
                </div>
                <div className="popup-textContainer">

                    <p className="popup-description">{t('popup.text_1')}</p>

                    <button
                        onClick={() => {
                            microsoftTeams.app.initialize().then(r =>
                            microsoftTeams.dialog.submit("result", process.env.REACT_APP_TEAMS_MANIFEST_APPID)
                            )
                        }}
                        className="popup-ok-button">
                        Ok
                    </button>

                </div>


            </div>

    )
}

export default NoAccessPopUp;
