import React, {FC} from "react";
import {useSearchParams} from "react-router-dom";
import "../css/VideoPlayer.css"

const CoachVideoPlayer: FC = () => {
    const [searchParams] = useSearchParams()
    const url = searchParams.get('url')
    console.log(url)
    if (url) {
        return (
            <div className="w-full h-screen">
                <video
                    className="h-full w-full"
                    src={url}
                    autoPlay
                    controls
                />
            </div>)
    } else {
        return (<></>)
    }

}
export default CoachVideoPlayer
