import { useRef } from "react";

type Props = {
  expanded: boolean;
  children?: React.ReactNode;
};

const VerticalExpander = ({ children, expanded }: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const style = {
    minHeight: expanded ? contentRef.current?.offsetHeight : 0,
    maxHeight: expanded ? contentRef.current?.offsetHeight : 0,
  };

  return (
    <div
      className="overflow-hidden transition-all ease-in-out duration-500"
      style={style}
    >
      <div ref={contentRef} className="flex h-max">
        {children}
      </div>
    </div>
  );
};

export default VerticalExpander;
