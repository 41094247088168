import {SearchIcon} from "@heroicons/react/outline";
import React from 'react';
import {useTranslation} from "react-i18next";

export default function Search({
    value: initialValue,
    onChange,
    debounce = 200,
    ...props
}: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = React.useState(initialValue);
    const {t} = useTranslation('commons');

    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value]);

    return (
        <div className="py-2 relative text-gray-600">
            <input value={value}
                   {...props}
                   onChange={e => {
                       setValue(e.target.value);
                   }}
                   placeholder={t('text1')}
                   className="w-full border-2 border-gray-light bg-white h-10 px-5 pr-16 rounded text-sm focus:outline-none"
                   type="search" name="search"/>
            <button className="absolute right-0 top-0 mt-5 mr-4">
                <SearchIcon className="text-gray-600 h-4 w-4"/>

            </button>
        </div>
)
}
