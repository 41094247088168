import React, {useEffect, useState} from 'react';
import {Form, Formik, FormikHelpers, FormikValues} from "formik";
import * as Yup from "yup";
import InputFormik from "../../../shared/components/formik/InputFormik/InputFormik";
import SelectFormik from "../../../shared/components/formik/SelectFormik";
import InputFormikWithIcon from "../../../shared/components/formik/InputFormik/InputFormikWithIcon";
import {LinkIcon, MailIcon, OfficeBuildingIcon, PhoneIcon, UserIcon} from "@heroicons/react/outline";
import SubmitFormik from "../../../shared/components/formik/SubmitFormik/SubmitFormik";
import ICoachProfile from "../../../models/ICoachProfile";
import useAuth from "@hooks/useAuth";
import {getCoachProfile, updateCoachProfile} from "../../../services/api/coachApi";
import MetaApi from "../../../services/api/metaApi";
import {IMeta} from "../../../models/IMeta";
import {toast} from "react-toastify";
import ENG from "../locale/eng/coaches.json";
import Fr from "../locale/fr/coaches.json";
import i18n from "../../../utils/lib/i18n";
import {useTranslation} from "react-i18next";


const CoachProfileForm = () => {

    const {user} = useAuth()
    i18n.addResourceBundle("en", "coaches", ENG);
    i18n.addResourceBundle("fr", "coaches", Fr);
    const { t } = useTranslation("coaches");
    const [languagesOptions, setLanguagesOptions] = useState<IMeta[]>()
    const [specializationsOptions, setSpecializationsOptions] = useState<IMeta[]>()

    const [profile, setProfile] = useState<ICoachProfile>({
        first_name: "",
        last_name: "",
        location: "",
        languages: [],
        specializations: [],
        email: "",
        phone: "",
        linkedin_url: "",
        video_url: "",
        video_url_fr: "",
        image_url: ""
    })
    console.log(profile)

    const _handleSubmit = async (values: FormikValues, actions: FormikHelpers<any>) => {
        updateCoachProfile(values as ICoachProfile, user?.id!).then((res) => {
            actions.setSubmitting(false);
            setProfile(res)
            toast.success(t("update_success"))

        }).catch((e) =>
            toast.error(e.response.data.message)
        )
    }
    useEffect(() => {
        const lng = MetaApi.getLanguages()
        const spc = MetaApi.getSpecializations()

        Promise.all([lng, spc]).then(([lng, spc]) => {
                setLanguagesOptions(lng)
                setSpecializationsOptions(spc)
            }
        ).catch((e) => {
            console.log("api error getting meta");
        }).then(() => getCoachProfile(user?.id!)
            .then(res => {
                setProfile(res)
            }).catch((e) => {
                console.log("api error");
            }))

    }, [])


    return (
        <div>
            <div className="absolute left-0 right-0 mx-auto bg-transparent -top-10 flex flex-col items-center">
                <img className="rounded-full border-white border-4 h-24 w-24"
                     src={profile.image_url}/>
                <h4>{profile.first_name} {profile.last_name}</h4>
            </div>
            <hr className="w-full mt-28"/>
            <Formik
                enableReinitialize={true}
                initialValues={profile}

                validationSchema={
                    Yup.object({
                        languages: Yup.array().min(1,t("choose_1"))
                            .required(t("required")),
                        specializations: Yup.array().min(1, t("choose_1")).max(4, t("choose_3"))
                            .required(t("required")),
                        first_name: Yup.string()
                            .required(t("required")),
                        last_name: Yup.string()
                            .required(t("required")),
                        video_url: Yup.string().nullable()
                            .required(t("required")),
                        image_url: Yup.string().nullable()
                            .required(t("required")),
                        email: Yup.string().email(t("invalid_email"))
                            .required(t("required")),
                    })}
                onSubmit={_handleSubmit}
            >
                <Form className="flex flex-col gap-6 px-5 py-4">
                    <div className="flex gap-3">
                        <InputFormik placeholder={t("first_name_placeholder")}
                                     label={t("first_name")} name={"first_name"}/>

                        <InputFormik placeholder={t("last_name_placeholder")}
                                     label={t("last_name")} name={"last_name"}/>

                    </div>

                    <InputFormik placeholder={t("location_placeholder")}
                                 label={t("location")} name={"location"}/>

                    <div className="flex flex-col gap-2">
                        <span className="font-medium">{t("language")}</span>
                        <SelectFormik name="languages" labelKey="name" valueKey="id" options={languagesOptions} primaryValueAvailable={true} primaryValueText={t("native")} tooltip={t("set_as_native")} placeholder={t("language_placeholder")}/>
                    </div>
                    <div className="flex flex-col gap-2">
                        <span className="font-medium">{t("expertise")}</span>
                        <SelectFormik name="specializations" labelKey="name" valueKey="id"
                            options={specializationsOptions} placeholder={t("expertise_placeholder")}/>
                    </div>

                    <InputFormikWithIcon placeholder={t("email_placeholder")}
                                         label={t("email")} name={"email"}
                                         HeroIcon={MailIcon}/>

                    <InputFormikWithIcon placeholder={t("phone_placeholder")}
                                         label={t("phone")} name={"phone"}
                                         HeroIcon={PhoneIcon}/>

                    <InputFormikWithIcon placeholder={t("linkedin_placeholder")}
                        label={t("linkedin")} name={"linkedin_url"}
                                         HeroIcon={OfficeBuildingIcon}/>
                    <InputFormikWithIcon readOnly placeholder={t("profile_url_placeholder")}
                        label={t("profile_url")} name={"image_url"}
                                         HeroIcon={UserIcon}/>

                    <InputFormikWithIcon placeholder={t("video_eng_placeholder")}
                                         label={t("video_eng")} name={"video_url"}
                                         HeroIcon={LinkIcon}/>

                    <InputFormikWithIcon placeholder={t("video_fr_placeholder")}
                                         label={t("video_fr")} name={"video_url_fr"}
                                         HeroIcon={LinkIcon}/>

                    <SubmitFormik className="dashboard-button bg-black py-3 justify-center"
                                  text={t("update")} onSubmittingText={t("updating")}/>
                </Form>
            </Formik>
        </div>
    );
};

export default CoachProfileForm;
