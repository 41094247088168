import React from "react";
import {CustomTickProps} from "./types";

const TickWithIcon: React.FC<CustomTickProps> = ({
                                                     x,
                                                     y,
                                                     index,
                                                     payload,
                                                     xOffset = 150,
                                                     data,
                                                     showTagLine,
                                                     showIcon
}) => {
    const yOffset = 10;
    const fontSize = 13;
    const maxTextLength = payload.value.length * 21 > xOffset ? 20: payload.value.length;

    const icon = data && data[index] && data[index].icon;
    const tagLine = data && data[index] && data[index].tagline;

    return (
        <g transform={`translate(${x - xOffset}, ${y - yOffset})`}>
            <g transform={`translate(-5, ${showTagLine ? -5 : -8})`}>
                {showIcon && icon && icon}
            </g>
            <text x={25} y="10" fill="black" fontFamily="Lexend" fontSize={fontSize} fontWeight={showTagLine ? "bold" : "normal"}>
                {payload.value.length > maxTextLength ? payload.value.slice(0, maxTextLength) + "..." : payload.value}
            </text>
            {showTagLine &&
                <switch>
                    <foreignObject x={25} y="10" width={170} height={40}>
                        {tagLine && <p className="text-xs text-black">{tagLine}</p>}
                    </foreignObject>
                </switch>
            }
        </g>
    );
}

const TickWithPercentage: React.FC<CustomTickProps> = ({
                                                           x,
                                                           y,
                                                           payload,
                                                           smallPercentageStyle = true,
                                                           isVertical = false
}) => {
    const xTickColor = '#c4c4c4'
    const padding = parseInt(payload.value) < 10 ? payload.value.toString().length * 9 : payload.value.toString().length * 5

    return (
        <g width={40}>
            <text x={isVertical ? x-5 : x} y={y + 10} fill={xTickColor} textAnchor={isVertical ? "end" : "middle"} fontSize="14" fontWeight="normal">
                {payload.value}{!smallPercentageStyle && "%"}
            </text>
            {
                smallPercentageStyle &&
                <text x={isVertical ? x+padding-5 : x+padding} y={y +10} fill={xTickColor} textAnchor={isVertical ? "end" : "middle"} fontSize="9" fontWeight="normal">
                    %
                </text>
            }
        </g>
    );
};

export { TickWithIcon, TickWithPercentage };