import {FC, useEffect, useState} from 'react'

import Calendar from 'react-calendar'

import clockIcon from '../../../../../assets/img/teams/icons/clock.png'
import ProfileCardSimple from '../ProfileCardSimple'
import {useSearchParams, useNavigate} from 'react-router-dom';
import {useSteps} from 'react-step-builder';
import moment from 'moment';
import {useTranslation} from "react-i18next";
import './Calender.css'
import {getAvailableDates} from "../../../../../services/api/teams/CalendarActions";

interface Props {
    bookingData?: any
}

interface IDate {
    [key: string]: any
}

const SelectDate: FC<Props> = (props) => {
    const {t} = useTranslation('booking')
    const {jump} = useSteps()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const [dates, setDates] = useState<IDate[]>([])
    const [selectedDateTimes, setSelectedDateTimes] = useState<IDate>()
    const [selectedTime, setSelectedTime] = useState<string | null>()

    let initDate:any = searchParams.get('date')
    let initTime = searchParams.get('time')
    const lng = localStorage.getItem("I18N_LANGUAGE") || 'en'

    useEffect(() => {
        let coachId = searchParams.get('coach_id')
        if (!coachId) {
            return jump(1)
        }

        loadAvailableDates(coachId)
    }, [jump])

    useEffect(() => {
        if (initDate) {
            setSelectedDateTimes(dates[initDate])
        }
    }, [dates])

    useEffect(() => {
        if (selectedDateTimes && selectedTime) {
            jump(3)
        }
    }, [selectedDateTimes, selectedTime])

    const handleDateChange = (date: any) => {
        date = moment(date).format('YYYY-MM-DD')

        setSelectedDateTimes(dates[date])

        searchParams.set('date', date)
        navigate('?' + searchParams.toString())
    }

    const handleSelectTime = (time: string) => {
        setSelectedTime(time)
        searchParams.set('time', time)
        navigate('?' + searchParams.toString())
    }

    const handleDisableDates = (date: any) => {
        let keys = Object.keys(dates)
        if (keys.length === 0) {
            return true;
        }

        return keys.indexOf(moment(date).format('YYYY-MM-DD')) === -1;
    }

    const handleMonthChange = ({activeStartDate}: any) => {
        let coachId = searchParams.get('coach_id')
        loadAvailableDates(coachId, activeStartDate)
    }

    const loadAvailableDates = (coachId: string | null, date?: string | null) => {

        let start = null;
        let end = null;
        console.log("scc")
        if (date) {
            start = moment(date).add(1, 'days').format('YYYY-MM-DD')
            // start = moment(date).startOf('month').format('YYYY-MM-DD')
            end = moment(date).endOf('month').format('YYYY-MM-DD')
        } else {
            start = moment().add(1, 'days').format('YYYY-MM-DD')
            // start = moment().startOf('month').format('YYYY-MM-DD')
            end = moment().endOf('month').format('YYYY-MM-DD')
        }

        getAvailableDates(coachId, start, end)
            .then(response => {
                setDates(response.data.calendar)

                searchParams.set('coach_timezone', response.data.coach_time_zone)

                window.history.pushState({
                    id: 'selct-date',
                    source: 'web',
                }, 'Select date', window.location.href + '&coach_timezone=' + response.data.coach_time_zone)
            })
    }

    return (
        <div className="flex flex-col w-full lg:flex-row">
            <ProfileCardSimple/>

            <div
                className="container flex flex-col justify-start w-full h-full lg:justify-between lg:items-stretch lg:flex-row">
                <div
                    className="flex flex-col items-center justify-start w-full px-5 py-12 mb-5 bg-white rounded-lg shadow lg:mx-6 lg:w-9/12 lg:mb-0 lg:px-10">
                    <h4 className="font-bold">{t('select_date.text_3')}</h4>
                    <Calendar
                        locale={lng}
                        className="border-0 lg:mb-12 "
                        next2Label=""
                        prev2Label=""
                        calendarType="US"
                        formatShortWeekday={(locale, date) => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][date.getDay()]}
                        tileDisabled={({activeStartDate, date, view}) => handleDisableDates(date)}
                        onChange={handleDateChange}
                        onActiveStartDateChange={handleMonthChange}
                        defaultValue={initDate ? moment(initDate).toDate() : new Date()}
                    />
                    <p className="font-light mt-3">
                        <img className="inline-flex mr-5" src={clockIcon} alt=""/>
                        <span>Central European Time(CET)</span>
                    </p>
                </div>
                <div
                    className="flex flex-col items-center justify-start w-full px-5 py-12 bg-white rounded-lg shadow lg:w-3/12">
                    <h4 className="font-bold">{t('select_date.text_3')}</h4>
                    <div className="grid grid-cols-2 gap-4 mt-8">
                        {selectedDateTimes
                            ?
                            selectedDateTimes.map((time: any, index: number) => {
                                return <button
                                    key={index}
                                    className={'col-span-2 sm:col-span-1 xl:col-span-1 p-2 font-light border rounded border-red hover:bg-red hover:text-white hover:font-medium ' + (time === selectedTime || time === initTime ? 'bg-red text-white' : 'text-red')}
                                    onClick={() => handleSelectTime(time)}
                                >{time}</button>
                            })
                            :
                            <div className="col-span-2">
                                <p className="text-sm font-light">{t('select_date.text_4')}</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectDate;
