import React, {FC, useEffect, useState} from "react";
import {getOrderIndexArr} from "../../../utils/helpers/arr";
import {
    Bar,
    BarChart, CartesianGrid, Cell, Label,
    ResponsiveContainer, Tooltip,
    XAxis, YAxis
} from "recharts"
import {TickWithIcon, TickWithPercentage} from "./CustomTick";
import CustomizedLabel from "./CustomizedLabel";
import {CustomBarChartProps, DataItem} from "./types";

const CustomBarChart: FC<CustomBarChartProps> = ({
                                                     showIcon = true,
                                                     showTagLine = false,
                                                     verticalLabel = false,
                                                     data = [],
                                                     range,
                                                     showXPercentage = false,
                                                     showPercentageLabel = false,
                                                     legendName,
                                                     key,
                                                     isVertical = false,
                                                     xAxisTitle = "",
                                                     yAxisTitle = ""
                                                 }: CustomBarChartProps) => {

    const getColourArr = (data: DataItem[]) => {
        const orderIndexes = getOrderIndexArr(data.map((entry, i) => {
            return entry.value
        }))
        const len = data.length + 1
        const potion = 1 / len
        return data.map((value, i) => {
            return `rgba(180, 225, 198,${(orderIndexes[i] + 1) * potion})`
        })
    }

    const getAvgTickLength = (data: DataItem[]) => {
        const lengths:number[] = data?.map((item) => {
            return item.label? item.label.length : 0
        })
        const sum = lengths?.reduce((acc, val) => acc + val)
        return lengths.length ? sum/lengths.length : 0
    }

    const getAvailableWidth = () => {
        const container = document.getElementById('CustomBarChartContainer');
        if (container) {
            const computedStyle = window.getComputedStyle(container);
            const width = computedStyle.width;
            const numericWidth = parseFloat(width);
            return numericWidth;
        } else {
            // Handle the case where the element doesn't exist
            return 150;
        }
    };

    const [availableWidth, setAvailableWidth] = useState(getAvailableWidth())
    const handleResize = () => {
        setAvailableWidth(getAvailableWidth())
    };

    const tickFormatter = (value: string, index: number) => {
        const characterWidth = 12;
        const maxCharacters = Math.floor(barSize / characterWidth);
        const limit = isVertical ? Math.max(5, maxCharacters) : 40;
        if (value.length < limit) return value;
        return `${value.substring(0, limit)}...`;
    };
    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const avgYTickLength = data && data.length > 0 ? getAvgTickLength(data) : 20;
    const tickWithIconWidth = availableWidth > 768 ? availableWidth/5 : availableWidth/3;
    const normalTickWidth = availableWidth > 768 ? availableWidth/7 : availableWidth/5;
    const barThickness = data.length > 4 ? 25 : 30
    const barSize = isVertical ? normalTickWidth * 0.8 : verticalLabel ? barThickness + 10 : barThickness
    const barContainerHeight =  25 + (verticalLabel ? barThickness + 50 : barThickness + 5)
    const axisColor = "rgb(243, 235, 226)"
    const axisStroke = { stroke: axisColor, strokeWidth: 2 }
    const barColor = '#ACC7E8'
    const highlightColor = 'rgba(243, 235, 226, 0.6)'
    const xTickColor = '#c4c4c4'
    const defaultColors = getColourArr(data);

    return (
        <div className="relative">
            <ResponsiveContainer width="95%"  minWidth={500} height={isVertical ? 350 : (data.length > 4 ? data.length * barContainerHeight : 300)}
                                 id="CustomBarChartContainer" key={key}>
                <BarChart data={data} layout={isVertical ? "horizontal" : "vertical"} margin={{ left: 20, right: 20, bottom: 20 }} barCategoryGap={10} barGap={10}>
                    <CartesianGrid horizontal={false} strokeDasharray="5 5" stroke={axisColor} />
                    {isVertical ? (
                        <>
                            {showXPercentage ? (
                                <YAxis
                                    tickLine={{display: "none"}} axisLine={axisStroke}
                                    // @ts-ignore
                                    type="number" hide={false} domain={range} interval={0} tick={<TickWithPercentage isVertical={isVertical} smallPercentageStyle={verticalLabel ? false : true}/>}
                                >
                                    {yAxisTitle && <Label value={yAxisTitle} angle={-90} position="insideLeft"
                                                          style={{textAnchor: 'middle', fontSize: 14, fontWeight: "bold", fill: "black"}}/>}
                                </YAxis>
                            ) : (
                                <YAxis
                                    tickLine={{display: "none"}} axisLine={axisStroke}
                                    tick={{fill: xTickColor, fontSize: 14, fontWeight: "normal"}}
                                    type="number" hide={false} domain={range}
                                >
                                    {yAxisTitle && <Label value={yAxisTitle} angle={-90} position="insideLeft"
                                                          style={{textAnchor: 'middle', fontSize: 14, fontWeight: "bold", fill: "black"}}/>}
                                </YAxis>
                            )}

                            <XAxis
                                tickLine={{display: "none"}} axisLine={axisStroke}
                                tickFormatter={tickFormatter}
                                // @ts-ignore
                                dataKey="label" type="category" tickCount={10} interval={0} width={avgYTickLength > 20 ? normalTickWidth + 20 : normalTickWidth} tick={{ fontSize: 11,
                                fill: "black",
                                dx: 0,
                                textAnchor: "middle",
                                fontWeight: showTagLine ? "bold" : "normal"
                            }}
                            >
                                {xAxisTitle && <Label value={xAxisTitle} position="bottom" style={{fontSize: 14, fontWeight: "bold", fill: "black"}}/>}
                            </XAxis>

                        </>
                    ) : (
                        <>
                            {showXPercentage ? (
                                <XAxis
                                    tickLine={{ display: "none" }} axisLine={axisStroke}
                                    // @ts-ignore
                                    type="number" hide={false} tickCount={10} domain={range} interval={0} tick={<TickWithPercentage smallPercentageStyle={verticalLabel ? false : true} />}
                                />
                            ) : (
                                <XAxis
                                    tickLine={{ display: "none" }} axisLine={axisStroke} tick={{ fill: xTickColor, fontSize: 14, fontWeight: "normal" }}
                                    type="number" hide={false} tickCount={10} domain={range} />
                            )}

                            {showIcon ?
                                <YAxis
                                    tickLine={{ display: "none" }} axisLine={axisStroke}
                                    // @ts-ignore
                                    dataKey="label" type="category" tick={<TickWithIcon xOffset={tickWithIconWidth} data={data} showTagLine={showTagLine} showIcon={showIcon}/>}
                                    width={tickWithIconWidth}
                                />
                                : verticalLabel ?
                                    <YAxis
                                        tickLine={{ display: "none" }} axisLine={axisStroke}
                                        // @ts-ignore
                                        dataKey="label" type="category" tick={{ angle: -90, textAnchor: "middle", dy: -5, dx: -20, fontSize: 11, fill: "black", fontWeight: showTagLine ? "bold" : "normal"}} />
                                    :
                                    <YAxis
                                        tickLine={{ display: "none" }} axisLine={axisStroke}
                                        tickFormatter={tickFormatter}
                                        // @ts-ignore
                                        dataKey="label" type="category" width={avgYTickLength > 20 ? normalTickWidth + 20 : normalTickWidth} tick={{ fontSize: avgYTickLength > 20 ? 11 : 14, fill: "black", dx: avgYTickLength > 20 ? 0 : -5, fontWeight: showTagLine ? "bold" : "normal" }}
                                    />
                            }
                        </>
                    )}
                    <Tooltip cursor={{ fill: highlightColor, strokeWidth: 1 }} />
                    <Bar
                        // @ts-ignore
                        name={legendName} dataKey="value" fill={barColor} barSize={barSize} label={showPercentageLabel && <CustomizedLabel isVertical={isVertical} />}>
                        {showPercentageLabel ?
                                (data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.color ? entry.color : defaultColors[index]} />
                            ))) : (
                                (data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.color ? entry.color : barColor} />
                            ))))
                        }
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default CustomBarChart;