import { useState } from "react";

import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import moment from "moment/moment";

import ConfirmBooking from "./ConfirmBooking";
import ActionButton from "./ActionButton";

import { createBooking } from "../../../../../../services/api/coachApi";
import { formatUTC } from "../../../../../../utils/helpers/time";
import useAuth from "@hooks/useAuth";

import i18n from "../../../../../../utils/lib/i18n";
import WelcomeENG from "../../../../locale/eng/welcome.json";
import WelcomeFR from "../../../../locale/fr/welcome.json";

import { TConfirmBookingForm } from "../../../../../../constants/types";
import ICoach from "../../../../../../models/ICoach";
import { ITimeSlot } from "../../../../../../models/IBooking";
import { EConfirmBookingFormField } from "../../../../../../constants/enums";

type Props = {
  selectedCoach: ICoach | null;
  timeSlot: ITimeSlot | null;
  handleStepNavigation: (nextStep?: boolean, guideComplete?: boolean) => void;
  onBackBtnClick: () => void;
};
const ConfirmBookingContainer = ({
  selectedCoach,
  timeSlot,
  handleStepNavigation,
  onBackBtnClick,
}: Props) => {
  const { user } = useAuth();
  const externalVisit = !user;
  i18n.addResourceBundle("en", "welcome", WelcomeENG);
  i18n.addResourceBundle("fr", "welcome", WelcomeFR);
  const { t } = useTranslation("welcome");
  const [confirmBookingForm, setConfirmBookingForm] =
    useState<TConfirmBookingForm>({
      noteForCoach: {
        value: "",
        error: false,
        errorText: t("note_is_required"),
      },
    });

  const [tripartiteEmail, setTripartiteEmail] = useState<string | undefined>(
    undefined,
  );

  const [isLoading, setIsLoading] = useState(false);

  const contructBookingData = (
    timeSlot: ITimeSlot | null,
    coach: ICoach | null,
    note?: string,
    is_consented?: boolean,
    meetingId?: number | null,
    tripartiteEmail?: string | undefined,
  ) => {
    const startTime = moment(
      new Date(`${timeSlot?.time.date}T${timeSlot?.time.start}`).toUTCString(),
    );
    const endTime = moment(
      new Date(`${timeSlot?.time.date}T${timeSlot?.time.end}`).toUTCString(),
    );

    return {
      user: {
        first_name: user?.firstName,
        last_name: user?.lastName,
        user_comment: note,
        email: user?.email,
        medium: "teams",
      },
      event: {
        start_time: formatUTC(startTime.format("YYYY-MM-DD HH:mm")),
        end_time: formatUTC(endTime.format("YYYY-MM-DD HH:mm")),
      },
      company_id: user?.companyId,
      coach_id: coach?.id,
      user_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      coach_time_zone: timeSlot?.coachTimezone,
      is_consented,
      reschedule: !!meetingId,
      meeting_id: meetingId ? meetingId : undefined,
      tripartite_email: tripartiteEmail,
      orientation: coach?.orientation

    };
  };

  const handleSetConfirmBookingForm = (key: string, value: string) => {
    const updatedForm: any = { ...confirmBookingForm };
    if (value === "") {
      updatedForm[key] = {
        ...updatedForm[key],
        value,
        error: true,
      };
    } else {
      updatedForm[key] = { ...updatedForm[key], value, error: false };
    }
    setConfirmBookingForm(updatedForm);
  };

  const handleConfirmBooking = async (is_consented: boolean = false) => {
    const errorField = Object.keys(confirmBookingForm).find((key) => {
      const fieldName = key as EConfirmBookingFormField;
      return !externalVisit
        ? key !== EConfirmBookingFormField.firstName &&
            key !== EConfirmBookingFormField.lastName &&
            confirmBookingForm[fieldName]?.value === ""
        : confirmBookingForm[fieldName]?.value === "";
    }) as EConfirmBookingFormField;

    if (errorField) {
      return setConfirmBookingForm({
        ...confirmBookingForm,
        [errorField]: {
          ...confirmBookingForm[errorField],
          error: true,
        },
      });
    }

    try {
      const bookingData = contructBookingData(
        timeSlot,
        selectedCoach,
        confirmBookingForm.noteForCoach?.value,
        is_consented,
        undefined,
        tripartiteEmail,
      );

      setIsLoading(true);

      const res = await createBooking(bookingData);
      if (res.error === false) {
        handleStepNavigation(true, true);
        localStorage.removeItem("coach");
        localStorage.removeItem("objectives");
        localStorage.removeItem("selfReflectionQuizDone");
      } else {
        toast.error(t("slot_occupied"));
      }
    } catch (err: any) {
      const e = err as AxiosError<any>;
      toast.error(e?.response?.data.message);
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-5/6">
      <ConfirmBooking
        selectedCoach={selectedCoach}
        timeSlot={timeSlot}
        confirmBookingForm={confirmBookingForm}
        handleSetConfirmBookingForm={handleSetConfirmBookingForm}
        handleConfirmBooking={handleConfirmBooking}
        externalVisit={externalVisit}
        tripartiteEmail={tripartiteEmail}
        setTripartiteEmail={setTripartiteEmail}
        isOnboarding
      />
      <div className="w-full flex flex-row justify-between">
        <ActionButton label={"Back"} onClick={onBackBtnClick} />
        <ActionButton
          label={"Confirm"}
          onClick={() => {
            if (!timeSlot || !timeSlot.time) return;
            handleConfirmBooking();
          }}
        />
      </div>
    </div>
  );
};

export default ConfirmBookingContainer;
