import {FC, useEffect, useState} from 'react'

import MailTo from "../../MailTo";

import ProfileCardSimple from '../ProfileCardSimple'
import {useNavigate, useSearchParams} from 'react-router-dom';
import moment from 'moment';

import {useSteps} from 'react-step-builder';
import {ITeamsAuthData} from "../../../../../models/ITeamsAuth";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";
import {jumpToDeepLink} from "../../../../../utils/helpers/teams";
import {createBooking} from "../../../../../services/api/teams/BookingActions";
import {isKeyExists} from "../../../../../utils/helpers/object";

interface Props {
    bookingData?: any
}

const schema = [
    'first_name',
    'last_name',
    'comment'
]

const BookingDetails: FC<Props> = (props) => {
    let user: ITeamsAuthData = JSON.parse(localStorage.getItem('ch_user') || '{}') as ITeamsAuthData

    const {t} = useTranslation('booking')
    const {jump} = useSteps()
    const [bookingData, setBookingData] = useState<any>({
        first_name: user.first_name,
        last_name: user.last_name ? user.last_name : " "
    })
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    console.log("getting user obj")

    useEffect(() => {
        if (!searchParams.get('coach_id')) {
            return jump(1)
        }

        if (!searchParams.get('date') || !searchParams.get('time')) {
            jump(2)
        }

    }, [jump])

    useEffect(() => {
        setBookingData({
            ...bookingData, ...{
                date: searchParams.get('date'),
                time: searchParams.get('time'),
                coach_id: searchParams.get('coach_id'),
                coach_timezone: searchParams.get('coach_timezone'),
                reschedule: searchParams.get('reschedule'),
                meeting_id: searchParams.get('meeting_id'),
            }
        })
    }, [searchParams, setBookingData])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        setBookingData({
            ...bookingData, ...{
                [e.target.name]: e.target.value
            }
        })
    }

    const validate = () => {
        let valid = true
        let vErrors = {}

        schema.map(input => {
            if (!bookingData[input]) {
                valid = false
                vErrors = {
                    ...vErrors,
                    ...{
                        [input]: 'This field is required.'
                    }
                }
            }
        })

        setErrors(vErrors)

        return valid
    }

    const handleSubmit = () => {
        if (!validate()) {
            return
        }
        setLoading(true)

        let times = bookingData['time'].split('-')
        let startTime = times[0]
        let endTime = times[1]

        let data = {
            user: {
                first_name: bookingData['first_name'],
                last_name: bookingData['last_name'],
                user_comment: bookingData['comment'],
                email: user.email,
                medium: "teams",
                SSO_token: user.teams_token

            },
            event: {
                start_time: formatUTC(bookingData['date'] + ' ' + startTime),
                end_time: formatUTC(bookingData['date'] + ' ' + endTime)
            },
            company_id: user.company_id,
            coach_id: bookingData['coach_id'],
            user_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            coach_time_zone: bookingData['coach_timezone'],
            reschedule: (bookingData['reschedule'] == "true"),
            meeting_id: bookingData['meeting_id'] ? bookingData['meeting_id'] : undefined
        }


        createBooking(data)
            .then(res => {
                setLoading(false)
                if (res.error === false) {
                    navigate('/booking/success')
                } else {
                    toast.error(t("slot_occupied"));
                }
            }).catch(err => {
            setLoading(false)
            // toast.error(err.response.data.message)

            toast((t) => (
                <div className="flex flex-col gap-3">
                    {err.response.data.message}
                    <div className="flex-row flex gap-3">

                        <MailTo email={err.response.data.message.match(/\(([^)]+)\)/)[1] || ''}
                                subject="Talk with Coachello"
                                body="I am currently enjoying a coaching program at Coachello.
              I’m benefiting hugely from the coaching sessions on a professional and personal level,
               but now I have reached the maximum number of sessions.
                Would it be possible to receive more coaching sessions?
                 If so, could you please write to Coachello for me? \n Kind regards,">
                            <button className="col-span-3 px-4 py-2 text-white rounded bg-red md:col-span-1">
                                E-mail HR
                            </button>
                        </MailTo>

                        <button className="col-span-3 px-4 py-2 text-white rounded bg-red md:col-span-1"
                                onClick={() => jumpToDeepLink("conversations")}>
                            Talk with Coachello
                        </button>
                    </div>
                </div>
            ));
            console.error(err.response)
        });
    }

    const formatUTC = (date: string) => {
        return moment(date).format('YYYY-MM-DDTHH:mm:ssZ')
    }

    return (
        <div className="flex flex-col w-full lg:flex-row">
            <ProfileCardSimple/>

            <div className="container flex justify-between w-full">
                <div
                    className="flex flex-col items-center justify-start w-full py-16 bg-white rounded-lg shadow lg:mx-6 px-4 lg:px-5">
                    <h3 className="font-bold">{t('details.text_1')}</h3>
                    <form action="" className="w-full grid grid-cols-2 gap-5 lg:w-8/12 mt-14">
                        <div className="col-span-2">
                            <label className="block text-sm font-medium text-gray-700">{t('details.text_2')}</label>
                            <div className="mt-1">
                                <input disabled={true} value={user.first_name} onChange={e => handleInputChange(e)}
                                       type="text"
                                       name="first_name"
                                       placeholder={t('details.text_3')} id="first-name" autoComplete="given-name"
                                       className="block w-full px-3 py-2 border border-gray-600 rounded shadow-sm sm:text-sm placeholder:font-light"/>
                                {isKeyExists(errors, 'first_name') ?
                                    <small
                                        className="text-sm font-light text-red-400">{t('details.text_4')}</small> : ''}
                            </div>
                        </div>
                        <div style={{display: "none"}}>
                            <label className="block text-sm font-medium text-gray-700">Last name</label>
                            <div className="mt-1">
                                <input disabled={true} value={user.last_name} onChange={e => handleInputChange(e)}
                                       type="text"
                                       name="last_name"
                                       placeholder="Enter your last name" id="last-name" autoComplete="given-name"
                                       className="block w-full px-3 py-2 border border-gray-600 rounded shadow-sm sm:text-sm placeholder:font-light"/>
                                {isKeyExists(errors, 'last_name') ?
                                    <small className="text-sm font-light text-red-400">This field is
                                        required</small> : ''}
                            </div>
                        </div>
                        <div className="col-span-2 mt-4">
                            <label
                                className="block text-sm font-medium text-gray-700">{user.company_name == 'HEC' ? t('details.text_5_1') : t('details.text_5')}*</label>
                            <div className="mt-1">
                                <textarea onChange={e => handleInputChange(e)} rows={6} name="comment"
                                          placeholder={user.company_name == 'HEC' ? t('details.text_6_1'):t('details.text_6')}
                                          id="first-name" autoComplete="given-name"
                                          className="block w-full px-3 py-2 border border-gray-600 rounded shadow-sm sm:text-sm placeholder:font-light"></textarea>
                                {isKeyExists(errors, 'comment') ?
                                    <small
                                        className="text-sm font-light text-red-400">{t('details.text_4')}</small> : ''}
                            </div>
                        </div>
                        <div className="flex items-center justify-end col-span-2 mt-4">
                            <button type="button" onClick={handleSubmit}
                                    className="px-10 py-2 text-white rounded bg-red placeholder:font-light"
                                    disabled={loading}>{loading ? 'Confirming...' : 'Confirm'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default BookingDetails;
